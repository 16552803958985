import classNames from 'classnames';
import style from './Loading.module.scss';

export const Loader: React.FC = () => {
    return (
        <div className="fixed z-[200] w-full h-full left-0 top-0 bg-[#FFF] flex justify-center items-center">
            <div className="loader-beyim flex flex-col items-center w-1/2">
                <img
                    src="/icons/brandlogo.svg"
                    alt="Logo"
                    width={100}
                    height={24}
                />
                <div
                    className={classNames(
                        'w-[160px] h-1 rounded-lg justify-start mx-0 my-4',
                    )}
                >
                    <div
                        className={classNames(
                            style.progress,
                            'relative left-0 rounded-[4px] h-1 w-[60px]',
                        )}
                    ></div>
                </div>
            </div>
        </div>
    );
};
