import React from 'react';
import styles from './ErrorScreenWrapper.module.scss';
import { EmptyFeedScreen } from './utils/EmptyScreen';
import { ErrorScreen } from './utils/ErrorScreen';
import { PageNotFoundScreen } from './utils/PageNotFoundScreen';

type Props = {
    type: 'empty' | 'error' | '404';
};

export const ErrorScreenWrapper = (props: Props) => {
    return (
        <div
            className={`flex w-100 h-[400px] p-8 gap-4 justify-center items-center flex-shrink-0 sm:rounded-2xl ${styles.root} z-10`}
        >
            {props.type === 'empty' ? (
                <EmptyFeedScreen />
            ) : props.type === '404' ? (
                <PageNotFoundScreen />
            ) : (
                <ErrorScreen />
            )}
        </div>
    );
};
