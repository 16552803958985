import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
export const SkeletonDetailAssessmentResults: React.FC = () => {
    return (
        <>
            {new Array(10).fill(0).map(index => (
                <div
                    key={index}
                    className=" w-full px-5 border-b-[1px]  border-x-0 border-[#E9F0F3]"
                >
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <div className="w-full md:w-[70%] mb-1">
                            <Skeleton
                                baseColor="#e9f0f3"
                                count={1}
                                borderRadius={12}
                                height={20}
                                width={'50%'}
                            />
                            <Skeleton
                                borderRadius={12}
                                baseColor="#e9f0f3"
                                count={1}
                                height={20}
                                width={'70%'}
                            />
                        </div>
                        <div className=" w-full md:w-[30%] text-start md:text-end mb-1">
                            <Skeleton
                                borderRadius={12}
                                baseColor="#e9f0f3"
                                count={1}
                                height={20}
                                width={'50%'}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
