import { useTranslation } from 'react-i18next';
import { AccordionBlock } from './utils/AccordionBlock';

export const DetailInfoForStudent: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="p-6 bg-white rounded-2xl overflow-auto">
            <table className="w-full border-0">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="border-[#E9F0F3] border-b-[1px] text-lg border-t-0 border-x-0 ps-0 pt-0 pb-6 pe-2 text-left text-[#364954]  font-medium leading-[normal] whitespace-nowrap"
                            colSpan={1}
                        >
                            {t('statistics.CompletedSections')}
                        </th>
                        <th
                            scope="col"
                            className="border-[#E9F0F3] border-b-[1px] border-t-0 border-x-0 ps-0 pt-0 pb-6 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal] whitespace-nowrap"
                        >
                            {t('statistics.AssessmentForSection')}
                        </th>
                        <th
                            scope="col"
                            className="border-[#E9F0F3] border-b-[1px] border-t-0 border-x-0 ps-0 pt-0 pb-6 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                        >
                            {t('statistics.topicsInTheSection')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <AccordionBlock />
                </tbody>
            </table>
        </div>
    );
};
