import React, { useEffect, useState } from 'react';
import style from './TypeOrganization.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useNormalizedLocale } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';

import { Class } from '../../../types/organizationsApiType/GetCheckCodeStudentResponseApiType';

import {
    useGetOrganizationDataByIdQuery,
    useLazyGetAllOrganizationsQuery,
} from '../../../api/organizationService/organizationApiType';
import { Organization } from '../../../types/organizationsApiType/GetCheckCodeTeacherResponseApiType';
import { SelectBeyim } from '../../../ui/SelectBeyim/SelectBeyim';
import { LabelLayout } from '../../AuthForms/components/LabelLayout';

interface TypeOrganizationProps {
    setOrganizationData: (data: Organization | undefined) => void;
    organizationData: Organization | undefined;
    classData: Class | undefined;
    setClassData: (data: Class) => void;
}

export const TypeOrganization: React.FC<TypeOrganizationProps> = ({
    setOrganizationData,
    organizationData,
    classData,
    setClassData,
}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const locale = useNormalizedLocale();
    const [wrongOrgPressed, setWrongOrgPressed] = React.useState(false);

    const [selectedValue, setSelectedValue] = useState<number>();

    const organization_id = searchParams.get('organization_id');
    const { data: organization } = useGetOrganizationDataByIdQuery(
        organization_id || '',
        {
            skip: !organization_id,
        },
    );
    const [getAllOrganizations, { data: organizations }] =
        useLazyGetAllOrganizationsQuery();

    useEffect(() => {
        if (wrongOrgPressed || (!organizationData && !organization_id)) {
            getAllOrganizations({
                limit: 1000,
            });
        }
    }, [wrongOrgPressed]);

    useEffect(() => {
        if (selectedValue) {
            setOrganizationData(
                organizations?.data.organizations.find(
                    org => org.id === selectedValue,
                ),
            );
        }
    }, [selectedValue]);

    return (
        <div className="w-full">
            <section
                className={classNames(
                    'rounded-2xl px-[16px] py-[8px] flex gap-3 items-center w-full',
                    style.wrapper,
                )}
            >
                <img
                    src={
                        organizationData?.iconUrl ||
                        organization?.data.organization.iconUrl ||
                        '/icons/default-org.svg'
                    }
                    alt="Beyim"
                    width={32}
                    height={32}
                />
                <div
                    className={classNames(
                        'w-[2px] h-[24px] rounded-[10px]',
                        style.verticalLine,
                    )}
                />
                <div className="w-full">
                    <span
                        className={classNames(
                            'm-0 font-medium text-xs leading-[normal] !h-[15px]',
                            style.spanName,
                        )}
                    >
                        {t('authorization.organization')}
                    </span>
                    <p
                        className={classNames(
                            'm-0 font-medium text-sm leading-[normal]',
                            style.pName,
                        )}
                    >
                        {classData?.school.organization.translations.find(
                            item => item.locale === locale,
                        )?.name ||
                            classData?.school.organization.translations[0]
                                .name ||
                            organization?.data.organization.translations.find(
                                item => item.locale === locale,
                            )?.name ||
                            organization?.data.organization.translations[0]
                                .name ||
                            organizationData?.translations.find(
                                item => item.locale === locale,
                            )?.name ||
                            organizationData?.translations[0].name}
                    </p>
                </div>
            </section>
            {/* <button
                onClick={() => setWrongOrgPressed(prev => !prev)}
                className={classNames(
                    'bg-transparent font-medium text-sm leading-[normal] mt-[8px]',
                    style.buttonWrong,
                )}
            >
                {t('authorization.wrongOrganization')}
            </button> */}
            {wrongOrgPressed && (
                <div className="mt-[8px] !w-full">
                    <LabelLayout
                        labelText={t('authorization.selectOrganization')}
                    >
                        <SelectBeyim
                            options={organizations?.data.organizations.map(
                                org => ({
                                    value: org.id,
                                    label:
                                        org.translations.find(
                                            (item: any) =>
                                                item.locale === locale,
                                        )?.name || org.translations[0].name,
                                }),
                            )}
                            size="md"
                            placeholder={t('authorization.selectOrganization')}
                            onChange={val => {
                                setSelectedValue(val?.value);
                                setSearchParams({
                                    organization_id:
                                        val?.value.toString() || '',
                                });
                            }}
                        />
                    </LabelLayout>
                </div>
            )}
            <hr className="!my-[20px]" />
        </div>
    );
};
