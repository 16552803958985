import classNames from 'classnames';
import style from './LabelLayout.module.scss';
export interface LabelLayoutProps {
    children: React.ReactNode;
    labelText: string;
    className?: string;
    classNameLabel?: string;
}

export const LabelLayout: React.FC<LabelLayoutProps> = ({
    children,
    labelText,
    className,
    classNameLabel,
}) => {
    return (
        <div className={classNames('w-full flex flex-col relative', className)}>
            <label
                htmlFor={labelText}
                className={classNames(
                    'font-medium text-base leading-[normal] mb-2',
                    style.word,
                    classNameLabel,
                )}
            >
                {labelText}
            </label>
            {children}
        </div>
    );
};
