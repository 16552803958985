import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Tooltip, TooltipRefProps } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css';
import style from './AssessmentProperties.module.scss';

import { usePostGetProgressMutation } from '../../../../api/beyimProgress';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { nanoid } from '@reduxjs/toolkit';

export const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0',
    )}:${String(seconds).padStart(2, '0')}`;
};

export enum AssessmentPropertiesEnum {
    TIME = 10,
    MINUTES = 60,
}

export interface AssessmentPropertiesProps {
    totalQuestions: number;
    currentQuestion: number;
    setIsCompleted?: (isCompleted: boolean) => void;
    isLoadingQuestions: boolean;
}

export const AssessmentProperties: React.FC<AssessmentPropertiesProps> = ({
    currentQuestion,
    totalQuestions,
    setIsCompleted,
    isLoadingQuestions,
}) => {
    const [tooltipId, setTooltipId] = useState<string>(nanoid());
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const { progressId, id } = useParams();
    const navigate = useNavigate();

    const [time, setTime] = useState<number>(0);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [tooltipClosed, setTooltipClosed] = useState<boolean>(false);

    const [postGetProgress, { data: questionsWithProgress }] =
        usePostGetProgressMutation();

    useEffect(() => {
        postGetProgress({
            progress_id: Number(progressId),
        })
            .unwrap()
            .then(res => {
                if (res.data.progress.completed_at !== null) {
                    navigate(
                        `/feed/assessment/${id}/results/${progressId}?${searchParams.toString()}`,
                    );
                }
            });
    }, []);

    useEffect(() => {
        if (questionsWithProgress?.data.progress.due) {
            const now_time = new Date().getTime();
            const due_time = new Date(
                questionsWithProgress.data.progress.due,
            ).getTime();
            const timeDifferenceInSeconds = Math.floor(
                (due_time - now_time) / 1000,
            );
            setTime(timeDifferenceInSeconds);
            if (
                questionsWithProgress.data.progress.has_passed === true ||
                questionsWithProgress.data.progress.has_passed === false
            ) {
                navigate(
                    `/feed/assessment/${id}/results/${progressId}?${searchParams.toString()}`,
                );
            }
        }
    }, [questionsWithProgress]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime: any) => {
                if (prevTime < 0) {
                    clearInterval(interval);
                    setIsCompleted && setIsCompleted(true);
                    return 0;
                } else if (
                    prevTime <= AssessmentPropertiesEnum.TIME * 60 + 1 &&
                    !tooltipClosed
                ) {
                    setShowTooltip(true);
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [tooltipClosed]);

    const handleCloseTooltip = () => {
        setShowTooltip(false);
        setTooltipClosed(true);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                showTooltip &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                setShowTooltip(false);
                setTooltipClosed(true);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, showTooltip]);

    return (
        <div className="flex md:gap-6">
            <div
                data-tooltip-id={`my-tooltip-${tooltipId}`}
                className="min-w-20"
            >
                <p
                    className={classNames(
                        'whitespace-nowrap m-0 text-xs font-medium leading-[16.8px]',
                        style.leftTime,
                    )}
                >
                    {t('mobile.timer')}:
                </p>
                <span
                    className={`text-base font-bold ${
                        time <= AssessmentPropertiesEnum.TIME * 60 &&
                        style.leftTime
                    }`}
                >
                    {isLoadingQuestions ? (
                        <Skeleton
                            baseColor="#e9f0f3"
                            width={70}
                            borderRadius={10}
                        />
                    ) : time === 0 ? (
                        '-'
                    ) : time < 0 ? (
                        t('assessment.limitTime')
                    ) : (
                        formatTime(time)
                    )}
                </span>
            </div>
            <div
                className={classNames(
                    `sm:block hidden h-6 m-auto`,
                    style.divider,
                )}
            />
            <div className="hidden sm:block">
                <p className="whitespace-nowrap m-0 text-xs font-medium">
                    {t('assessment.questions')}:
                </p>
                <span className={`text-base font-bold`}>
                    {isLoadingQuestions ? (
                        <Skeleton
                            baseColor="#e9f0f3"
                            width={70}
                            borderRadius={10}
                        />
                    ) : (
                        currentQuestion + 1 + '/' + totalQuestions
                    )}
                </span>
            </div>
            {showTooltip && (
                <div ref={ref}>
                    <Tooltip
                        id={`my-tooltip-${tooltipId}`}
                        isOpen={showTooltip}
                        noArrow={false}
                        clickable={true}
                        className={style.wrapperTooltip}
                        opacity={1}
                        offset={15}
                    >
                        <div
                            className={`flex gap-1 items-center text-sm font-medium ${style.tooltip}`}
                        >
                            {t('assessment.tooltipFinishTime')}
                            <img
                                className="cursor-pointer hidden md:block"
                                src=" /icons/xTest.svg"
                                alt="AssessmentProperties"
                                onClick={handleCloseTooltip}
                            />
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
