import { FC } from 'react';
import { SelectBeyim } from '../../ui/SelectBeyim/SelectBeyim';
import { Subject } from '../../types/GetSubjectResponseApiType';
import { useGetAllSubjectsQuery } from '../../api';
import { useSearchParams } from 'react-router-dom';
import { useNormalizedLocale } from '../../hooks';

export const SubjectSelect: FC = () => {
    const { data: subjects } = useGetAllSubjectsQuery({ limit: 100 });
    const [searchParams, setSearchParams] = useSearchParams();

    const locale = useNormalizedLocale();
    const formatOptionLabel = (data: Subject) => {
        const label = data.translations.find(t => t.locale === locale)?.name;
        return (
            <div
                className={
                    'flex gap-1 items-center overflow-hidden max-w-[100%] text-ellipsis'
                }
            >
                {data.icon_url ? (
                    <img
                        src={data.icon_url}
                        alt="icon"
                        className={'w-8 h-8 rounded-full'}
                    />
                ) : (
                    <div className="w-8 h-8 rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium text-[18px]">
                        {label?.charAt(0).toUpperCase()}
                    </div>
                )}

                <p
                    className={
                        'text-sm font-normal leading-normal text-[#364954] text-ellipsis overflow-hidden '
                    }
                >
                    {label}
                </p>
            </div>
        );
    };

    return (
        <SelectBeyim<Subject>
            isDisabled={searchParams.get('type') === 'results'}
            size={'sm'}
            isSearchable={false}
            options={subjects?.data.subjects}
            formatOptionLabel={formatOptionLabel}
            value={subjects?.data.subjects.find(
                s => s.id === Number(searchParams.get('subject_id')),
            )}
            isOptionSelected={(option, selectValue) => {
                return selectValue.some(s => s.id === option.id);
            }}
            className={'disabled:opacity-50'}
            onChange={s => {
                if (s) {
                    setSearchParams(
                        prev =>
                            new URLSearchParams({
                                ...Object.fromEntries(prev),
                                subject_id: String(s.id),
                            }),
                    );
                }
            }}
        />
    );
};
