import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import style from './SubjectsFilter.module.scss';
import classNames from 'classnames';
import { Button } from './utils';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useGetAllSubjectsQuery } from '../../api';
import { useSearchParams } from 'react-router-dom';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import { roles } from '../AuthForms/constants';

interface ISubjectsFilter {}

export const SubjectsFilter: React.FC<ISubjectsFilter> = ({ ...props }) => {
    const { t } = useTranslation();
    const { data: subjects, isFetching: isFetchingSubjects } =
        useGetAllSubjectsQuery({ limit: 100 });

    const { data: myData } = useGetUserDataQuery('');

    const [searchParams, setSearchParams] = useSearchParams();
    const [isDone, setIsDone] = useState(false);
    const locale = useNormalizedLocale();

    const onChangeSubject = (id?: string) => {
        setSearchParams(prev => {
            return {
                idContent: prev.get('contentId') || '',
                fromSearch: prev.get('fromSearch') || '',
                subject: id || '',
                sectionsBySubject: '',
                them: '',
                idActivity: '',
                scroll: '',
            };
        });
    };
    const [shouldScroll, setShouldScroll] = useState(false);
    const activeElementRef = useRef<any>(null);
    const containerRef = useRef<any>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setShouldScroll(!entry.isIntersecting);
            },
            { threshold: 0.5 },
        );
        if (activeElementRef.current) {
            observer.observe(activeElementRef.current);
        }

        return () => {
            if (activeElementRef.current) {
                observer.unobserve(activeElementRef?.current);
            }
        };
    }, [subjects]);

    useEffect(() => {
        if (shouldScroll && activeElementRef.current && !isDone) {
            activeElementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
            setIsDone(true);
        }
    }, [shouldScroll]);

    useEffect(() => {
        const container = containerRef.current.container.current;
        const onWheel = (e: any) => {
            if (e.deltaY === 0) return;
            e.preventDefault();
            container.scrollLeft += e.deltaY;
        };

        container.addEventListener('wheel', onWheel);
        return () => {
            container.removeEventListener('wheel', onWheel);
        };
    }, []);

    const clickOnSubject = (id: string) => {
        return searchParams.get('subject') !== id ? true : false;
    };
    return (
        <div
            className={classNames(
                style.wrapper,
                'overflow-hidden sm:rounded-2xl h-[99px]  sm:shadow-lg',
            )}
            {...props}
        >
            <ScrollContainer
                style={{
                    overflowX: 'auto',
                }}
                //@ts-ignore
                ref={containerRef}
            >
                <div className="flex px-2 h-full">
                    <Button
                        imgSrc="/icons/allFeed.svg"
                        name={t('feed.allFeed')}
                        isActive={
                            searchParams.get('subject') === '' ? true : false
                        }
                        disabled={myData?.data?.User?.organizations?.some(
                            r => r?.role === roles.EXPERT,
                        )}
                        onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'feed_subject',
                                    feed_name: 'allFeed',
                                    feed_id: 'allFeed',
                                    locale: locale,
                                },
                                dataLayerName: 'feed',
                            });
                            if (!searchParams.get('subject')) return;
                            onChangeSubject('');
                        }}
                        onMouseDown={() => {
                            if (!searchParams.get('subject')) return;
                            onChangeSubject('');
                        }}
                        onTouchStart={() => {
                            if (!searchParams.get('subject')) return;
                            onChangeSubject('');
                        }}
                    />
                    {subjects?.data.subjects.map(item => {
                        return (
                            <div
                                key={item.id}
                                //@ts-ignore
                                ref={
                                    searchParams.get('subject') ===
                                    String(item.id)
                                        ? activeElementRef
                                        : null
                                }
                            >
                                <Button
                                    key={item.id}
                                    imgSrc={
                                        item.icon_url || '/icons/noIcon.svg'
                                    }
                                    disabled={
                                        myData?.data?.User?.organizations?.some(
                                            r => r?.role === roles.EXPERT,
                                        )
                                            ? item.id !== 1
                                            : false
                                    }
                                    isActive={
                                        searchParams.get('subject') ===
                                        String(item.id)
                                            ? true
                                            : false
                                    }
                                    name={
                                        item.translations.find(
                                            lang => lang.locale === locale,
                                        )?.name
                                    }
                                    onClick={() => {
                                        const subject_name =
                                            item.translations.find(
                                                lang => lang.locale === 'en',
                                            )?.name;
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'feed_subject',
                                                feed_name: subject_name,
                                                feed_id: item.id,
                                                locale: locale,
                                            },
                                            dataLayerName: 'feed',
                                        });
                                        clickOnSubject(String(item.id)) &&
                                            onChangeSubject(String(item.id));
                                    }}
                                    onMouseDown={() => {
                                        clickOnSubject(String(item.id)) &&
                                            onChangeSubject(String(item.id));
                                    }}
                                    onTouchStart={() => {
                                        clickOnSubject(String(item.id)) &&
                                            onChangeSubject(String(item.id));
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </ScrollContainer>
        </div>
    );
};
