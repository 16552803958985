import React, { useState } from 'react';
import style from './CreatePasswordForm.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreatePasswordMutation } from '../../../../api/authService/authServiceApi';
import { toast } from 'react-toastify';
import { states } from '../../constants';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { expires } from '../../../../constants/expires';
import { LabelLayout } from '../../../AuthForms/components/LabelLayout';
import { Button } from '../../../AuthForms/components/ButtonComponent';

interface IFormInput {
    password: string;
    confirmPassword: string;
}

interface IProps {
    setState: React.Dispatch<React.SetStateAction<states>>;
}

export const CreatePasswordForm: React.FC<IProps> = ({ setState }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean | null>(null);
    const [showConfirmPassword, setShowConfirmPassword] = useState<
        boolean | null
    >(null);

    const [createPassword] = useCreatePasswordMutation();

    const schema = yup
        .object({
            password: yup
                .string()
                .required(t('authorization.form.passwordRequired')),

            confirmPassword: yup
                .string()

                .oneOf(
                    //@ts-ignore
                    [yup.ref('password'), null],
                    t('authorization.form.mustMatch'),
                )
                .required(t('authorization.form.confirmPasswordRequired')),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormInput> = data => {
        const session = localStorage.getItem('session');
        const challenge_name = localStorage.getItem('challenge_name');

        const dataForm = {
            password: data.password,
            challengeName: challenge_name ? challenge_name : '',
            session: session ? session : '',
            username: searchParams.get('username') || '',
        };

        createPassword(dataForm)
            .unwrap()
            .then(res => {
                localStorage.removeItem('session');
                localStorage.removeItem('challenge_name');
                toast.success(t('authorization.passwordSuccess'));
                Cookies.set('access_token', res.data.tokenData.access_token, {
                    expires,
                    // secure: true,
                });
                Cookies.set('id_token', res.data.tokenData.id_token, {
                    expires,
                    // secure: true,
                });
                Cookies.set('refresh_token', res.data.tokenData.refresh_token, {
                    expires: 30,
                    // secure: true,
                });
                setSearchParams({ username: '', code: '' });
                setState(states.personal_data);
            })
            .catch(e => {
                toast.error(t('authorization.errorPassword'));
                setState(states.login);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-wrap justify-between gap-4 mb-6">
                <LabelLayout labelText={t('authorization.createPassword')}>
                    <input
                        className={classNames(
                            'rounded-2xl text-sm pl-4 py-4',
                            style.input,
                            style.word,
                        )}
                        {...register('password')}
                        placeholder={t(
                            'authorization.form.passwordPlaceholder',
                        )}
                        autoComplete="off"
                        type={showPassword ? '' : 'password'}
                    />
                    <button
                        type="button"
                        className={classNames('absolute right-4 top-[42px]')}
                        onClick={() => setShowPassword(prev => !prev)}
                    >
                        <img
                            className="ad-icon"
                            src={
                                showPassword
                                    ? '/icons/visible-password-active.svg'
                                    : '/icons/visible-password.svg'
                            }
                            alt="Toggle password visibility"
                        />
                    </button>
                    {errors['password'] && (
                        <p className="text-red-500 text-xs mt-1">
                            {errors['password']?.message}
                        </p>
                    )}{' '}
                </LabelLayout>

                <LabelLayout labelText={t('authorization.form.passwordAgain')}>
                    <input
                        className={classNames(
                            'rounded-2xl text-sm pl-4 py-4',
                            style.input,
                            style.word,
                        )}
                        {...register('confirmPassword')}
                        placeholder={t(
                            'authorization.form.passwordPlaceholder',
                        )}
                        autoComplete="off"
                        type={showConfirmPassword ? '' : 'password'}
                    />

                    <button
                        type="button"
                        className={classNames('absolute right-4 top-[42px]')}
                        onClick={() => setShowConfirmPassword(prev => !prev)}
                    >
                        <img
                            className="ad-icon"
                            src={
                                showConfirmPassword
                                    ? '/icons/visible-password-active.svg'
                                    : '/icons/visible-password.svg'
                            }
                            alt="Toggle password visibility"
                            width={24}
                            height={24}
                        />
                    </button>

                    {errors['confirmPassword'] && (
                        <p className="text-red-500 text-xs mt-1">
                            {errors['confirmPassword']?.message}
                        </p>
                    )}
                </LabelLayout>
            </div>

            <Button
                text={t('authorization.next')}
                variant={isValid ? 'primary' : 'disabled'}
                type="submit"
            />
        </form>
    );
};
