import { Tooltip } from 'react-tooltip';
import style from './HeaderPost.module.scss';
import classNames from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { FC, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ModalComponent } from '../../ui/ModalComponent';
import { useNormalizedLocale } from '../../hooks';
import { Post } from '../../types/GetContentsResponseApiType';
import { Daum } from '../../types/GetCustomFeedRequestApiType';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import { roles } from '../AuthForms/constants';
interface IHeaderPost {
    iconUrl?: string;
    objective?: string;
    subject?: string;
    id?: string;
    microptopic?: string;
    type?: 'assessment' | 'feed';
    assessmentType?: string | false;
    assessmentText?: string;
    assessmentState?: 'success' | 'default' | 'fail';
    dataPost?: Post;
    dataActivity?: Daum;
    activeIndex?: number;
}

export const HeaderPost: FC<IHeaderPost> = ({
    iconUrl,
    objective,
    subject,
    id,
    microptopic,
    type,
    assessmentType,
    assessmentText,
    assessmentState,
    dataPost,
    dataActivity,
    activeIndex = 0,
}) => {
    const locale = useNormalizedLocale();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const microNanoId = nanoid();
    const [showMenu, setShowMenu] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const idPost = nanoid();

    const { data: me } = useGetUserDataQuery('');

    const isAuthor = me?.data?.User?.organizations.some(
        org => org?.role === roles.AUTHOR,
    );
    const data = dataPost?.id ? dataPost : dataActivity;
    const subjectId = data?.subjectId ?? searchParams.get('subject');
    const category =
        data?.category === 'activity' && 'activities' in data
            ? data?.activities?.[activeIndex]?.id
            : 'post-editor';
    let contentId;

    if (data && 'activities' in data) {
        contentId = data?.activities?.[activeIndex]?.contentId;
    } else {
        contentId = data?.contentId;
    }
    const editLink = `${process.env.REACT_APP_ADMIN_MAIN}/${locale}/content/${subjectId}/${data?.microtopicId}/${locale}/${contentId}/${category}?redirectTo=/content/${subjectId}/${data?.microtopicId}/${locale}/${contentId}/${category}`;

    return (
        <header
            className={classNames(
                'flex gap-3 sm:p-0 justify-between',
                !assessmentType && 'px-4 mb-3',
            )}
        >
            <img
                src={iconUrl}
                alt={objective}
                className="w-10 h-10  grow-0 shrink-0 rounded-full overflow-hidden"
            />
            <div className="overflow-hidden flex items-center">
                {!type && (
                    <div>
                        <span
                            className="cursor-default leading-[19.6px] text-[#364954] text-sm font-bold items-center overflow-hidden line-clamp-2 text-ellipsis break-words"
                            data-tooltip-id={'objective' + microNanoId}
                        >
                            {microptopic}
                        </span>
                    </div>
                )}

                {type && (
                    <div className="flex flex-col">
                        <span
                            className={classNames(
                                style.type,
                                'font-bold text-sm leading-[19.6px] max-w-full overflow-hidden text-nowrap block whitespace-nowrap text-ellipsis',
                                style[`color-${assessmentState}`],
                            )}
                        >
                            {assessmentType}
                        </span>
                        <h3
                            className={classNames(
                                'font-bold text-sm m-0 leading-[19.6px]',
                                style.title,
                            )}
                        >
                            {assessmentText}
                        </h3>
                    </div>
                )}
            </div>
            <Tooltip
                id={'objective' + microNanoId}
                className={classNames(style.wrapperTooltip, 'drop-shadow-2xl')}
                clickable={true}
                place="bottom-end"
                opacity={1}
            >
                {microptopic}
            </Tooltip>
            <div className=" ms-auto grow-0 shrink-0 relative">
                {!type && (
                    <button data-tooltip-id={'data-post-info' + idPost}>
                        <img
                            src="/icons/information.svg"
                            alt="information"
                            className=" w-6 h-6 block"
                        />
                    </button>
                )}
                <Tooltip
                    id={'data-post-info' + idPost}
                    className={classNames(
                        style.wrapperTooltip,
                        'drop-shadow-2xl max-w-xs rounded-xl p-3',
                    )}
                    clickable={true}
                    opacity={1}
                    place={'bottom-end'}
                >
                    {objective}
                </Tooltip>
                <button
                    data-tooltip-id={'tooltip-info' + idPost}
                    data-tooltip-offset={20}
                >
                    <img
                        src="/icons/dots.svg"
                        alt="dots"
                        className=" w-6 h-6 block"
                    />
                </button>
                <Tooltip
                    id={'tooltip-info' + idPost}
                    className={classNames(
                        'drop-shadow-xl max-w-xs rounded-xl p-3',
                        style.help,
                    )}
                    clickable={true}
                    // isOpen={showMenu}
                    openOnClick={true}
                    opacity={1}
                    // offset={20}
                    noArrow={true}
                    place={'bottom-end'}
                >
                    <div
                        ref={dropdownRef}
                        className={classNames(
                            'w-[216px] rounded-xl  flex-col overflow-hidden hidden md:flex',
                            style.dropdown,
                        )}
                    >
                        {type !== 'assessment' && isAuthor && (
                            <Link
                                target="_blank"
                                to={editLink}
                                className={classNames(
                                    'h-10 flex items-center text-xs font-medium cursor-pointer   p-[10px] gap-2 leading-[140%] ',
                                    style.dropdown_option,
                                )}
                            >
                                <img
                                    src="/icons/EditIcon.svg"
                                    alt="edit"
                                    width={20}
                                    height={20}
                                />
                                {t('feed.editContent')}
                            </Link>
                        )}
                        <div
                            className={classNames(
                                'h-10 flex items-center text-xs font-medium cursor-pointer  p-[10px] gap-2 leading-[140%]',
                                style.dropdown_option,
                            )}
                            onClick={() => {
                                setIsOpenModal(true);
                                setShowMenu(false);
                            }}
                        >
                            <img
                                src="/icons/link.svg"
                                alt="link"
                                width={20}
                                height={20}
                            />
                            {t('feed.viewLiterature')}
                        </div>
                        <Link
                            target="_blank"
                            to="https://forms.office.com/r/DnVB0dBG9X"
                            className={classNames(
                                'h-10 flex items-center text-xs font-medium cursor-pointer  p-[10px] gap-2 leading-[140%]   ',
                                style.dropdown_option,
                            )}
                        >
                            <img
                                src="/icons/flag.svg"
                                alt="flag"
                                width={20}
                                height={20}
                            />
                            {t('feed.feedbackButton')}
                        </Link>
                    </div>
                    <div
                        className={classNames(
                            'w-[208px] rounded-lg  flex-col overflow-hidden md:hidden flex p-4 gap-4',
                        )}
                    >
                        <Link
                            target="_blank"
                            to="https://forms.office.com/r/DnVB0dBG9X"
                            className={classNames(
                                'text-sm whitespace-nowrap flex gap-2 items-center cursor-pointer',
                            )}
                        >
                            <img
                                src="/icons/headerFlag.svg"
                                alt="fwef"
                                className="w-4 h-4"
                            />
                            <span className="text-[#678AA1] text-xs leading-[16.8px] font-medium">
                                {t('feed.feedbackButton')}
                            </span>
                        </Link>
                        <div
                            className={classNames(
                                'text-sm whitespace-nowrap flex gap-2 items-center cursor-pointer',
                            )}
                            onClick={() => {
                                setIsOpenModal(true);
                                setShowMenu(false);
                            }}
                        >
                            <img src="/icons/bookOpen.svg" alt="fwef" />
                            <span className="text-[#678AA1] text-xs leading-[16.8px] font-medium">
                                {t('feed.viewLiterature')}
                            </span>
                        </div>
                    </div>
                </Tooltip>
            </div>

            <ModalComponent
                isOpen={isOpenModal}
                onClose={() => {
                    setIsOpenModal(false);
                }}
                classNameModal={style.modal}
                headerContent={
                    <h2
                        className={classNames(
                            'text-2xl font-normal',
                            style.titleModal,
                        )}
                    >
                        {t('feed.literature.title')}
                    </h2>
                }
                confirmText={t('feed.literature.confirmText')}
                content={
                    <div className="flex items-center justify-center flex-col mt-[76px] mb-[76px]">
                        <img
                            src="/icons/empty-literature-icon.svg"
                            alt="No literture icon"
                            className="w-[52px] h-[52px]"
                        />
                        <h3 className="text-base text-center font-bold mt-4 mb-2">
                            {t('feed.literature.infoTitle')}
                        </h3>
                        <p className="text-sm font-medium">
                            {t('feed.literature.infoDescription')}
                        </p>
                    </div>
                }
            />
        </header>
    );
};
