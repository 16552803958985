import { StudentProgress } from '../../../../features/StudentProgress';
import { ProgressBySection } from '../ProgressBySection';

export const dataTabsLabel = [
    { label: 'studentProgress', id: 'studentProgress' },
    { label: 'progressBySection', id: 'progressBySection' },
];

export const dataComponents = (classId: string, subjectId: string) => [
    {
        idLabel: 'studentProgress',
        children: <StudentProgress classId={classId} subjectId={subjectId} />,
    },
    {
        idLabel: 'progressBySection',
        children: <ProgressBySection classId={classId} subjectId={subjectId} />,
    },
];
