import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetAssessmentInfoQuery } from '../../../../api/beyimAssessmentApi';
import style from './SectionAssessment.module.scss';
import { useGetSectionsBySubjectQuery } from '../../../../api/programService';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import { useNormalizedLocale } from '../../../../hooks/useNormalizedLocale';
export const SectionAssessment: React.FC = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const locale = useNormalizedLocale();
    const [sectionId, setSectionId] = useState<string>('');

    const { data: sectionsBySubject } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const them =
        Number(searchParams.get('sectionsBySubject')) ||
        Number(
            sectionsBySubject?.data.sections.find(section => {
                return (
                    String(section.id) === searchParams.get('sectionsBySubject')
                );
            })?.children[0]?.id ||
                Number(sectionsBySubject?.data.sections[0]?.id) ||
                null,
        );

    const { data: sectionAssessmentInfo } = useGetAssessmentInfoQuery(
        {
            locale: locale,
            section_id: them,
            subject_id: Number(searchParams.get('subject')),
        },
        {
            skip: !searchParams.get('subject') || !them,
        },
    );

    return sectionAssessmentInfo &&
        sectionAssessmentInfo?.success &&
        sectionAssessmentInfo.data.assessment.subject_id ===
            Number(searchParams.get('subject')) ? (
        <div
            className={`rounded-xl flex px-4 py-2 flex-row items-center gap-1 justify-between ${
                style.assessmentThem
            } ${
                sectionAssessmentInfo?.data.assessment.progress
                    ? sectionAssessmentInfo?.data.assessment.progress
                          .percentage >=
                      sectionAssessmentInfo.data.assessment.threshold
                        ? style.successSectionAssessment
                        : style.failureSectionAssessment
                    : !sectionAssessmentInfo?.data.assessment.is_available
                      ? style.notAvailableAssessment
                      : ''
            }`}
        >
            <div>
                <span
                    className={classNames(
                        'text-sm font-bold flex items-center mb-1',
                        style.assessmentTitle,
                    )}
                >
                    {locale !== 'en' && locale !== 'kk'
                        ? t('assessment.page-title')
                        : ''}{' '}
                    {locale !== 'en' && locale !== 'kk'
                        ? t('assessment.for')
                        : ''}{' '}
                    {t('assessment.type.section')}
                </span>
                {sectionAssessmentInfo?.data.assessment.progress ? (
                    sectionAssessmentInfo?.data.assessment.progress
                        .percentage >=
                    sectionAssessmentInfo?.data.assessment.threshold ? (
                        <p
                            className={` text-xs font-normal ${style.assessmentDescription} ${style.descriptionSuccess}`}
                        >
                            {t('assessment.pointsEarned')}:{' '}
                            {
                                sectionAssessmentInfo.data.assessment.progress
                                    .percentage
                            }
                            % ({t('assessment.covered')})
                        </p>
                    ) : (
                        <p
                            className={` text-xs font-normal ${style.assessmentDescription} ${style.descriptionFail}`}
                        >
                            {t('assessment.pointsEarned')}:{' '}
                            {
                                sectionAssessmentInfo.data.assessment.progress
                                    .percentage
                            }
                            % ({t('assessment.notCovered')})
                        </p>
                    )
                ) : (
                    <p
                        className={` text-xs font-normal ${style.assessmentDescription}`}
                    >
                        {t('assessment.passing')}{' '}
                        {sectionAssessmentInfo?.data.assessment.threshold}%{' '}
                        {t('assessment.orMore')}
                    </p>
                )}
            </div>

            {sectionAssessmentInfo?.data.assessment.is_available ? (
                <Link
                    to={
                        sectionAssessmentInfo.data.assessment.progress
                            ? `/feed/assessment/${sectionAssessmentInfo?.data
                                  .assessment
                                  .id}/results/${sectionAssessmentInfo?.data
                                  .assessment.progress
                                  .id}?subject=${searchParams.get(
                                  'subject',
                              )}&them=${them}&sectionsBySubject=${
                                  searchParams.get('sectionsBySubject') || ''
                              }
                                  `
                            : `/feed/assessment/${sectionAssessmentInfo?.data
                                  .assessment.id}?subject=${searchParams.get(
                                  'subject',
                              )}&them=${them}&sectionsBySubject=${
                                  searchParams.get('sectionsBySubject') || ''
                              }
                            `
                    }
                    className={classNames(
                        'w-fit rounded-xl text-center text-sm font-semibold block px-3 py-2 lg:text-nowrap ',
                        style.sectionAssessmentLink,
                    )}
                >
                    {sectionAssessmentInfo?.data.assessment.progress
                        ? t(`assessment.results`)
                        : t(`assessment.pass`)}
                </Link>
            ) : (
                <img
                    className="cursor-pointer"
                    src={'/icons/locked.svg'}
                    alt="locked"
                    data-tooltip-id={'objectiveTooltip'}
                    data-tooltip-content={`${t('feed.willOpenAfterPassing')}`}
                />
            )}
            <Tooltip
                id="objectiveTooltip"
                className={classNames(style.wrapperTooltip)}
                offset={10}
                place="bottom"
                opacity={1}
            />
        </div>
    ) : null;
};
