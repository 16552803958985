import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import style from './Profile.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useAppDispatch, useNormalizedLocale } from '../../hooks';
import { logout } from '../slice/authSlice';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import Skeleton from 'react-loading-skeleton';
import { useGetAllSubjectsQuery } from '../../api';
import TagManager from 'react-gtm-module';
import { roles } from '../AuthForms/constants';

export const Profile: React.FC = () => {
    const { t } = useTranslation(); //локализация
    const locale = useNormalizedLocale();
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { data, isLoading } = useGetUserDataQuery('');

    const isStudent =
        (data?.data.User.organizations.length &&
            data?.data.User.organizations.some(
                org => org.role === roles.STUDENT,
            )) ||
        data?.data.User.email === 'student@beyim.nis.edu.kz';

    const { data: subjects } = useGetAllSubjectsQuery({ limit: 100 });

    return (
        <div>
            <button
                data-tooltip-id="profile"
                className="flex items-center"
                data-tooltip-offset={20}
            >
                {isLoading ? (
                    <Skeleton circle={true} height={32} width={32} />
                ) : data?.data.User.picture ? (
                    <img
                        src={data?.data.User.picture}
                        className="h-8 w-8 block rounded-full"
                        alt="student"
                    />
                ) : (
                    <div className="w-[32px] h-[32px] rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium text-[12px]">
                        {data?.data.User.firstName[0]?.toUpperCase()}
                        {data?.data.User.secondName[0]?.toUpperCase()}
                    </div>
                )}

                <img
                    src="/icons/arrow-top.svg"
                    className={classNames(
                        'block h-6 w-6 duration-200  me-2',
                        open ? '' : 'rotate-180',
                    )}
                    alt="arrow"
                />
            </button>
            <Tooltip
                id="profile"
                className={classNames(style.wrapperTooltip, 'drop-shadow-2xl')}
                openOnClick={true}
                clickable={true}
                noArrow={true}
                opacity={1}
                afterShow={() => {
                    setOpen(true);
                }}
                afterHide={() => {
                    setOpen(false);
                }}
                place={'bottom-end'}
            >
                <div className={classNames(style.tooltip)}>
                    <div className="p-4 w-80">
                        <div className="flex mb-2 items-center gap-2 ">
                            {isLoading ? (
                                <Skeleton
                                    circle={true}
                                    height={56}
                                    width={56}
                                />
                            ) : data?.data.User.picture ? (
                                <img
                                    src={data?.data.User.picture}
                                    className="w-14 h-14 block"
                                    alt="student"
                                />
                            ) : (
                                <div className="w-14 h-14 rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium text-[20px]">
                                    {data?.data.User.firstName[0]?.toUpperCase()}
                                    {data?.data.User.secondName[0]?.toUpperCase()}
                                </div>
                            )}
                            <div className="flex flex-col">
                                <span className="font-semibold text-sm">
                                    {data?.data.User.fullName ||
                                        t('profile.user')}
                                </span>
                                <span
                                    className={classNames(
                                        style.mail,
                                        ' text-xs',
                                    )}
                                >
                                    {data?.data.User.email ||
                                        'student@beyim.nis.edu.kz'}
                                </span>
                            </div>
                        </div>
                        <div className={classNames('rounded-lg', style.item)}>
                            <Link
                                to="/favorites"
                                onClick={() => {
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            event: 'my_actions',
                                            locale: locale,
                                        },
                                        dataLayerName: 'feed',
                                    });
                                }}
                                preventScrollReset
                            >
                                <span className="flex p-2 h-8 items-center">
                                    <img
                                        className="w-5 h-5 block me-2"
                                        src="/icons/myActions.svg"
                                        alt="myActions"
                                    />{' '}
                                    <span>{t('profile.myActions')}</span>
                                </span>
                            </Link>
                        </div>
                        {isStudent ? (
                            <div
                                className={classNames('rounded-lg', style.item)}
                            >
                                <Link
                                    to={`/dashboard-student?subject_id=${
                                        searchParams.get('subject')
                                            ? searchParams.get('subject')
                                            : subjects?.data?.subjects[0]?.id
                                    }&user_id=${data?.data.User
                                        ?.id}&class_id=${data?.data.User?.organizations.find(
                                        o => o.role === roles.STUDENT,
                                    )?.schools[0]?.classes[0]?.classId}`}
                                    preventScrollReset
                                >
                                    <span className="flex p-2 h-8 items-center">
                                        <img
                                            className="w-5 h-5 block me-2"
                                            src="/icons/myProgress.svg"
                                            alt="myActions"
                                        />
                                        <span>{t('profile.myProgress')}</span>
                                    </span>
                                </Link>
                            </div>
                        ) : null}
                        <hr
                            className={classNames(
                                'mt-2 mb-2 h-[1px]',
                                style.hr,
                            )}
                        />
                        <div
                            onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'exit',
                                        locale: locale,
                                    },
                                    dataLayerName: 'feed',
                                });
                                dispatch(logout());
                                navigate('/');
                            }}
                            className={classNames('rounded-lg', style.item)}
                        >
                            <button>
                                <span className="flex p-2 h-8 items-center">
                                    <img
                                        className="w-4 h4 block me-2"
                                        src="/icons/logout.svg"
                                        alt="logout"
                                    />{' '}
                                    <span>{t('profile.exit')}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};
