import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import style from './FormText.module.scss';

import { states } from '../constants';
import { TypeOrganization } from '../TypeOrganization';
import { Class } from '../../../types/organizationsApiType/GetCheckCodeStudentResponseApiType';
import { Organization } from '../../../types/organizationsApiType/GetCheckCodeTeacherResponseApiType';

interface FormTextProps {
    organizationData: Organization | undefined;
    setOrganizationData: (data: Organization | undefined) => void;
    classData: Class | undefined;
    setClassData: (data: Class) => void;
    title: string;
    description: string;
    setState: (state: states) => void;
    state: states;
}

export const FormText = ({
    title,
    description,
    setState,
    state,
    organizationData,
    setOrganizationData,
    classData,
    setClassData,
}: FormTextProps) => {
    const { t } = useTranslation();
    const handleBack = (state: states) => {
        switch (state) {
            case states.enter_code:
                setState(states.type_user);
                break;
            case states.change_password:
                setState(states.enter_code);
                break;
            case states.create_mail:
                setState(states.enter_code);
                break;
            default:
                setState(states.login);
                break;
        }
    };

    return (
        <div className="mb-6c max-w-[464px] w-full">
            {(state === states.enter_code || state === states.create_mail) && (
                <button
                    className={classNames(
                        ' flex items-center font-normal text-sm bg-transparent mb-[26px]',
                        style.colorDescription,
                    )}
                    onClick={() => handleBack(state)}
                >
                    <img
                        src="/icons/back.svg"
                        alt="Beyim"
                        width={24}
                        height={24}
                    />
                    {t('authorization.back')}
                </button>
            )}
            <div className="w-full">
                {(state === states.create_mail ||
                    state === states.personal_data ||
                    state === states.change_password) && (
                    <TypeOrganization
                        organizationData={organizationData}
                        setOrganizationData={setOrganizationData}
                        classData={classData}
                        setClassData={setClassData}
                    />
                )}
            </div>
            <div className="max-w-[426px] w-full mb-[24px]">
                <h2
                    className={classNames(
                        'flex font-bold text-2xl mb-[12px]',
                        style.colorTitle,
                    )}
                >
                    {title}
                </h2>
                <p
                    className={classNames(
                        'm-0 text-sm font-normal leading-5',
                        style.colorDescription,
                    )}
                >
                    {description}
                </p>
            </div>
        </div>
    );
};
