import React from 'react';
import style from './CheckForm.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';

import { states } from '../constants';

import { useNormalizedLocale } from '../../../hooks';

import { Class } from '../../../types/organizationsApiType/GetCheckCodeStudentResponseApiType';
import { useLazyGetCheckCodeStudentQuery } from '../../../api/organizationService/organizationApiType';
import { LabelLayout } from '../../AuthForms/components/LabelLayout';
import { Button } from '../../AuthForms/components/ButtonComponent';
interface IFormInput {
    classCode: string;
}
interface IProps {
    setState: React.Dispatch<React.SetStateAction<states>>;
    setClassData: (data: Class) => void;
}
export const ClassCodeCheck: React.FC<IProps> = ({
    setState,
    setClassData,
}) => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();

    const [checkCode, { data, isLoading }] = useLazyGetCheckCodeStudentQuery();

    const [isError, setIsError] = React.useState<boolean>(false);
    const [isCodeChecked, setIsCodeChecked] = React.useState<boolean>(false);
    const [isCorrect, setIsCorrect] = React.useState<boolean>(false);

    const {
        watch,
        register,
        reset,
        handleSubmit,
        formState: { isValid },
    } = useForm<IFormInput>();

    const watchOrganizationCode = watch('classCode');

    const onSubmit: SubmitHandler<IFormInput> = data => {
        checkCode(data.classCode)
            .unwrap()
            .then(res => {
                setIsCorrect(true);
                setIsCodeChecked(true);
                setClassData(res.data.class);
            })
            .catch(e => {
                setIsError(true);
            });
    };

    const handleNextClick = () => {
        if (isCodeChecked && isCorrect) {
            setState(states.create_mail);
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col relative"
        >
            <LabelLayout labelText={t('authorization.codeClass')}>
                <input
                    className={classNames(
                        'rounded-2xl text-sm pl-6 py-4',
                        style.input,
                        style.word,
                        isError &&
                            watchOrganizationCode?.length > 0 &&
                            style.inputError,
                        isCorrect &&
                            watchOrganizationCode?.length > 0 &&
                            style.successIcon,
                        isError && style.errorCode,
                        isCorrect && style.inputSuccess,
                    )}
                    {...register('classCode', {
                        required: t('authorization.organizationCodeRequired'),
                        minLength: {
                            value: 6,
                            message: t(
                                'authorization.organizationCodeLengthError',
                            ),
                        },
                    })}
                    placeholder={t('authorization.codeClassPlaceholder')}
                    autoComplete="off"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIsError(false);
                        setIsCorrect(false);
                        if (e.target.value.length > 6) {
                            e.target.value = e.target.value.slice(0, 6);
                        }
                        if (e.target.value.length === 6) {
                            e.target.blur();
                        }
                    }}
                />
                {isError && watchOrganizationCode?.length > 0 && (
                    <button
                        className="absolute top-[48px] right-[20px] bg-transparent"
                        onClick={() => {
                            reset();
                            setIsError(false);
                        }}
                    >
                        <img
                            src={'/icons/cancel.svg'}
                            alt="cancel"
                            width={20}
                            height={20}
                        />
                    </button>
                )}

                {isError && (
                    <p
                        className={classNames(
                            'text-sm mt-[8px] font-medium leading-normal',
                            style.errorMessage,
                        )}
                    >
                        {t('authorization.noClassFound')}
                    </p>
                )}
                {data && isCorrect && (
                    <p
                        className={classNames(
                            'mt-[8px] text-[#678AA1] font-medium text-base leading-[normal]',
                        )}
                    >
                        {t('authorization.foundClass')}{' '}
                        <span className="text-[#55BBEB] font-medium text-base leading-[normal]">
                            “
                            {data?.data?.class?.grade?.number +
                                data?.data?.class?.letter}
                            ”
                        </span>
                    </p>
                )}
            </LabelLayout>
            <Button
                isLoading={isLoading}
                className="mt-[24px]"
                text={
                    isCodeChecked && isCorrect
                        ? t('authorization.next')
                        : t('authorization.check')
                }
                variant={isValid ? 'primary' : 'disabled'}
                type="submit"
                onClick={handleNextClick}
            />
        </form>
    );
};
