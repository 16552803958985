import { beyimProgressApi } from '../beyimProgress/beyimProgressApi';
import { getProgressByAssessmentResponseApiType } from './types/getProgressByAssessmentResponseApiType';
import { getProgressByAssessmentDetailedResponseApiType } from './types/getProgressByAssessmentDetailedResponseApiType';
import { getProgressByStudentsFullResponseApiType } from './types/getProgressByStudentsFullResponseApiType/getProgressByStudentsFullResponseApiType';
import { getProgressByStudentsResponseApiType } from './types/getProgressByStudentsResponseApiType/getProgressByStudentsResponseApiType';
import { getProgressByUnitResponseApiType } from './types/getProgressByUnitResponseApiType';

const beyimProgressDashboardApi = beyimProgressApi.injectEndpoints({
    endpoints: build => ({
        getProgressByStudents: build.query<
            getProgressByStudentsResponseApiType,
            {
                subjectId: string;
                classId: string;
                sectionId: string;
                limit: number;
                userIds: string[];
                page: number;
            }
        >({
            query: ({
                subjectId,
                classId,
                sectionId,
                limit,
                userIds,
                page,
            }) => {
                return {
                    url: '/progress/students',
                    method: 'GET',
                    params: {
                        'subject-id': subjectId,
                        'class-id': classId,
                        'section-id': sectionId,
                        limit,
                        offset: page * limit,
                        'user-ids': userIds,
                    },
                };
            },
        }),
        getProgressByUnit: build.query<
            getProgressByUnitResponseApiType,
            {
                subjectId: string;
                classId: string;
                sectionId: string;
            }
        >({
            query: ({ subjectId, classId, sectionId }) => {
                return {
                    url: '/progress/unit',
                    method: 'GET',
                    params: {
                        'subject-id': subjectId,
                        'class-id': classId,
                        'section-id': sectionId,
                    },
                };
            },
        }),
        getProgressByStudentFull: build.query<
            getProgressByStudentsFullResponseApiType,
            {
                subjectId: string;
                userId: string;
                classId?: string;
            }
        >({
            query: ({ subjectId, userId, classId }) => {
                return {
                    url: '/progress/student',
                    method: 'GET',
                    params: {
                        'subject-id': subjectId,
                        'user-id': userId,
                        'class-id': classId,
                    },
                };
            },
        }),
        getProgressByAssessment: build.query<
            getProgressByAssessmentResponseApiType,
            {
                sectionId: string;
                userId: string;
            }
        >({
            query: ({ userId, sectionId }) => {
                return {
                    url: `/progress/unit/${sectionId}`,
                    method: 'GET',
                    params: {
                        'user-id': userId,
                    },
                };
            },
        }),
        getProgressByAssessmentDetailed: build.query<
            getProgressByAssessmentDetailedResponseApiType,
            {
                progressId: string;
            }
        >({
            query: ({ progressId }) => {
                return {
                    url: `/progress/assessment/${progressId}`,
                    method: 'GET',
                };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetProgressByStudentsQuery,
    useLazyGetProgressByStudentsQuery,
    useGetProgressByStudentFullQuery,
    useLazyGetProgressByStudentFullQuery,
    useGetProgressByUnitQuery,
    useLazyGetProgressByUnitQuery,
    useGetProgressByAssessmentQuery,
    useLazyGetProgressByAssessmentQuery,
    useGetProgressByAssessmentDetailedQuery,
    useLazyGetProgressByAssessmentDetailedQuery,
} = beyimProgressDashboardApi;
