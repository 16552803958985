import { ReactNode } from 'react';
import style from './AssessmentHeader.module.scss';
import classNames from 'classnames';
export interface IAssessmentHeaderProps {
    buttonNode?: ReactNode;
    assessmentProperties?: ReactNode;
    icons?: ReactNode;
    center?: ReactNode;
    typePost?: ReactNode;
    className?: string;
}

export const AssessmentHeader = ({
    buttonNode,
    assessmentProperties,
    icons,
    center,
    typePost,
    className,
}: IAssessmentHeaderProps) => {
    return (
        <header
            className={`${style.headerAssessment} ${className} marker:relative h-12 flex items-center bg-white justify-between z-[100] mx-0 mb-auto py-2 px-6 `}
        >
            {center}
            <aside className="flex gap-3 md:gap-[121px] justify-center items-center">
                {buttonNode}
                {typePost}
                <div className="hidden md:block">{assessmentProperties}</div>
            </aside>
            {icons}
        </header>
    );
};
