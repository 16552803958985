import React, { useEffect, useState } from 'react';
import { Header } from '../../features/Header';
import classNames from 'classnames';
import styles from './PageNotFound.module.scss';
import { ErrorScreenWrapper } from '../../features/ErrorScreenWrapper';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks';

export const PageNotFound = () => {
    const { t } = useTranslation();
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const locale = useNormalizedLocale();
    const openModal = () => {
        setIsOpenFilter(true);

        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpenFilter(false);
        document.body.style.overflow = 'visible';
    };
    useEffect(()=>{
        document.title= t('pages.errorPage');
    },[locale]);
    return (
        <>
            <Helmet>
                <title>{t('pages.errorPage')}</title>
            </Helmet>
            <div
                className={classNames(
                    ' min-h-[calc(100vh-59px)] md:min-h-screen',
                    styles.wrapper,
                )}
            >
                <Header
                    handleOpenFilterMobile={isOpen => {
                        if (isOpen) {
                            openModal();
                        } else {
                            closeModal();
                        }
                        // setIsOpenFilter(isOpen);
                    }}
                    isOpenFilterMobile={isOpenFilter}
                />
                <main className="mt-4 container  grid gap-x-4 relative grid-cols-12 ">
                    <div className=" md:col-span-3 md:block sm:hidden hidden">
                        {' '}
                        {/* Меню боковое{' '} */}
                    </div>
                    <div className=" md:col-span-6 col-span-12 flex flex-col gap-4">
                        <ErrorScreenWrapper type="404" />
                    </div>
                </main>
            </div>
        </>
    );
};
