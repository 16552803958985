import ReactModal from 'react-modal';
import { useGetAllSectionsQuery } from '../../api';
import { useSearchParams } from 'react-router-dom';
import { BeyimAccordion } from '../../ui/BeyimAccordion';
import { useNormalizedLocale } from '../../hooks';
import { useGetSectionsBySubjectQuery } from '../../api/programService';
import { useTranslation } from 'react-i18next';
import { TopicBlock } from '../SectionStatus/utils';
import { SectionAssessment } from '../SectionStatus/utils/SectionAssessment';
import classNames from 'classnames';
import style from './SectionMobile.module.scss';
import { useEffect, useRef } from 'react';

interface IProps {
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
}

export const SectionMobile: React.FC<IProps> = ({
    isOpen,
    openModal,
    closeModal,
}) => {
    const [searchParams] = useSearchParams();
    const ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const locale = useNormalizedLocale();

    const { data: dataSectionsBySubject } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const element = document.getElementById('section-star');

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                ref.current &&
                !ref.current.contains(event.target as Node) &&
                event.target !== element
            ) {
                closeModal();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, closeModal]);

    return (
        <div>
            <ReactModal
                isOpen={true}
                shouldCloseOnOverlayClick={true}
                ariaHideApp={true}
                shouldCloseOnEsc={true}
                onRequestClose={() => {
                    closeModal();
                }}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(35, 47, 55, 0.80)',
                        zIndex: 1000,
                        padding: 0,
                        top: 48,
                        height: isOpen ? 'calc(100vh - 48px)' : 0,
                        opacity: isOpen ? 1 : 0,
                        transition: '0.2s',
                    },
                    content: {
                        inset: 0,
                        backgroundColor: '#E9F0F3',
                        border: 0,
                        overflow: 'auto',
                        padding: 0,
                        zIndex: 1000,
                        height: isOpen ? 'calc(100vh - 70px)' : 0,
                        borderRadius: 0,
                        transition: '0.2s',
                        marginTop: 'auto',
                        paddingBottom: '179px',
                    },
                }}
            >
                <div ref={ref}>
                    {dataSectionsBySubject?.data.sections.length === 0 && (
                        <div className="absolute top-1/3 w-full">
                            <div className="flex flex-col justify-center items-center ">
                                <img
                                    src="/icons/emptyInfo.svg"
                                    alt="noInfo"
                                    className="w-6 h-6 mb-2"
                                />
                                <span className="text-[#425A69] text-sm font-semibold leading-[normal] mb-0.5">
                                    {t('mobile.noSections')}
                                </span>
                                <p className="text-[#678AA1] text-sm font-normal leading-[normal] text-center max-w-[202px]">
                                    {t('mobile.noSectionsDescription')}
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col gap-2 w-full">
                        {dataSectionsBySubject?.data.sections.map(
                            (section, num) => {
                                return (
                                    <BeyimAccordion
                                        key={section.id}
                                        name={
                                            section.translations.find(
                                                translation => {
                                                    return (
                                                        translation.locale ===
                                                        locale
                                                    );
                                                },
                                            )?.name ||
                                            section.translations[0].name
                                        }
                                        title={t('onboarding.section')}
                                        bg="white"
                                        disabled={section.children.length === 0}
                                        forceOpen={
                                            !searchParams.get(
                                                'sectionsBySubject',
                                            )
                                                ? num === 0
                                                : Number(
                                                      searchParams.get(
                                                          'sectionsBySubject',
                                                      ),
                                                  ) === section.id
                                        }
                                    >
                                        <div className="px-4 py-3 flex flex-col gap-2">
                                            {section.children.map(children => {
                                                return (
                                                    <TopicBlock
                                                        key={children.id}
                                                        item={children}
                                                        closeSectionMobile={() =>
                                                            closeModal()
                                                        }
                                                    />
                                                );
                                            })}
                                            <SectionAssessment />
                                        </div>
                                    </BeyimAccordion>
                                );
                            },
                        )}
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};
