import { useState, useEffect } from 'react';

export enum breakpoints {
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280,
}

interface WindowSize {
    width: number;
    height: number;
}

function useResposive(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize(): void {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}

export default useResposive;
