import React, { useEffect } from 'react';
import styles from './ModalComponent.module.scss';
import { ModalButton } from '../Buttons';
import classNames from 'classnames';
import { title } from 'process';

interface IModalProps {
    isOpen: boolean;
    onClose: () => void;

    headerContent?: React.ReactNode;
    content?: React.ReactNode;
    footerContent?: React.ReactNode;
    customContent?: React.ReactNode;

    onConfirm?: () => void;
    confirmText?: string;
    cancelText?: string;

    classNameTitle?: string;
    classNameContent?: string;
    classNameFooter?: string;
    classNameModal?: string;
}

export const ModalComponent: React.FC<IModalProps> = ({
    isOpen,
    onClose,
    content,

    headerContent,
    footerContent,
    customContent,

    onConfirm,
    confirmText = 'Принять',
    cancelText = 'Отмена',

    classNameTitle,
    classNameContent,
    classNameFooter,
    classNameModal,
}) => {
    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    return isOpen ? (
        <div
            onClick={onClose}
            className={classNames(
                'flex justify-center items-center fixed top-0 left-0 w-full h-full z-210',
                styles.modal,
            )}
        >
            {/* Контент */}
            <div
                onClick={e => e.stopPropagation()}
                className={`p-6 w-[400px] max-w-[400px] rounded-[10px] z-[1001] ${
                    styles.modalWrapper
                }  ${classNameModal ?? ''}`}
            >
                <header
                    className={
                        classNameTitle ||
                        `font-normal text-2xl m-0 mb-2 ${styles.title}`
                    }
                >
                    {headerContent}
                </header>
                <div className={`mb-6 ${classNameContent || ''}`}>
                    {content}
                </div>
                <footer
                    className={`${classNameFooter || 'flex justify-end gap-4'}`}
                >
                    {footerContent && (
                        <>
                            <button
                                className={classNames(
                                    'text-base font-medium px-4 py-[9px] rounded-xl',
                                    styles.button,
                                )}
                                onClick={onClose}
                            >
                                {cancelText}
                            </button>
                            {footerContent}
                        </>
                    )}
                    {!footerContent && !customContent && (
                        <ModalButton color="blue" onClick={onClose}>
                            {confirmText}
                        </ModalButton>
                    )}
                    {customContent && <>{customContent}</>}
                </footer>
            </div>
        </div>
    ) : null;
};
