import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'react-loading-skeleton/dist/skeleton.css';
import { ChatBot } from './features/ChatBot';
import './fonts/Inter/Inter-Black.ttf';
import './fonts/Inter/Inter-Bold.ttf';
import './fonts/Inter/Inter-ExtraBold.ttf';
import './fonts/Inter/Inter-ExtraLight.ttf';
import './fonts/Inter/Inter-Light.ttf';
import './fonts/Inter/Inter-Medium.ttf';
import './fonts/Inter/Inter-Regular.ttf';
import './fonts/Inter/Inter-SemiBold.ttf';
import './fonts/Inter/Inter-Thin.ttf';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ChatBot />
            <div className="mainWrapper">
                <BrowserRouter>
                    <App />
                    <ToastContainer />
                </BrowserRouter>
            </div>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
