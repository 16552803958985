import React, { useState } from 'react';
import style from './Registration.module.scss';
import classNames from 'classnames';
import {
    FieldError,
    SubmitHandler,
    useForm,
    Path,
    Controller,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import MaskedInput from 'react-text-mask';

import { toast } from 'react-toastify';
import { useUpdateCurrentUserMutation } from '../../../../api/authService/authServiceApi';

import { PHONE_MASK } from '../../constants';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAppDispatch } from '../../../../hooks';
import { setRole } from '../../../slice/authSlice';
import { LabelLayout } from '../../../AuthForms/components/LabelLayout';
import { Button } from '../../../AuthForms/components/ButtonComponent';
interface IFormInput {
    firstName: string;
    secondName: string;
    telephone?: string | undefined;
}

interface InputForm {
    label: string;
    placeholder: string;
    name: Path<IFormInput>;
    id: string;
}

export const Registration = () => {
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    const [updateCurrentUser] = useUpdateCurrentUserMutation();
    const navigate = useNavigate();
    const schema = yup
        .object({
            firstName: yup
                .string()
                .required(t('authorization.form.firstNameRequired')),
            secondName: yup
                .string()
                .required(t('authorization.form.lastNameRequired')),
            telephone: yup.string(),
        })
        .required();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema),
    });

    const inputForms: InputForm[] = [
        {
            label: t('authorization.form.firstName') + '*',
            placeholder: t('authorization.form.firstNamePlaceholder'),
            name: 'firstName',
            id: 'firstName',
        },
        {
            label: t('authorization.form.lastName') + '*',
            placeholder: t('authorization.form.lastNamePlaceholder'),
            name: 'secondName',
            id: 'secondName',
        },
    ];

    const onSubmit: SubmitHandler<IFormInput> = data => {
        updateCurrentUser(data)
            .unwrap()
            .then(() => {
                navigate('/feed');
                dispath(setRole('student'));
                toast.success(t('authorization.successPersonalData'));
            })
            .catch(() => {
                toast.error(t('authorization.errorPersonalData'));
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex flex-wrap justify-between gap-4 mb-6">
                {inputForms.map(({ label, placeholder, name, id }, index) => (
                    <LabelLayout
                        key={id}
                        labelText={label}
                        className={classNames(
                            'w-full flex flex-col relative md:max-w-[220px]',
                        )}
                    >
                        <input
                            className={classNames(
                                'rounded-2xl text-sm py-4 pl-4',
                                style.input,
                                style.word,
                                errors[name] && 'border-red-500',
                            )}
                            {...register(name)}
                            placeholder={placeholder}
                            autoComplete="off"
                        />
                        {errors[name] && (
                            <p className="text-red-500 text-xs mt-1">
                                {errors[name]?.message}
                            </p>
                        )}
                    </LabelLayout>
                ))}

                <LabelLayout labelText={t('authorization.personal.telephone')}>
                    <Controller
                        control={control}
                        name="telephone"
                        render={({ field: { onChange, value } }) => (
                            <MaskedInput
                                mask={PHONE_MASK}
                                className={classNames(
                                    'rounded-2xl text-sm py-4 pl-4',
                                    style.input,
                                    style.word,
                                )}
                                value={value}
                                placeholder="+7 (___) ___-____"
                                onChange={e => onChange(e.target.value)}
                            />
                        )}
                    />
                </LabelLayout>
            </div>

            <Button
                text={t('authorization.next')}
                variant={isValid ? 'primary' : 'disabled'}
                type="submit"
            />
        </form>
    );
};
