export enum states {
    change_password = 'CHANGE_PASSWORD',
    enter_code = 'ENTER_CODE',
    personal_data = 'PERSONAL_DATA',
    type_user = 'TYPE_USER',
    create_mail = 'CREATE_MAIL',
    login = 'LOGIN',
}
export const PHONE_MASK = [
    '+',
    // /\d/,
    '7',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
