import classNames from 'classnames';
import style from './HeaderMobile.module.scss';
import ReactModal from 'react-modal';
import { useState } from 'react';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChangingLanguage } from '../../../ChangingLanguage';
import { useAppDispatch } from '../../../../hooks';
import { logout } from '../../../slice/authSlice';
import { SmartSearch } from '../../../SmartSearch';
import { useGetAllSubjectsQuery } from '../../../../api';
import { trace } from 'console';
import { useNormalizedLocale } from '../../../../hooks/useNormalizedLocale';

interface IHeaderMobile {
    handleOpenFilterMobile?: (isOpnen: boolean) => void;
    isOpenFilterMobile?: boolean;
}

export const HeaderMobile: React.FC<IHeaderMobile> = ({
    handleOpenFilterMobile,
    isOpenFilterMobile,
    ...rest
}) => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);

    return (
        <div
            className="flex justify-between md:hidden h-[48px] px-4 py-2"
            {...rest}
        >
            {isOpenSearch ? (
                <div className="flex justify-between w-full gap-1 items-center">
                    <button
                        onClick={() => {
                            setIsOpenSearch(false);
                            document.body.style.overflow = 'visible';
                        }}
                        className={classNames(
                            'w-9 h-9 rounded-full border-2 flex items-center justify-center shrink-0 grow-0',
                            style.button,
                        )}
                    >
                        <img
                            src="/icons/arrowBack.svg"
                            className={classNames(
                                'w-6 h-6 block',
                                locale === 'ar' ? 'rotate-180' : '',
                            )}
                            alt="arrowBack"
                        />
                    </button>
                    <SmartSearch
                        changeSearch={value => {
                            setIsVisible(value);
                        }}
                        closeSearch={() => {
                            setIsOpenSearch(false);
                        }}
                        isOpenSearch={isOpenSearch}
                    />
                </div>
            ) : (
                <div className="flex justify-between w-full">
                    <Link to={'https://beyim.ai/'}>
                        {/* <img
                            src="/icons/beyimMobile.svg"
                            className="w-[123px] h-[22px] bloc me-4"
                            alt="student"
                        /> */}
                        <img src="/icons/logoMain.svg" alt="logoMain" />
                    </Link>
                    <div className="flex items-center gap-3">
                        <button
                            onClick={() => {
                                setIsOpenSearch(() => !isOpenSearch);
                            }}
                        >
                            <img
                                src="/icons/searchIcon.svg"
                                className="w-[32px] h-[32px] block"
                                alt="search"
                            />
                        </button>
                        {searchParams.get('subject') && (
                            <button
                                onClick={() =>
                                    handleOpenFilterMobile &&
                                    handleOpenFilterMobile(!isOpenFilterMobile)
                                }
                            >
                                <img
                                    src={
                                        isOpenFilterMobile
                                            ? '/icons/starIconActive.svg'
                                            : '/icons/starIcon.svg'
                                    }
                                    className="w-[32px] h-[32px] block"
                                    id="section-star"
                                    alt="star"
                                />
                            </button>
                        )}
                    </div>
                </div>
            )}

            {/* <div className="flex items-center">
                {isVisible && (
                    <button
                        className={classNames(
                            'w-10 h-10 border-2 rounded-xl duration-200 justify-center flex items-center',
                            style.filter,
                        )}
                        onClick={() => {
                            if (handleOpenFilterMobile) {
                                if (isOpenFilterMobile) {
                                    handleOpenFilterMobile(false);
                                } else {
                                    handleOpenFilterMobile(true);
                                }
                            }
                        }}
                    >
                        <img
                            src="/icons/filter.svg"
                            className="w-6 h-6 block"
                            alt="filter"
                        />
                    </button>
                )}
            </div> */}
            {isOpenFilterMobile && (
                <img
                    src="/icons/topWhite.svg"
                    alt="top"
                    className="absolute right-[24px] top-[60px] w-4 h-4"
                />
            )}
        </div>
    );
};
