import classNames from 'classnames';
import { Header } from '../../features/Header';
import More from '../feed/more';
import style from './Favorites.module.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllSubjectsQuery } from '../../api';
import {
    useGetBookmarksFeedQuery,
    useGetLikesFeedQuery,
} from '../../api/contentService';
import { ImagePost } from '../../features/ImagePost';
import { Post } from '../../types/GetContentsResponseApiType';
import { VideoPost } from '../../features/VideoPost';
import { ActivityPost } from '../../features/ActivityPost/ActivityPost';
import { Daum } from '../../types/GetCustomFeedRequestApiType';
import { ErrorScreenWrapper } from '../../features/ErrorScreenWrapper';
import ReactModal from 'react-modal';
import { FilterBlock } from './utils';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { PostSkeleton } from '../../ui/PostSkeleton';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { TextPost } from '../../features/TextPost';
import TabSwitch from '../../ui/TabGroupBeyim/TabSwitch';
import { MobileFilter } from './utils/MobileFilter';
import { useLazyGetFooterDataByPostIdsQuery } from '../../api/contentInteractionApi/contentInteractionApi';
import { PostFeed } from '../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';

const Favorites: React.FC = () => {
    const { t } = useTranslation();

    // document.body.style.overflow = 'hidden';
    const locale = useNormalizedLocale();

    const [variant, setVariant] = useState<'like' | 'bookmark'>('like');
    const [openType, setOpenType] = useState<boolean>(false);
    const [openSubject, setOpenSubject] = useState<boolean>(false);
    const [subjectFilter, setSubjectFilter] = useState<number[]>([]);
    const [isActivity, setIsActivity] = useState<boolean>(false);
    const [isVideo, setIsVideo] = useState<boolean>(false);
    const [isImage, setIsImage] = useState<boolean>(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [likesDataFooter, setLikesDataFooter] = useState<PostFeed[]>([]);
    const [bookmarksDataFooter, setBookmarksDataFooter] = useState<PostFeed[]>(
        [],
    );

    const include = [];
    isActivity && include.push('activity');
    isVideo && include.push('video');
    isImage && include.push('image');

    const { data: feedLikes, isFetching: isFetchingFeedLikes } =
        useGetLikesFeedQuery(
            {
                include,
                locale: locale,
                page: 0,
                subjectIds: subjectFilter,
                limit: 50,
            },
            {
                skip: variant !== 'like',
            },
        );

    const { data: feedBookmarks, isFetching: isFetchingBookmarks } =
        useGetBookmarksFeedQuery(
            {
                include,
                locale: locale,
                page: 0,
                subjectIds: subjectFilter,
                limit: 50,
            },
            {
                skip: variant !== 'bookmark',
            },
        );
    const [getFootData] = useLazyGetFooterDataByPostIdsQuery();

    const openModal = () => {
        setIsOpenFilter(true);

        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpenFilter(false);
        document.body.style.overflow = 'visible';
    };

    const tablist = [
        { label: t('favorite.favorite'), value: 'bookmark' },
        { label: t('favorite.likes'), value: 'like' },
    ];
    useEffect(() => {
        document.title = t('pages.favorite');
    }, [locale]);

    useEffect(() => {
        if (feedLikes?.data.data) {
            getFootData(
                {
                    //@ts-ignore
                    postIds: feedLikes?.data?.data
                        ?.map(item => {
                            if (item.category !== 'activity') {
                                return item.post?.id;
                            }
                            if (item.category === 'activity') {
                                return item.activity?.id;
                            }
                        })
                        .filter(Boolean),
                },
                {
                    skip: !feedLikes?.data.data.length,
                },
            )
                .unwrap()
                .then(data => {
                    setLikesDataFooter(data.data.postFeed);
                });
        }
    }, [feedLikes]);

    useEffect(() => {
        if (feedBookmarks?.data.data) {
            getFootData(
                {
                    //@ts-ignore
                    postIds: feedBookmarks?.data?.data
                        ?.map(item => {
                            if (item.category !== 'activity') {
                                return item.post?.id;
                            }
                            if (item.category === 'activity') {
                                return item.activity?.id;
                            }
                        })
                        .filter(Boolean),
                },
                {
                    skip: !feedBookmarks?.data.data.length,
                },
            )
                .unwrap()
                .then(data => {
                    setBookmarksDataFooter(data.data.postFeed);
                });
        }
    }, [feedBookmarks]);

    return (
        <>
            <Helmet>
                <title>{t('pages.favorite')}</title>
            </Helmet>
            <div
                className={classNames(
                    'min-h-[calc(100vh-59px)] md:min-h-screen',
                    style.wrapper,
                )}
            >
                <div className="hidden md:block">
                    <Header
                        handleOpenFilterMobile={isOpen => {
                            setIsOpenFilter(!isOpen);
                        }}
                        isOpenFilterMobile={isOpenFilter}
                    />
                </div>
                <header className="w-full bg-[#FFF] h-[79px] block md:hidden sticky top-0 z-210 ">
                    <div className="flex flex-col gap-0 px-4 pt-[10px]">
                        <div className="flex justify-between items-center">
                            <h2 className=" text-lg font-bold leading-[25.2px] text-[#364954]">
                                {t('mobile.favorite')}
                            </h2>
                            <button
                                className="w-[36px] h-[36px] p-1"
                                onClick={() => {
                                    setIsOpenFilter(!isOpenFilter);
                                }}
                            >
                                <img
                                    className="!w-[28x] h-[28px] cursor-pointer md:hidden"
                                    src="/icons/filterMobile.svg"
                                    alt="filterMObile"
                                />
                            </button>
                        </div>
                        <TabSwitch
                            tabsList={tablist}
                            variant={variant}
                            setVariant={setVariant}
                        />
                    </div>
                </header>
                <main className="container  grid gap-4  grid-cols-12 pt-4 pb-5">
                    <nav className=" md:col-span-2 md:block sm:hidden hidden">
                        {' '}
                    </nav>

                    <div className=" md:col-span-6 col-span-12 flex flex-col gap-4">
                        <div
                            className={classNames(
                                'md:flex pt-4 pb-3 px-4 rounded-2xl flex-col hidden ',
                                style.titleWrapper,
                            )}
                        >
                            <h1 className="text-xl font-semibold mb-1 leading-6">
                                {t('favorite.title')}
                            </h1>

                            <span
                                className={classNames(
                                    'text-sm leading-6',
                                    style.subTitle,
                                )}
                            >
                                {t('favorite.subTitle')}
                            </span>
                        </div>

                        {variant === 'like' ? (
                            isFetchingFeedLikes ? (
                                <>
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                </>
                            ) : (
                                feedLikes &&
                                (!feedLikes.data.data?.length ? (
                                    <div>
                                        <div className="hidden md:block">
                                            <ErrorScreenWrapper type="empty" />
                                        </div>
                                        <div className="flex justify-center items-center flex-col h-[calc(100vh-200px)] gap-3 md:hidden">
                                            <img
                                                src="/icons/myActionsEmpty.svg"
                                                alt="myACtionsEmpty"
                                            />
                                            <span className="f font-bold leading-[20.8px] text-base text-[#678AA1] max-w-[244px] text-center">
                                                {t('mobile.emptyFavourite')}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    feedLikes.data.data?.map(
                                        itemActivityFeed => {
                                            const post = {
                                                microtopicId:
                                                    itemActivityFeed.microtopicId,
                                                objectiveId:
                                                    itemActivityFeed.objectiveId,
                                                subject:
                                                    itemActivityFeed.subject,
                                                microtopic:
                                                    itemActivityFeed.microtopic,
                                                objective:
                                                    itemActivityFeed.objective,
                                                iconUrl:
                                                    itemActivityFeed.iconUrl,
                                                // subjectId: 1,
                                                id: itemActivityFeed.post?.id,
                                                category:
                                                    itemActivityFeed.category,
                                                resources:
                                                    itemActivityFeed.post
                                                        ?.resources,
                                                contentId:
                                                    itemActivityFeed.post
                                                        ?.contentId,
                                                description:
                                                    itemActivityFeed.post
                                                        ?.description,
                                                thumbnail:
                                                    itemActivityFeed.post
                                                        ?.thumbnail || '',
                                                generatorType:
                                                    itemActivityFeed.post
                                                        ?.generatorType ||
                                                    'manual',
                                            };
                                            const postFooterData =
                                                likesDataFooter?.find(
                                                    postFooter =>
                                                        postFooter.postId ===
                                                        itemActivityFeed.post
                                                            ?.id,
                                                );

                                            if (
                                                itemActivityFeed.category ===
                                                'image'
                                            ) {
                                                return (
                                                    <ImagePost
                                                        data={post as Post}
                                                        key={
                                                            itemActivityFeed
                                                                .post?.id
                                                        }
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                );
                                            }

                                            if (
                                                itemActivityFeed.category ===
                                                'text'
                                            ) {
                                                return (
                                                    <TextPost
                                                        data={post as Post}
                                                        key={
                                                            itemActivityFeed
                                                                .post?.id
                                                        }
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                );
                                            }

                                            if (
                                                itemActivityFeed.category ===
                                                'video'
                                            ) {
                                                return (
                                                    <div
                                                        key={
                                                            itemActivityFeed
                                                                .post?.id
                                                        }
                                                    >
                                                        <VideoPost
                                                            data={post as Post}
                                                            postFooterData={
                                                                postFooterData
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (
                                                itemActivityFeed.category ===
                                                'activity'
                                            ) {
                                                return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    <ActivityPost
                                                        //   them={sectionId}
                                                        //@ts-ignore
                                                        data={itemActivityFeed}
                                                        postFooterData={likesDataFooter?.find(
                                                            postFooter =>
                                                                postFooter.postId ===
                                                                itemActivityFeed
                                                                    .activity
                                                                    ?.id,
                                                        )}
                                                    />
                                                );
                                            }

                                            return (
                                                <span
                                                    className="block h-28"
                                                    key={
                                                        itemActivityFeed.post
                                                            ?.id
                                                    }
                                                >
                                                    {itemActivityFeed.post?.id}
                                                </span>
                                            );
                                        },
                                    )
                                ))
                            )
                        ) : isFetchingBookmarks ? (
                            <>
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                            </>
                        ) : (
                            feedBookmarks &&
                            (!feedBookmarks.data.data?.length ? (
                                <div>
                                    <div className="hidden md:block">
                                        <ErrorScreenWrapper type="empty" />
                                    </div>
                                    <div className="flex justify-center items-center flex-col h-[calc(100vh-200px)] gap-3 md:hidden">
                                        <img
                                            src="/icons/myActionsEmpty.svg"
                                            alt="myACtionsEmpty"
                                        />
                                        <span className="f font-bold leading-[20.8px] text-base text-[#678AA1] max-w-[244px] text-center">
                                            {t('mobile.emptyFavourite')}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                feedBookmarks.data.data?.map(
                                    itemActivityFeed => {
                                        const post = {
                                            microtopicId:
                                                itemActivityFeed.microtopicId,
                                            objectiveId:
                                                itemActivityFeed.objectiveId,
                                            subject: itemActivityFeed.subject,
                                            microtopic:
                                                itemActivityFeed.microtopic,
                                            objective:
                                                itemActivityFeed.objective,
                                            iconUrl: itemActivityFeed.iconUrl,
                                            // subjectId: 1,
                                            id: itemActivityFeed.post?.id,
                                            category: itemActivityFeed.category,
                                            resources:
                                                itemActivityFeed.post
                                                    ?.resources,
                                            contentId:
                                                itemActivityFeed.post
                                                    ?.contentId,
                                            description:
                                                itemActivityFeed.post
                                                    ?.description,
                                            thumbnail:
                                                itemActivityFeed.post
                                                    ?.thumbnail || '',
                                        };
                                        const postFooterData =
                                            bookmarksDataFooter?.find(
                                                postFooter =>
                                                    postFooter.postId ===
                                                    itemActivityFeed.post?.id,
                                            );

                                        if (
                                            itemActivityFeed.category ===
                                            'image'
                                        ) {
                                            return (
                                                <ImagePost
                                                    data={post as Post}
                                                    key={
                                                        itemActivityFeed.post
                                                            ?.id
                                                    }
                                                    postFooterData={
                                                        postFooterData
                                                    }
                                                />
                                            );
                                        }

                                        if (
                                            itemActivityFeed.category === 'text'
                                        ) {
                                            return (
                                                <TextPost
                                                    data={post as Post}
                                                    key={
                                                        itemActivityFeed.post
                                                            ?.id
                                                    }
                                                    postFooterData={
                                                        postFooterData
                                                    }
                                                />
                                            );
                                        }

                                        if (
                                            itemActivityFeed.category ===
                                            'video'
                                        ) {
                                            return (
                                                <div
                                                    key={
                                                        itemActivityFeed.post
                                                            ?.id
                                                    }
                                                >
                                                    <VideoPost
                                                        data={post as Post}
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                </div>
                                            );
                                        }

                                        if (
                                            itemActivityFeed.category ===
                                            'activity'
                                        ) {
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <ActivityPost
                                                    //   them={sectionId}
                                                    //@ts-ignore
                                                    data={itemActivityFeed}
                                                    postFooterData={bookmarksDataFooter?.find(
                                                        postFooter =>
                                                            postFooter.postId ===
                                                            itemActivityFeed
                                                                .activity?.id,
                                                    )}
                                                />
                                            );
                                        }

                                        return (
                                            <span
                                                className="block h-28"
                                                key={itemActivityFeed.post?.id}
                                            >
                                                {itemActivityFeed.post?.id}
                                            </span>
                                        );
                                    },
                                )
                            ))
                        )}

                        <More />
                    </div>
                    <div className="hidden md:flex col-span-4">
                        <FilterBlock
                            setVariant={setVariant}
                            variant={variant}
                            setOpenType={setOpenType}
                            openType={openType}
                            setIsActivity={setIsActivity}
                            isActivity={isActivity}
                            setIsVideo={setIsVideo}
                            setIsImage={setIsImage}
                            setSubjectFilter={setSubjectFilter}
                            setOpenSubject={setOpenSubject}
                            openSubject={openSubject}
                        />
                    </div>
                </main>
                {isOpenFilter && (
                    <div
                        onClick={() => setIsOpenFilter(false)}
                        className={classNames(
                            ' fixed top-[80px] left-0 bottom-0 right-0 w-full h-full z-210',
                            style.modal,
                        )}
                    >
                        <div
                            onClick={e => e.stopPropagation()}
                            className={`w-full h-[60vh] ${style.modalWrapper} overflow-auto  `}
                        >
                            <MobileFilter
                                setOpenType={setOpenType}
                                setIsActivity={setIsActivity}
                                isActivity={isActivity}
                                setIsVideo={setIsVideo}
                                setIsImage={setIsImage}
                                setSubjectFilter={setSubjectFilter}
                                setOpenSubject={setOpenSubject}
                                openSubject={openSubject}
                                isVideo={isVideo}
                                isImage={isImage}
                                subjectFilter={subjectFilter}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Favorites;
