import { useTranslation } from 'react-i18next';

type Locale = 'ru' | 'en' | 'kk' | 'ar' | 'ko';

export const useNormalizedLocale = (): Locale => {
    const { i18n } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const locale = i18n.translator.language;
    if (!locale) return 'en';
    const languageCode: Locale = locale.toLowerCase().split(/[_-]+/)[0];

    const supportedLanguages = {
        ru: 'ru',
        en: 'en',
        kk: 'kk',
        ar: 'ar',
        ko: 'ko'
    };
    if (supportedLanguages[languageCode]) {
        return supportedLanguages[languageCode] as Locale;
    } else {
        return 'en';
    }
};
