import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        isLogin: false,
        role: null,
    },
    reducers: {
        login: state => {
            state.isLogin = true;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        logout: state => {
            Cookies.remove('access_token');
            Cookies.remove('id_token');
            Cookies.remove('filled');
            Cookies.remove('refresh_token');
            Cookies.remove('user_id');
            localStorage.removeItem('userId');
            state.isLogin = false;
            state.role = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { login, setRole, logout } = authSlice.actions;

export default authSlice.reducer;
