import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from './Assessment.module.scss';
import classNames from 'classnames';

import { useGetBulkAssessmentInfoQuery } from '../../api/beyimAssessmentApi/beyimAssessmentApi';
import { useGetSectionsBySubjectQuery } from '../../api/programService';
import { useGetAssessmentInfoQuery } from '../../api/beyimAssessmentApi';
import { usePostStartAssessmentMutation } from '../../api/beyimProgress';

import { AssessmentStarting } from '../../features/AssessmentStarting';
import { NotificationBlock } from '../../features/AssessmentStarting/NotificationBlock';
import { Accordion } from '../../features/AssessmentStarting/Accordion';
import { TopicBlock } from '../../features/SectionStatus/utils';
import { Locale } from '../../types/common';
import { ModalComponent } from '../../ui/ModalComponent';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { TypePost } from '../../features/AssessmentHeader/utils/TypePost';
import { AssessmentHeader } from '../../features/AssessmentHeader';
import { ButtonNode } from '../../features/AssessmentHeader/utils/ButtonNode';
import Skeleton from 'react-loading-skeleton';
import { useCancelProgressMutation } from '../../api/beyimProgress/beyimProgressApi';
import { SectionMobile } from '../../features/SectionMobile';

const Assessment: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const navigate = useNavigate();
    const [cancelProgress] = useCancelProgressMutation();
    // const passing = localStorage.getItem('passing');
    const [passing, setPassing] = useState<null | string>(
        localStorage.getItem('passing'),
    );

    useEffect(() => {
        if (passing) {
            cancelProgress({ progress_id: Number(passing) });
            localStorage.removeItem('passing');
            setPassing(null);
        }
    }, [passing]);

    const [notificationBlockType, setNotificationBlockType] = useState<
        'success' | 'warning' | 'error'
    >('success');

    const [isStarted, setIsStarted] = useState<boolean | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { t } = useTranslation();

    const locale = useNormalizedLocale();
    const { data: sectionsBySubject } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const openModal = () => {
        setIsOpen(true);

        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    };

    useEffect(() => {
        if (params.id) {
            setSearchParams(prev => {
                return {
                    ...prev,
                    subject: searchParams.get('subject') || '',
                    sectionsBySubject:
                        searchParams.get('sectionsBySubject') || '',
                    them: searchParams.get('them') || '',
                };
            });
        }
    }, [params.id]);

    const [postStartAssessment] = usePostStartAssessmentMutation();
    const {
        data: assessmentInfo,
        isLoading: isLoadingAssessmentInfo,
        isFetching: isFetchingAssessmentInfo,
    } = useGetAssessmentInfoQuery(
        {
            locale: locale as Locale,
            section_id:
                Number(searchParams.get('them')) ||
                Number(searchParams.get('sectionsBySubject') || ''),
            subject_id: Number(searchParams.get('subject')),
        },
        {
            skip:
                !searchParams.get('subject') ||
                (!searchParams.get('them') &&
                    !searchParams.get('sectionsBySubject')),
        },
    );
    const searchParamsThem = searchParams.get('them') || '';

    const searchParamsSectionsBySubject =
        searchParams.get('sectionsBySubject') || '';

    const handleStartAssessment = () => {
        postStartAssessment({
            assessment_id: Number(params.id),
            section_id:
                searchParamsThem && searchParamsSectionsBySubject
                    ? assessmentInfo?.data.assessment.section_type_id === 3
                        ? Number(searchParams.get('them'))
                        : Number(searchParams.get('sectionsBySubject'))
                    : Number(searchParamsThem),
            locale: locale,
        })
            .unwrap()
            .then()
            .then(res => {
                navigate(
                    `/feed/assessment/${params.id}/passing/${
                        res.data.id
                    }?${searchParams.toString()}`,
                );

                setPassing(res.data.id.toString());
                localStorage.setItem('passing', res.data.id.toString());
            })
            .catch(err => {
                setIsStarted(false);
                setErrorMessage(err.data.message);
            });
    };

    const { data: bulkAssessmentInfo } = useGetBulkAssessmentInfoQuery(
        {
            locale: locale,
            subjectId: Number(searchParams.get('subject')),
            sectionIds:
                assessmentInfo?.data.assessment.section_type_id === 2
                    ? sectionsBySubject?.data?.sections
                          .find(section => {
                              return (
                                  String(section.id) ===
                                  searchParams.get('them')
                              );
                          })
                          ?.children.map(child => child.id) || []
                    : [Number(searchParams.get('them') || '')] || [],
        },
        {
            skip: !assessmentInfo,
        },
    );
    useEffect(() => {
        document.title = t('pages.assessment');
    }, [locale]);
    return (
        <>
            <Helmet>
                <title>{t('pages.assessment')}</title>
            </Helmet>
            <div
                className={classNames(
                    'min-h-[calc(100vh-59px)] md:min-h-screen',
                    style.wrapper,
                )}
            >
                <AssessmentHeader
                    buttonNode={
                        <ButtonNode
                            href={`/feed?idContent=${
                                searchParams.get('idContent') || ''
                            }&fromSearch=${
                                searchParams.get('fromSearch') || ''
                            }&subject=${
                                searchParams.get('subject') || ''
                            }&sectionsBySubject=${
                                searchParams.get('sectionsBySubject') || ''
                            }&them=${
                                assessmentInfo?.data.assessment
                                    .section_type_id === 3
                                    ? searchParams.get('them' || '')
                                    : ''
                            }&idActivity=${
                                searchParams.get('idActivity') || ''
                            }`}
                        />
                    }
                    typePost={
                        <TypePost
                            isLoading={
                                isLoadingAssessmentInfo ||
                                isFetchingAssessmentInfo
                            }
                            title={
                                assessmentInfo?.data.assessment.section_name
                                    ? assessmentInfo?.data.assessment
                                          .section_name
                                    : assessmentInfo?.data.assessment
                                            .section_type_id === 2
                                      ? t('available.sectionIsNotAvailable')
                                      : t('available.topicIsNotAvailable')
                            }
                            typePost={t(
                                `${
                                    assessmentInfo?.data.assessment
                                        .section_type_id === 2
                                        ? 'assessment.sectionAssessment'
                                        : 'assessment.topicAssessment'
                                }`,
                            )}
                        />
                    }
                    // icons={
                    //     <button
                    //         className="block md:hidden w-[32px] h-[32px]"
                    //         onClick={() => {
                    //             setIsOpen(!isOpen);
                    //         }}
                    //     >
                    //         <img
                    //             src={'/icons/starIcon.svg'}
                    //             className="w-[32px] h-[32px] block"
                    //             alt="star"
                    //         />
                    //     </button>
                    // }
                />

                <main className="m-auto mt-2 md:mt-[31px] mb-[10px] max-w-[592px] w-full flex flex-col gap-y-2">
                    {isLoadingAssessmentInfo || isFetchingAssessmentInfo ? (
                        <div className="bg-white rounded-2xl p-4">
                            <div className="flex flex-row gap-2">
                                <Skeleton
                                    circle
                                    height={40}
                                    width={40}
                                    baseColor="#e9f0f3"
                                />
                                <div className="flex flex-col gap-1">
                                    <Skeleton
                                        count={2}
                                        baseColor="#e9f0f3"
                                        height={20}
                                        width={250}
                                        borderRadius={10}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        assessmentInfo?.data?.assessment?.section_type_id ===
                            2 && (
                            <NotificationBlock
                                type={notificationBlockType}
                                icon={
                                    notificationBlockType === 'success'
                                        ? '/icons/lock_success.svg'
                                        : '/icons/lock_warning.svg'
                                }
                                title={t('assessment.sectionAssessmentOpen')}
                                description={t(
                                    'assessment.sectionAssessmentOpenDescription',
                                )}
                            />
                        )
                    )}
                    <AssessmentStarting
                        type="start"
                        callBack={handleStartAssessment}
                    />
                    <Accordion
                        title={t('assessment.advice')}
                        description={
                            assessmentInfo?.data?.assessment
                                ?.section_type_id === 2
                                ? t('assessment.accordionDescriptionSection')
                                : t('assessment.accordionDescriptionTopic')
                        }
                        type="success"
                    >
                        {sectionsBySubject?.data.sections &&
                        assessmentInfo?.data.assessment.section_type_id ===
                            3 ? (
                            <TopicBlock
                                item={
                                    sectionsBySubject?.data.sections[0]
                                        .children[0]
                                }
                                assessment={
                                    bulkAssessmentInfo?.data.assessments[0]
                                }
                                type="topic"
                            />
                        ) : (
                            <div className="flex gap-4 flex-col">
                                {sectionsBySubject?.data.sections
                                    .find(section => {
                                        return (
                                            String(section.id) ===
                                            searchParams.get('them')
                                        );
                                    })
                                    ?.children.map((child, index) => {
                                        return (
                                            <TopicBlock
                                                item={child}
                                                key={child.id}
                                                assessment={
                                                    bulkAssessmentInfo?.data
                                                        .assessments[index]
                                                }
                                                type="section"
                                            />
                                        );
                                    })}
                            </div>
                        )}
                    </Accordion>
                    <ModalComponent
                        headerContent={<h1> {t('assessment.error')}</h1>}
                        content={
                            <div>
                                <p>
                                    {t('assessment.errorStart')}({errorMessage})
                                </p>
                            </div>
                        }
                        isOpen={isStarted === false}
                        onClose={() => setIsStarted(null)}
                    />
                </main>
            </div>
        </>
    );
};

export default Assessment;
