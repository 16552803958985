import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import { Header } from '../../features/Header';
import { TitleCabinert } from '../../features/TitleCabinert/TitleCabinert';
import { ActiveStudent } from './utils/ActiveStudent';
import { ActivityProgress } from './utils/ActivityProgress';
import { AssessmentProgress } from './utils/AssessmentProgress';

import { useEffect, useState } from 'react';
import { useGetClassByIdQuery } from '../../api/organizationService/organizationApiType';
import { Menu } from '../../features/Menu';
import { Tabs } from '../../features/Tabs';
import { useNormalizedLocale } from '../../hooks';
import { dataComponents, dataTabsLabel } from './utils/dataTabs/dataTabs';
import { FilterCabinet2 } from './utils/FilterCabinet/FilterCabinet2';
import { MobileHeader } from './utils/MobileHeader';
import Skeleton from 'react-loading-skeleton';
import { roles } from '../../features/AuthForms/constants';

export const Dashboard: React.FC = () => {
    const locale = useNormalizedLocale();
    const { data } = useGetUserDataQuery('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (
            data?.data.User.organizations.some(
                item => item.role === roles.STUDENT,
            )
        ) {
            navigate('/feed');
        }
    }, []);
    const [stateFilter, setStateFilter] = useState({
        subject_id: '',
        class_id: '',
        quarter: '',
    });
    useEffect(() => {
        document.title = t('pages.dashboard');
    }, [locale]);

    const {
        data: classData,
        isLoading,
        isFetching,
    } = useGetClassByIdQuery(
        {
            classId: stateFilter.class_id || searchParams.get('class_id') || '',
        },
        {
            skip: !searchParams.get('class_id') && !stateFilter.class_id,
        },
    );

    return (
        <div className="bg-[#E9F0F3] min-h-screen">
            <div className="hidden md:block fixed w-full z-[1003] top-0">
                <Header withSearch={false} />
            </div>
            <div className="block md:hidden">
                <MobileHeader />
            </div>
            <div className="flex min-h-[calc(100vh_-_56px)] relative">
                <div className="hidden md:block fixed duration-300 max-h-full top-14 z-10 overflow-auto">
                    <Menu />
                </div>
                <div className="p-8 md:ps-[272px] flex flex-col gap-4 w-full max-w-full pt-20 md:pt-24">
                    <TitleCabinert
                        h1={
                            t('dashboard.hello') +
                                ' ' +
                                data?.data.User.firstName || ''
                        }
                        subTitle={t('dashboard.subTitle')}
                    />
                    {/* <BannerCabinet
                        banner={<img src="/images/Frame1321315828.png" />}
                    /> */}
                    <FilterCabinet2 setStateFilter={setStateFilter} />
                    <div className=" flex-wrap grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
                        {/* <CountStudent /> */}

                        <ActiveStudent
                            classId={stateFilter.class_id}
                            subjectId={stateFilter.subject_id}
                            quarter={stateFilter.quarter}
                        />
                        <ActivityProgress
                            classId={stateFilter.class_id}
                            subjectId={stateFilter.subject_id}
                            quarter={stateFilter.quarter}
                        />
                        <AssessmentProgress
                            classId={stateFilter.class_id}
                            subjectId={stateFilter.subject_id}
                            quarter={stateFilter.quarter}
                        />
                    </div>
                    {stateFilter.class_id ? (
                        isLoading || isFetching ? (
                            <Skeleton
                                width={120}
                                borderRadius={10}
                                height={30}
                                baseColor="#FFF"
                            />
                        ) : (
                            <h2 className="text-[20px] text-[#364954] leading-[normal] font-medium">
                                {classData?.data.class.grade.number} “
                                {classData?.data.class.letter}”{' '}
                                {t('dashboard.class')}
                            </h2>
                        )
                    ) : (
                        <span className="text-base text-[#364954] leading-[normal] font-medium">
                            {t('dashboard.noSelectedClass')}
                        </span>
                    )}

                    <Tabs
                        optionsLabel={dataTabsLabel}
                        optionsChildren={dataComponents(
                            stateFilter.class_id,
                            stateFilter.subject_id,
                        )}
                    />

                    {/* <div className="bg-white grid md:grid-cols-2 grid-cols-1 px-9 gap-10 rounded-2xl">
                        <Chart />
                        <RatingStudent />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
