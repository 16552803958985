import Skeleton from 'react-loading-skeleton';
import style from './AssessmentStarting.module.scss';
import classNames from 'classnames';
export const SkeletonAssessmentStarting: React.FC = () => {
    const isSmallScreen = window.innerWidth <= 640;
    return (
        <article
            className={classNames(
                'rounded-2xl flex flex-col gap-4 pl-4 pr-4 pt-6 pb-4 z-10 relative',
                style.wrapper,
            )}
        >
            <Skeleton
                count={1}
                baseColor="#e9f0f3"
                height={48}
                borderRadius={10}
            />
            <div className="flex flex-col gap-2 sm:justify-between sm:gap-x-2 sm:flex-row overflow-hidden">
                {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton
                        key={index}
                        count={1}
                        baseColor="#e9f0f3"
                        height={58}
                        width={isSmallScreen ? '100%' : 180}
                        borderRadius={16}
                    />
                ))}
            </div>
            <Skeleton
                count={1}
                baseColor="#e9f0f3"
                height={44}
                borderRadius={10}
            />
        </article>
    );
};
