import { useTranslation } from 'react-i18next';
import {
    useGetCustomFeedQuery,
    useGetFeedQuery,
} from '../../api/contentService';
import { Header } from '../../features/Header';
import { SubjectsFilter } from '../../features/SubjectsFilter';
import React, { useEffect, useRef, useState } from 'react';
import { Post } from '../../types/GetContentsResponseApiType';
import { ImagePost } from '../../features/ImagePost';
import style from './Feed.module.scss';
import classNames from 'classnames';
import { VideoPost } from '../../features/VideoPost';
import { scroller } from 'react-scroll';
import More from './more';
import {
    useGetFeedMicrotopicsQuery,
    useGetSectionsBySubjectQuery,
} from '../../api/programService';
import { useSearchParams } from 'react-router-dom';
import { WithVideoOrImage } from '../../features/WithVideoOrImage';
import { SectionStatus } from '../../features/SectionStatus';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityPost } from '../../features/ActivityPost/ActivityPost';

import { AssessmentStarting } from '../../features/AssessmentStarting';
import { animateScroll as scroll } from 'react-scroll';
import { TypeResources } from '../../types/getContentsRequestApiType';

import { ErrorScreenWrapper } from '../../features/ErrorScreenWrapper';
import { Helmet } from 'react-helmet';
import { PostSkeleton } from '../../ui/PostSkeleton';

import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { TextPost } from '../../features/TextPost';

import { SectionMobile } from '../../features/SectionMobile';
import { useLazyGetFooterDataByPostIdsQuery } from '../../api/contentInteractionApi/contentInteractionApi';
import { PostFeed } from '../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';
import { RightBlock } from './utils/RightBlock/RightBlock';
import InfiniteScroll from 'react-infinite-scroll-component';
import TagManager from 'react-gtm-module';
import { useAppSelector } from '../../hooks';
import { roles } from '../../features/AuthForms/constants';

const debounce = (
    mainFunction: (arg0: any) => void,
    delay: number | undefined,
) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer: string | number | NodeJS.Timeout | undefined;

    // Return an anonymous function that takes in any number of arguments
    return function (...args: any) {
        // Clear the previous timer to prevent the execution of 'mainFunction'
        clearTimeout(timer);

        // Set a new timer that will execute 'mainFunction' after the specified delay
        timer = setTimeout(() => {
            //@ts-ignore
            mainFunction(...args);
        }, delay);
    };
};

const Feed: React.FC = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const locale = useNormalizedLocale();

    //логика для загрузки обычной ленты
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [withVideo, setWithVideo] = useState<boolean>(true);
    const [withPicture, setWithPicture] = useState<boolean>(true);
    const [isCatchLoadingCustomFeed, setIsCatchLoadingCustomFeed] =
        useState(false);
    const role = useAppSelector(state => state.auth.role);
    const {
        data: dataFeed,
        isFetching: isFetchingDataFeed,
        isLoading: isLoadingDataFeed,
        refetch,
        isError: isDataFeedError,
    } = useGetFeedQuery(
        {
            limit: 10,
            page: currentPage,
            locale,
            typeResources: [
                withPicture ? 'image' : '',
                withVideo ? 'video' : '',
            ].filter(Boolean) as TypeResources[],
        },
        {
            skip: searchParams.get('subject') ? true : false,
        },
    );
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [posts, setPosts] = useState<Post[]>([]);
    const [feedLikesAndBookmarksData, setFeedLikesAndBookmarksData] = useState<
        PostFeed[]
    >([]);
    const [
        customFeedLikesAndBookmarksData,
        setCustomFeedLikesAndBookmarksData,
    ] = useState<PostFeed[]>();
    const [isVisible, setIsVisible] = useState(false); //для дозагрузки обычной ленты

    const [keyForScroll, setKeyForScroll] = useState(nanoid());
    //для специальной ленты
    const [getFootData] = useLazyGetFooterDataByPostIdsQuery();
    const {
        data: dataSectionsBySubject,
        isFetching: isFetchingSectionsBySubject,
        isLoading: isLoadingSectionsBySubject,
    } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const [isOpenFilter, setIsOpenFilter] = useState(false);

    const openModal = () => {
        setIsOpenFilter(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpenFilter(false);
        document.body.style.overflow = 'visible';
    };

    useEffect(() => {
        console.log(role);
        if (role === roles.EXPERT) {
            console.log('hapeen');
            // @ts-ignore

            setSearchParams(prev => {
                return {
                    idContent: prev.get('idContent') || '',
                    fromSearch: prev.get('fromSearch') || '',
                    subject: 1,
                    sectionsBySubject: prev.get('sectionsBySubject') || '',
                    them: prev.get('them') || '',
                    idActivity: prev.get('idActivity') || '',
                };
            });
        }
    }, [role]);

    useEffect(() => {
        if (dataFeed?.data.isLastPage && !searchParams.get('subject')) {
            getFootData({
                postIds: dataFeed.data.posts.map(post => post.id),
            })
                .unwrap()
                .then(data => {
                    setFeedLikesAndBookmarksData(data.data.postFeed);
                });
        }
    }, [searchParams.get('subject')]);

    const sectionId = String(
        searchParams.get('them') ||
            dataSectionsBySubject?.data.sections.find(section => {
                return (
                    String(section.id) === searchParams.get('sectionsBySubject')
                );
            })?.children[0]?.id ||
            dataSectionsBySubject?.data.sections[0]?.children[0]?.id ||
            '',
    );

    const {
        data: dataFeedMicrotopics,
        isFetching: isFetchingDataFeedMicrotopics,
        isLoading: isLoadingDataFeedMicrotopics,
    } = useGetFeedMicrotopicsQuery(
        {
            section_id: String(
                searchParams.get('them') ||
                    dataSectionsBySubject?.data.sections.find(section => {
                        return (
                            String(section.id) ===
                            searchParams.get('sectionsBySubject')
                        );
                    })?.children[0]?.id ||
                    dataSectionsBySubject?.data.sections[0]?.children[0]?.id ||
                    '',
            ),
            limit: 100,
        },
        {
            skip: !sectionId,
        },
    );

    const {
        data: dataCustomFeed,
        isFetching: isFetchingCustomFeed,
        isLoading: isLoadingCustomFeed,
        isError: isCustomFeedError,
    } = useGetCustomFeedQuery(
        {
            microtopicIds: dataFeedMicrotopics?.data.result.ids || [],
            include: [
                withPicture ? 'image' : '',
                withVideo ? 'video' : '',
                'text',
            ],
            locale,
        },
        {
            skip: !dataFeedMicrotopics,
        },
    );

    const handleChangeFilter = (type?: 'video' | 'image') => {
        const typeSend = type === 'video' ? 'Video Filter' : 'Image Filter';
        const verb =
            type === 'video'
                ? withVideo
                    ? 'switched-off'
                    : 'switched-on'
                : withPicture
                  ? 'switched-off'
                  : 'switched-on';

        // try {
        //     //  Block of code to try
        //     new XAPIActivity({
        //         type: 'filter',
        //         verb: verb,
        //         lrs_endpoint: `${process.env.REACT_APP_BACKEND_XAPI}/beyim-lrs/xapi/`,
        //         local_url: window.location.href as string,
        //         content_name: typeSend,
        //         content_type: 'filter',
        //         language: locale,
        //         subject: dataCustomFeed?.data?.data[0]?.subject || '',
        //         section:
        //             (section?.translations.find(
        //                 translation => translation.locale === locale,
        //             )?.name as string) || '',
        //         topic:
        //             (topic?.translations.find(
        //                 translation => translation.locale === locale,
        //             )?.name as string) || '',
        //     }).sendStatment();
        // } catch (e) {
        //     console.warn(e);
        // }

        if (type === 'image') {
            if (withVideo) {
                setWithPicture(prev => !prev);
            } else {
                if (withPicture) {
                    setWithVideo(true);
                    setWithPicture(false);
                } else {
                    setWithPicture(false);
                }
            }
        } else {
            if (withPicture) {
                setWithVideo(prev => !prev);
            } else {
                if (withVideo) {
                    setWithPicture(true);
                    setWithVideo(false);
                } else {
                    setWithPicture(false);
                }
            }
        }

        scroll.scrollTo(0);
    };

    const changeTopic = debounce((prevOrNext: 'prev' | 'next') => {
        if (
            searchParams.get('subject') &&
            !isFetchingCustomFeed &&
            !isFetchingSectionsBySubject &&
            !isFetchingDataFeedMicrotopics &&
            dataCustomFeed
        ) {
            scroll.scrollTo(0);
            setSearchParams(prev => {
                const idSection = prev.get('sectionsBySubject') || '';

                const indexSection =
                    dataSectionsBySubject?.data.sections.findIndex(
                        section => String(section.id) === idSection,
                    ) === -1
                        ? 0
                        : dataSectionsBySubject?.data.sections.findIndex(
                              section => String(section.id) === idSection,
                          ) || 0;

                const index =
                    dataSectionsBySubject?.data.sections[
                        indexSection
                    ]?.children.findIndex(
                        children => String(children.id) === sectionId,
                    ) || 0;

                return {
                    idContent: prev.get('idContent') || '',
                    fromSearch: prev.get('fromSearch') || '',
                    subject: prev.get('subject') || '',
                    sectionsBySubject: prev.get('sectionsBySubject') || '',
                    them: String(
                        dataSectionsBySubject?.data.sections[indexSection]
                            ?.children[
                            prevOrNext === 'next' ? index + 1 : index - 1
                        ]?.id ||
                            dataSectionsBySubject?.data.sections[indexSection]
                                ?.children[0]?.id ||
                            dataSectionsBySubject?.data.sections[0]?.children[0]
                                ?.id ||
                            '',
                    ),
                };
            });

            setKeyForScroll(nanoid());
        }
    }, 1000);

    const loaderIndicator = useRef(null);

    useEffect(() => {
        setPosts([]);
        setCurrentPage(0);
    }, [locale, refetch]);

    useEffect(() => {
        if (dataFeed) {
            setIsLastPage(dataFeed.data.isLastPage);

            if (dataFeed.data.posts) {
                //
                if (currentPage === 0) {
                    setPosts(dataFeed.data.posts);
                } else {
                    setPosts(prev => {
                        const oldState = [...prev];
                        return [...oldState, ...dataFeed.data.posts];
                    });
                }
            }
        }
    }, [dataFeed]);
    useEffect(() => {
        if (dataFeed?.data.posts) {
            // Получаем данные для футера лайков и закладок
            getFootData({
                postIds: dataFeed.data.posts.map(post => post.id),
            })
                .unwrap()
                .then(data => {
                    if (currentPage === 0) {
                        setFeedLikesAndBookmarksData(data.data.postFeed);
                    } else {
                        setFeedLikesAndBookmarksData(prev => {
                            return [...prev, ...data.data.postFeed];
                        });
                    }
                });
        }
    }, [dataFeed, currentPage]);

    useEffect(() => {
        if (dataFeed && isVisible && !isFetchingDataFeed && !isLastPage) {
            setCurrentPage(prev => prev + 1);
        }
    }, [isVisible, isFetchingDataFeed, dataFeed]);

    useEffect(() => {
        setCurrentPage(0);
    }, [withPicture, withVideo]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchParams.get('subject')]);
    useEffect(() => {
        const idActivity = searchParams.get('idActivity');
        const scroll = searchParams.get('scroll');
        if (idActivity) {
            setTimeout(() => {
                scroller.scrollTo(idActivity, {
                    smooth: true,
                    offset: -250,
                });
            }, 700);
        }
        if (scroll) {
            setTimeout(() => {
                scroller.scrollTo(scroll, {
                    smooth: true,
                    offset: -250,
                });
            }, 700);
        }
    }, []);

    //------------------------------

    const [startY, setStartY] = useState<number | null>(null);
    const [isVisiblePrevMarker, setIsVisiblePrevMarker] =
        useState<boolean>(false);
    const [isVisibleNextMarker, setIsVisibleNextMarker] =
        useState<boolean>(false);
    const currentSectionsBySubject =
        dataSectionsBySubject?.data.sections.find(
            section =>
                String(section.id) ===
                (searchParams.get('sectionsBySubject') || ''),
        ) || dataSectionsBySubject?.data.sections[0];
    const indexChildren = currentSectionsBySubject?.children.findIndex(
        children => String(children.id) === sectionId,
    );

    const currentStatusChildren =
        indexChildren === 0 &&
        Number(indexChildren) + 1 === currentSectionsBySubject?.children.length
            ? 'one'
            : indexChildren === 0
              ? 'first'
              : Number(indexChildren) + 1 ===
                  currentSectionsBySubject?.children.length
                ? 'last'
                : !currentSectionsBySubject?.children
                  ? 'false'
                  : 'any';

    const markerForChangePrevTopic = useRef(null);
    const markerForChangeNextTopic = useRef(null);

    const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
        if (startY !== null) {
            const deltaY = e.touches[0].clientY - startY;
            // Отслеживаем свайп вниз на 50px
            if (deltaY > 10 && isVisiblePrevMarker) {
                changeTopic('prev');
            } else if (deltaY < -10 && isVisibleNextMarker) {
                changeTopic('next');
            }

            // Сбрасываем начальную позицию
            setStartY(null);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    setIsVisiblePrevMarker(entry.isIntersecting);
                });
            },
            { threshold: 0.5 }, // Порог видимости (от 0 до 1)
        );

        if (markerForChangePrevTopic.current) {
            observer.observe(markerForChangePrevTopic.current);
        }

        return () => {
            if (markerForChangePrevTopic.current) {
                observer.unobserve(markerForChangePrevTopic.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    setIsVisibleNextMarker(entry.isIntersecting);
                });
            },
            { threshold: 0.5 }, // Порог видимости (от 0 до 1)
        );

        if (markerForChangeNextTopic.current) {
            observer.observe(markerForChangeNextTopic.current);
        }

        return () => {
            if (markerForChangeNextTopic.current) {
                observer.unobserve(markerForChangeNextTopic.current);
            }
        };
    }, []);

    useEffect(() => {
        if (
            isFetchingCustomFeed ||
            isLoadingCustomFeed ||
            isFetchingSectionsBySubject ||
            isFetchingDataFeedMicrotopics ||
            isLoadingSectionsBySubject ||
            isLoadingDataFeedMicrotopics
        ) {
            setIsCatchLoadingCustomFeed(true);
        } else {
            setIsCatchLoadingCustomFeed(false);
        }
    }, [
        searchParams.get('subject'),
        dataCustomFeed,
        isFetchingCustomFeed,
        isLoadingCustomFeed,
        isFetchingSectionsBySubject,
        isFetchingDataFeedMicrotopics,
        isLoadingSectionsBySubject,
        isLoadingDataFeedMicrotopics,
    ]);
    useEffect(() => {
        document.title = t('pages.feed');
    }, [locale]);

    useEffect(() => {
        if (dataCustomFeed?.data.data) {
            getFootData({
                //@ts-ignore
                postIds: dataCustomFeed?.data?.data
                    ?.map(item => {
                        if (item.category !== 'activity') {
                            return item.post?.id;
                        }
                    })
                    .filter(Boolean),
            })
                .unwrap()
                .then(data => {
                    setCustomFeedLikesAndBookmarksData(data.data.postFeed);
                });
        }
    }, [dataCustomFeed]);

    return (
        <>
            <Helmet>
                <title>{t('pages.feed')}</title>
                <meta
                    name="description"
                    content="Dive into a world of learning with EduSite’s Student Learning Hub. Explore a wide range of subjects, access in-depth study materials, and test your knowledge with interactive task assessments. Perfect for students of all levels!"
                />
                <meta
                    name="keywords"
                    content="student learning, educational resources, subject guides, study materials, task assessments, interactive learning, EduSite subjects, student hub"
                />
            </Helmet>
            <div
                className={classNames(
                    'min-h-[calc(100vh-59px)] md:min-h-screen',
                    style.wrapper,
                )}
            >
                <Header
                    handleOpenFilterMobile={isOpen => {
                        if (isOpen) {
                            openModal();
                        } else {
                            closeModal();
                        }
                    }}
                    isOpenFilterMobile={isOpenFilter}
                />
                <main
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    className="container  grid gap-x-4 relative grid-cols-12 "
                >
                    <nav className=" md:col-span-2 md:block sm:hidden hidden">
                        {' '}
                        {/* Меню боковое{' '} */}
                    </nav>
                    <div className=" md:col-span-6 col-span-12 flex flex-col gap-4">
                        <div
                            className={classNames(
                                'sm:pt-3 sticky top-[48px] md:top-[56px] z-[101]',
                                style.wrapperSubjectFilter,
                            )}
                        >
                            <SubjectsFilter />
                        </div>

                        <div className="flex flex-col gap-4">
                            {searchParams.get('subject') ? (
                                isCatchLoadingCustomFeed ? (
                                    <>
                                        <PostSkeleton />
                                        <PostSkeleton />
                                        <PostSkeleton />
                                        <PostSkeleton />
                                    </>
                                ) : isCustomFeedError ? (
                                    <ErrorScreenWrapper type={'error'} />
                                ) : sectionId !== '' &&
                                  dataCustomFeed &&
                                  dataCustomFeed?.data?.data?.length > 0 ? (
                                    dataCustomFeed?.data.data?.map(
                                        itemActivityFeed => {
                                            const post = {
                                                microtopicId:
                                                    itemActivityFeed.microtopicId,
                                                objectiveId:
                                                    itemActivityFeed.objectiveId,
                                                subject:
                                                    itemActivityFeed.subject,
                                                microtopic:
                                                    itemActivityFeed.microtopic,
                                                objective:
                                                    itemActivityFeed.objective,
                                                iconUrl:
                                                    itemActivityFeed.iconUrl,
                                                // subjectId: 1,
                                                id: itemActivityFeed.post?.id,
                                                category:
                                                    itemActivityFeed.category,
                                                resources:
                                                    itemActivityFeed.post
                                                        ?.resources,
                                                contentId:
                                                    itemActivityFeed.post
                                                        ?.contentId,
                                                description:
                                                    itemActivityFeed.post
                                                        ?.description,
                                                thumbnail:
                                                    itemActivityFeed.post
                                                        ?.thumbnail,
                                                generatorType:
                                                    itemActivityFeed.post
                                                        ?.generatorType ||
                                                    'manual',
                                            };
                                            const postFooterData =
                                                customFeedLikesAndBookmarksData?.find(
                                                    postFooter =>
                                                        postFooter.postId ===
                                                        itemActivityFeed.post
                                                            ?.id,
                                                );

                                            if (
                                                itemActivityFeed.category ===
                                                'image'
                                            ) {
                                                return (
                                                    <ImagePost
                                                        data={post as Post}
                                                        key={
                                                            itemActivityFeed
                                                                .post?.id
                                                        }
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                );
                                            }

                                            if (
                                                itemActivityFeed.category ===
                                                'text'
                                            ) {
                                                return (
                                                    <TextPost
                                                        data={post as Post}
                                                        key={
                                                            itemActivityFeed
                                                                .post?.id
                                                        }
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                );
                                            }

                                            if (
                                                itemActivityFeed.category ===
                                                'video'
                                            ) {
                                                return (
                                                    <div
                                                        key={
                                                            itemActivityFeed
                                                                .post?.id
                                                        }
                                                    >
                                                        <VideoPost
                                                            data={post as Post}
                                                            postFooterData={
                                                                postFooterData
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (
                                                itemActivityFeed.category ===
                                                'activity'
                                            ) {
                                                return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    <ActivityPost
                                                        them={sectionId}
                                                        data={itemActivityFeed}
                                                    />
                                                );
                                            }

                                            return (
                                                <span
                                                    className="block h-28"
                                                    key={
                                                        itemActivityFeed.post
                                                            ?.id
                                                    }
                                                >
                                                    {itemActivityFeed.post?.id}
                                                </span>
                                            );
                                        },
                                    )
                                ) : (
                                    <ErrorScreenWrapper type={'empty'} />
                                )
                            ) : isDataFeedError ? (
                                <ErrorScreenWrapper type={'empty'} />
                            ) : (
                                <InfiniteScroll
                                    dataLength={posts.length}
                                    next={() => {
                                        setCurrentPage(prev => prev + 1);
                                    }}
                                    hasMore={!isLastPage}
                                    loader={
                                        <div className="flex flex-col gap-4 pt-4">
                                            <PostSkeleton />
                                            <PostSkeleton />
                                            <PostSkeleton />
                                        </div>
                                    }
                                >
                                    <div className="flex flex-col gap-4">
                                        {posts.map(item => {
                                            const postFooterData =
                                                feedLikesAndBookmarksData.find(
                                                    postFooter =>
                                                        postFooter.postId ===
                                                        item.id,
                                                );

                                            if (item.category === 'image') {
                                                return (
                                                    <ImagePost
                                                        data={item}
                                                        key={item.id}
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                );
                                            }

                                            if (item.category === 'text') {
                                                return (
                                                    <TextPost
                                                        data={item as Post}
                                                        key={item?.id}
                                                        postFooterData={
                                                            postFooterData
                                                        }
                                                    />
                                                );
                                            }

                                            if (item.category === 'video') {
                                                return (
                                                    <div key={item.id}>
                                                        <VideoPost
                                                            data={item}
                                                            postFooterData={
                                                                postFooterData
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (item.category === 'activity') {
                                                return (
                                                    <div
                                                        className=""
                                                        key={item.id}
                                                    >
                                                        картинки
                                                        <div className=" h-96"></div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </InfiniteScroll>
                            )}
                        </div>

                        {searchParams.get('subject') && <AssessmentStarting />}

                        <div className="hidden md:flex justify-between pb-10">
                            <div>
                                <button
                                    className={classNames(
                                        'h-10 rounded-2xl flex items-center gap-2 px-4 text-[#678aa1] hover:border hover:border-solid hover:border-[#55BBEB] group hover:bg-[#E4F3FB] active:bg-[#55BBEB]  active:text-[white] duration-200',
                                        searchParams.get('subject') &&
                                            currentStatusChildren !== 'one' &&
                                            currentStatusChildren !== 'first' &&
                                            currentStatusChildren !== 'false'
                                            ? 'flex'
                                            : 'hidden',

                                        style.grayColor,
                                        style.buttonChangeTopic,
                                    )}
                                    onClick={() => {
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'Prev_topic',
                                                feed_id:
                                                    searchParams.get('subject'),
                                                topic_id:
                                                    searchParams.get('them'),
                                                locale: locale,
                                            },
                                            dataLayerName: 'feed',
                                        });
                                        changeTopic('prev');
                                    }}
                                >
                                    <img
                                        src="/icons/changeTopicArrow.svg"
                                        className={classNames(
                                            'w-2 h-2 block group-hover:hidden',
                                            locale === 'ar' ? '' : 'rotate-180',
                                        )}
                                        alt="arrowRightSlider"
                                    />
                                    <img
                                        src="/icons/blueArrow.svg"
                                        className={classNames(
                                            'w-2 h-2 hidden group-hover:block group-active:hidden',
                                            locale === 'ar' ? '' : 'rotate-180',
                                        )}
                                        alt="arrowRightSlider"
                                    />
                                    <img
                                        src="/icons/whiteArrow.svg"
                                        className={classNames(
                                            'w-2 h-2 hidden group-active:block',
                                            locale === 'ar' ? '' : 'rotate-180',
                                        )}
                                        alt="arrowRightSlider"
                                    />
                                    <span
                                        className={classNames(
                                            style.buttonChangeTopicColor,
                                            'text-sm group-hover:text-[#55BBEB] group-active:text-[white]',
                                        )}
                                    >
                                        {t('feed.lastTopic')}
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button
                                    className={classNames(
                                        'h-10 rounded-2xl flex items-center px-4 gap-2 text-[#678aa1] hover:border hover:border-solid hover:border-[#55BBEB]  hover:bg-[#E4F3FB] group  active:bg-[#55BBEB] active:text-[white] duration-200',
                                        searchParams.get('subject') &&
                                            currentStatusChildren !== 'one' &&
                                            currentStatusChildren !== 'last' &&
                                            currentStatusChildren !== 'false'
                                            ? 'flex'
                                            : 'hidden',

                                        style.buttonChangeTopic,
                                    )}
                                    onClick={() => {
                                        const topicId = !searchParams.get(
                                            'them',
                                        )
                                            ? 'first_click'
                                            : searchParams.get('them');
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                event: 'Next_topic',
                                                feed_id:
                                                    searchParams.get('subject'),
                                                topic_id: topicId,
                                                locale: locale,
                                            },
                                            dataLayerName: 'feed',
                                        });
                                        changeTopic('next');
                                    }}
                                >
                                    <span
                                        className={classNames(
                                            style.buttonChangeTopicColor,
                                            'text-sm group-hover:text-[#55BBEB] group-active:text-[white]',
                                        )}
                                    >
                                        {t('feed.nextTopic')}
                                    </span>{' '}
                                    <img
                                        src="/icons/changeTopicArrow.svg"
                                        className={classNames(
                                            'w-2 h-2 block group-hover:hidden',
                                            locale === 'ar' ? 'rotate-180' : '',
                                        )}
                                        alt="arrowRightSlider"
                                    />
                                    <img
                                        src="/icons/blueArrow.svg"
                                        className={classNames(
                                            'w-2 h-2 hidden group-hover:block group-active:hidden',
                                            locale === 'ar' ? 'rotate-180' : '',
                                        )}
                                        alt="arrowLeftSlider"
                                    />
                                    <img
                                        src="/icons/whiteArrow.svg"
                                        className={classNames(
                                            'w-2 h-2 hidden group-active:block',
                                            locale === 'ar' ? 'rotate-180' : '',
                                        )}
                                        alt="arrowLeftSlider"
                                    />
                                </button>
                            </div>
                        </div>

                        {/* <div ref={loaderIndicator}> </div> */}

                        <More />
                    </div>

                    <RightBlock
                        handleChangeFilter={handleChangeFilter}
                        withPicture={withPicture}
                        withVideo={withVideo}
                    />
                </main>
                <SectionMobile
                    closeModal={() => {
                        closeModal();
                    }}
                    isOpen={isOpenFilter}
                    openModal={() => {
                        openModal();
                    }}
                />
            </div>
        </>
    );
};

export default Feed;
