/* eslint-disable react/display-name */
import classNames from 'classnames';
import { Daum } from '../../types/GetCustomFeedRequestApiType';
import { HeaderPost } from '../HeaderPost';
import style from './ActivityPost.module.scss';
import { Question } from './utils/Question';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import React, { useEffect, useRef, useState } from 'react';
import { current, nanoid } from '@reduxjs/toolkit';
import { Element } from 'react-scroll';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks';
import { useGetFooterDataByPostIdsQuery } from '../../api/contentInteractionApi/contentInteractionApi';
import { PostFeed } from '../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';
import TagManager from 'react-gtm-module';

interface IActivityPost {
    data: Daum;
    them?: string;
    postFooterData?: PostFeed;
}

export const ActivityPost: React.FC<IActivityPost> = ({
    data,
    postFooterData,
}) => {
    const [sliderRef, setSliderRef] = useState<any>(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const idActivity = searchParams.get('idActivity');
    const locale = useNormalizedLocale();

    const { data: footerData, refetch } = useGetFooterDataByPostIdsQuery(
        {
            postIds: data.activities?.map(activity => activity.id) || [],
        },
        {
            skip: !data.activities || data.activities.length === 0,
        },
    );

    useEffect(() => {
        if (idActivity) {
            if (sliderRef) {
                const currentIndex = data.activities?.findIndex(activity => {
                    return activity.id === idActivity;
                });

                currentIndex !== -1 && sliderRef.slideTo(currentIndex, 500);
            }
        }
    }, [idActivity, sliderRef]);

    useEffect(() => {
        if (data.activities && data.activities.length > 0) refetch();
    }, [data.activities]);
    return (
        <>
            <div
                className={classNames(
                    'sm:p-4 py-4 sm:rounded-2xl activity relative z-[9]',
                    style.wrapper,
                )}
            >
                <HeaderPost
                    iconUrl={data.iconUrl || '/icons/noIcon.svg'}
                    objective={data.objective}
                    subject={data.subject}
                    microptopic={data.microtopic}
                    dataActivity={data}
                    activeIndex={activeIndex}
                />
                {data.activities ? (
                    <div
                        className={classNames(
                            'relative px-4 sm:p-0',
                            // locale === 'ar' ? 'arLng' : '',
                        )}
                    >
                        {data.activities.map(item => {
                            return (
                                <Element
                                    key={`${item.id}-element`}
                                    name={item.id}
                                    className="element"
                                ></Element>
                            );
                        })}

                        <Swiper
                            onSwiper={(swiper: any) => {
                                setSliderRef(swiper);
                            }}
                            onSlideChange={(swiper: any) => {
                                const isType =
                                    activeIndex < swiper.activeIndex
                                        ? 'next_activity'
                                        : 'prev_activity';
                                const activity_prev = data.activities
                                    ? data.activities[activeIndex].id
                                    : 'null';
                                const activity_next = data.activities
                                    ? data.activities[swiper.activeIndex].id
                                    : 'null';
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: isType,
                                        activity_id_prev: activity_prev,
                                        activity_id_next: activity_next,
                                        subject_id: data.subjectId,
                                        locale: locale,
                                    },
                                    dataLayerName: 'feed',
                                });
                                setActiveIndex(swiper.activeIndex);
                            }}
                            autoHeight={true}
                            modules={[Pagination, Navigation]}
                            navigation={true}
                            pagination={{
                                type: 'fraction',

                                renderFraction: (
                                    currentClass: string,
                                    totalClass: string,
                                ) => {
                                    return locale === 'ar'
                                        ? `<div dir="rtl"><span class=" text-base font-bold ${style.pagination} ` +
                                              currentClass +
                                              '"></span>' +
                                              `<span class="text-base font-bold ${style.pagination}">/</span>` +
                                              `<span class=" text-base font-bold ${style.pagination} ` +
                                              totalClass +
                                              '"></span></div>'
                                        : `<span class=" text-base font-bold ${style.pagination} ` +
                                              currentClass +
                                              '"></span>' +
                                              `<span class="text-base font-bold ${style.pagination}">/</span>` +
                                              `<span class=" text-base font-bold ${style.pagination} ` +
                                              totalClass +
                                              '"></span>';
                                },
                            }}
                            height={200}
                            updateOnWindowResize={true}
                        >
                            {data.activities.map(item => {
                                return (
                                    <SwiperSlide key={`${item?.id}`}>
                                        <div
                                            className={
                                                data.activities &&
                                                data.activities.length > 1
                                                    ? 'pb-16'
                                                    : ''
                                            }
                                        >
                                            <Question
                                                onResize={() => {
                                                    if (sliderRef) {
                                                        sliderRef.updateAutoHeight(
                                                            200,
                                                        );
                                                    }
                                                }}
                                                data={item}
                                                activityFooterData={
                                                    postFooterData
                                                        ? postFooterData
                                                        : footerData?.data.postFeed.find(
                                                              post =>
                                                                  post.postId ===
                                                                  item.id,
                                                          )
                                                }
                                            />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>

                        {data.activities.length > 1 && (
                            <hr
                                className={classNames(
                                    'absolute h-[2px] bottom-12 rounded-[10px] w-[calc(100%_-_32px)]',
                                    style.hr,
                                )}
                            />
                        )}
                    </div>
                ) : (
                    data.activity && (
                        <div key={data.activity?.id}>
                            <Element
                                name={data.activity.id}
                                className="element"
                            ></Element>
                            <div>
                                <Question
                                    data={data.activity}
                                    microtopic={data.microtopic}
                                    category={data.category}
                                    subject={data.subject}
                                    activityFooterData={
                                        postFooterData
                                            ? postFooterData
                                            : footerData?.data.postFeed.find(
                                                  post =>
                                                      post.postId ===
                                                      data.activity?.id,
                                              )
                                    }
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
            <Tooltip
                id="hint"
                className={classNames(
                    'p-3 rounded-xl max-w-80',
                    style.wrapperTooltip,
                )}
                offset={18}
                place="bottom"
                opacity={1}
            >
                {t('feed.hint')}
            </Tooltip>
        </>
    );
};
