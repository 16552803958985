import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import style from './ChangingLanguage.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { useAppSelector } from '../../hooks';
import { roles } from '../AuthForms/constants';

interface IChangingLanguage {
    onlyDesktop?: boolean;
    mobile?: boolean;
}

export const ChangingLanguage: React.FC<IChangingLanguage> = ({
    onlyDesktop = false,
    mobile,
}) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const role = useAppSelector(state => state.auth.role);
    const [isOpenLanguageMobile, setIsOpenLanguageMobile] = useState(false);
    const locale = useNormalizedLocale();

    const changeLng = (lng: 'kk' | 'en' | 'ru' | 'ar' | 'ko') => {
        const root = document.documentElement;
        if (lng === 'ar') {
            root.style.setProperty('--location-support-left', '20px');
            root.style.setProperty('--location-support-right', 'auto');
        } else {
            root.style.setProperty('--location-support-right', '20px');
            root.style.setProperty('--location-support-left', 'auto');
        }
        localStorage.setItem('i18nextLng', lng);
        i18n.changeLanguage(lng);
        document.documentElement.lang = lng;
        // if (lng === 'ar') {
        //     document.documentElement.dir = 'rtl';
        // } else {
        //     document.documentElement.dir = 'lrt';
        // }

        setOpen(false);
    };

    useEffect(() => {
        document.documentElement.lang = locale;
        if (locale === 'ar') {
            document.documentElement.dir = 'rtl';
        } else {
            document.documentElement.dir = 'lrt';
        }
    }, [locale]);

    useEffect(() => {
        if (role === roles.EXPERT) {
            changeLng('kk');
        }
    }, [role]);

    // useEffect(() => {
    //     if (locale !== 'kk' || locale !== 'ru' || locale !== 'en') {
    //         const lngLocal = String(
    //             localStorage.getItem('i18nextLng'),
    //         ) as string;

    //         //@ts-ignore
    //         if (lngLocal === 'kk' || lngLocal === 'ru' || lngLocal === 'en') {
    //             localStorage.setItem('i18nextLng', lngLocal);
    //             i18n.changeLanguage(lngLocal);
    //         } else {
    //             localStorage.setItem('i18nextLng', 'kk');
    //             i18n.changeLanguage('kk');
    //         }
    //     }
    // }, []);

    return mobile ? (
        <ul>
            <li
                className={classNames(
                    'px-4 py-4 rounded-2xl bg-[#EDF5FA] w-full cursor-pointer',
                    !isOpenLanguageMobile ? 'h-[54px]' : 'min-h-[54px]',
                )}
                onClick={() => {
                    setIsOpenLanguageMobile(!isOpenLanguageMobile);
                }}
            >
                <div className="flex w-full justify-between items-center">
                    <div className="flex flex-row gap-2">
                        <img
                            src="/icons/global.svg"
                            alt="global"
                            className="w-6 h-6"
                        />
                        <span className="text-base font-medium leading-[22.4px] text-[#678AA1]">
                            {t('authorization.selectLanguage')}
                        </span>
                    </div>
                    <button>
                        <img
                            src="/icons/profileGo.svg"
                            alt="go"
                            className={classNames(
                                'w-[18px] h-[18px] transform rotate-90',
                            )}
                        />
                    </button>
                </div>
                {isOpenLanguageMobile && (
                    <div className="mt-[17px]">
                        <div
                            className={classNames(
                                'flex flex-col min-w-[208px] rounded-lg',
                            )}
                        >
                            {role !== roles.EXPERT && (
                                <button
                                    className={classNames(
                                        'flex h-10 items-center duration-200 text-sm justify-between p-2 rounded-lg ',
                                        locale === 'ru' &&
                                            style.selectedLanguage,
                                    )}
                                    onClick={e => {
                                        changeLng('ru');
                                    }}
                                >
                                    <span className="text-[#364954] text-base font-medium leading-[22.4px]">
                                        Русский
                                    </span>
                                    {locale === 'ru' && (
                                        <img
                                            src="/icons/checkedLanguage.svg"
                                            alt="check"
                                        />
                                    )}
                                </button>
                            )}

                            <button
                                className={classNames(
                                    'flex h-10 items-center duration-200 text-sm justify-between p-2 rounded-lg',
                                    locale === 'kk' && style.selectedLanguage,
                                )}
                                onClick={e => {
                                    changeLng('kk');
                                }}
                            >
                                <span className="text-[#364954] text-base font-medium leading-[22.4px]">
                                    Қазақша
                                </span>
                                {locale === 'kk' && (
                                    <img
                                        src="/icons/checkedLanguage.svg"
                                        alt="check"
                                    />
                                )}
                            </button>
                            {role !== roles.EXPERT && (
                                <>
                                    <button
                                        className={classNames(
                                            'flex h-10 items-center duration-200 text-sm justify-between p-2 rounded-lg',
                                            locale === 'en' &&
                                                style.selectedLanguage,
                                        )}
                                        onClick={e => {
                                            changeLng('en');
                                        }}
                                    >
                                        <span className="text-[#364954] text-base font-medium leading-[22.4px]">
                                            English
                                        </span>
                                        {locale === 'en' && (
                                            <img
                                                src="/icons/checkedLanguage.svg"
                                                alt="check"
                                            />
                                        )}
                                    </button>
                                    <button
                                        className={classNames(
                                            'flex h-10 items-center duration-200 text-sm justify-between p-2 rounded-lg',
                                            locale === 'ar' &&
                                                style.selectedLanguage,
                                        )}
                                        onClick={e => {
                                            changeLng('ar');
                                        }}
                                    >
                                        <span className="text-[#364954] text-base font-medium leading-[22.4px]">
                                            العربية
                                        </span>
                                        {locale === 'ar' && (
                                            <img
                                                src="/icons/checkedLanguage.svg"
                                                alt="check"
                                            />
                                        )}
                                    </button>
                                    <button
                                        className={classNames(
                                            'flex h-10 items-center duration-200 text-sm justify-between p-2 rounded-lg',
                                            locale === 'ko' &&
                                                style.selectedLanguage,
                                        )}
                                        onClick={e => {
                                            changeLng('ko');
                                        }}
                                    >
                                        <span className="text-[#364954] text-base font-medium leading-[22.4px]">
                                            한국어
                                        </span>
                                        {locale === 'ko' && (
                                            <img
                                                src="/icons/checkedLanguage.svg"
                                                alt="check"
                                            />
                                        )}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </li>
        </ul>
    ) : (
        <div className="">
            <div
                className={classNames(
                    onlyDesktop ? 'hidden' : 'flex gap-4   md:hidden',
                )}
            >
                <button
                    onClick={e => {
                        changeLng('kk');
                    }}
                >
                    <span
                        className={classNames(
                            style.lngButton,
                            locale === 'kk' ? style.lngActive : '',
                            ' font-medium  p-[10px] block',
                        )}
                    >
                        Қаз
                    </span>
                </button>
                <button
                    onClick={e => {
                        changeLng('ru');
                    }}
                    className={classNames(' font-bold')}
                >
                    {' '}
                    <span
                        className={classNames(
                            style.lngButton,
                            locale === 'ru' ? style.lngActive : '',
                            ' font-medium  p-[10px] block',
                        )}
                    >
                        Рус
                    </span>
                </button>
                <button
                    onClick={e => {
                        changeLng('en');
                    }}
                >
                    <span
                        className={classNames(
                            style.lngButton,
                            locale === 'en' ? style.lngActive : '',
                            'font-medium p-[10px] block',
                        )}
                    >
                        Eng
                    </span>
                </button>

                <button
                    onClick={e => {
                        changeLng('ar');
                    }}
                >
                    <span
                        className={classNames(
                            style.lngButton,
                            locale === 'ar' ? style.lngActive : '',
                            'font-medium p-[10px] block',
                        )}
                    >
                        العربية
                    </span>
                </button>
                <button
                    onClick={e => {
                        changeLng('ko');
                    }}
                >
                    <span
                        className={classNames(
                            style.lngButton,
                            locale === 'ko' ? style.lngActive : '',
                            'font-medium p-[10px] block',
                        )}
                    >
                        한국어
                    </span>
                </button>
            </div>
            <button
                data-tooltip-id="changing-language"
                className={classNames(
                    'h-6 text-sm items-center gap-1',
                    onlyDesktop ? 'flex' : 'hidden md:flex ',
                )}
                data-tooltip-offset={24}
                onClick={() => {
                    setOpen(prev => !prev);
                }}
            >
                {locale === 'kk'
                    ? 'Қаз'
                    : locale === 'ru'
                      ? 'Рус'
                      : locale === 'en'
                        ? 'Eng'
                        : locale === 'ko'
                          ? '한국어'
                          : 'العربية'}
                <img
                    src="/icons/arrow-top.svg"
                    className={classNames(
                        'block h-6 w-6 duration-200',
                        open ? '' : 'rotate-180',
                    )}
                    alt="arrow"
                />
            </button>

            {open && (
                <div className="z-[1000] relative	">
                    <Tooltip
                        id="changing-language"
                        className={classNames(
                            style.wrapperTooltip,
                            'drop-shadow-2xl',
                        )}
                        isOpen={open}
                        setIsOpen={value => {
                            setOpen(value);
                        }}
                        openOnClick={true}
                        clickable={true}
                        noArrow={true}
                        opacity={1}
                        place={'bottom-end'}
                        afterShow={() => {
                            setOpen(true);
                        }}
                        afterHide={() => {
                            setOpen(false);
                        }}
                    >
                        <div
                            className={classNames(
                                style.tooltip,
                                'flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden',
                            )}
                        >
                            <span
                                className={classNames(
                                    'flex h-10 items-center px-4 text-sm',
                                )}
                            >
                                {t('authorization.selectLanguage')}
                            </span>
                            {role !== roles.EXPERT && (
                                <button
                                    className={classNames(
                                        'flex h-10 items-center px-4 duration-200 text-sm justify-between',
                                    )}
                                    onClick={e => {
                                        changeLng('ru');
                                    }}
                                >
                                    <span>Русский</span>
                                    {locale === 'ru' && (
                                        <img
                                            src="/icons/check.svg"
                                            alt="check"
                                        />
                                    )}
                                </button>
                            )}

                            <button
                                className={classNames(
                                    'flex h-10 items-center px-4 duration-200 text-sm justify-between',
                                )}
                                onClick={e => {
                                    changeLng('kk');
                                }}
                            >
                                <span>Қазақша</span>
                                {locale === 'kk' && (
                                    <img src="/icons/check.svg" alt="check" />
                                )}
                            </button>
                            {role !== roles.EXPERT && (
                                <>
                                    <button
                                        className={classNames(
                                            'flex h-10 items-center px-4 duration-200 text-sm justify-between',
                                        )}
                                        onClick={e => {
                                            changeLng('en');
                                        }}
                                    >
                                        <span>English</span>
                                        {locale === 'en' && (
                                            <img
                                                src="/icons/check.svg"
                                                alt="check"
                                            />
                                        )}
                                    </button>
                                    <button
                                        className={classNames(
                                            'flex h-10 items-center px-4 duration-200 text-sm justify-between',
                                        )}
                                        onClick={e => {
                                            changeLng('ar');
                                        }}
                                    >
                                        <span>العربية</span>
                                        {locale === 'ar' && (
                                            <img
                                                src="/icons/check.svg"
                                                alt="check"
                                            />
                                        )}
                                    </button>
                                    <button
                                        className={classNames(
                                            'flex h-10 items-center px-4 duration-200 text-sm justify-between',
                                        )}
                                        onClick={e => {
                                            changeLng('ko');
                                        }}
                                    >
                                        <span>한국어</span>
                                        {locale === 'ko' && (
                                            <img
                                                src="/icons/check.svg"
                                                alt="check"
                                            />
                                        )}
                                    </button>
                                </>
                            )}
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
