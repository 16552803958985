import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { TopicInfo } from './types';
import { getProgressByStudentsResponseApiType } from '../beyimProgressDashboard/types/getProgressByStudentsResponseApiType/getProgressByStudentsResponseApiType';

export const progressServiceApi = createApi({
    reducerPath: 'progressServiceApi',
    tagTypes: ['Progress'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PROGRESS_URL}/api/v2/`,
        prepareHeaders: headers => {
            const token = Cookies.get('access_token');
            const id_token = Cookies.get('id_token');

            // If we have a token set in state, let's assume that we should be passing it.
            if (token && id_token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('X-Api-Key', id_token);
            }

            return headers;
        },
    }),
    endpoints: build => ({
        getV2ProgressByUnit: build.query<
            {
                success: boolean;
                message: string;
                data: { data: { topics: TopicInfo[] } };
            },
            {
                subjectId: string;
                classId: string;
                sectionId: string;
            }
        >({
            query: ({ subjectId, classId, sectionId }) => {
                return {
                    url: '/progress/unit',
                    method: 'GET',
                    params: {
                        'subject-id': subjectId,
                        'class-id': classId,
                        'section-id': sectionId,
                    },
                };
            },
        }),
        getV2ProgressByStudents: build.query<
            getProgressByStudentsResponseApiType,
            {
                subjectId: string;
                classId: string;
                sectionId: string;
                limit: number;
                userIds: string[];
                page: number;
            }
        >({
            query: ({
                subjectId,
                classId,
                sectionId,
                limit,
                userIds,
                page,
            }) => {
                return {
                    url: '/progress/students',
                    method: 'GET',
                    params: {
                        'subject-id': subjectId,
                        'class-id': classId,
                        'section-id': sectionId,
                        limit,
                        offset: page * limit,
                        'user-ids': userIds,
                    },
                };
            },
        }),
    }),
});

export const { useGetV2ProgressByUnitQuery, useGetV2ProgressByStudentsQuery } =
    progressServiceApi;
