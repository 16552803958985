import { useForm } from 'react-hook-form';
import { roles } from '../AuthForms/constants';
import { FC } from 'react';

import styles from './AddClassToTeacher.module.scss';
import { useLazyGetAllClassesQuery } from '../../api/organizationService';
import {
    useGetUserDataQuery,
    useUpdateUserByAdminMutation,
} from '../../api/authService/authServiceApi';

interface IAddClassToTeacher {
    role: roles;
}
export const AddClassToTeacher: FC<IAddClassToTeacher> = ({ role }) => {
    const { watch, handleSubmit } = useForm();

    const { data: me } = useGetUserDataQuery('');
    const [getClasses, { data: classes }] = useLazyGetAllClassesQuery();


    const [updateUserByAdmin, { isLoading: isLoadingUpdate }] =
        useUpdateUserByAdminMutation();


    return <form className={styles.form}>add class</form>;
};
