import classNames from 'classnames';
import style from './Header.module.scss';
import { title } from 'process';
import Skeleton from 'react-loading-skeleton';
import { useNormalizedLocale } from '../../hooks';
import { Tooltip } from 'react-tooltip';
import TagManager from 'react-gtm-module';

interface IHeaderMore {
    onBack?: () => void;
    title?: string;
    subTitle?: string;
    isLoadingContent?: boolean;
    isLoadingSubjects?: boolean;
    isFetchingContent?: boolean;
    isFetchingSubjects?: boolean;
    contentId?: string;
    subjectId?: number;
}

export const HeaderMore: React.FC<IHeaderMore> = ({
    onBack,
    subTitle,
    title,
    isLoadingContent,
    isLoadingSubjects,
    isFetchingContent,
    isFetchingSubjects,
    contentId,
    subjectId,
    ...rest
}) => {
    const locale = useNormalizedLocale();
    return (
        <header
            className={classNames(
                'h-14 flex sticky top-0 items-center px-6 rounded-bl-2xl rounded-br-2xl sm:rounded-none',
                style.wrapper,
            )}
            {...rest}
        >
            <button
                onClick={() => {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'back_post',
                            content_id: contentId,
                            subject_id: subjectId,
                            locale: locale,
                        },
                        dataLayerName: 'feed',
                    });
                    onBack && onBack();
                }}
                className={classNames(
                    'w-10 h-10 rounded-full border-2 flex items-center justify-center shrink-0 grow-0',
                    style.button,
                )}
            >
                <img
                    src="/icons/arrowBack.svg"
                    className={classNames(
                        'w-6 h-6 block',
                        locale === 'ar' ? 'rotate-180' : '',
                    )}
                    alt="arrowBack"
                />
            </button>
            <div className="md:ms-[121px] pl-2">
                {isLoadingContent ||
                isLoadingSubjects ||
                isFetchingContent ||
                isFetchingSubjects ? (
                    <Skeleton
                        count={2}
                        height={16}
                        width={150}
                        baseColor="#e9f0f3"
                    />
                ) : (
                    <>
                        <h2
                            className={classNames(
                                'text-base font-bold whitespace-nowrap text-ellipsis',
                                style.title,
                            )}
                        >
                            {title}
                        </h2>
                        <div
                            className=" overflow-hidden"
                            data-tooltip-id="info"
                        >
                            <span
                                className={classNames(
                                    'text-xs font-medium whitespace-nowrap text-ellipsis overflow-hidden block max-w-[calc(100vw_-_40px_-_24px_-_32px)]',
                                    style.subText,
                                )}
                            >
                                {subTitle}
                            </span>
                        </div>
                    </>
                )}
            </div>
            <Tooltip
                id={'info'}
                className={classNames(style.wrapperTooltip, 'drop-shadow-2xl')}
                clickable={true}
                place="bottom"
                opacity={1}
            >
                {subTitle}
            </Tooltip>
        </header>
    );
};
