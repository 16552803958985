import { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import style from './BeyimAccordion.module.scss';
import { nanoid } from '@reduxjs/toolkit';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

interface IBeyimAccordion {
    children?: ReactNode;
    name?: string;
    title?: string;
    titleChildren?: string;
    onEdit?: () => void;
    onAddChildren?: () => void;
    bg?: 'blue' | 'white' | 'light';
    disabled?: boolean;
    onDelete?: () => void;
    forceOpen?: boolean;
    onResetNewSectionAdded?: () => void;
    className?: string;
}

export const BeyimAccordion: React.FC<IBeyimAccordion> = ({
    children,
    name = '',
    title = '',
    onEdit,
    titleChildren = '',
    onAddChildren,
    onDelete,
    disabled = false,
    bg = 'white',
    forceOpen = false,
    onResetNewSectionAdded,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);
    const id = nanoid();
    const { t } = useTranslation(['micro-topics']);

    useEffect(() => {
        const clickOutsideHandle = (e: MouseEvent) => {
            if (divRef && !divRef.current?.contains(e.target as Node)) {
                setIsOpenTooltip(false);
            }
        };

        document.addEventListener('click', clickOutsideHandle);

        return () => document.removeEventListener('click', clickOutsideHandle);
    }, []);
    useEffect(() => {
        if (forceOpen) {
            setIsOpen(true);
            onResetNewSectionAdded && onResetNewSectionAdded();
        }
    }, [forceOpen]);

    return (
        <div>
            <div
                className={classNames(
                    'min-h-[64px] flex px-4 py-3 gap-2 flex-col justify-start',
                    style[`bg-${bg}`],
                )}
            >
                <div
                    className={classNames(
                        'flex min-h-[44px]  items-center gap-3',
                    )}
                >
                    <div
                        onClick={() => {
                            setIsOpen(prev => {
                                return disabled ? false : !prev;
                            });
                        }}
                        className={classNames(
                            'flex flex-col cursor-pointer flex-grow',
                        )}
                    >
                        <span
                            className={classNames(
                                'text-sm leading-6 font-medium overflow-hidden whitespace-nowrap text-ellipsis block max-w-[300px] w-fit cursor-default',
                                style.blueText,
                            )}
                        >
                            {title}
                        </span>
                        <span
                            className={classNames(
                                'font-semibold text-base leading-6 line-clamp-2',
                                style.blackText,
                            )}
                        >
                            {name}
                        </span>
                    </div>
                    <div
                        className={classNames(
                            'ms-auto flex gap-2 items-center',
                        )}
                    >
                        <div ref={divRef}>
                            {!disabled && (
                                <button
                                    className={classNames(
                                        'rounded-full w-[32px] h-[32px] flex justify-center items-center bg-[#FFF]',
                                        style.arrowDown,
                                    )}
                                    onClick={() => {
                                        setIsOpen(prev => !prev);
                                    }}
                                >
                                    <img
                                        className={classNames(
                                            '',
                                            isOpen ? style['rotate-180'] : '',
                                            'block',
                                        )}
                                        src={'/icons/arrowDownAccordion.svg'}
                                        alt={'down'}
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && !disabled && (
                <div className={style[`bg-${bg}`]}>{children}</div>
            )}
        </div>
    );
};
