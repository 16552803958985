import { useRef, useState } from 'react';
import style from './Userdata.module.scss';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import {
    useGetUserDataQuery,
    useUpdateUserDataMutation,
} from '../../../api/authService/authServiceApi';
import { useTranslation } from 'react-i18next';
import { useUploadImageUserMutation } from '../../../api/storageService';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

export const UserData: React.FC = () => {
    const { t } = useTranslation();

    const { data: userData, isLoading } = useGetUserDataQuery('');
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadImage] = useUploadImageUserMutation();
    const [updateUserData] = useUpdateUserDataMutation();
    const handleGalleryAdd = () => {
        fileInputRef.current?.click();
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formdata = new FormData();
        const file = event.target.files![0];
        if (file) {
            formdata.append('photos', file);
            const id = toast.loading(t('mobile.loadingPhoto'));
            uploadImage({
                file: formdata,
                id: userData?.data.User.id || '',
            })
                .unwrap()
                .then(res => {
                    updateUserData({
                        ...userData?.data.User,
                        picture: res.data.filepaths[0],
                    })
                        .unwrap()
                        .then(() => {
                            toast.update(id, {
                                render: t('mobile.successPhoto'),
                                type: 'success',
                                autoClose: 2000,
                                isLoading: false,
                            });
                        })
                        .catch(() => {
                            toast.update(id, {
                                render: t('mobile.errorPhoto'),
                                type: 'error',
                                autoClose: 2000,
                                isLoading: false,
                            });
                        });
                })
                .catch(error => {
                    toast.update(id, {
                        render: t('mobile.errorPhoto'),
                        type: 'error',
                        autoClose: 2000,
                        isLoading: false,
                    });
                });
        }
    };

    const handleDelete = () => {
        toast.promise(
            updateUserData({
                ...userData?.data.User,
                picture: '',
            })
                .unwrap()
                .catch(() => {
                    return Promise.reject(t('mobile.errorDeletePhoto'));
                }),
            {
                pending: t('mobile.isLoadingDeletePhoto'),
                success: t('mobile.successDeletePhoto'),
                error: t('mobile.errorDeletePhoto'),
            },
            {
                toastId: 'deleteImage',
                position: 'top-center',
            },
        );
    };
    return (
        <div className="w-full py-2">
            <div className="flex mb-2 items-center gap-[7px] ">
                <div className="relative">
                    {isLoading ? (
                        <Skeleton circle={true} height={56} width={56} />
                    ) : userData?.data.User.picture ? (
                        <img
                            src={
                                userData?.data.User.picture ||
                                '/icons/student2.svg'
                            }
                            className="w-[63px] h-[63px] block rounded-full object-cover object-center"
                            alt="student"
                        />
                    ) : (
                        <div className="w-[63px] h-[63px] rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium">
                            {userData?.data.User.firstName[0]?.toUpperCase()}
                            {userData?.data.User.secondName[0]?.toUpperCase()}
                        </div>
                    )}

                    <button
                        data-tooltip-id="edit"
                        className="absolute bottom-[-5px] right-0 shadow-lg w-[21px] h-[21px] bg-[#FFF] rounded-full flex justify-center items-center z-10"
                        type="button"
                        onClick={() => {
                            setIsTooltipOpen(true);
                        }}
                    >
                        <img
                            className="p-[3px]"
                            src="/icons/pencil.svg"
                            alt="online"
                        />
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        accept=".png, .jpg, .jpeg, .svg"
                    />
                    <Tooltip
                        id="edit"
                        className={classNames(
                            style.wrapperTooltip,
                            'drop-shadow-2xl',
                        )}
                        isOpen={isTooltipOpen}
                        setIsOpen={value => {
                            setIsTooltipOpen(value);
                        }}
                        openOnClick={true}
                        clickable={true}
                        noArrow={true}
                        opacity={1}
                        place={'bottom-end'}
                        afterShow={() => {
                            setIsTooltipOpen(true);
                        }}
                        afterHide={() => {
                            setIsTooltipOpen(false);
                        }}
                    >
                        <div
                            className={
                                'bg-[#FFF] rounded-2xl p-4 w-[206px] flex flex-col gap-4 '
                            }
                        >
                            <button
                                onClick={handleGalleryAdd}
                                className="flex gap-2 items-center text-[#678AA1] text-sm font-medium leading-[16.8px]"
                            >
                                <img
                                    className="w-5 h-5"
                                    src="/icons/imageUpload.svg"
                                    alt="upload"
                                />
                                {t('mobile.uploadPhoto')}
                            </button>

                            <button
                                onClick={handleDelete}
                                className="flex gap-2 items-center text-[#678AA1] text-sm font-medium leading-[16.8px]"
                            >
                                <img
                                    className="w-5 h-5"
                                    src="/icons/trash.svg"
                                    alt="upload"
                                />
                                {t('mobile.deletePhoto')}
                            </button>
                        </div>
                    </Tooltip>
                </div>
                <div className="flex flex-col">
                    <span className="font-bold text-base text-[#364954] leading-[20.8px]">
                        {userData?.data.User.firstName +
                            ' ' +
                            userData?.data.User.secondName ?? t('profile.user')}
                    </span>
                    <span
                        className={classNames(
                            style.mail,
                            ' text-sm text-[#678AA1] leading-[16.8px] font-medium',
                        )}
                    >
                        {userData?.data.User.email}
                    </span>
                </div>
            </div>
        </div>
    );
};
