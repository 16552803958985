import React from 'react';
import style from './TypeUser.module.scss';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { states } from '../constants';
import { Link } from 'react-router-dom';
import { useNormalizedLocale } from '../../../hooks';
interface TypeUserProps {
    activeType: number | undefined;
    setState: (state: states) => void;
    setActiveType: (type: number) => void;
}

export const TypeUser = ({
    activeType,
    setState,
    setActiveType,
}: TypeUserProps) => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();
    const userTypes = [
        {
            title: t('authorization.student'),
            description: t('authorization.studentText'),
            img: '/icons/book.svg',
        },
        {
            title: t('authorization.teacher'),
            description: t('authorization.teacherText'),
            img: '/icons/book.svg',
        },
    ];

    return (
        <div className="flex flex-col gap-4 mb-1">
            {userTypes.map((type, index) => (
                <button
                    key={index}
                    onClick={() => {
                        setActiveType(index);
                        setState(states.enter_code);
                    }}
                    className={classNames(
                        'rounded-2xl max-w-[464px] w-full cursor-pointer ',
                        style.wrapper,
                        activeType === index && style.wrapperSelected,
                    )}
                >
                    <div className="flex flex-row gap-6 pl-4 pr-10 py-[24px] items-center justify-center">
                        <div
                            className={classNames(
                                'rounded-[50%] w-[46px] h-[46px] flex-shrink-0 flex items-center justify-center',
                                style.imgWrapper,
                                activeType === index &&
                                    style.imgWrapperSelected,
                            )}
                        >
                            <img
                                className="w-[19px] h-[19px]"
                                src={
                                    index === activeType
                                        ? `/icons/bookActive.svg`
                                        : `/icons/book.svg`
                                }
                                alt={'type.title'}
                            />
                        </div>
                        <div className="flex gap-[8px]">
                            <div className="text-left">
                                <h2
                                    className={classNames(
                                        'font-bold text-base m-0 !mb-[4px] leading-[19px]',
                                        style.colorTitle,
                                    )}
                                >
                                    {type.title}
                                </h2>
                                <p
                                    className={classNames(
                                        'm-0 font-normal text-sm leading-[normal]',
                                        style.colorDescription,
                                    )}
                                >
                                    {type.description}
                                </p>
                            </div>
                            <img
                                className="self-center w-[19px] h-[19px]"
                                src={
                                    activeType === index
                                        ? `/icons/goTypeActive.svg`
                                        : `/icons/goType.svg`
                                }
                                alt={'type.title'}
                            />
                        </div>
                    </div>
                </button>
            ))}
        </div>
    );
};
