import { useNavigate, useSearchParams } from 'react-router-dom';
import { Children } from '../../../../types/GetSectionBySubjectsApiResponseApiType';
import { useTranslation } from 'react-i18next';
import { Suspense, useEffect, useState } from 'react';
import {
    useGetFeedMicrotopicsQuery,
    useGetSectionsBySubjectQuery,
} from '../../../../api/programService';
import classNames from 'classnames';
import style from './TopicBlock.module.scss';
import { GetAnswerResponseApiType } from '../../../../types';
import { useGetCustomFeedQuery } from '../../../../api/contentService';
import { useGetAnswerQuery } from '../../../../api/beyimProgress';
import { Link } from 'react-scroll';
import { useGetAssessmentInfoQuery } from '../../../../api/beyimAssessmentApi';
import { Tooltip } from 'react-tooltip';
import { AssessmentInfo } from '../../../../types/beyimAssessmentApiType/GetBulkAssessmentInfoResponseApiType';
import { useNormalizedLocale } from '../../../../hooks/useNormalizedLocale';
import ActivitySkeleton from '../../../../ui/ActivitySkeleton';
import Skeleton from 'react-loading-skeleton';
import ActivityStar from './ActivityStar';
import AssessmentStar from './AssessmentStar';

interface ITopicBlock {
    item: Children;
    type?: 'topic' | 'section';
    assessment?: AssessmentInfo | undefined;
    closeSectionMobile?: () => void;
}

export const TopicBlock: React.FC<ITopicBlock> = ({
    item,
    type,
    assessment,
    closeSectionMobile,
    ...props
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const locale = useNormalizedLocale();

    const [searchParams, setSearchParams] = useSearchParams();
    const [sectionId, setSectionId] = useState<string>('');
    const [allActivity, setAllActivity] = useState<string[] | undefined>([]);
    const [isAllActivityFetching, setIsAllActivityFetching] =
        useState<boolean>(false);

    const { data: dataSectionsBySubject } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const { data: dataFeedMicrotopics } = useGetFeedMicrotopicsQuery(
        {
            section_id: type === 'section' ? String(item.id) : sectionId,
            limit: 100,
        },
        {
            skip: !sectionId,
        },
    );

    const {
        data: dataCustomFeed,
        isFetching,
        isLoading,
    } = useGetCustomFeedQuery(
        {
            microtopicIds: dataFeedMicrotopics?.data.result.ids || [],
            include: ['image', 'video', 'text'],
            locale,
        },
        {
            skip: !dataFeedMicrotopics,
        },
    );

    const dataMicrotopicIds =
        dataCustomFeed?.data.data !== null
            ? dataCustomFeed?.data.data
                ? dataCustomFeed?.data.data
                : []
            : [];

    const {
        data: answer,
        isFetching: isAnswersFetching,
        isLoading: isAnswersLoading,
    } = useGetAnswerQuery(
        {
            microtopicIds:
                dataMicrotopicIds
                    .filter(item => item.category === 'activity')
                    .map(activity => activity.microtopicId) || [],
        },
        {
            skip: !dataMicrotopicIds.length,
        },
    );

    const {
        data: assessmentInfo,
        isFetching: isAssessmentInfoFetching,
        isLoading: isAssessmentInfoLoading,
    } = useGetAssessmentInfoQuery(
        {
            locale: locale,
            section_id: +sectionId,
            subject_id: Number(searchParams.get('subject')),
        },
        {
            skip: !searchParams.get('subject') || !sectionId,
        },
    );

    // Function to handle the activation of a link.
    const handleSetActive = (activity: string) => {
        setSearchParams(prev => {
            return {
                idContent: prev.get('idContent') || '',
                fromSearch: prev.get('fromSearch') || '',
                subject: prev.get('subject') || '',
                sectionsBySubject: prev.get('sectionsBySubject') || '',
                them: String(item.id || ''),
                idActivity: activity,
            };
        });
    };

    useEffect(() => {
        setSectionId(
            String(searchParams.get('them')) ||
                String(
                    dataSectionsBySubject?.data.sections.find(section => {
                        return (
                            String(section.id) ===
                            searchParams.get('sectionsBySubject')
                        );
                    })?.children[0]?.id ||
                        String(
                            dataSectionsBySubject?.data.sections[0]?.children[0]
                                ?.id,
                        ),
                ),
        );
    }, [
        searchParams.get('them'), //тема из урла
        dataSectionsBySubject?.data.sections.find(section => {
            return String(section.id) === searchParams.get('sectionsBySubject');
        })?.children[0]?.id, //выбор темы из активной секции
        dataSectionsBySubject?.data.sections[0]?.children[0]?.id, //выбор самой первой доступной темы
    ]);

    useEffect(() => {
        if (dataCustomFeed) {
            setIsAllActivityFetching(true);

            const data =
                dataCustomFeed?.data.data !== null
                    ? dataCustomFeed?.data.data
                    : [];
            setAllActivity(
                data
                    .filter(post => post.category === 'activity')
                    .map(
                        element =>
                            element.activities?.map(activity => activity.id),
                    )
                    .reduce((accumulate, current) => {
                        if (current) {
                            return accumulate?.concat(current);
                        }
                    }, []),
            );
            setIsAllActivityFetching(false);
        }
    }, [dataCustomFeed]);

    const trueActivity = answer?.data.answers
        ?.filter(answer => answer.is_correct)
        .filter(answerFilter => allActivity?.indexOf(answerFilter.id) !== -1)
        .length;

    const percent = ((trueActivity || 0) / (allActivity?.length || 1)) * 100;
    let firstNonCorrectFound = false;

    useEffect(() => {
        (isFetching ||
            isLoading ||
            isAnswersFetching ||
            isAnswersLoading ||
            isAssessmentInfoFetching ||
            isAssessmentInfoLoading) &&
            setIsAllActivityFetching(true);

        !(
            isFetching &&
            isLoading &&
            isAnswersFetching &&
            isAnswersLoading &&
            isAssessmentInfoFetching &&
            isAssessmentInfoLoading
        ) && setIsAllActivityFetching(false);
    }, [
        isFetching,
        isLoading,
        isAnswersFetching,
        isAnswersLoading,
        isAssessmentInfoFetching,
        isAssessmentInfoLoading,
    ]);

    return (
        <button
            {...props}
            className={classNames(
                'rounded-xl px-4 py-3 border-2 flex flex-col w-full relative',
                style.wrapper,
                sectionId === String(item.id) ||
                    (searchParams.get('sectionsBySubject') &&
                        sectionId === String(item.parent_id))
                    ? style.active
                    : '',
                'w-full bg-white',
            )}
            onClick={() => {
                if (
                    !type &&
                    !(
                        sectionId === String(item.id) ||
                        sectionId === String(item.parent_id)
                    )
                ) {
                    setIsAllActivityFetching(true);

                    setSearchParams(prev => ({
                        idContent: prev.get('idContent') || '',
                        fromSearch: prev.get('fromSearch') || '',
                        subject: prev.get('subject') || '',
                        sectionsBySubject: String(item.parent_id) || '',
                        them: String(item.id || ''),
                        idActivity: prev.get('idActivity') || '',
                    }));
                }
            }}
        >
            <div className="flex justify-between w-full">
                <h4 className="text-sm font-bold block text-left">
                    {!type
                        ? item.translations.find(translation => {
                              return translation.locale === locale;
                          })?.name ?? t('available.topicIsNotAvailable')
                        : assessment?.info.section_name}
                </h4>

                <img
                    src={
                        assessment
                            ? !assessment?.info?.progress
                                ? '/icons/Checkboxes-gray.svg'
                                : assessment.info?.progress?.percentage >=
                                    assessment.info?.threshold
                                  ? ' /icons/Checkboxes.svg'
                                  : '/icons/Checkboxes-gray.svg'
                            : ' /icons/Checkboxes-gray.svg'
                    }
                    alt="check"
                />
            </div>
            {!type ? (
                sectionId === String(item.id) ||
                (searchParams.get('sectionsBySubject') &&
                    sectionId === String(item.parent_id)) ? (
                    <div className="w-full">
                        <hr className="h-[2px] my-2" />
                        {isAllActivityFetching ||
                        isFetching ||
                        isLoading ||
                        isAnswersFetching ||
                        isAnswersLoading ||
                        isAssessmentInfoFetching ||
                        isAssessmentInfoLoading ? (
                            <ActivitySkeleton />
                        ) : (
                            <div className="flex items-center flex-wrap xl:flex-nowrap">
                                <span
                                    className={classNames(
                                        style.percentage,
                                        'text-sm font-medium me-2 leading-6 text-left text-nowrap mb-1 xl:md-0',
                                    )}
                                >
                                    {locale === 'kk' || locale === 'ko'
                                        ? `${Math.round(percent)}% ${t(
                                              'onboarding.completed',
                                          )} `
                                        : `${t(
                                              'onboarding.completed',
                                          )} ${Math.round(percent)}%`}
                                </span>

                                <div
                                    className="flex gap-1 items-center flex-wrap"
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    {allActivity?.map(activity => {
                                        const isCorrect =
                                            answer?.data.answers?.find(
                                                (answer: { id: string }) =>
                                                    answer.id === activity,
                                            )?.is_correct || false;

                                        return (
                                            <ActivityStar
                                                key={`${activity}-activity`}
                                                activity={activity}
                                                isCorrect={isCorrect}
                                                handleSetActive={
                                                    handleSetActive
                                                }
                                                closeSectionMobile={
                                                    closeSectionMobile
                                                }
                                            />
                                        );
                                    })}

                                    <AssessmentStar
                                        assessmentInfo={assessmentInfo}
                                        searchParams={searchParams}
                                        closeSectionMobile={closeSectionMobile}
                                    />
                                    <Tooltip
                                        id="activity"
                                        className={style.wrapperTooltip}
                                        offset={10}
                                        place="bottom"
                                        opacity={1}
                                    >
                                        {t('feed.activity')}
                                    </Tooltip>
                                    <Tooltip
                                        id="assessment"
                                        className={classNames(
                                            style.wrapperTooltip,
                                        )}
                                        offset={10}
                                        place="bottom"
                                        opacity={1}
                                    >
                                        {t('feed.assessment')}
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null
            ) : (
                <div className="w-full">
                    <hr className="h-[2px] my-2" />
                    <div className="flex items-center">
                        <span
                            className={classNames(
                                style.percentage,
                                'text-sm font-medium me-2 leading-6 whitespace-nowrap',
                            )}
                        >
                            {t('onboarding.completed')} {Math.round(percent)}%
                        </span>
                        <div
                            className="flex gap-1 items-center flex-wrap"
                            onClick={e => {
                                e.preventDefault();
                            }}
                        >
                            {allActivity?.map(activity => {
                                const isCorrect =
                                    answer?.data.answers?.find(
                                        (answer: { id: string }) =>
                                            answer.id === activity,
                                    )?.is_correct || false;
                                const shouldRenderTooltip =
                                    !isCorrect && !firstNonCorrectFound;
                                if (shouldRenderTooltip) {
                                    firstNonCorrectFound = true;
                                }

                                return (
                                    <li
                                        key={`${activity}-activity`}
                                        className="flex flex-col items-center justify-between overflow-visible w-6 h-6"
                                    >
                                        <img
                                            src={`/icons/solar_star${
                                                isCorrect ? '' : '-gray'
                                            }.svg`}
                                            alt="solar"
                                            onClick={() => {
                                                handleSetActive(activity);
                                                navigate(
                                                    `/feed/?idContent=${
                                                        searchParams.get(
                                                            'idContent',
                                                        ) || ''
                                                    }&fromSearch=${
                                                        searchParams.get(
                                                            'fromSearch',
                                                        ) || ''
                                                    }&subject=${
                                                        searchParams.get(
                                                            'subject',
                                                        ) || ''
                                                    }&sectionsBySubject=${
                                                        searchParams.get(
                                                            'sectionsBySubject',
                                                        ) || ''
                                                    }&them=${
                                                        type === 'section'
                                                            ? item.id
                                                            : searchParams.get(
                                                                  'them',
                                                              ) || ''
                                                    }&idActivity=${activity}
                                                    `,
                                                );
                                            }}
                                        />
                                        {shouldRenderTooltip && (
                                            <div
                                                className={classNames(
                                                    ' flex px-3 py-2 justify-center items-center gap-[10px] rounded-xl h-fit text-center text-sm font-medium opacity-100 z-10 mt-[10px] ms-[4px] flex-wrap md:w-[270px]',
                                                    style.tooltip,
                                                )}
                                            >
                                                {t('assessment.passAndRepeat')}
                                            </div>
                                        )}
                                    </li>
                                );
                            })}

                            <img
                                onClick={() => {
                                    navigate(
                                        `/feed/?idContent=${
                                            searchParams.get('idContent') || ''
                                        }&fromSearch=${
                                            searchParams.get('fromSearch') || ''
                                        }&subject=${
                                            searchParams.get('subject') || ''
                                        }&sectionsBySubject=${
                                            searchParams.get(
                                                'sectionsBySubject',
                                            ) || ''
                                        }&them=${
                                            type === 'section'
                                                ? item.id
                                                : searchParams.get('them') || ''
                                        }&scroll=assessment
                                                    `,
                                    );
                                }}
                                src={
                                    !assessment?.info.progress
                                        ? `/icons/activity-lightning-gray.svg`
                                        : assessment.info.progress.percentage >=
                                            assessment.info.threshold
                                          ? `/icons/activity-lightning-green.svg`
                                          : `/icons/activity-lightning-red.svg`
                                }
                                alt="A"
                            />
                        </div>
                    </div>
                </div>
            )}
            {sectionId === String(item.id) ||
            (searchParams.get('sectionsBySubject') &&
                sectionId === String(item.parent_id)) ? (
                <img
                    className="absolute top-10 left-[-13px]"
                    src="/icons/blueCurrent.svg"
                    alt="current"
                />
            ) : null}
        </button>
    );
};
