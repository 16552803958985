import { useTranslation } from 'react-i18next';
import { Header } from '../../features/Header';
import { Menu } from '../../features/Menu';
import { MobileHeader } from '../dashboard/utils/MobileHeader';
import { StudentInfo } from '../../features/StudentInfo';
import { DetailInfoForStudent } from '../../features/DetailInfoForStudent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DetailAssessmentResults } from '../../features/DetailAssessmentResults';
import { useEffect, useState } from 'react';

const DetailStudent: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [typeRender, setTypeRender] = useState<'info' | 'results'>('info');

    useEffect(() => {
        if (searchParams.get('type')) {
            setTypeRender(searchParams.get('type') as 'info' | 'results');
        }
    }, [searchParams.get('type')]);
    return (
        <div className="bg-[#E9F0F3] min-h-screen">
            <div className="hidden md:block fixed w-full z-[1003] top-0">
                <Header withSearch={false} />
            </div>
            <div className="block md:hidden">
                <MobileHeader />
            </div>
            <div className="flex min-h-[calc(100vh_-_56px)] relative">
                <div className="hidden md:block fixed duration-300 max-h-full top-14 z-10 overflow-auto">
                    <Menu />
                </div>
                <div className="p-8 md:ps-[272px] flex flex-col gap-4 w-full max-w-full pt-20 md:pt-24">
                    <button
                        className="w-fit flex bg-white p-2 items-center rounded-lg gap-1 text-xs text-[#577487]"
                        onClick={() =>
                            navigate(`/dashboard?${searchParams.toString()}`)
                        }
                    >
                        <img src="/icons/back1.svg" />
                        {t('statistics.back')}
                    </button>
                    <div>
                        <StudentInfo />
                    </div>
                    <div>
                        {typeRender === 'info' ? (
                            <DetailInfoForStudent />
                        ) : (
                            <DetailAssessmentResults />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DetailStudent;
