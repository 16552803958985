import { FC } from 'react';
import { AccordionBlock } from '../../../../features/DetailInfoForStudent/utils/AccordionBlock';
import { useTranslation } from 'react-i18next';

interface IDashboardStudentResults {
    isStudent?: boolean;
}
export const DashboardStudentResults: FC<IDashboardStudentResults> = ({
    isStudent = false,
}) => {
    const { t } = useTranslation();

    return (
        <div className={'w-full rounded-b-2xl bg-white p-6 overflow-x-auto '}>
            <table className="w-full border-0 min-w-[700px]">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="border-[#E9F0F3] border-b-[1px] text-lg border-t-0 border-x-0 ps-0 pt-0 pb-6 pe-2  text-[#364954] text-start  font-medium leading-[normal] whitespace-nowrap"
                            colSpan={1}
                        >
                            {t('statistics.CompletedSections')}
                        </th>
                        <th
                            scope="col"
                            className="border-[#E9F0F3] border-b-[1px] border-t-0 border-x-0 ps-0 pt-0 pb-6 pe-2  text-[#678AA1] text-start text-sm font-medium leading-[normal] whitespace-nowrap"
                        >
                            {t('statistics.AssessmentForSection')}
                        </th>
                        <th
                            scope="col"
                            className="border-[#E9F0F3] border-b-[1px] border-t-0 border-x-0 ps-0 pt-0 pb-6 pe-2 text-[#678AA1] text-start text-sm font-medium leading-[normal]"
                        >
                            {t('statistics.topicsInTheSection')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <AccordionBlock isStudent={isStudent} />
                </tbody>
            </table>
        </div>
    );
};
