import { Link, useLocation } from 'react-router-dom';
import { Learn } from '../../../Learn';
import { SmartSearch } from '../../../SmartSearch';
import { ChangingLanguage } from '../../../ChangingLanguage';
import { Profile } from '../../../Profile';
import classNames from 'classnames';
import { useAppSelector, useNormalizedLocale } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

interface IHeaderDesktop {
    withSearch?: boolean;
}

export const HeaderDesktop: React.FC<IHeaderDesktop> = ({
    withSearch = true,
    ...rest
}) => {
    const isLogin = useAppSelector(state => state.auth.isLogin);
    const locale = useNormalizedLocale();
    const location = useLocation();

    return (
        <div className={classNames('h-14 hidden px-6 items-center md:flex')}>
            <div>
                <Link to={isLogin ? '/feed' : 'https://beyim.ai/'}>
                    <img src="/icons/logoMain.svg" alt="logoMain" />
                </Link>
            </div>
            {location.pathname !== '/dashboard' && (
                <Learn className={classNames('ms-[49px]')} />
            )}
            {withSearch && (
                <div className="ms-7 me-2">
                    <SmartSearch />
                </div>
            )}

            <div className="ms-auto flex items-center gap-8">
                <ChangingLanguage />

                <Profile />
            </div>
        </div>
    );
};
