import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useNormalizedLocale } from '../../hooks';

export const Menu: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const locale = useNormalizedLocale();
    const isKK = locale === 'kk';
    const isRU = locale === 'ru';
    return (
        <div className="shrink-0 bg-white flex flex-col  w-60 sticky   px-4 justify-between pb-12 min-h-[calc(100vh_-_56px)] overflow-hidden min-w-60 pt-1">
            <div className="flex-col gap-1 flex shrink-0 mb-5">
                <span className="text-[#678AA1] leading-[normal] text-sm p-2 pb-1">
                    {t('dashboard.menu')}
                </span>
                <Link
                    to={'/dashboard'}
                    className={classNames(
                        'flex items-center text-[#232F37]  gap-2 p-2 text-sm  rounded-xl duration-150 font-medium',
                        location.pathname === '/dashboard'
                            ? 'bg-[#E4F3FB]'
                            : 'bg-white hover:bg-[#F2F6F8]',
                    )}
                >
                    <span className="w-5 h-5 flex items-center justify-center">
                        <img src="/icons/report.svg" alt="" />
                    </span>
                    {t('dashboard.myClasses')}
                </Link>
                <Link
                    to={
                        '/feed?idContent=&fromSearch=&subject=&sectionsBySubject=&them=&idActivity=&scroll='
                    }
                    className={classNames(
                        'text-[#232F37] flex gap-2 p-2 text-sm  rounded-xl duration-150 font-medium',
                        location.pathname === '/'
                            ? 'bg-[#E4F3FB]'
                            : 'bg-white hover:bg-[#F2F6F8]',
                    )}
                >
                    {' '}
                    <span className="w-5 h-5 flex items-center justify-center">
                        <img src="/icons/student1.svg" alt="" />
                    </span>
                    {t('dashboard.studentView')}
                </Link>
            </div>
            <div className="shrink-0">
                <img
                    className="mt-auto mb-5"
                    src={
                        isRU
                            ? '/icons/ru.webp'
                            : isKK
                              ? '/icons/kz.webp'
                              : '/icons/eng.webp'
                    }
                    alt=""
                />
                <div className="flex gap-1 flex-col">
                    <span className="text-[#678AA1] leading-[normal] text-sm p-2 mb-1  block">
                        {t('dashboard.contacts')}
                    </span>
                    {/* <Link
                        to={'#'}
                        className={classNames(
                            'flex items-centetext-[#232F37]  gap-2 p-2 text-sm  rounded-xl duration-150 font-medium leading-[normal]',
                            location.pathname === '/'
                                ? 'bg-[#E4F3FB]'
                                : 'bg-white hover:bg-[#F2F6F8]',
                        )}
                    >
                        {' '}
                        <img src="/icons/GroupTeam.svg" alt="" />
                        Microsoft Teams
                    </Link> */}
                    <Link
                        to={'https://api.whatsapp.com/send?phone=77780427012'}
                        target="_blank"
                        className={classNames(
                            'flex items-centetext-[#232F37]  gap-2 p-2 text-sm  rounded-xl duration-150 font-medium leading-[normal]',
                            location.pathname === '/'
                                ? 'bg-[#E4F3FB]'
                                : 'bg-white hover:bg-[#F2F6F8]',
                        )}
                    >
                        {' '}
                        <img src="/icons/whatsapp.svg" alt="" />
                        Whatsapp
                    </Link>
                    <Link
                        to={'tel:+77780427012'}
                        className={classNames(
                            'flex items-centetext-[#232F37]  gap-2 p-2 text-sm mb-5  rounded-xl duration-150 font-medium',
                            location.pathname === '/'
                                ? 'bg-[#E4F3FB]'
                                : 'bg-white hover:bg-[#F2F6F8]',
                        )}
                    >
                        {' '}
                        <img src="/icons/GroupCall.svg" alt="" />+ 7 778 042 70
                        12
                    </Link>
                    <span className="text-[#678AA1] leading-[normal] text-sm p-2 block mb-0">
                        {t('dashboard.contactsSocial')}
                    </span>
                    <div className="flex gap-4 p-2 pt-1 items-center mb-6">
                        <Link
                            target="_blank"
                            to={'https://t.me/beyimtech'}
                            className={classNames(
                                'w-[18px] h-6 flex items-center justify-center',
                            )}
                        >
                            <img src="/icons/SocialTelegram.svg" alt="" />
                        </Link>
                        <Link
                            target="_blank"
                            to={'https://www.instagram.com/beyim.ai/'}
                            className={classNames(
                                'w-[18px] h-6 flex items-center justify-center',
                            )}
                        >
                            <img src="/icons/SocialInst.svg" alt="" />
                        </Link>
                        <Link
                            target="_blank"
                            to={'https://www.linkedin.com/company/beyimtech/'}
                            className={classNames(
                                'w-[18px] h-6 flex items-center justify-center',
                            )}
                        >
                            <img src="/icons/SocialLink.svg" alt="" />
                        </Link>
                        <Link
                            to={'https://www.youtube.com/@Beyimtech'}
                            target="_blank"
                            className={classNames(
                                'w-6 h-6 flex items-center justify-center',
                            )}
                        >
                            <img src="/icons/SocialYouTube.svg" alt="" />
                        </Link>
                    </div>
                    <span className="px-2 text-xs text-[#A3C0D2]">
                        © 2024 BeyimTech
                    </span>
                </div>
            </div>
        </div>
    );
};
