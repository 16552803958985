import { useTranslation } from 'react-i18next';
import { Section } from '../../types/GetSectionBySubjectsApiResponseApiType';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './SectionStatus.module.scss';
import { TopicBlock } from './utils';
import { SectionBlock } from './utils/SectionBlock';
import { GetAnswerResponseApiType } from '../../types';
import { SectionAssessment } from './utils/SectionAssessment';
import { Tooltip } from 'react-tooltip';
import { useGetBulkAssessmentInfoQuery } from '../../api/beyimAssessmentApi/beyimAssessmentApi';
import { useGetSectionsBySubjectQuery } from '../../api/programService';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import TagManager from 'react-gtm-module';
import { check } from 'prettier';
import { useAppSelector } from '../../hooks';
import { roles } from '../AuthForms/constants';

interface ISectionStatus {
    data: Section[];
}

export const SectionStatus: React.FC<ISectionStatus> = ({ data, ...rest }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const role = useAppSelector(state => state.auth.role);
    const locale = useNormalizedLocale();

    const [indexSectionsBySubject, setIndexSectionsBySubject] =
        useState<number>(
            data.findIndex(
                item =>
                    String(item.id) === searchParams.get('sectionsBySubject'),
            ) !== -1
                ? data.findIndex(
                      item =>
                          String(item.id) ===
                          searchParams.get('sectionsBySubject'),
                  )
                : 0,
        );

    useEffect(() => {
        setIndexSectionsBySubject(
            data.findIndex(
                item =>
                    String(item.id) === searchParams.get('sectionsBySubject'),
            ) !== -1
                ? data.findIndex(
                      item =>
                          String(item.id) ===
                          searchParams.get('sectionsBySubject'),
                  )
                : 0,
        );
    }, [
        data.findIndex(
            item => String(item.id) === searchParams.get('sectionsBySubject'),
        ),
    ]);

    const nextSection = () => {
        if (
            indexSectionsBySubject + 1 !== data.length &&
            data[indexSectionsBySubject + 1].children[0]
        ) {
            setSearchParams(prev => {
                return {
                    idContent: prev.get('idContent') || '',
                    fromSearch: prev.get('fromSearch') || '',
                    subject: prev.get('subject') || '',
                    sectionsBySubject: String(
                        data[indexSectionsBySubject + 1].id || '',
                    ),
                    them: '',
                };
            });
        }
    };

    const prevSection = () => {
        if (indexSectionsBySubject !== 0) {
            setSearchParams(prev => {
                return {
                    idContent: prev.get('idContent') || '',
                    fromSearch: prev.get('fromSearch') || '',
                    subject: prev.get('subject') || '',
                    sectionsBySubject: String(
                        data[indexSectionsBySubject - 1].id || '',
                    ),
                    them: '',
                };
            });
        }
    };
    const { data: sectionsBySubject } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );
    const currentSection = sectionsBySubject?.data?.sections[0];
    const sectionIds = searchParams.get('sectionsBySubject')
        ? sectionsBySubject?.data?.sections
              .find(section => {
                  return (
                      String(section.id) ===
                      searchParams.get('sectionsBySubject')
                  );
              })
              ?.children.map(child => child.id) || []
        : currentSection?.children.map(child => child.id) || [];

    const { data: bulkAssessmentInfo } = useGetBulkAssessmentInfoQuery(
        {
            locale: locale,
            subjectId: Number(searchParams.get('subject')),
            sectionIds,
        },
        {
            skip: !sectionsBySubject || !searchParams.get('sectionsBySubject'),
        },
    );

    return (
        <div
            {...rest}
            className={classNames(
                'sm:p-4 py-4 rounded-2xl sticky top-[181px]',
                style.wrapper,
            )}
        >
            <div className="flex justify-between items-start">
                <div>
                    <h2
                        className={classNames(
                            style.section,
                            'text-sm font-medium',
                        )}
                    >
                        {t('onboarding.section')}
                    </h2>

                    {data.map((item, index) => {
                        return (
                            <SectionBlock
                                item={item}
                                key={item.id}
                                isHidden={!(indexSectionsBySubject === index)}
                            />
                        );
                    })}
                </div>
                <div className="flex grow-0 shrink-0 gap-2">
                    <button
                        data-tooltip-id="prevSection"
                        type="button"
                        className={classNames(
                            'border-2 w-8 h-8 flex items-center justify-center rounded-[10.7px]',
                            style.borderButton,
                            indexSectionsBySubject !== 0 ? '' : 'opacity-50',
                        )}
                        onClick={() => {
                            const data_prev = data[indexSectionsBySubject];
                            const data_next = data[indexSectionsBySubject - 1];
                            const checkIndex = indexSectionsBySubject !== 0;
                            const section_next = checkIndex
                                ? data_next
                                : data_prev;
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'prev_section',
                                    subject_id: data_prev.subject_id,
                                    section_id_prev: data_prev.id,
                                    section_id_next: section_next.id,
                                    locale: locale,
                                },
                                dataLayerName: 'feed',
                            });
                            prevSection();
                        }}
                        disabled={!(indexSectionsBySubject !== 0)}
                    >
                        <img
                            src="/icons/buttonNext.svg"
                            className={classNames(
                                'block',
                                locale === 'ar' ? '' : 'rotate-180',
                            )}
                            alt="buttonNext"
                        />
                    </button>
                    <button
                        data-tooltip-id="nextSection"
                        className={classNames(
                            'border-2 w-8 h-8 flex items-center justify-center rounded-[10.7px]',
                            style.borderButton,
                            indexSectionsBySubject + 1 !== data.length &&
                                data[indexSectionsBySubject + 1]?.children &&
                                data[indexSectionsBySubject + 1]?.children[0]
                                ? ''
                                : 'opacity-50',
                        )}
                        onClick={() => {
                            const data_prev = data[indexSectionsBySubject];
                            const data_next = data[indexSectionsBySubject + 1];
                            const checkIndex =
                                indexSectionsBySubject + 1 !== data.length;
                            const section_next = checkIndex
                                ? data_next
                                : data_prev;
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'next_section',
                                    subject_id: data_prev.subject_id,
                                    section_id_prev: data_prev.id,
                                    section_id_next: section_next.id,
                                    locale: locale,
                                },
                                dataLayerName: 'feed',
                            });
                            nextSection();
                        }}
                        disabled={
                            !(
                                indexSectionsBySubject + 1 !== data.length &&
                                data[indexSectionsBySubject + 1]?.children &&
                                data[indexSectionsBySubject + 1]?.children[0]
                            )
                        }
                    >
                        <img
                            src="/icons/buttonNext.svg"
                            className={classNames(
                                ' block',
                                locale === 'ar' ? 'rotate-180' : '',
                            )}
                            alt="buttonNext"
                        />
                    </button>
                    <Tooltip
                        id="nextSection"
                        className={classNames(style.wrapperTooltip)}
                        offset={10}
                        place="bottom"
                        opacity={1}
                    >
                        {t('feed.transferToNextSection')}
                    </Tooltip>
                    <Tooltip
                        id="prevSection"
                        className={classNames(style.wrapperTooltip)}
                        offset={10}
                        place="bottom"
                        opacity={1}
                    >
                        {t('feed.transferToPrevSection')}
                    </Tooltip>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                {data[indexSectionsBySubject]?.children?.map((item, index) => {
                    return (
                        <TopicBlock
                            assessment={
                                bulkAssessmentInfo?.data.assessments[index]
                            }
                            item={item}
                            key={item.id}
                        />
                    );
                })}
                {role !== roles.EXPERT && <SectionAssessment />}
            </div>
        </div>
    );
};
