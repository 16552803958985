import React, { useEffect } from 'react';

import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './AssessmentStarting.module.scss';

import { useGetAssessmentInfoQuery } from '../../api/beyimAssessmentApi';
import { AssessmentBlockMetricsSection } from './AssessmentBlockMetricsSection';
import { Locale } from '../../types/common';
import { useGetSectionsBySubjectQuery } from '../../api/programService';
import { Element } from 'react-scroll';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { SkeletonAssessmentStarting } from './SkeletonAssessmentStarting';
import { useGetSubjectByIdQuery } from '../../api/programService/programServiceApi';
import { HeaderPost } from '../HeaderPost';

export const AssessmentStarting: React.FC<{
    type?: string;
    callBack?: () => void;
    footer?: React.ReactNode;
}> = ({ type, callBack, footer }) => {
    const [searchParams] = useSearchParams();

    const { t } = useTranslation();

    const locale = useNormalizedLocale();

    const {
        data: dataSectionsBySubject,
        isLoading: isLoadingSections,
        isFetching: isFetchingSections,
    } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );
    const { data: subject } = useGetSubjectByIdQuery(
        {
            id: searchParams.get('subject') || '',
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const them =
        Number(searchParams.get('them') || '') ||
        Number(
            dataSectionsBySubject?.data.sections.find(section => {
                return (
                    String(section.id) === searchParams.get('sectionsBySubject')
                );
            })?.children[0]?.id ||
                Number(
                    dataSectionsBySubject?.data.sections[0]?.children[0]?.id,
                ),
        );

    const {
        data: assessmentInfo,
        refetch,
        isLoading: isLoadingAssessmentInfo,
        isFetching: isFetchingAssessmentInfo,
    } = useGetAssessmentInfoQuery(
        {
            locale: locale as Locale,
            section_id: them,
            subject_id: Number(searchParams.get('subject') || ''),
        },
        {
            skip: !searchParams.get('subject') || !them,
        },
    );
    useEffect(() => {
        if (assessmentInfo) {
            refetch();
        }
    }, []);

    const state = assessmentInfo?.data.assessment.progress
        ? assessmentInfo?.data.assessment.progress.percentage >=
          assessmentInfo?.data.assessment.threshold
            ? 'success'
            : 'fail'
        : 'default';

    const metricsData = {
        points:
            (assessmentInfo?.data.assessment.progress
                ? assessmentInfo.data.assessment.progress.percentage
                : assessmentInfo?.data.assessment.threshold) ?? 0,
        questionsCount: {
            correct: assessmentInfo?.data?.assessment?.progress?.score ?? 0,
            total: assessmentInfo?.data?.assessment?.question_count,
        },
        time:
            (assessmentInfo?.data.assessment.progress
                ? assessmentInfo.data.assessment.progress.duration
                : assessmentInfo?.data?.assessment?.duration) ?? 0,
    };

    return isFetchingAssessmentInfo ||
        isLoadingAssessmentInfo ||
        isLoadingSections ||
        isFetchingSections ? (
        <SkeletonAssessmentStarting />
    ) : assessmentInfo?.data.assessment &&
      searchParams.get('subject') &&
      assessmentInfo.data.assessment.is_available &&
      Number(searchParams.get('subject')) ===
          assessmentInfo.data.assessment.subject_id ? (
        <Element name="assessment" className="element">
            <article
                className={classNames(
                    'sm:rounded-2xl flex flex-col gap-4 pl-4 pr-4 pt-6 pb-4 z-10 relative mb-1 md:mb-0',
                    style.wrapper,
                    style[state],
                )}
            >
                <HeaderPost
                    iconUrl={
                        subject?.data.subject.icon_url || '/icons/noIcon.svg'
                    }
                    assessmentText={
                        assessmentInfo?.data.assessment.section_name.length > 0
                            ? assessmentInfo.data.assessment.section_name
                            : assessmentInfo?.data.assessment
                                    .section_type_id === 3
                              ? t('available.topicIsNotAvailable')
                              : t('available.sectionIsNotAvailable')
                    }
                    assessmentType={t('assessment.header')}
                    assessmentState={state}
                    type="assessment"
                />
                <AssessmentBlockMetricsSection
                    metricsData={metricsData}
                    state={state}
                />
                <footer id="step-12" className="flex flex-row gap-2">
                    {!type ? (
                        <Link
                            className={classNames(
                                style[`button-info`],
                                'w-full cursor-pointer rounded-2xl font-semibold block text-center text-sm',
                            )}
                            to={
                                assessmentInfo?.data.assessment.progress
                                    ? `/feed/assessment/${assessmentInfo?.data
                                          .assessment
                                          .id}/results/${assessmentInfo?.data
                                          .assessment.progress
                                          .id}?subject=${searchParams.get(
                                          'subject',
                                      )}&sectionsBySubject=${searchParams.get(
                                          'sectionsBySubject',
                                      )}&them=${String(them)}`
                                    : `/feed/assessment/${assessmentInfo?.data
                                          .assessment
                                          .id}?idContent=${searchParams.get(
                                          'idContent',
                                      )}&fromSearch=${searchParams.get(
                                          'fromSearch',
                                      )}&subject=${searchParams.get(
                                          'subject',
                                      )}&sectionsBySubject=${searchParams.get(
                                          'sectionsBySubject' || '',
                                      )}&them=${String(them)}`
                            }
                        >
                            {state === 'default'
                                ? t('assessment.examine')
                                : t('assessment.result')}
                        </Link>
                    ) : type === 'results' ? (
                        footer
                    ) : (
                        <button
                            className={classNames(
                                style[`button-info`],
                                'w-full cursor-pointer rounded-2xl font-semibold block text-center text-sm ',
                            )}
                            onClick={callBack}
                        >
                            {t('assessment.start')}
                        </button>
                    )}
                </footer>
            </article>
        </Element>
    ) : null;
};
