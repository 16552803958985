import { useEffect, useRef, useState } from 'react';

import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import 'react-tooltip/dist/react-tooltip.css';
import style from './Icons.module.scss';

import { Question } from '../../../../types/beyimAssessmentApiType/GetQuestionsAssessmentResponseApiType';

export interface IconsProps {
    test: Question[] | undefined;
    changeCurrentQuestion: (index: number) => void;
    values: any;
    children?: React.ReactNode;
}

export const Icons: React.FC<IconsProps> = ({
    test,
    changeCurrentQuestion,
    values,
    children,
}) => {
    const { t } = useTranslation();
    const [openNavigationQuestion, setOpenNavigationQuestion] = useState(false);
    const navigateRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleOutsideClick = (event: MouseEvent) => {
        if (
            openNavigationQuestion &&
            navigateRef.current &&
            !navigateRef.current.contains(event.target as Node) &&
            (!dropdownRef.current ||
                !dropdownRef.current.contains(event.target as Node))
        ) {
            setOpenNavigationQuestion(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [openNavigationQuestion]);

    return (
        <>
            <div className="flex flex-row gap-2 items-center justify-center">
                <div>{children}</div>
                <div
                    className={classNames(
                        'cursor-pointer relative flex justify-center items-center h-[32px] w-[32px] p-1.5 md:h-10 md:w-16 md:py-[9px] md:pl-[11px] md:pr-[5px] gap-1 rounded-xl',
                        style.leftPart,
                        openNavigationQuestion && style.active,
                    )}
                    ref={navigateRef}
                    onClick={() =>
                        setOpenNavigationQuestion(!openNavigationQuestion)
                    }
                    data-tooltip-id="my-navigation"
                >
                    {openNavigationQuestion ? (
                        <>
                            <img
                                src={'/icons/testSquareWhite.svg'}
                                alt="Beyim logo"
                                className="!w-5 h-5"
                            />
                            <img
                                className="transform rotate-180 hidden md:block"
                                src="/icons/arrowDownWhite.svg"
                                alt="Beyim logo"
                                width={18}
                                height={18}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                src="/icons/testSquare.svg"
                                alt="Beyim logo"
                                className="w-5 h-5"
                                width={25}
                            />
                            <img
                                className="hidden md:block"
                                src="/icons/arrowDown.svg"
                                alt="Beyim logo"
                            />
                        </>
                    )}
                </div>
            </div>
            <Tooltip
                id="my-navigation"
                className={classNames(
                    'drop-shadow-2xl max-w-xs rounded-xl p-3',
                    style.navigation,
                )}
                clickable={true}
                isOpen={openNavigationQuestion}
                opacity={1}
                offset={20}
                noArrow={true}
                place={'bottom-end'}
            >
                <div
                    ref={dropdownRef}
                    className={classNames(
                        `flex flex-col z-10`,
                        style.openNavigation,
                    )}
                >
                    <header
                        className={classNames(
                            'flex items-center px-4 py-3 rounded-tl-2xl rounded-tr-2xl rounded-br-none rounded-bl-none',
                            style.header,
                        )}
                    >
                        <h2
                            className={classNames(
                                `m-0 font-bold text-lg`,
                                style.title,
                            )}
                        >
                            {t('assessment.navigationByQuestions')}
                        </h2>
                    </header>
                    <div className="m-0 px-4 py-3">
                        <span
                            className={`text-sm font-medium ${style.numbers}`}
                        >
                            {t('assessment.answered')}
                            {':'}
                            <strong className="font-bold">
                                {
                                    values.answers.filter(
                                        (item: any) =>
                                            item?.answer || item?.answer === 0,
                                    ).length
                                }
                                {'/'}
                                {test && test?.length}
                            </strong>
                            , {t('assessment.unAnswered')}:
                            <strong>
                                {' '}
                                {test &&
                                    test?.length -
                                        values.answers.filter(
                                            (item: any) =>
                                                item?.answer ||
                                                item?.answer === 0,
                                        ).length}
                                {'/'}
                                {test && test?.length}
                            </strong>
                        </span>
                    </div>
                    <div className="px-4 pt-0 pb-6 flex flex-row flex-wrap gap-1.5 rounded-bl-2xl rounded-br-2xl">
                        {test?.map((item, index) => (
                            <div
                                key={item.id}
                                className={classNames(
                                    'cursor-pointer relative w-[55px] h-[53px] flex px-[9px] py-[8px] justify-center items-center rounded-2xl',
                                    style.item,
                                )}
                                onClick={() => {
                                    changeCurrentQuestion &&
                                        changeCurrentQuestion(index);
                                }}
                            >
                                {values.answers[index]?.answer ||
                                values.answers[index]?.answer === 0 ? (
                                    <img
                                        src="/icons/navigationBlue.svg"
                                        alt="Beyim logo"
                                    />
                                ) : (
                                    <img
                                        src="/icons/navigationGrey.svg"
                                        alt="Beyim logo"
                                    />
                                )}
                                <span
                                    className={`absolute left-1/2 top-1/2 text-center font-medium text-base transform translate-x-[-50%] translate-y-[-50%] ${
                                        values.answers[index]?.answer ||
                                        values.answers[index]?.answer === 0
                                            ? style.colorWhite
                                            : ''
                                    }`}
                                >
                                    {index + 1}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </Tooltip>
        </>
    );
};
