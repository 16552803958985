import React from 'react';
import Skeleton from 'react-loading-skeleton';

const HintSkeleton = () => {
    return (
        <Skeleton
            count={1}
            baseColor="#ddf4ff"
            className="w-100 h-[100px] !rounded-2xl mb-1"
        />
    );
};

export default HintSkeleton;
