import { useTranslation } from 'react-i18next';
import style from './ActiveStudent.module.scss';
import classNames from 'classnames';
import { BaseCard } from '../BaseCard';
import { useGetStatisticsQuery } from '../../../../api/beyimProgress/beyimProgressApi';
interface IActiveStudent {
    classId: string;
    subjectId: string;
    quarter: string;
}

export const ActiveStudent: React.FC<IActiveStudent> = ({
    classId,
    subjectId,
    quarter,
}) => {
    const { t } = useTranslation();
    const {
        data: statistics,
        isLoading: isLoadingStatistics,
        isFetching: isFetchingStatistics,
    } = useGetStatisticsQuery(
        {
            classId,
            subjectId,
            quarter: quarter,
            countRequired: true,
        },
        {
            skip: !classId || !subjectId || !quarter,
        },
    );

    const valueActiveStudent = `${
        statistics?.data.data.widgetStatistics.student_count
            ? (statistics?.data.data.widgetStatistics.student_count.active /
                  statistics?.data.data.widgetStatistics.student_count.total) *
                  100 +
              '%'
            : '0%'
    }`;

    const valueOutOf =
        classId && subjectId && quarter
            ? statistics?.data.data.widgetStatistics.student_count
                ? `${statistics?.data.data.widgetStatistics.student_count.active}/${statistics?.data.data.widgetStatistics.student_count.total}`
                : '-'
            : '-';

    return (
        <BaseCard
            firstBlock={
                <span className="w-14 h-14 shrink-0 relative">
                    <span className="bg-[#E4F3FB] w-full h-full block absolute rounded-full" />
                    <span
                        className={classNames(
                            style['pie-chart'],
                            'relative w-full h-full block',
                        )}
                        //@ts-ignore
                        style={{
                            // @ts-ignore
                            '--valueActiveStudent': `${valueActiveStudent}`,
                        }}
                    />
                    <span className="absolute bg-white w-10 h-10 rounded-full top-2 left-2"></span>
                </span>
            }
            title={`${valueOutOf}`}
            subTitle={t('dashboard.activeStudent')}
            isLoading={isLoadingStatistics || isFetchingStatistics}
        />
    );
};
