import React from 'react';

import styles from './ButtonComponent.module.scss';

// Определение интерфейса для пропсов
interface ButtonOrLinkProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    color?: 'green' | 'red' | 'blue';
}

export const ModalButton: React.FC<ButtonOrLinkProps> = ({
    color = 'red',
    ...props
}) => {
    return (
        <button
            className={`${styles.button} ${styles[`${color}`]}`}
            {...props}
        />
    );
};
