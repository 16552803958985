import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllSectionsQuery } from '../../../../api/programService';
import { useSearchParams } from 'react-router-dom';
import { useNormalizedLocale } from '../../../../hooks';

type Option = {
    value: number;
    label: string;
};

interface ISelectComponent {
    initialOptions: Option[];
    additionalOptions: Option[];
    setTypeAssessment: (type: number) => void;
    setSectionId: (sectionId: number) => void;
}
export const SelectComponent: React.FC<ISelectComponent> = ({
    initialOptions,
    additionalOptions,
    setTypeAssessment,
    setSectionId,
}) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const locale = useNormalizedLocale();

    const [input, setInput] = useState<string>('');
    const navigateRef = React.useRef<HTMLInputElement>(null);
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const secondRef = React.useRef<HTMLDivElement>(null);
    const [isOpenFirst, setIsOpenFirst] = useState<boolean>(false);
    const [isOpenSecond, setIsOpenSecond] = useState<boolean>(false);

    const { data: sections } = useGetAllSectionsQuery(
        {
            subject_id: Number(searchParams.get('subject_id')) || 0,
            type_id: 2,
        },
        {
            skip:
                !searchParams.get('section_id') ||
                searchParams.get('type_id') === '3',
        },
    );
    const { data: sectionInfoWithChildren } = useGetAllSectionsQuery(
        {
            parent_id: Number(searchParams.get('section_id')) || 0,
            subject_id: Number(searchParams.get('subject_id')) || 0,
        },
        { skip: !searchParams.get('section_id') },
    );

    useEffect(() => {
        if (sections) {
            const section = sections.data.sections.find(
                item => item.id === Number(searchParams.get('section_id')),
            );
            if (section) {
                setInput(
                    section.translations.find(
                        translation => translation.locale === locale,
                    )?.name || section.translations[0].name,
                );
                setSectionId(section.id);
            }
        }
    }, [sections, locale]);

    useEffect(() => {
        if (sectionInfoWithChildren) {
            const section = sectionInfoWithChildren.data.sections.find(
                item => item.id === Number(searchParams.get('them')),
            );
            if (section) {
                setInput(
                    section.translations.find(
                        translation => translation.locale === locale,
                    )?.name || section.translations[0].name,
                );
                setSectionId(section.id);
            }
        }
    }, [sectionInfoWithChildren, locale]);

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            (isOpenFirst || isOpenSecond) &&
            navigateRef.current &&
            !navigateRef.current.contains(event.target as Node) &&
            (!dropdownRef.current ||
                !dropdownRef.current.contains(event.target as Node)) &&
            (!secondRef.current ||
                !secondRef.current.contains(event.target as Node))
        ) {
            setIsOpenFirst(false);
            setIsOpenSecond(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpenFirst, isOpenSecond]);

    return (
        <div className="relative">
            <input
                className="w-full h-[30px] border border-gray-300 rounded-lg px-2  font-medium text-sm hover:border-[#495e6b] hover:border overflow-ellipsis overflow-hidden cursor-auto"
                id="placeholderChange"
                onChange={e => setInput(e.target.value)}
                onTouchStart={() =>
                    input ? setIsOpenSecond(true) : setIsOpenFirst(true)
                }
                ref={navigateRef}
                value={input}
                onFocus={e => {
                    e.stopPropagation();
                    input ? setIsOpenSecond(true) : setIsOpenFirst(true);
                }}
                placeholder={t('dashboardResults.sectionAssessment')}
                readOnly
            />
            {isOpenFirst && (
                <div
                    ref={dropdownRef}
                    className="w-full bg-white absolute top-9  border border-gray-300 rounded-lg z-10"
                >
                    <ul className="h-fit">
                        {initialOptions.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    setIsOpenFirst(false);
                                    setIsOpenSecond(true);
                                    setTypeAssessment(item.value);
                                }}
                                className="hover:bg-[#e9f0f3] px-2  py-1 rounded-lg text-sm min-h-[35px] flex items-center font-medium"
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {isOpenSecond && (
                <div
                    ref={secondRef}
                    className="w-full bg-white absolute top-9  border border-gray-300 rounded-lg z-10 "
                >
                    <button
                        className="px-2 bg-white rounded-lg text-xs text-[#577487] pt-2 hover:underline"
                        onClick={() => {
                            setIsOpenFirst(true);
                            setIsOpenSecond(false);
                        }}
                    >
                        {t('statistics.back')}
                    </button>
                    <ul className="max-h-[300px] overflow-y-auto">
                        {additionalOptions.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    setInput(item.label);
                                    setIsOpenSecond(false);
                                    setSectionId(item.value);
                                }}
                                className="hover:bg-[#e9f0f3] px-2  py-1 rounded-lg text-sm min-h-[35px] flex items-center font-medium"
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
