import { Link, useNavigate } from 'react-router-dom';
import { ChangingLanguage } from '../ChangingLanguage';
import style from './Header.module.scss';
import classNames from 'classnames';
import { Learn } from '../Learn';
import { SmartSearch } from '../SmartSearch';
import { Profile } from '../Profile';
import { useEffect } from 'react';
import { HeaderDesktop, HeaderMobile } from './utils';

interface IHeader {
    handleOpenFilterMobile?: (isOpnen: boolean) => void;
    isOpenFilterMobile?: boolean;
    withSearch?: boolean;
}

export const Header: React.FC<IHeader> = ({
    handleOpenFilterMobile,
    isOpenFilterMobile,
    withSearch = true,
    ...rest
}) => {
    return (
        <header
            className={classNames(' sticky top-0 z-[2000]', style.wrapper)}
            {...rest}
        >
            <HeaderDesktop withSearch={withSearch} />
            <HeaderMobile
                handleOpenFilterMobile={handleOpenFilterMobile}
                isOpenFilterMobile={isOpenFilterMobile}
            />
            <div
                className={classNames(
                    'w-0 h-0 overflow-hidden',
                    style.iconPreload,
                )}
            >
                <img src={`/icons/solar_star-gray.svg`} />
                <img src={`/icons/solar_star.svg`} />
                <img src={`/icons/activity-lightning-gray.svg`} />
                <img src={`/icons/activity-lightning-green.svg`} />
                <img src={`/icons/activity-lightning-red.svg`} />
                <div className={style.arrowNext}></div>
                <div className={style.arrowPrev}></div>
                <div className={style.iconClose}></div>
            </div>
        </header>
    );
};
