import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { StudentInfo } from '../../api/progressService/types';

interface IClassStatus {
    students: StudentInfo[];
    classId: string;
    subjectId: string;
}
export const ClassStatus: FC<IClassStatus> = ({
    students,
    classId,
    subjectId,
}) => {
    const { t } = useTranslation();

    const passedStudents: StudentInfo[] = [];
    const failedStudents: StudentInfo[] = [];
    const notAttemptedStudents: StudentInfo[] = [];

    students?.forEach(student => {
        if (!student.assessmentProgress.hasAttempt) {
            notAttemptedStudents.push(student);
        } else if (student.assessmentProgress.hasPassed) {
            passedStudents.push(student);
        } else {
            failedStudents.push(student);
        }
    });

    return (
        <div className="flex gap-8 bg-[#E9F0F3] px-8 pb-4 pt-2">
            <div className="max-w-72 w-full">
                <span className="text-sm text-[#678AA1] font-medium  block mb-2">
                    {t('statistics.passed')} ({passedStudents.length})
                </span>
                {passedStudents.map(student => (
                    <Link
                        to={`/detail-student?subject_id=${subjectId}&class_id=${classId}&user_id=${
                            student?.userInfo.id ?? '1654564564564sad'
                        }`}
                        className="mb-2 flex flex-col gap-2 justify-center"
                        key={student.userInfo.id}
                    >
                        <div className="flex gap-2">
                            <div className="text-[#678AA1] bg-white rounded-full  text-xs w-6 h-6 flex items-center justify-center">
                                {student.userInfo.firstName.charAt(0).toUpperCase() +
                                    student.userInfo.secondName.charAt(0).toUpperCase()}
                            </div>{' '}
                            <span className="text-[#71BD4E] text-sm hover:underline">
                                {(
                                    (student.assessmentProgress.score /
                                        student.assessmentProgress.totalScore) *
                                    100
                                ).toFixed()}
                                % {student.userInfo.firstName},{' '}
                                {student.userInfo.secondName}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="max-w-72 w-full">
                <span className="text-sm text-[#678AA1] font-medium block mb-2">
                    {t('statistics.didntPass')} ({failedStudents.length})
                </span>
                {failedStudents.map(student => (
                    <Link
                        to={`/detail-student?subject_id=${subjectId}&class_id=${classId}&user_id=${
                            student?.userInfo.id ?? '1654564564564sad'
                        }`}
                        className="mb-2 flex flex-col gap-2 justify-center"
                        key={student.userInfo.id}
                    >
                        <div className="flex gap-2">
                            <div className="text-[#678AA1] bg-white rounded-full  text-xs w-6 h-6 flex items-center justify-center">
                                {student.userInfo.firstName.charAt(0).toUpperCase() +
                                    student.userInfo.secondName.charAt(0).toUpperCase()}
                            </div>{' '}
                            <span className="text-[#E55959] text-sm hover:underline">
                                {(
                                    (student.assessmentProgress.score /
                                        student.assessmentProgress.totalScore) *
                                    100
                                ).toFixed()}
                                % {student.userInfo.firstName},{' '}
                                {student.userInfo.secondName}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="max-w-72 w-full">
                <span className="text-sm text-[#678AA1] font-medium  block mb-2">
                    {t('statistics.havenStarted')} (
                    {notAttemptedStudents.length})
                </span>
                {notAttemptedStudents.map(student => (
                    <Link
                        to={`/detail-student?subject_id=${subjectId}&class_id=${classId}&user_id=${
                            student?.userInfo.id ?? '1654564564564sad'
                        }`}
                        className="mb-2 flex flex-col gap-2 justify-center"
                        key={student.userInfo.id}
                    >
                        <div className="flex gap-2">
                            <div className="text-[#678AA1] bg-white rounded-full  text-xs w-6 h-6 flex items-center justify-center">
                                {student.userInfo.firstName.charAt(0).toUpperCase() +
                                    student.userInfo.secondName.charAt(0).toUpperCase()}
                            </div>{' '}
                            <span className="text-[#678AA1] text-sm hover:underline">
                                {student.userInfo.firstName},{' '}
                                {student.userInfo.secondName}
                            </span>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};
