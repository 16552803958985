import React, { useState } from 'react';
import styles from './TabSwitch.module.scss';
import classNames from 'classnames';

type Props = {
    tabsList: any[];
    variant: 'like' | 'bookmark';
    setVariant: (variant: 'like' | 'bookmark') => void;
};

const TabSwitch = ({ tabsList, variant, setVariant }: Props) => {
    return (
        <div className={classNames(styles.root)}>
            {tabsList.map((tab, index) => (
                <span
                    key={index}
                    className={classNames(
                        styles.tabButton,
                        tab.value === variant && styles.activeTab,
                    )}
                    onClick={() => setVariant(tab.value)}
                >
                    {tab.label}
                </span>
            ))}
        </div>
    );
};

export default TabSwitch;
