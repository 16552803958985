import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './ResultElement.module.scss';
import { Question } from '../../../../api/beyimProgressDashboard/types/getProgressByAssessmentDetailedResponseApiType';
import { LexcialEditor } from '../../../LexicalEditor/LexcialEditor';
import { nanoid } from '@reduxjs/toolkit';
import { Explanation } from '../Explanation';
interface IResultElement {
    item: Question;
    index: number;
}
export const ResultElement: React.FC<IResultElement> = ({ item, index }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={classNames(
                    ' w-full  px-6 ',
                    !isOpen && 'border-b-[1px]  border-x-0 border-[#E9F0F3]',
                    isOpen && 'bg-[#F7FAFB]',
                )}
            >
                <div
                    className=" border-x-0 py-3  flex justify-between "
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex gap-2 items-start ">
                        <img
                            src="/icons/arrowAc.svg"
                            className={classNames(
                                'block duration-200',
                                isOpen ? 'rotate-90' : 'rotate-0',
                            )}
                        />{' '}
                        <div className="flex flex-col justify-start items-start">
                            <span className="text-[#8CA9BC] text-sm font-medium leading-[normal]">
                                {t('dashboardResults.question')} {index + 1}
                            </span>
                            <LexcialEditor fieldData={item.question.body} />
                        </div>
                    </div>
                    <div className="flex gap-4 ">
                        <span
                            className={classNames(
                                'hidden md:block text-sm font-medium leading-[normal] whitespace-nowrap',
                                item.isCorrect
                                    ? 'text-[#71BD4E]'
                                    : 'text-[#E55959]',
                            )}
                        >
                            {item.isCorrect
                                ? t('dashboardResults.correctAnswer')
                                : t('dashboardResults.incorrectAnswer')}
                        </span>
                        <div className="flex gap-1 w-4 h-4">
                            <img
                                src={`/icons/${
                                    item.isCorrect
                                        ? 'checkedRight'
                                        : 'checkedWrong'
                                }.svg`}
                                alt="assessment"
                            />
                        </div>
                    </div>
                </div>
            </button>
            {isOpen && (
                <div className="flex flex-col gap-1 bg-[#F7FAFB] pb-4">
                    {item.question.options.map((option, indexNum) => {
                        const id = nanoid();
                        return (
                            <div className="px-6" key={indexNum}>
                                <input
                                    className={classNames(
                                        'opacity-100 w-0 h-0 hidden',
                                        style.input,
                                    )}
                                    type="radio"
                                    id={id}
                                    disabled
                                />
                                <label
                                    htmlFor={id}
                                    className={classNames(
                                        'border-2 rounded-xl py-2 px-4 flex items-center gap-3 duration-200 h-fit mb-1',
                                        style.option,
                                        item.answer === indexNum &&
                                            item.isCorrect &&
                                            style.answerTrue,
                                        item.answer === indexNum &&
                                            !item.isCorrect &&
                                            style.answerFalse,
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            'block w-6 h-6 shrink-0 grow-0 border-2 bg-center  rounded-full',
                                            style.checked,
                                        )}
                                    />
                                    <span className={classNames(style.index)}>
                                        {String.fromCharCode(65 + indexNum)}.
                                    </span>
                                    <LexcialEditor fieldData={option.body} />
                                </label>
                                {indexNum === item.answer && (
                                    <Explanation
                                        item={item.explanation}
                                        isCorrect={
                                            item.isCorrect &&
                                            item.answer === indexNum
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
