import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSearchParams } from 'react-router-dom';
import { HeaderMore } from '../../../features/HeaderMore';
import style from './More.module.scss';
import classNames from 'classnames';
import {
    useGetOnePostQuery,
    useGetOneQuery,
} from '../../../api/contentService';
import { LexcialEditor } from '../../../features/LexicalEditor/LexcialEditor';
import { nanoid } from '@reduxjs/toolkit';
import { useGetAllSubjectsQuery } from '../../../api';
import { useNormalizedLocale } from '../../../hooks/useNormalizedLocale';
import Skeleton from 'react-loading-skeleton';
import useResposive, { breakpoints } from '../../../hooks/useResponsive';

const More: React.FC = () => {
    const locale = useNormalizedLocale();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [keyData, setKeyData] = useState(nanoid());
    const [searchParams, setSearchParams] = useSearchParams();
    const openId = searchParams.get('idContent');
    const windowSize = useResposive();
    const {
        data: dataContent,
        isLoading: isLoadingContent,
        isFetching: isFetchingContent,
    } = useGetOneQuery(openId || '', {
        skip: !openId,
    });
    const {
        data: subjects,
        isLoading: isLoadingSubjects,
        isFetching: isFetchingSubjects,
    } = useGetAllSubjectsQuery({
        limit: 100,
    });

    const openModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpen(false);

        if (!searchParams.get('fromSearch')) {
            document.body.style.overflow = 'visible';
        }
        setSearchParams(prev => {
            return {
                idContent: '',
                fromSearch: '',
                subject: prev.get('subject') || '',
                sectionsBySubject: prev.get('sectionsBySubject') || '',
                them: prev.get('them') || '',
                idActivity: prev.get('idActivity') || '',
            };
        });
    };

    useEffect(() => {
        if (searchParams.get('idContent')) {
            openModal();
        } else {
            closeModal();
        }
    }, [openId]);

    useEffect(() => {
        setKeyData(nanoid());
    }, [dataContent]);

    useEffect(() => {
        isOpen
            ? document.documentElement.style.setProperty(
                  '--visible-pop',
                  'true',
              )
            : document.documentElement.style.setProperty(
                  '--visible-pop',
                  'false',
              );
    }, [isOpen]);

    const neededSubject = subjects?.data.subjects.find(
        item => item.id === dataContent?.data.content.subjectId,
    );
    return (
        <div>
            <ReactModal
                isOpen={isOpen}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                shouldCloseOnEsc={false}
                style={{
                    overlay: {
                        backgroundColor: '#E9F0F3',
                        zIndex: 3001,
                        padding: 0,
                    },
                    content: {
                        inset: 0,
                        backgroundColor: '#E9F0F3',
                        border: 0,
                        padding: 0,
                        height: '100vh',
                        paddingBottom:
                            windowSize.width < breakpoints.md ? '80px' : '20px',
                    },
                }}
            >
                <>
                    <HeaderMore
                        title={
                            neededSubject?.translations.find(translation => {
                                return translation.locale === locale;
                            })?.name ?? neededSubject?.translations[0].name
                        }
                        subTitle={dataContent?.data.content.description}
                        onBack={closeModal}
                        isLoadingContent={isLoadingContent}
                        isLoadingSubjects={isLoadingSubjects}
                        isFetchingContent={isFetchingContent}
                        isFetchingSubjects={isFetchingSubjects}
                        contentId={dataContent?.data.content.id}
                        subjectId={dataContent?.data.content.subjectId}
                    />
                    <div
                        className={classNames(
                            'container  grid gap-4 pt-6 grid-cols-12',
                        )}
                    >
                        <div
                            className={classNames(
                                'sm:col-span-8 sm:col-start-3 col-span-12 rounded-2xl md:p-10 p-4',
                                style.content,
                            )}
                            key={`openIdMore-${keyData}`}
                        >
                            {isLoadingContent || isFetchingContent ? (
                                <Skeleton
                                    count={4}
                                    height={24}
                                    width={'100%'}
                                    baseColor="#e9f0f3"
                                />
                            ) : (
                                dataContent?.data && (
                                    <LexcialEditor
                                        fieldData={
                                            dataContent.data.content.body
                                        }
                                        generatorType={
                                            dataContent.data.content
                                                .generatorType
                                        }
                                    />
                                )
                            )}
                        </div>
                    </div>
                </>
            </ReactModal>
        </div>
    );
};

export default More;
