import { useSearchParams } from 'react-router-dom';
import { useGetSectionsBySubjectQuery } from '../api/programService';

interface IGetTypeId {
    type: 'topic' | 'section';
    topicId?: number;
    searchParams: URLSearchParams;
}

export const useGetTopicSection = ({
    type,
    topicId,
    searchParams,
}: IGetTypeId) => {
    const { data } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const sectionId = searchParams.get('sectionsBySubject') || '';
    const them = searchParams.get('them') || '';

    const section = data?.data.sections.find(
        section => section.id === +sectionId,
    );
    const defaultSection = data?.data.sections[0];

    if (type === 'topic') {
        const topicIdOrThem = topicId || +them || 0;
        return (
            section?.children.find(topic => topic.id === topicIdOrThem) ||
            section?.children[0] ||
            defaultSection?.children[0]
        );
    }

    if (type === 'section') {
        return section || defaultSection;
    }
};
