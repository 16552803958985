import { FC, useEffect, useState } from 'react';
import { Header } from '../../features/Header';
import { MobileHeader } from '../dashboard/utils/MobileHeader';
import { DashboardStudentInfo } from './utils/DashboardStudentInfo';
import { DashboardStudentResults } from './utils/DashboardStudentResults';
import { useSearchParams } from 'react-router-dom';
import { DetailAssessmentResults } from '../../features/DetailAssessmentResults';

const DashboardStudent: FC = () => {
    const [searchParams] = useSearchParams();
    const [typeRender, setTypeRender] = useState<'info' | 'results'>('info');

    useEffect(() => {
        if (searchParams.get('type')) {
            setTypeRender(searchParams.get('type') as 'info' | 'results');
        }
    }, [searchParams.get('type')]);
    return (
        <div className="bg-[#E9F0F3] min-h-screen  ">
            <div className="hidden md:block fixed w-full z-[1003] top-0">
                <Header withSearch={false} />
            </div>
            {/* <div className="block md:hidden"><MobileHeader /></div> */}
            <div className="flex min-h-[calc(100vh_-_56px)] pt-14">
                <div
                    className={
                        'w-full px-4 mx-auto max-w-[1168px] py-4 flex gap-[2px] flex-col overflow-x-scroll  '
                    }
                >
                    <DashboardStudentInfo />
                    {typeRender === 'info' ? (
                        <DashboardStudentResults isStudent />
                    ) : (
                        <DetailAssessmentResults isStudent />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardStudent;
