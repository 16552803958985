import { SubmitHandler, useForm } from 'react-hook-form';
import { usePostCheckAnswerMutation } from '../../../../api/beyimProgress';
import { Activity } from '../../../../types/GetCustomFeedRequestApiType';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useLazyGetHintQuery } from '../../../../api/assessmentService';
import { Hint } from '../../../../types/GetHintResponseApiType';
import classNames from 'classnames';
import style from './Question.module.scss';
import { indexLatinLetters } from '../indexLatinLetters';
import { Like } from '../../../like';
import { Bookmark } from '../../../Bookmark';
import { LexcialEditor } from '../../../LexicalEditor/LexcialEditor';
import { useTranslation } from 'react-i18next';
import { XAPIActivity } from '../../../XAPI/sender';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNormalizedLocale } from '../../../../hooks/useNormalizedLocale';
import { useGetTopicSection } from '../../../../hooks';
import HintSkeleton from '../../../../ui/HintSkeleton';
import { PostFeed } from '../../../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';
import TagManager from 'react-gtm-module';

interface IQuestion {
    data: Activity;
    onResize?: () => void;
    refSlide?: any;
    microtopic?: string;
    subject?: string;
    category?: string;
    activityFooterData: PostFeed | undefined;
}

export const Question: React.FC<IQuestion> = ({
    data,
    onResize,
    microtopic,
    subject,
    category,
    activityFooterData,
}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const locale = useNormalizedLocale();
    const location = useLocation();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<{
        answer: number | number[];
        id: string;
    }>();
    const [postCheckAnswer] = usePostCheckAnswerMutation();
    const [currentAnswers, setCurrentAnswers] = useState<{
        index: number[];
        isCorrect: boolean;
    }>({ index: [], isCorrect: false });
    const [currentHints, setCurrentHints] = useState<Hint[]>([]);
    const [getHint, { isFetching: isFetchingHints }] = useLazyGetHintQuery();
    const [isLastHint, setIsLastHint] = useState<boolean>(false);
    const section = useGetTopicSection({
        type: 'section',
        searchParams,
    });
    const topic = useGetTopicSection({
        type: 'topic',
        searchParams,
    });
    const onSubmit: SubmitHandler<{
        answer: number | number[];
        id: string;
    }> = dataForm => {
        postCheckAnswer({ answer: dataForm.answer, id: data.id })
            .unwrap()
            .then(res => {
                // try {
                //     new XAPIActivity({
                //         type: 'activity',
                //         verb: 'attempted',
                //         lrs_endpoint: `${process.env.REACT_APP_BACKEND_XAPI}/beyim-lrs/xapi/`,
                //         local_url: window.location.href,
                //         content_name: data.body,
                //         content_type: 'activity',
                //         language: locale,
                //         subject: subject || '',
                //         section:
                //             (section?.translations.find(
                //                 translation => translation.locale === locale,
                //             )?.name as string) || '',
                //         topic:
                //             (topic?.translations.find(
                //                 translation => translation.locale === locale,
                //             )?.name as string) || '',
                //         hintCount: currentHints.length,
                //         hintMax: data.hintsCount,
                //         response: data.options[dataForm.answer as number].Body,
                //         isResponseCorrect: res.data.is_correct,
                //         result_completion: true,
                //     }).sendStatment();
                // } catch (e) {
                //     console.log(e);
                // }

                setCurrentAnswers(() => {
                    if (data.type === 'MS_MCQ') {
                        return {
                            index: [...(dataForm.answer as [])],
                            isCorrect: res.data.is_correct,
                        };
                    }

                    return {
                        index: [dataForm.answer as number],
                        isCorrect: res.data.is_correct,
                    };
                });
            });
    };

    const onHints = () => {
        setCurrentHints(prev => {
            const oldState = [...prev];

            oldState.push({ body: 'loader' });
            return oldState;
        });
        if (!isLastHint && !isFetchingHints) {
            getHint({ id: data.id, offset: currentHints.length })
                .unwrap()
                .then(hint => {
                    setCurrentHints(prev => {
                        const oldState = [...prev.slice(0, prev.length - 1)];

                        oldState.push(hint.data.hint);
                        return oldState;
                    });
                    setIsLastHint(hint.data.isLast);
                })
                .catch((error: any) => {
                    console.log(error, 'error');
                });
        }
    };

    useEffect(() => {
        if (onResize) {
            onResize();
        }
    }, [currentHints]);

    return (
        <div
            className={classNames(
                'question',
                location.pathname === '/favorites' && 'px-4',
            )}
            // dir={locale === 'ar' ? 'rtl' : 'lrt'}
        >
            <form>
                <h2 className="text-base font-bold mb-4 ">
                    {t('activity.activity')}
                </h2>
                <div className="mb-4">
                    <LexcialEditor fieldData={data.body} />
                </div>

                {data.options.map((option, index) => {
                    const id = nanoid();
                    return (
                        <div key={option.Body}>
                            <input
                                id={id}
                                type={
                                    data.type === 'MS_MCQ'
                                        ? 'checkbox'
                                        : 'radio'
                                }
                                value={index}
                                {...register(
                                    data.type === 'MS_MCQ'
                                        ? `answer.${index}`
                                        : `answer`,
                                )}
                                className={classNames(style.input, 'hidden')}
                            />
                            <label
                                htmlFor={id}
                                className={classNames(
                                    'border-2 rounded-xl p-4 mb-1 cursor-pointer flex items-center gap-3 duration-200 md:hover:bg-[#e9f0f3]',
                                    style.option,
                                    //@ts-ignore
                                    currentAnswers.index.includes(String(index))
                                        ? currentAnswers.isCorrect
                                            ? style.answerTrue
                                            : style.answerFalse
                                        : '',
                                )}
                            >
                                <span
                                    className={classNames(
                                        'block w-6 h-6 shrink-0 grow-0 border-2 bg-center  rounded-full',
                                        style.checked,
                                    )}
                                />
                                <span className={classNames(style.index)}>
                                    {indexLatinLetters[index]}
                                </span>

                                <LexcialEditor fieldData={option.Body} />
                            </label>
                        </div>
                    );
                })}

                <div className="mb-4">
                    {currentHints.map((item, index) => {
                        const id = nanoid();
                        const idLoad = nanoid();
                        if (item.body === 'loader')
                            return <HintSkeleton key={idLoad} />;

                        return (
                            <div
                                key={id}
                                className={classNames(
                                    'px-3 py-4 rounded-2xl mb-1',
                                    style.hint,
                                )}
                            >
                                <span
                                    className={classNames(
                                        style.hintCount,
                                        'text-sm font-bold block',
                                    )}
                                >
                                    {t('feed.hint')} {index + 1}
                                    {'/'}
                                    {data.hintsCount}
                                </span>
                                <span className={classNames(' block')}>
                                    <LexcialEditor fieldData={item.body} />
                                </span>
                            </div>
                        );
                    })}
                </div>
                <div
                    className="flex justify-between pb-1 items-center"
                    // dir={locale === 'ar' ? 'rtl' : 'lrt'}
                >
                    <div className="flex gap-4">
                        <Like
                            postId={data.id}
                            postType="activity"
                            category={category}
                            subject={subject}
                            title={data.body}
                            isLikedPost={activityFooterData?.isLiked}
                            likeCount={activityFooterData?.likesCount}
                        />
                        <Bookmark
                            postId={data.id}
                            postType="activity"
                            microtopic={microtopic}
                            category={category}
                            subject={subject}
                            title={data.body}
                            isBookmarkedPost={activityFooterData?.isBookmarked}
                            bookmarkCount={activityFooterData?.bookmarkCount}
                        />
                    </div>
                    <div className="flex gap-2">
                        <button
                            type="button"
                            className={classNames(
                                ' w-12 h-11 flex items-center justify-center rounded-2xl duration-200',
                                style.hintButton,
                                currentHints.length === data.hintsCount &&
                                    'opacity-50',
                                currentHints.length === data.hintsCount &&
                                    style.hintButtonDisable,
                            )}
                            disabled={currentHints.length === data.hintsCount}
                            onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'hint',
                                        activity_id: data.id,
                                        subject: subject,
                                        clickNum: currentHints.length,
                                        locale: locale,
                                    },
                                    dataLayerName: 'feed',
                                });
                                onHints();
                            }}
                        >
                            <img
                                data-tooltip-id="hint"
                                src="/icons/hint.svg"
                                className="block h-6 w-6"
                                alt="Hint"
                            />
                        </button>
                        <button
                            onClick={handleSubmit(onSubmit)}
                            // className={classNames(
                            //     'flex items-center tracking-[1px] justify-center px-6 rounded-2xl uppercase text-xs font-bold',
                            //     style.checkAnswer,
                            //     !watch('answer') && style.checkAnswerDisable,
                            // )}
                            className={classNames(
                                'rounded-2xl  h-11 items-center flex justify-center uppercase text-xs font-bold min-w-[132px] tracking-[1px] p-3',
                                style.popularButton,
                                !watch('answer') &&
                                    style.popularButton__disabled,
                            )}
                            type="button"
                            // disabled={!watch('answer')}
                        >
                            {t('onboarding.post.check')}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

///api/v1/bookmark?postId=656d8e4c66ad80c6cbda8af7&postType=post
///api/v1/bookmark?postId=655c89939fc9a3c2698ca49f&postType=activity
