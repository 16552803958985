import classNames from 'classnames';
import style from './SearchFilterForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../../../hooks';
import {
    SubmitHandler,
    UseFormSetValue,
    useForm,
    useFormContext,
} from 'react-hook-form';
import { IFilter } from '../../SmartSearch';
import { useGetAllSubjectsQuery } from '../../../../api';
import { useEffect, useState } from 'react';
import { Button } from '../../../AuthForms/components/ButtonComponent';

export const SearchFilterForm = ({
    setIsOpen,
}: {
    setIsOpen?: (value: boolean) => void;
}) => {
    const { t } = useTranslation();
    const locale = useNormalizedLocale();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue: setValueFilter,
    } = useFormContext();
    const { data: subjects } = useGetAllSubjectsQuery({ limit: 100 });

    useEffect(() => {
        setValueFilter('locale', locale);
    }, [locale]);

    return (
        <section className="p-4">
            <div className="flex justify-between mb-3 items-center">
                <h2 className="text-lg font-bold">
                    {t('search.filter.title')}
                </h2>
                <button onClick={() => setIsOpen && setIsOpen(false)}>
                    <img
                        src="/icons/closeSearch.svg"
                        alt="close"
                        className="w-5 h-5"
                    />
                </button>
            </div>
            <div>
                <span
                    className={classNames(
                        style.blue,
                        'text-sm font-medium mb-4 block',
                    )}
                >
                    {t('search.filter.subjects')}
                </span>
                <div className="flex flex-wrap gap-2 mb-4">
                    {subjects?.data.subjects.map((item, index) => {
                        return (
                            <div key={`${item.id}-subject-filter`}>
                                <input
                                    type="checkbox"
                                    {...register(`subjects.${index}`)}
                                    id={`subjects.${index}`}
                                    value={item.id}
                                    className={classNames(
                                        'hidden',
                                        style.input,
                                    )}
                                />
                                <label
                                    className={classNames(
                                        'gap-1 rounded-xl !px-3 py-2 cursor-pointer border-2 border-solid !flex h-8 items-center',
                                        style.label,
                                    )}
                                    htmlFor={`subjects.${index}`}
                                >
                                    <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                                        {item.translations.find(localeItem => {
                                            return localeItem.locale === locale;
                                        })?.name || item.translations[0].name}
                                    </span>
                                    {!watch(`subjects.${index}`) && (
                                        <img src="/icons/plus.svg" alt="" />
                                    )}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div>
                <span
                    className={classNames(
                        style.blue,
                        'text-sm font-medium mb-4 block',
                    )}
                >
                    {t('search.filter.type')}
                </span>
                <div className="flex flex-wrap gap-2 mb-4">
                    <input
                        type="checkbox"
                        {...register(`typeResources.0`)}
                        value={'image'}
                        className={classNames('hidden', style.input)}
                        checked={true}
                        id={`typeResources.0`}
                    />
                    <label
                        className={classNames(
                            'gap-1 rounded-xl !px-3 py-2 cursor-pointer border-2 border-solid !flex h-8 items-center',
                            style.label,
                        )}
                        htmlFor={`typeResources.0`}
                    >
                        <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                            {t('search.filter.picture')}
                        </span>
                    </label>
                    {/* <input
                            className={classNames(
                                'hidden',
                                style.input,
                            )}
                            type="checkbox"
                            {...register(
                                `typeResources.1`,
                            )}
                            value={'video'}
                            id={`typeResources.1`}
                        />
                        <label
                            className={classNames(
                                'gap-1 rounded-xl !px-3 py-2 cursor-pointer border-2 border-solid !flex h-8 items-center',
                                style.label,
                            )}
                            htmlFor={`typeResources.1`}
                        >
                             <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                                {t(
                                    'search.filter.video',
                                )}
                            </span>
                        </label> */}
                </div>
            </div>
            <div className="mb-4">
                <span
                    className={classNames(
                        style.blue,
                        'text-sm font-medium mb-4 block',
                    )}
                >
                    {t('search.filter.language')}
                </span>
                <div className="flex flex-wrap gap-2 mb-4">
                    <input
                        id="locale-kk"
                        className={classNames('hidden', style.input)}
                        type="radio"
                        {...register(`locale`)}
                        value={'kk'}
                    />
                    <label
                        className={classNames(
                            'gap-1 rounded-xl !px-3 py-2 cursor-pointer border-2 border-solid !flex h-8 items-center',
                            style.label,
                        )}
                        htmlFor="locale-kk"
                    >
                        <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                            {t('search.filter.kk')}
                        </span>
                    </label>
                    <input
                        type="radio"
                        {...register(`locale`)}
                        className={classNames('hidden', style.input)}
                        value={'ru'}
                        id="locale-ru"
                    />
                    <label
                        className={classNames(
                            'gap-1 rounded-xl !px-3 py-2 cursor-pointer border-2 border-solid !flex h-8 items-center',
                            style.label,
                        )}
                        htmlFor="locale-ru"
                    >
                        <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                            {t('search.filter.ru')}
                        </span>
                    </label>

                    <input
                        id="locale-en"
                        className={classNames('hidden', style.input)}
                        type="radio"
                        {...register(`locale`)}
                        value={'en'}
                    />
                    <label
                        className={classNames(
                            'gap-1 rounded-xl px-3 py-2 cursor-pointer border-2 border-solid flex h-8 items-center',
                            style.label,
                        )}
                        htmlFor="locale-en"
                    >
                        <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                            {t('search.filter.en')}
                        </span>
                    </label>
                    <input
                        id="locale-ko"
                        className={classNames('hidden', style.input)}
                        type="radio"
                        {...register(`locale`)}
                        value={'ko'}
                    />
                    <label
                        className={classNames(
                            'gap-1 rounded-xl px-3 py-2 cursor-pointer border-2 border-solid flex h-8 items-center',
                            style.label,
                        )}
                        htmlFor="locale-ko"
                    >
                        <span className="text-sm leading-[14px] font-medium text-[#A3C0D2]">
                            {t('search.filter.en')}
                        </span>
                    </label>
                </div>
            </div>
            <div>
                <Button
                    text={t('search.filter.apply')}
                    type="submit"
                    variant="primary"
                />
                <button
                    type="button"
                    className={classNames(
                        'rounded-2xl w-full h-10 items-center flex justify-center uppercase text-xs font-bold min-w-[132px] tracking-[1px] p-3',
                        style.clear,
                    )}
                    onClick={() => {
                        reset();
                    }}
                >
                    {t('search.filter.resetFilter')}
                </button>
            </div>
        </section>
    );
};
