import React, { FC, HTMLAttributes } from 'react';

import { useGetProgressByStudentFullQuery } from '../../../../api/beyimProgressDashboard';
import { AccordionElements } from './utils/AccordionTopic';
import { useSearchParams } from 'react-router-dom';
import { SkeletonAccordionBlock } from './utils/Skeleton';
import { useGetUserDataQuery } from '../../../../api/authService/authServiceApi';
import { startsWithSeven } from '../../../../pages/dashboard/utils/startsWithSeven/startsWithSeven';

interface IAccordionBlock extends HTMLAttributes<HTMLDivElement> {
    isStudent?: boolean;
}

export const AccordionBlock: FC<IAccordionBlock> = ({ isStudent = false }) => {
    const [searchParams] = useSearchParams();
    const {
        data: progressStudent,
        isLoading,
        isFetching,
    } = useGetProgressByStudentFullQuery({
        subjectId: searchParams.get('subject_id') || '',
        userId: searchParams.get('user_id') || '',
        classId: searchParams.get('class_id') || '',
    });
    const { data } = useGetUserDataQuery('');

    const isDemo =
        data?.data.User.email === 'quantum_demo_teacher@beyim.ai' ||
        data?.data.User.email === 'binom_demo_teacher@beyim.ai' ||
        'demo_student_1@beyim.ai' ||
        'demo_student_2@beyim.ai' ||
        'demo_student_3@beyim.ai' ||
        'demo_student_4@beyim.ai' ||
        'demo_student_5@beyim.ai';

    const filterData = progressStudent?.data.data.sections?.length
        ? progressStudent?.data.data.sections.filter(section => {
              return startsWithSeven(section.section.translations[0].name);
          })
        : [];

    return (
        <>
            {isFetching || isLoading ? (
                <SkeletonAccordionBlock />
            ) : isDemo ? (
                filterData.map(item => {
                    return (
                        <React.Fragment key={item.section.id}>
                            <AccordionElements
                                item={item}
                                isStudent={isStudent}
                            />
                        </React.Fragment>
                    );
                })
            ) : (
                progressStudent?.data.data.sections?.length &&
                progressStudent?.data.data.sections?.map(item => {
                    return (
                        <React.Fragment key={item.section.id}>
                            <AccordionElements
                                item={item}
                                isStudent={isStudent}
                            />
                        </React.Fragment>
                    );
                })
            )}
        </>
    );
};
