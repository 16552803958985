import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import style from './Passing.module.scss';

import { AssessmentHeader } from '../../../features/AssessmentHeader';
import { ButtonNode } from '../../../features/AssessmentHeader/utils/ButtonNode';
import { AssessmentProperties } from '../../../features/AssessmentHeader/utils/AssessmentProperties';
import { Icons } from '../../../features/AssessmentHeader/utils/Icons';
import { TestAssessment } from '../../../features/AssessmentPassing/TestAssessment';
import { useGetQuestionsAssessmentQuery } from '../../../api/beyimAssessmentApi/beyimAssessmentApi';
import {
    usePostAnswersAssessmentMutation,
    usePostToCompleteAssessmentMutation,
} from '../../../api/beyimProgress';
import { ModalComponent } from '../../../ui/ModalComponent';
import { useNormalizedLocale } from '../../../hooks/useNormalizedLocale';
import { TypePost } from '../../../features/AssessmentHeader/utils/TypePost';

export interface FormValues {
    answers: {
        id: string;
        answer: number | null;
    }[];
}

export const Passing: React.FC = () => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();
    const [searchParams] = useSearchParams();
    const { id, progressId } = useParams();

    const navigate = useNavigate();

    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [errorSubmitQuestions, setErrorSubmitQuestions] = useState<string>();
    const [errorOnComplete, setErrorOnComplete] = useState<string>();
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const {
        data: questionsOnAssessment,
        isLoading: isLoadingQuestions,
        isFetching: isFetchingQuestions,
    } = useGetQuestionsAssessmentQuery({
        locale: locale,
        assessment_id: id || '',
        section_id: searchParams.get('them') || '',
    });

    const [postAnswersAssessment, { isLoading: isLoadingSubmitAnswer }] =
        usePostAnswersAssessmentMutation();

    const [postToComplete, { isLoading: isLoadingCompleted }] =
        usePostToCompleteAssessmentMutation();

    const changeCurrentQuestion = (index: number) => {
        setCurrentQuestion(index);
    };

    const initialAnswers =
        (questionsOnAssessment?.data.questions?.length &&
            questionsOnAssessment?.data.questions.map(question => ({
                id: question.id,
                answer: null,
            }))) ||
        [];

    const { handleSubmit, register, getValues } = useForm({
        defaultValues: {
        answers: initialAnswers,
    },
});

const onSubmit = (data: FormValues) => {
        if (questionsOnAssessment?.data.assessment) {
            const answers = data.answers.map((answer, index) => ({
                id: questionsOnAssessment.data.questions[index].id,
                answer: answer.answer !== null ? Number(answer.answer) : null,
            }));

            postAnswersAssessment({
                progress_id: Number(progressId),
                //@ts-ignore
                answers: answers,
            })
                .unwrap()
                .then(res => {
                    postToComplete({
                        progress_id: Number(progressId),
                    })
                        .unwrap()
                        .then(res => {
                            navigate(
                                `/feed/assessment/${id}/results/${progressId}?${searchParams.toString()}`,
                            );
                        })
                        .catch(err => {
                            setErrorOnComplete(err.data.message);
                        });
                    localStorage.removeItem('passing');
                })
                .catch(err => {
                    setErrorSubmitQuestions(err.data.message);
                });
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            const confirmationMessage =
                'Are you sure you want to leave? Your changes not be saved.';
            e.preventDefault();
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (isCompleted) {
            onSubmit(getValues());
        }
    }, [isCompleted]);
    useEffect(()=>{
        document.title=t('pages.passing');
    },[locale]);
    return (
        <>
            <Helmet>
                <title>{t('pages.passing')}</title>
            </Helmet>
            <div
                className={classNames(
                    'min-h-[calc(100vh-59px)] md:min-h-screen relative z-[3202]',
                    style.wrapper,
                )}
            >
                <AssessmentHeader
                    buttonNode={
                        <ButtonNode
                            href={`/feed/assessment/${id}?${searchParams.toString()}`}
                            confirm
                        />
                    }
                    assessmentProperties={
                        <AssessmentProperties
                            isLoadingQuestions={
                                isLoadingQuestions || isFetchingQuestions
                            }
                            currentQuestion={currentQuestion}
                            totalQuestions={
                                questionsOnAssessment?.data.questions?.length ||
                                0
                            }
                            setIsCompleted={setIsCompleted}
                        />
                    }
                    typePost={
                        <TypePost
                            isLoading={
                                isLoadingQuestions || isFetchingQuestions
                            }
                            title={
                                questionsOnAssessment?.data.assessment
                                    .section_name
                            }
                            typePost={
                                questionsOnAssessment?.data.assessment
                                    .section_type_id === 3
                                    ? t('assessment.topicAssessment')
                                    : t('assessment.sectionAssessment')
                            }
                        />
                    }
                    icons={
                        <Icons
                            test={questionsOnAssessment?.data.questions}
                            changeCurrentQuestion={changeCurrentQuestion}
                            values={getValues()}
                        >
                            <div className="block md:hidden">
                                <AssessmentProperties
                                    isLoadingQuestions={
                                        isLoadingQuestions ||
                                        isFetchingQuestions
                                    }
                                    currentQuestion={currentQuestion}
                                    totalQuestions={
                                        questionsOnAssessment?.data.questions
                                            ?.length || 0
                                    }
                                    setIsCompleted={setIsCompleted}
                                />
                            </div>
                        </Icons>
                    }
                    className={`gap-[10px] sm:gap-2`}
                />
                <form className='pb-[60px]' onSubmit={handleSubmit(onSubmit)}>
                    <TestAssessment
                        isLoadingQuestions={
                            isLoadingQuestions || isFetchingQuestions
                        }
                        test={questionsOnAssessment?.data.questions}
                        currentQuestion={currentQuestion}
                        changeCurrentQuestion={changeCurrentQuestion}
                        isLoadingComplete={isLoadingCompleted}
                        isLoadingSubmitAnswer={isLoadingSubmitAnswer}
                        register={register}
                    />
                </form>
                <ModalComponent
                    isOpen={!!errorOnComplete || !!errorSubmitQuestions}
                    headerContent={<h1> {t('assessment.error')}</h1>}
                    content={
                        <div>
                            <p>
                                {t('assessment.errorSumbit')} (
                                {errorSubmitQuestions || errorOnComplete})
                            </p>
                        </div>
                    }
                    onClose={() => {
                        setErrorSubmitQuestions('');
                        navigate(
                            `/feed/assessment/${id}?${searchParams.toString()}`,
                        );
                    }}
                />
            </div>
        </>
    );
};
