import { useSearchParams } from 'react-router-dom';
import { useGetProgressByStudentFullQuery } from '../../api/beyimProgressDashboard';
import { useGetClassByIdQuery } from '../../api/organizationService/organizationApiType';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks';
import { formatDate } from './utils/FormatDate';
export const StudentInfo: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { data: currentClass } = useGetClassByIdQuery({
        classId: searchParams.get('class_id') || '',
    });
    const { t } = useTranslation();
    const {
        data: progressStudent,
        isLoading,
        isFetching,
    } = useGetProgressByStudentFullQuery(
        {
            subjectId: searchParams.get('subject_id') || '',
            userId: searchParams.get('user_id') || '',
        },
        {
            skip: !searchParams.get('subject_id'),
        },
    );

    const locale = useNormalizedLocale();

    return (
        <div className="bg-white rounded-2xl p-4 flex gap-4 item-center ">
            {/* <img
                className="w-16 h-16 rounded-lg"
                src={
                    progressStudent?.data.data.userInfo.picture ||
                    '/images/1.png'
                }
                alt="user"
            />{' '} */}
            {isLoading || isFetching ? (
                <Skeleton
                    circle={true}
                    baseColor="#e9f0f3"
                    width={64}
                    height={64}
                />
            ) : (
                <div className="w-16 h-16 rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium text-[18px]">
                    {progressStudent?.data.data.userInfo.firstName[0]?.toUpperCase()}
                    {progressStudent?.data.data.userInfo.secondName[0]?.toUpperCase()}
                </div>
            )}
            <div className="flex flex-col">
                {isFetching || isLoading ? (
                    <Skeleton
                        baseColor="#e9f0f3"
                        count={3}
                        height={20}
                        width={250}
                    />
                ) : (
                    <>
                        <div className=" flex gap-2">
                            <h1 className="mb-1 text-lg leading-[normal] font-medium text-[#364954]">
                                {progressStudent?.data.data.userInfo.firstName}{' '}
                                {progressStudent?.data.data.userInfo.secondName}
                            </h1>
                            <div className="flex bg-[#ECFCE4] rounded-full items-center p-1">
                                <img
                                    src="/icons/medal.svg"
                                    className="w-4 h-4"
                                    alt=""
                                />
                                <span className="text-xs font-bold text-[#71BD4E] ">
                                    34%
                                </span>
                            </div>
                        </div>
                        <span className="mb-2 text-sm font-normal text-[#678AA1] leading-[normal]">
                            {currentClass?.data.class?.grade?.number ?? ' - '} “
                            {currentClass?.data.class?.letter}” класс
                        </span>
                        <span className=" text-sm font-normal text-[#55BBEB] leading-[normal]">
                            {locale === 'ru' || locale === 'en'
                                ? t('dashboardResults.wasOnline') +
                                  ' ' +
                                  formatDate(new Date())
                                : formatDate(new Date()) +
                                  ' ' +
                                  t('dashboardResults.wasOnline')}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};
