import { FC } from 'react';
import { useGetUserDataQuery } from '../../../../api/authService/authServiceApi';
import Skeleton from 'react-loading-skeleton';
import { DashboardStudentInfoButtons } from '../DashboardStudentInfoButtons';
import { roles } from '../../../../features/AuthForms/constants';

export const DashboardStudentInfo: FC = () => {
    const { data, isLoading, isFetching } = useGetUserDataQuery('');

    const studentOrg = data?.data.User?.organizations?.find(
        org => org?.role === roles.STUDENT,
    );

    return (
        <div
            className={
                'bg-white min-h-[84px] gap-y-2 rounded-t-2xl p-4 flex justify-between flex-wrap '
            }
        >
            <div className={'flex gap-4 '}>
                {isLoading || isFetching ? (
                    <Skeleton
                        circle={true}
                        baseColor="#e9f0f3"
                        width={50}
                        height={50}
                    />
                ) : (
                    <>
                        {data?.data?.User?.picture ? (
                            <img
                                src={data?.data?.User?.picture}
                                alt="Student Avatar"
                                className={'w-[50px] h-[50px] rounded-full'}
                            />
                        ) : (
                            <div className="w-[50px] h-[50px] rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium text-[18px]">
                                {data?.data?.User?.firstName
                                    .charAt(0)
                                    .toUpperCase()}
                                {data?.data?.User?.secondName
                                    .charAt(0)
                                    .toUpperCase()}
                            </div>
                        )}
                    </>
                )}
                <div className="flex flex-col gap-1">
                    {isFetching || isLoading ? (
                        <Skeleton
                            baseColor="#e9f0f3"
                            count={2}
                            height={20}
                            width={250}
                        />
                    ) : (
                        <>
                            <div className=" flex gap-2 items-center">
                                <h1 className="text-base leading-[normal] font-semibold text-[#364954]">
                                    {data?.data.User.firstName}{' '}
                                    {data?.data.User.secondName}
                                </h1>
                                <div className="flex bg-[#ECFCE4] rounded-full items-center p-1">
                                    <img
                                        src="/icons/medal.svg"
                                        className="w-4 h-4"
                                        alt=""
                                    />
                                    <span className="text-xs font-bold text-[#71BD4E] ">
                                        34%
                                    </span>
                                </div>
                            </div>
                            <span className="text-sm font-medium text-[#678AA1] leading-[normal]">
                                {
                                    studentOrg?.schools[0]?.classes[0]?.class
                                        .grade.number
                                }{' '}
                                “
                                {
                                    studentOrg?.schools[0]?.classes[0]?.class
                                        .letter
                                }
                                ” класс
                            </span>
                        </>
                    )}
                </div>
            </div>
            <DashboardStudentInfoButtons />
        </div>
    );
};
