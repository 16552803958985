import ReactSelect, { GroupBase, components } from 'react-select';
import StateManagedSelect from 'react-select';
import cx from 'classnames';
import style from './SelectBeyim.module.scss';
import type {} from 'react-select/base';
import makeAnimated from 'react-select/animated';
import { useTranslation } from 'react-i18next';
declare module 'react-select/base' {
    export interface Props<
        Option,
        IsMulti extends boolean,
        Group extends GroupBase<Option>,
    > {
        size?: 'xs' | 'sm' | 'md' | 'xl';
        isError?: boolean;
        customClassName?: string;
        indicatorContainerCustomClassName?: string;
        inputCustomClassName?: string;
        singleValueCustomClassName?: string;
        hasBorder?: boolean;
        isPrefix?: boolean;
        widthFitContent?: boolean;
    }
}

export const SelectBeyim: StateManagedSelect = ({
    size = 'md',
    isError = false,
    customClassName,
    indicatorContainerCustomClassName,
    inputCustomClassName,
    hasBorder = true,
    singleValueCustomClassName,
    placeholder,
    isPrefix,
    widthFitContent = false,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <ReactSelect
            components={{
                IndicatorsContainer: () => (
                    <div
                        className={cx(
                            style.indicatorsContainer,
                            indicatorContainerCustomClassName,
                        )}
                    >
                        <img src="/icons/arrowDown.svg" />
                    </div>
                ),
                SingleValue: ({ children, ...props }) => {
                    const placeholderText = placeholder || '';
                    if (isPrefix) {
                        return (
                            <components.SingleValue {...props}>
                                <span className={style['prefix']}>
                                    {placeholderText.toString()}
                                </span>
                                {children}
                            </components.SingleValue>
                        );
                    } else {
                        return (
                            <components.SingleValue {...props}>
                                {children}
                            </components.SingleValue>
                        );
                    }
                },
            }}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'white',
                },
            })}
            classNames={{
                container: () => {
                    return cx(
                        style[`size-${size}`],
                        !widthFitContent && style.container,
                        widthFitContent && style.widthFitContent,
                    );
                },
                control: ({ isFocused }) => {
                    return cx(
                        isFocused && style['border-focused'],
                        style[`size-${size}`],
                        style.control,
                        hasBorder ? style.border : style.noBorder,
                        isError ? style.errorStyle : '',
                        customClassName,
                    );
                },
                placeholder: () => {
                    return cx(style[`size-${size}`], style.placeholder);
                },
                valueContainer: state => {
                    return cx(
                        style[`size-${size}`],
                        style.valueContainer,
                        state ? style.focused : '',
                    );
                },
                indicatorSeparator: () => {
                    return cx(style.indicatorSeparator, style[`size-${size}`]);
                },
                singleValue: () => {
                    return cx(style.singleValue, singleValueCustomClassName);
                },
                option: ({ isFocused, isSelected }) => {
                    return cx(
                        isFocused && style['border-focused'],
                        isSelected && style['selected'],
                        style[`size-${size}`],
                        style.option,
                    );
                },
                multiValue: () => {
                    return cx(style.multiValue);
                },
                menu: () => {
                    return cx(style.menu);
                },
                menuList: () => {
                    return style.menuList;
                },
                input: () => {
                    return cx(inputCustomClassName);
                },
            }}
            noOptionsMessage={() => t('select.noOption')}
            placeholder={placeholder}
            {...props}
        />
    );
};
