import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import style from './CollectingMail.module.scss';

import { states } from '../../constants';
import { useCreateUserByCodeMutation } from '../../../../api/authService/authServiceApi';
import { toast } from 'react-toastify';
import { Class } from '../../../../types/organizationsApiType/GetCheckCodeStudentResponseApiType';
import { Organization } from '../../../../types/organizationsApiType/GetCheckCodeTeacherResponseApiType';
import { LabelLayout } from '../../../AuthForms/components/LabelLayout';
import { Button } from '../../../AuthForms/components/ButtonComponent';
import { roles } from '../../../AuthForms/constants';

interface IFormInput {
    email: string;
}
interface ICollectingMail {
    setState: (state: states) => void;
    activeType: number | undefined;
    classData: Class | undefined;
    organizationData: Organization | undefined;
}
export const CollectingMail: React.FC<ICollectingMail> = ({
    setState,
    activeType,
    classData,
    organizationData,
}) => {
    const { t } = useTranslation();

    const [createUserByCode, { isLoading }] = useCreateUserByCodeMutation();

    const schema = yup
        .object({
            email: yup
                .string()
                .email()
                .required(t('authorization.form.emailRequired')),
        })
        .required();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<IFormInput> = data => {
        const dataForm = {
            username: data.email,
            organizations:
                activeType === 0
                    ? [
                          {
                              classCode: classData?.code,
                              role: roles.STUDENT,
                          },
                      ]
                    : [
                          {
                              organizationCode: organizationData?.code,
                              role: roles.CLASS_TEACHER,
                          },
                          {
                              organizationCode: organizationData?.code,
                              role: roles.SUBJECT_TEACHER,
                          },
                      ],
        };
        createUserByCode(dataForm)
            .unwrap()
            .then(() => {
                toast.success(t('authorization.emailSend'));
                setState(states.login);
            })
            .catch(err => {
                setValue('email', '');
                if (err.data?.message === 'user already exists') {
                    toast.error(t('authorization.userExist'));
                    return;
                }
                toast.error(t('authorization.errorEmailSend'));
            });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LabelLayout labelText={t('authorization.personal.mail') + '*'}>
                <input
                    className={classNames(
                        'rounded-2xl text-sm py-4 pl-4',
                        style.input,
                        style.word,
                    )}
                    {...register('email')}
                    placeholder={t('authorization.personal.mailPlaceholder')}
                    autoComplete="off"
                />
                {errors.email && (
                    <p className="text-[12px] text-red-500 mt-[4px]">
                        {t('authorization.personal.mailValidate')}
                    </p>
                )}
            </LabelLayout>
            <div className="mt-[24px]">
                <Button
                    isLoading={isLoading}
                    type="submit"
                    text={t('authorization.next')}
                    variant={isValid ? 'primary' : 'disabled'}
                />
            </div>
        </form>
    );
};
