import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from 'react-slick';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs, Zoom, Keyboard } from 'swiper/modules';
import { Swiper as ISwiper } from 'swiper/types';
import { useNormalizedLocale } from '../../hooks';

interface ISliderPopup {
    data: string[];
    initialSlide?: number;
    closeModal?: (args?: any) => void;
}

const PrevArrow: React.FC = ({ ...restProps }) => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.keyCode === 37 && divRef.current) {
                // Arrow Right Key
                divRef.current.click(); // Trigger click event
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div ref={divRef} className="slick-arrow prev-arrow" {...restProps} />
    );
};

const NextArrow: React.FC = ({ ...restProps }) => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.keyCode === 39 && divRef.current) {
                // Arrow Right Key
                divRef.current.click(); // Trigger click event
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div ref={divRef} className="slick-arrow next-arrow" {...restProps} />
    );
};

export const SliderPopup: React.FC<ISliderPopup> = ({
    data,
    initialSlide = 0,
    closeModal,
}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const [openSlide, setOpenSlide] = useState(false);
    // Состояние для хранения текущего разрешения экрана
    const [screenResolution, setScreenResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleLoad = () => {
        // setTimeout(() => {
        //     thumbsSwiper.focus();
        // }, 100);
    };

    // Обработчик изменения размеров окна
    const handleResize = () => {
        setScreenResolution({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    // Добавление и удаление обработчика событий при монтировании и размонтировании компонента
    useEffect(() => {
        // Добавление обработчика при монтировании
        window.addEventListener('resize', handleResize);

        // Удаление обработчика при размонтировании
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Пустой массив зависимостей означает, что эффект сработает только при монтировании и размонтировании компонента

    const [nav1, setNav1] = useState<any>();
    const [nav2, setNav2] = useState<any>();

    const wrapperRef = useRef<any>(null);
    const locale = useNormalizedLocale();
    const chatbot = document.getElementsByTagName('flowise-chatbot')[0];
    useEffect(() => {
        // Function to handle clicks outside of the component
        const handleClickOutside = (event: any) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) && event.target != chatbot
            ) {
                // Call the provided callback if clicked outside of the component
                closeModal && closeModal();
                setOpenSlide(false);
            }
        };

        // Attach the event listener to the document body
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup: remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeModal]);

    return (
        <div className="w-full max-w-3xl sliderPopup" ref={wrapperRef}>
            <div className=" mb-4  z-10 relative">
                
                {
                    <Swiper
                        initialSlide={initialSlide}
                        style={{
                            //@ts-ignore
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        onKeyPress={(swiper: ISwiper, keyCode: string) => {
                            console.log(keyCode, 'keyCode');
                        }}
                        spaceBetween={10}
                        navigation={true}
                        keyboard={{ enabled: true, onlyInViewport: true }}
                        // thumbs={
                        //     thumbsSwiper ? { swiper: thumbsSwiper } : undefined
                        // }
                        thumbs={{
                            swiper:
                                thumbsSwiper && !thumbsSwiper.destroyed
                                    ? thumbsSwiper
                                    : null,
                        }}
                        modules={[FreeMode, Navigation, Thumbs, Zoom, Keyboard]}
                        className="mySwiper2 h-full"
                        zoom={screenResolution.width < 640}
                        lazy={true}
                    >
                        {data.map(item => {
                            return (
                                <SwiperSlide key={item}>
                                    <div
                                        key={`item-top-${item}`}
                                        className="wrapper-img-full h-full max-h-[calc(100vh_-_120px_-_80px_-_16px)]"
                                    >
                                        <div className="sm:px-[68px] h-full  max-h-[calc(100vh_-_120px_-_80px_-_16px)] flex items-center  overflow-hidden justify-center swiper-zoom-container">
                                            <img
                                                src={item}
                                                alt="slider"
                                                className="rounded-2xl block w-auto h-auto max-h-[calc(100vh_-_120px_-_80px_-_16px)] "
                                                loading="lazy"
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                }
            </div>
            <div className="sm:px-[68px] bottomGallery">
                <Swiper
                    onSwiper={(swiper: ISwiper) => {
                        setThumbsSwiper(swiper);
                        handleLoad();
                    }}
                    onSliderMove={(value: any) => {
                        // console.log(value, 'ThumbsThumbsThumbsThumbs');
                    }}
                    onSlide={true}
                    spaceBetween={10}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                    lazy={true}
                    // keyboard={{ enabled: true }}
                    style={{
                        //@ts-ignore
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                    }}
                >
                    {data.map(item => {
                        return (
                            <SwiperSlide key={item}>
                                {/* <img
                                    loading="lazy"
                                    src={item}
                                    className="block  rounded overflow-hidden cursor-pointer"
                                />
                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div> */}
                                <div
                                    key={`item-bot-${item}`}
                                    className="wrapper-img-navigate pt-[100%] overflow-hidden "
                                    // dir="ltr"
                                >
                                    <div className="absolute top-0 left-0 flex justify-center overflow-hidden w-full items-center h-full rounded">
                                        <img
                                            alt="Slider"
                                            loading="lazy"
                                            src={item}
                                            className="block  rounded overflow-hidden cursor-pointer h-auto max-w-none"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

//

//слик слайдер

/* <Slider
                    className="popup slider"
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    asNavFor={nav2}
                    initialSlide={initialSlide}
                    ref={slider1 => setNav1(slider1)}
                    infinite={false}
                    touchMove
                    afterChange={slide => {
                        console.log(slide, 'afterChange');
                    }}
                    beforeChange={slide => {
                        console.log(slide, 'beforeChange');
                    }}
                    // rtl={locale === 'ar'}
                >
                    {data.map(item => {
                        return (
                            <div
                                key={`item-top-${item}`}
                                className="wrapper-img-full h-full"
                            >
                                <div className="sm:px-[68px] h-full flex items-center justify-center swiper-zoom-container">
                                    <img
                                        src={item}
                                        alt="slider"
                                        className="rounded-2xl block max-h-full"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Slider> */

//слик навигация
/* <Slider
                    asNavFor={nav1}
                    ref={(slider2: any) => setNav2(slider2)}
                    slidesToShow={screenResolution.width < 640 ? 5 : 5}
                    swipeToSlide={true}
                    className="popup-slider-navigate"
                    infinite={false}
                    arrows={false}
                    initialSlide={initialSlide}
                    focusOnSelect={true}
                    // initialSlide={initialSlide}
                    // rtl={locale === 'ar'}
                >
                    {data.map(item => {
                        return (
                            <div
                                key={`item-bot-${item}`}
                                className="wrapper-img-navigate p-1 "
                                // dir="ltr"
                            >
                                <img
                                    alt="Slider"
                                    loading="lazy"
                                    src={item}
                                    className="block  rounded overflow-hidden cursor-pointer"
                                />
                            </div>
                        );
                    })}
                </Slider> */
