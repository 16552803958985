import { useSearchParams } from 'react-router-dom';
import { SectionStatus } from '../../../../features/SectionStatus';
import { WithVideoOrImage } from '../../../../features/WithVideoOrImage';
import { useGetSectionsBySubjectQuery } from '../../../../api/programService';

interface IRightBlock {
    handleChangeFilter: (type?: 'video' | 'image') => void;
    withPicture: boolean;
    withVideo: boolean;
}

export const RightBlock: React.FC<IRightBlock> = ({
    handleChangeFilter,
    withPicture,
    withVideo,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        data: dataSectionsBySubject,
        isFetching: isFetchingSectionsBySubject,
        isLoading: isLoadingSectionsBySubject,
    } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );
    return (
        <aside className=" hidden col-span-4 pt-3 md:flex flex-col gap-4">
            <WithVideoOrImage
                handleChangeFilter={handleChangeFilter}
                withPicture={withPicture}
                withVideo={withVideo}
            />
            {dataSectionsBySubject && searchParams.get('subject') && (
                <SectionStatus
                    data={dataSectionsBySubject?.data.sections || []}
                />
            )}
        </aside>
    );
};
