import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { date } from 'yup';

interface ITabs {
    optionsLabel: { label: string; id: string }[];
    optionsChildren: { children: ReactNode; idLabel: string }[];
}

export const Tabs: React.FC<ITabs> = ({ optionsLabel, optionsChildren }) => {
    const [activeTab, setActiveTab] = useState<string | null>(
        optionsLabel[0].id || null,
    );

    const { t } = useTranslation();

    const changeTab = (id: string) => {
        setActiveTab(id);
    };

    return (
        <div>
            <div className="flex">
                {optionsLabel.map(item => {
                    return (
                        <span
                            key={item.id}
                            onClick={() => {
                                changeTab(item.id);
                            }}
                            className={classNames(
                                'cursor-pointer min-h-10 text-sm leading-[100%] py-2 first:rounded-tl-xl flex items-center justify-center px-4 duration-200 border-[1px] text-[#364954] last:rounded-tr-xl',
                                activeTab === item.id
                                    ? 'bg-white border-white'
                                    : ' bg-[#E4F3FB]  border-[1px] border-[#CBDCE7]',
                            )}
                        >
                            {t(`statistics.${item.label}`)}
                        </span>
                    );
                })}
            </div>
            {optionsChildren.map(date => {
                return (
                    <div
                        className={classNames(
                            activeTab !== date.idLabel ? 'hidden' : '',
                        )}
                        key={`idLabel-${date.idLabel}`}
                    >
                        {date.children}
                    </div>
                );
            })}
        </div>
    );
};
