import ReactModal from 'react-modal';
import style from './ProfileMobile.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks';

import { UserData } from './UserData';
import { logout } from '../slice/authSlice';
import useResposive, { breakpoints } from '../../hooks/useResponsive';
import { ChangingLanguage } from '../ChangingLanguage';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import TagManager from 'react-gtm-module';
import { useGetAllSubjectsQuery } from '../../api';
import { roles } from '../AuthForms/constants';

interface IProps {
    isOpen: boolean;
    closeModal?: () => void;
}

export const ProfileMobile: React.FC<IProps> = ({ isOpen, closeModal }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const windowSize = useResposive();
    const { data, isLoading } = useGetUserDataQuery('');
    const isStudent =
        (data?.data.User.organizations.length &&
            data?.data.User.organizations.some(
                org => org.role === roles.STUDENT,
            )) ||
        data?.data.User.email === 'student@beyim.nis.edu.kz';
    const { data: subjects } = useGetAllSubjectsQuery({ limit: 100 });
    return (
        <ReactModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            shouldCloseOnEsc={false}
            style={{
                overlay: {
                    backgroundColor: '#E9F0F3',
                    zIndex: 2200,
                    padding: 0,
                },
                content: {
                    inset: 0,
                    backgroundColor: '#FFF',
                    border: 0,
                    padding: 0,
                    height: '100vh',
                },
            }}
        >
            <div className="px-4 py-[11px]">
                <h1 className="mb-[9px] text-[#364954] text-lg leading-[25.2px] font-bold">
                    {t('mobile.profile')}
                </h1>
                <div className="w-full py-2">
                    <UserData />
                </div>
                <ChangingLanguage mobile={windowSize.width < breakpoints.md} />
                {isStudent ? (
                    <div
                        className={classNames(
                            'mt-4 px-4 py-4 rounded-2xl bg-[#EDF5FA] w-full cursor-pointer h-[54px]',
                        )}
                    >
                        <Link
                            to={`/dashboard-student?subject_id=${
                                searchParams.get('subject')
                                    ? searchParams.get('subject')
                                    : subjects?.data?.subjects[0]?.id
                            }&user_id=${data?.data.User
                                ?.id}&class_id=${data?.data.User?.organizations.find(
                                o => o.role === roles.STUDENT,
                            )?.schools[0]?.classes[0]?.classId}`}
                            preventScrollReset
                            onClick={closeModal}
                        >
                            <span className="flex items-center">
                                <img
                                    className="w-6 h-6 block me-2"
                                    src="/icons/myProgress.svg"
                                    alt="myActions"
                                />
                                <span className="text-base font-medium leading-[22.4px] text-[#678AA1]">
                                    {t('profile.myProgress')}
                                </span>
                            </span>
                        </Link>
                    </div>
                ) : null}
                <button
                    className={classNames(
                        'p-[10px] flex absolute bottom-[200px]',
                    )}
                    onClick={() => {
                        dispatch(logout());
                        navigate('/');
                    }}
                >
                    <img
                        src="/icons/exit.svg"
                        className="block w-6 h-6 me-3"
                        alt="exit"
                    />

                    <span
                        className={classNames(
                            style.exit,
                            'font-medium text-base leading-[22.4px] text-[#364954]',
                        )}
                    >
                        {t('profile.exit')}
                    </span>
                </button>
            </div>
        </ReactModal>
    );
};
