import React from 'react';
import style from './AssessmentBlockMetricsSection.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type Props = {
    metricsData: {
        points: number;
        questionsCount: { correct?: number; total: number | undefined };
        time: number;
    };
    state: 'default' | 'success' | 'fail';
};

const MetricWrapper = ({
    children,
    state,
}: {
    children: React.ReactNode;
    state: 'default' | 'success' | 'fail';
}) => {
    return (
        <div
            className={classNames(
                `flex p-2 flex-row gap-2 rounded-2xl w-full flex-wrap sm:flex-nowrap md:flex-wrap lg:flex-nowrap`,
                style.metricWrapper,
                state !== 'default' && style[`bgWhite`],
            )}
        >
            {children}
        </div>
    );
};

export const AssessmentBlockMetricsSection = ({
    metricsData,
    state,
}: Props) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row gap-2 ">
            <MetricWrapper state={state}>
                <div className="w-full sm:w-fit md:w-full lg:w-fit shrink-0">
                    <img
                        className="w-4 h-4"
                        src={
                            state === 'default'
                                ? '/icons/iconamoon_lightning-1-fill.svg'
                                : state === 'fail'
                                  ? '/icons/iconamoon_lightning-1-fill-fail.svg'
                                  : '/icons/iconamoon_lightning-1-fill-successs.svg'
                        }
                        alt="MetricsSection"
                    />
                </div>

                <div className="flex flex-col gap-y-1">
                    <span
                        className={classNames(
                            style.time,
                            'font-medium text-sm  break-keep',
                            style[`color-${state}`],
                        )}
                    >
                        {state !== 'default'
                            ? t('assessment.pointsEarned')
                            : t('assessment.treshold')}
                    </span>
                    <span
                        className={classNames(
                            style.minutes,
                            'text-base font-semibold',
                        )}
                    >
                        {metricsData.points}%
                    </span>
                </div>
            </MetricWrapper>
            <MetricWrapper state={state}>
                <div className="w-full sm:w-fit md:w-full shrink-0 lg:w-fit">
                    <img
                        className="w-4 h-4"
                        src={
                            state === 'default'
                                ? '/icons/fluent_question-circle-24-filled.svg'
                                : state === 'fail'
                                  ? '/icons/fluent_question-circle-24-filled-fail.svg'
                                  : '/icons/fluent_question-circle-24-filled-green.svg'
                        }
                        alt="MetricsSection"
                    />
                </div>

                <div className="flex flex-col gap-y-1">
                    <span
                        className={classNames(
                            style.time,
                            'text-sm font-medium break-keep',
                            style[`color-${state}`],
                        )}
                    >
                        {state !== 'default'
                            ? t('assessment.correctAnswersCount')
                            : t('assessment.totalQuestionsCount')}
                    </span>
                    <span
                        className={classNames(
                            style.minutes,
                            'text-base font-semibold',
                        )}
                    >
                        {state !== 'default' &&
                            `${metricsData.questionsCount.correct}/`}
                        {metricsData.questionsCount.total}
                    </span>
                </div>
            </MetricWrapper>
            <MetricWrapper state={state}> 
                <div className="w-full sm:w-fit md:w-full shrink-0 lg:w-fit">
                    <img
                        className="w-4 h-4"
                        src={
                            state === 'default'
                                ? '/icons/ic_round-access-time-filled.svg'
                                : state === 'fail'
                                  ? '/icons/ic_round-access-time-filled-fail.svg'
                                  : '/icons/ic_round-access-time-filled-green.svg'
                        }
                        alt="MetricsSection"
                    />
                </div>
                <div className="flex flex-col gap-y-1">
                    <span
                        className={classNames(
                            style.time,
                            'text-sm font-medium break-keep ',
                            style[`color-${state}`],
                        )}
                    >
                        {state !== 'default'
                            ? t('assessment.completedAt')
                            : t('assessment.duration')}
                    </span>
                    <span
                        className={classNames(
                            style.minutes,
                            'text-base font-semibold break-keep',
                        )}
                    >
                        {metricsData.time} {t('assessment.minutes')}
                    </span>
                </div>
            </MetricWrapper>
        </div>
    );
};
