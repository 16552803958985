import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './SearchPostSkeleton.module.scss';
export const SearchPostSkeleton = () => {
    return (
        <div className="container  grid gap-4 pt-6 grid-cols-12  mt-14">
            <div className="md:col-span-8 col-span-12 md:col-start-3 flex gap-4 flex-col">
                <div
                    className={`bg-white h-40 flex items-center justify-start gap-[14px] p-4 sm:rounded-2xl sm:border-2 border-solid ${styles.wrapper}`}
                >
                    <Skeleton
                        count={1}
                        width={128}
                        height={128}
                        baseColor="#e9f0f3"
                        borderRadius={'0.75rem'}
                    />
                    <div className="h-[100%] flex flex-col align-baseline justify-between w-[100%]">
                        <header className="flex gap-2 mb-3 sm:p-0 px-4">
                            <Skeleton
                                count={1}
                                width={32}
                                height={32}
                                baseColor="#e9f0f3"
                                circle
                            />
                            <div>
                                <Skeleton
                                    count={1}
                                    height={19}
                                    width={200}
                                    baseColor="#e9f0f3"
                                />

                                <Skeleton
                                    count={1}
                                    height={17}
                                    width={100}
                                    baseColor="#e9f0f3"
                                />
                            </div>
                        </header>
                        <Skeleton
                            count={3}
                            width={'100%'}
                            baseColor="#e9f0f3"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
