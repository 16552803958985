import classNames from 'classnames';
import { LexcialEditor } from '../../../LexicalEditor/LexcialEditor';
import { useTranslation } from 'react-i18next';

interface IExplanation {
    item: string;
    isCorrect: boolean;
}
export const Explanation: React.FC<IExplanation> = ({ item, isCorrect }) => {
    const { t } = useTranslation();
    return (
        <div
            className={classNames(
                'w-full py-2 px-4 rounded-xl',
                isCorrect ? 'bg-[#ECFCE4]' : 'bg-[#FFEFEF]',
            )}
        >
            <div className="flex flex-col">
                <span
                    className={classNames(
                        'text-sm font-bold leading-6',
                        isCorrect ? 'text-[#65A845]' : 'text-[#E55959]',
                    )}
                >
                    {isCorrect
                        ? t('dashboardResults.correctAnswer')
                        : t('dashboardResults.incorrectAnswer')}
                </span>
                <LexcialEditor fieldData={item} />
            </div>
        </div>
    );
};
