import { states } from '../constants';

interface TitleAndDescription {
    title: string;
    description: string;
}

export const useGetTitleAndDescription = (
    t: (key: string) => string,
    state: states,
    actionType: number | undefined,
): TitleAndDescription => {
    let title = '';
    let description = '';

    switch (state) {
        case states.login:
            title = t('authorization.title');
            description = t('authorization.subTitle');
            break;
        case states.type_user:
            title = t('authorization.join');
            description = t('authorization.joinText');
            break;
        case states.enter_code:
            title =
                actionType === 0
                    ? t('authorization.codeClass')
                    : t('authorization.enterCode');
            description = t('authorization.enterCodeText');
            break;
        case states.personal_data:
            title = t('authorization.personal.personalData');
            description = t('authorization.personal.personalDataDescription');
            break;
        case states.change_password:
            title = t('authorization.createPassword');
            description = t('authorization.createPasswordText');
            break;
        case states.create_mail:
            title = t('authorization.createEmail');
            description = t('authorization.createMailText');
            break;
        default:
            title = t('authorization.title');
            description = t('authorization.accessPersonal');
            break;
    }

    return { title, description };
};
