import React, { useEffect, useRef, useState } from 'react';
import style from './Accordion.module.scss';
import { useNormalizedLocale } from '../../../hooks';

interface AccordionProps extends React.AllHTMLAttributes<HTMLDivElement> {
    type?: 'warning' | 'success' | 'error';
    title?: string;
    description?: string;
    icon?: any;
    children?: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({
    type = 'warning',
    title = 'Title',
    description = 'Description',
    icon = '/icons/accordion-bulb.svg',
    children,
    ...props
}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const contentRef = useRef<any>(null);

    const locale = useNormalizedLocale();

    useEffect(() => {
        if (isOpen && contentRef.current) {
            setHeight(contentRef.current.scrollHeight + 5);
        } else {
            setHeight(0);
        }
    }, [isOpen]);

    return (
        <div className={`${style.wrapper} ${style[type]}`} {...props}>
            <div className={style.root}>
                {icon && <img src={icon} alt="!" />}
                <div className={style.info}>
                    <h3 className={style.title}>{title}</h3>
                    <h6 className={style.description}>{description}</h6>
                </div>
                {children && (
                    <button
                        className={`${style.dropButton} ${
                            style[`button-${type}`]
                        } ${locale === 'ar' ? style.arLng : ''}`}
                    >
                        <img
                            src={'/icons/accordion-dropdown.svg'}
                            alt="open"
                            style={{
                                transform: isOpen ? 'scaleY(-1)' : 'scaleY(1)',
                                // rotate: isOpen ? '-180deg' : '0deg',
                                // transition: 'transform 0.3s ease-in-out',
                            }}
                            width={28}
                            height={28}
                            onClick={() => setIsOpen(prev => !prev)}
                        />
                    </button>
                )}
            </div>
            <div
                className={style.content}
                ref={contentRef}
                style={{
                    height: isOpen ? `${height}px` : '0px',
                    overflow: 'hidden',
                    transition:
                        'height 0.3s ease-in-out, margin-top 0.3s ease-in-out',
                    marginTop: isOpen ? '24px' : '0px',
                }}
            >
                {children}
            </div>
        </div>
    );
};
