import classNames from 'classnames';
import {
    useDeleteLikeMutation,
    useGetCountQuery,
    useGetUserLikedThisPostQuery,
    usePostLikeMutation,
} from '../../api/contentInteractionApi';
import style from './Like.module.scss';
import { useEffect, useState } from 'react';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { useGetSectionsBySubjectQuery } from '../../api/programService';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetFooterDataByPostIdsQuery } from '../../api/contentInteractionApi/contentInteractionApi';
import { useAppDispatch } from '../../hooks';
import { contentServiceApi } from '../../api/contentService';

interface ILike {
    postId: string;
    postType?: 'activity' | 'post';
    microtopic?: string | undefined;
    subject?: string | undefined;
    category?: string | undefined;
    title?: string | undefined;
    isLikedPost?: boolean;
    likeCount?: number;
}

export const Like: React.FC<ILike> = ({
    postId,
    postType = 'post',
    microtopic,
    subject,
    category,
    title,
    isLikedPost,
    likeCount,
}) => {
    const [searchParams] = useSearchParams();
    const locale = useNormalizedLocale();

    const [isLiked, setIsLiked] = useState<boolean>(false);

    const { data: oneSectionInfo } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    const [getCount, { data }] = useLazyGetFooterDataByPostIdsQuery();
    const [postLike] = usePostLikeMutation();
    const [deleteLike] = useDeleteLikeMutation();

    useEffect(() => {
        if (isLikedPost) {
            setIsLiked(true);
        } else {
            setIsLiked(false);
        }
    }, [isLikedPost]);

    const sectionsBySubject = searchParams.get('sectionsBySubject');
    const dispath = useAppDispatch();
    return (
        <beyim-xapi
            type="button"
            verb={!isLiked ? 'liked' : 'unliked'}
            language={locale}
            name={microtopic ? microtopic : title || 'name'}
            contentType={category || postType || 'post'}
            subject={subject || 'subject'}
            backend={process.env.REACT_APP_BACKEND_XAPI}
            section={
                sectionsBySubject
                    ? oneSectionInfo?.data.sections
                          .find(section => section.id === +sectionsBySubject)
                          ?.translations.find(
                              section => section.locale === locale,
                          )?.name
                    : oneSectionInfo?.data.sections[0]?.translations.find(
                          section => section.locale === locale,
                      )?.name
            }
        >
            <button
                type="button"
                className="flex gap-2 items-center h-7 justify-between"
                onClick={() => {
                    if (isLiked) {
                        deleteLike({ postId })
                            .unwrap()
                            .then(() => {
                                getCount({
                                    postIds: [postId],
                                });
                                dispath(
                                    contentServiceApi.util.invalidateTags([
                                        'likes',
                                    ]),
                                );
                            });
                        setIsLiked(false);
                    } else {
                        postLike({ postId, postType })
                            .unwrap()
                            .then(() => {
                                setIsLiked(true);
                                getCount({
                                    postIds: [postId],
                                });
                                dispath(
                                    contentServiceApi.util.invalidateTags([
                                        'likes',
                                    ]),
                                );
                            });
                    }
                }}
            >
                <img
                    src={
                        isLiked ? '/icons/activeHeart.svg' : '/icons/heart.svg'
                    }
                    alt="Like"
                    className="block h-7 w-7"
                />{' '}
                <span
                    className={classNames(
                        'text-base font-bold ',
                        style.count,
                        isLiked ? style.active : '',
                    )}
                >
                    {data
                        ? data.data.postFeed[0]?.likesCount === 0
                            ? ''
                            : data.data.postFeed[0]?.likesCount
                        : likeCount === 0
                          ? ''
                          : likeCount}
                </span>
            </button>
        </beyim-xapi>
    );
};
