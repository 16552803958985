import React, { useEffect } from 'react';
import styles from './Beyim.module.scss';
import { ModalButton } from '../Buttons';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal } from './modalSlice/modalSlice';

export const BeyimModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector(state => state.modal.isOpen);
    const content = useAppSelector(state => state.modal.content);

    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            dispatch(closeModal());
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [dispatch]);

    return isOpen ? (
        <div
            onClick={() => dispatch(closeModal())}
            className={classNames(
                'flex justify-center items-center fixed top-0 left-0 w-full h-full z-[4000]',
                styles.modal,
            )}
        >
            <div
                onClick={e => e.stopPropagation()}
                className={`p-6 w-[400px] max-w-[400px] rounded-[10px]  ${styles.modalWrapper} `}
            >
                {content}
            </div>
        </div>
    ) : null;
};
