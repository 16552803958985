import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export const CloseStudentMode: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <div
            className={classNames(
                'fixed md:bottom-0 bottom-14 z-[4000]  bg-[#55BBEB] w-full',
                location.pathname !== '/dashboard' &&
                    location.pathname !== '/authorization' &&
                    location.pathname !== '/' && 
                    location.pathname !== '/detail-student'
                    ? 'flex'
                    : 'hidden',
            )}
        >
            <div className="container  ">
                <div className="flex  h-12 justify-between px-4 md:px-0 shrink-0D items-center">
                    <div className="text-white font-medium text-xs md:text-sm">
                        {t('dashboard.modeStudent')}
                    </div>
                    <Link
                        to="/dashboard"
                        className="gap-2 md:pe-4 text-xs md:text-sm font-medium shrink-0 bg-[#E9F0F3] h-8 flex items-center rounded-[32px] px-4 text-[#30404A]"
                    >
                        {t('dashboard.exitModeStudent')}{' '}
                        <img src="/icons/close1.svg" alt="" />
                    </Link>
                </div>
            </div>
        </div>
    );
};
