import React from 'react';
import styles from './PageNotFoundScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export const PageNotFoundScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center gap-4 ">
            <img
                src="/icons/404.svg"
                alt="error"
                className="block w-[160px] h-[160px]"
            />
            <div className="flex flex-col items-center gap-2">
                <p className="text-center font-inter text-base font-bold leading-140">
                    {t('PageNotFoundScreen.header')}
                </p>
                <p className="text-center font-inter text-sm font-medium leading-140">
                    {t('PageNotFoundScreen.description')}
                </p>
            </div>
            <button
                className={classNames(
                    'mt-2 flex items-center border-solid border rounded-xl px-4 py-3 gap-2 ',
                    styles.refreshButton,
                )}
                onClick={() => navigate('/')}
            >
                <p className="text-[17px] font-medium leading-[100%]">
                    {t('PageNotFoundScreen.refresh')}
                </p>
            </button>
        </div>
    );
};
