import classNames from 'classnames';
import style from './Button.module.scss';
interface ButtonProps {
    text: string;
    type?: 'submit' | 'button';
    className?: string;
    variant?: 'primary' | 'success' | 'disabled';
    isLoading?: boolean;
    onClick?: () => void;
}
export const Button: React.FC<ButtonProps> = ({
    text,
    type,
    className,
    variant,
    isLoading,
    onClick,
}) => {
    return (
        <button
            onClick={onClick}
            type={type}
            className={classNames(
                'block w-full whitespace-nowrap rounded-2xl text-center text-[#fff]  text-sm leading-4 font-semibold px-[24px] py-[14px] mb-1.5',
                style.popularButton,
                style[
                    `popularButton__${
                        variant === 'primary' ? 'simple' : variant
                    }`
                ],
                className,
            )}
        >
            {isLoading ? (
                <div className="spinner-load spinner-load--btn" />
            ) : (
                text
            )}
        </button>
    );
};
