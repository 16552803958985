// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
    PostADAuthResponseApiType,
    PostAuthRequestApiType,
    PostAuthResponseApiType,
} from '../../types';
import Cookies from 'js-cookie';
import { getUserDataResponseApiType } from '../../types/getUserDataApiType';
import { getSearchUsersResponseApiType } from './types/getSearchUsersResponseApiType/getSearchUsersResponseApiType';
import { CreatePasswordResponseApiType } from '../../types/CreatePasswordResponseApiType';
import { CreatePassowordRequestApiType } from '../../types/CreatePassowordRequestApiType';
import { UpdateCurrentUserRequestApiType } from '../../types/UpdateCurrentUserRequestApiType';

// Define a service using a base URL and expected endpoints
export const authServiceApi = createApi({
    reducerPath: 'authServiceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ``,
    }),
    tagTypes: ['auth'],
    endpoints: builder => ({
        postLogin: builder.mutation<
            PostAuthResponseApiType,
            PostAuthRequestApiType
        >({
            query: body => ({
                url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/auth/login`,
                method: 'POST',
                body,
            }),
        }),
        getUserData: builder.query<getUserDataResponseApiType, string>({
            query: () => {
                const token = Cookies.get('access_token');
                const id_token = Cookies.get('id_token');

                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/users/me`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Api-Key': id_token,
                    },
                };
            },
            providesTags: ['auth'],
        }),

        updateUserData: builder.mutation<any, any>({
            query: body => {
                const token = Cookies.get('access_token');
                const id_token = Cookies.get('id_token');
                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/users/me`,
                    method: 'PUT',
                    body,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Api-Key': id_token,
                    },
                };
            },
            invalidatesTags: ['auth'],
        }),
        updateRefreshToken: builder.mutation<
            PostAuthResponseApiType,
            {
                refreshToken: string;
                id: string;
            }
        >({
            query: ({ refreshToken, id }) => {
                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/auth/refresh-tokens`,
                    method: 'POST',
                    body: {
                        refreshToken,
                        id,
                    },
                };
            },
            invalidatesTags: ['auth'],
        }),
        searchUsers: builder.query<
            getSearchUsersResponseApiType,
            {
                email?: string;
                role?: string;
                organizationId?: string;
                classIds?: string[];
                page?: number;
                limit?: number;
                subjectIds?: string[];
                schoolIds?: string[];
                includeDetails?: boolean;
            }
        >({
            query: ({
                email,
                role,
                organizationId,
                classIds,
                page,
                limit,
                subjectIds,
                schoolIds,
                includeDetails,
            }) => {
                const token = Cookies.get('access_token');
                const id_token = Cookies.get('id_token');
                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/admin/users`,
                    params: {
                        email,
                        role,
                        organizationId,
                        classIds: classIds?.join(','),
                        page,
                        limit,
                        subjectIds: subjectIds?.join(','),
                        schoolIds: schoolIds?.join(','),
                        includeDetails,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Api-Key': id_token,
                    },
                };
            },
            providesTags: ['auth'],
        }),

        createPassword: builder.mutation<
            CreatePasswordResponseApiType,
            CreatePassowordRequestApiType
        >({
            query: body => {
                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/auth/handle-challenge`,
                    method: 'POST',
                    body,
                };
            },
        }),

        updateCurrentUser: builder.mutation<
            any,
            UpdateCurrentUserRequestApiType
        >({
            query: body => {
                const token = Cookies.get('access_token');
                const id_token = Cookies.get('id_token');
                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/users/me`,
                    method: 'PUT',
                    body,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Api-Key': id_token,
                    },
                };
            },
        }),

        createUserByCode: builder.mutation<any, any>({
            query: body => {
                return {
                    url: `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/v1/auth/signup-with-code`,
                    method: 'POST',
                    body,
                };
            },
        }),
        updateUserByAdmin: builder.mutation<any, any>({
            query: body => {
                const token = Cookies.get('access_token');
                const id_token = Cookies.get('id_token');
                return {
                    url: `/admin/users/${body.id}`,
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Api-Key': id_token,
                    },
                    body,
                };
            },
            invalidatesTags: ['auth'],
        }),
    }),
});

export const authServiceADApi = createApi({
    reducerPath: 'authServiceADApi',
    baseQuery: fetchBaseQuery({
        baseUrl: ``,
        prepareHeaders: headers => {
            headers.set('Content-Type', 'application/x-www-form-urlencoded');
            headers.set(
                'Authorization',
                `Basic ${btoa(
                    `${process.env.REACT_APP_AD_CLIENT_ID}:${process.env.REACT_APP_AD_CLIENT_SECRET}`,
                )}`,
            );

            return headers;
        },
    }),
    endpoints: builder => ({
        postAuthADLogin: builder.mutation<PostADAuthResponseApiType, string>({
            query: code => {
                const params = new URLSearchParams();

                params.append('grant_type', 'authorization_code');
                params.append(
                    'client_id',
                    process.env.REACT_APP_AD_CLIENT_ID as string,
                );
                params.append('code', code);
                params.append(
                    'redirect_uri',
                    `${process.env.REACT_APP_MAIN}/authorization/`,
                );

                return {
                    url: `${process.env.REACT_APP_AD_CLIENT_DOMAIN}/oauth2/token`,
                    method: 'POST',
                    body: params,
                };
            },
        }),
        refreshToken: builder.mutation<
            {
                id_token: string;
                access_token: string;
                expires_in: number;
                token_type: string;
            },
            string
        >({
            query: refreshToken => {
                return {
                    url: `${process.env.REACT_APP_AD_CLIENT_DOMAIN}/oauth2/token`,
                    method: 'POST',
                    params: {
                        grant_type: 'refresh_token',
                        client_id: process.env.REACT_APP_AD_CLIENT_ID,
                        refresh_token: refreshToken,
                    },
                };
            },
        }),
    }),
});

export const {
    usePostLoginMutation,
    useGetUserDataQuery,
    useLazyGetUserDataQuery,
    useUpdateUserDataMutation,
    useUpdateRefreshTokenMutation,
    useCreatePasswordMutation,
    useUpdateCurrentUserMutation,
    useCreateUserByCodeMutation,
    useSearchUsersQuery,
    useLazySearchUsersQuery,
    useUpdateUserByAdminMutation,
} = authServiceApi;

export const { usePostAuthADLoginMutation, useRefreshTokenMutation } =
    authServiceADApi;
