import classNames from 'classnames';
import style from './WithVideoOrImage.module.scss';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks';
import TagManager from 'react-gtm-module';

interface IWithVideoOrImage {
    withVideo?: boolean;
    withPicture?: boolean;
    handleChangeFilter: (type?: 'video' | 'image') => void;
}

export const WithVideoOrImage: React.FC<IWithVideoOrImage> = ({
    withVideo = true,
    withPicture = true,
    handleChangeFilter,
    ...rest
}) => {
    const { t } = useTranslation();
    const locale = useNormalizedLocale();
    return (
        <>
            <div
                className={classNames(
                    style.wrapper,
                    ' rounded-xl sticky top-[68px] px-4 py-3 flex flex-col gap-3',
                )}
                {...rest}
            >
                <div className=" w-full">
                    <input
                        className="hidden"
                        type="checkbox"
                        checked={withVideo}
                        onChange={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'filter_video',
                                    status: withVideo ? 'on' : 'off',
                                    locale: locale,
                                },
                                dataLayerName: 'video filter',
                            });
                            handleChangeFilter('video');
                        }}
                        name=""
                        id="videoFilterFeed"
                    />
                    <label
                        htmlFor="videoFilterFeed"
                        className="flex items-center justify-between cursor-pointer "
                    >
                        <div className="flex">
                            <img
                                src="/icons/videoFilter.svg"
                                alt="videoFilter"
                                className="w-6 h-6 block me-2"
                            />
                            <span className="text-sm font-semibold">
                                {t('feed.video')}
                            </span>
                        </div>

                        <span
                            className={classNames(
                                ' w-11 h-6 rounded-3xl flex items-center p-1 duration-200',
                                style.checked,
                            )}
                        />
                    </label>
                </div>
                <hr className={classNames('h-[1px]', style.hr)} />
                <div className="">
                    <input
                        className="hidden"
                        type="checkbox"
                        name=""
                        id="pictureFilterFeed"
                        checked={withPicture}
                        onChange={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'filter_picture',
                                    status: withPicture ? 'on' : 'off',
                                    locale: locale,
                                },
                                dataLayerName: 'feed',
                            });
                            handleChangeFilter('image');
                        }}
                    />

                    <label
                        className="flex items-center cursor-pointer"
                        htmlFor="pictureFilterFeed"
                    >
                        <div className="flex">
                            <img
                                src="/icons/imageFilter.svg"
                                alt="imageFilter"
                                className="w-5 h-5 block me-3"
                            />
                            <span className="text-sm font-semibold ">
                                {t('feed.picture')}
                            </span>
                        </div>
                        <span
                            className={classNames(
                                'ms-auto w-11 h-6 rounded-3xl flex items-center p-1 duration-200',
                                style.checked,
                            )}
                        />
                    </label>
                </div>
            </div>
        </>
    );
};
