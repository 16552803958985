import { useTranslation } from 'react-i18next';
import { SelectBeyim } from '../../ui/SelectBeyim/SelectBeyim';
import { useGetProgressByStudentsQuery } from '../../api/beyimProgressDashboard';
import { useNormalizedLocale } from '../../hooks';
import { useEffect, useState } from 'react';
import { BeyimFooterPage } from '../../ui/BeyimFooterTable';
import {
    useGetUserDataQuery,
    useSearchUsersQuery,
} from '../../api/authService/authServiceApi';
import { useGetAllSectionsQuery } from '../../api';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { SkeletonProgress } from './utils/SkeletonProgress';
import classNames from 'classnames';
import { startsWithSeven } from '../../pages/dashboard/utils/startsWithSeven/startsWithSeven';
import { useGetV2ProgressByStudentsQuery } from '../../api/progressService';

interface IStudentProgress {
    classId: string;
    subjectId: string;
}

export const StudentProgress: React.FC<IStudentProgress> = ({
    classId,
    subjectId,
}) => {
    const { t } = useTranslation();
    const locale = useNormalizedLocale();
    const navigate = useNavigate();
    const { data } = useGetUserDataQuery('');
    // const [isDemo, setIsDemo] = useState(false);

    const { data: sections } = useGetAllSectionsQuery({
        type_id: 2,
        limit: 1000,
        subject_id: Number(subjectId) || 3,
    });
    const {
        data: users,
        isLoading: isLoadingUsers,
        isFetching: isFetchingUsers,
    } = useSearchUsersQuery(
        {
            role: 'student',
            classIds: [classId],
        },
        {
            skip: !classId,
        },
    );

    const [page, setPage] = useState(0);
    const [sectionValue, setSectionValue] = useState<{
        value: number;
        label: string;
    } | null>();
    const [studentValue, setStudentValue] = useState<{
        value: string;
        label: string;
    }>();

    const isDemo =
        data?.data.User.email === 'quantum_demo_teacher@beyim.ai' ||
        data?.data.User.email === 'binom_demo_teacher@beyim.ai';

    const dataSectionDemo =
        sections?.data.sections.filter(sections => {
            return startsWithSeven(sections.translations[0].name) && isDemo;
        }) || [];

    useEffect(() => {
        if (data) {
            if (isDemo) {
                setSectionValue({
                    value: dataSectionDemo[0]?.id || 0,
                    label:
                        dataSectionDemo[0]?.translations?.find(
                            item => item.locale === locale,
                        )?.name ||
                        dataSectionDemo[0]?.translations?.[0].name ||
                        '',
                });
            } else {
                setSectionValue({
                    value: sections?.data.sections[0]?.id || 0,
                    label:
                        sections?.data.sections[0]?.translations?.find(
                            item => item.locale === locale,
                        )?.name ||
                        sections?.data.sections[0]?.translations?.[0].name ||
                        '',
                });
            }
        }
    }, [sections, locale]);

    const {
        data: students,
        isLoading: isLoadingStudents,
        isFetching: isFetchingStudents,
    } = useGetProgressByStudentsQuery(
        {
            subjectId,
            classId,
            page: page,
            limit: 10,
            sectionId: sectionValue?.value.toString() || '',
            userIds: studentValue?.value
                ? [studentValue.value.toString()]
                : users?.data.users?.map(item => item.id) || [],
        },
        {
            skip: !classId || !users || !subjectId,
        },
    );

    return (
        <>
            <div className="flex justify-between bg-white px-6 py-3 rounded-tr-2xl ">
                <div className="flex gap-2 flex-wrap w-full">
                    <div className="w-[250px] max-w-full">
                        <SelectBeyim
                            isDisabled={!subjectId}
                            size="xs"
                            placeholder={t('statistics.section') + ': '}
                            value={sectionValue}
                            isPrefix={true}
                            options={
                                isDemo
                                    ? dataSectionDemo.map(section => ({
                                          value: section.id,
                                          label:
                                              section.translations?.find(
                                                  item =>
                                                      item.locale === locale,
                                              )?.name ??
                                              section.translations?.[0].name,
                                      }))
                                    : sections?.data.sections.map(section => ({
                                          value: section.id,
                                          label:
                                              section.translations?.find(
                                                  item =>
                                                      item.locale === locale,
                                              )?.name ??
                                              section.translations?.[0].name,
                                      }))
                            }
                            onChange={value => {
                                setSectionValue(value);
                            }}
                        />
                    </div>
                    {/* <div className="w-[186px] max-w-full">
                        <SelectBeyim
                            size="xs"
                            placeholder={t('statistics.student')}
                            value={studentValue}
                            options={
                                users?.data.users.map(user => ({
                                    value: user.id,
                                    label:
                                        user.firstName + ' ' + user.secondName,
                                })) || []
                            }
                            onChange={value => {
                                //@ts-ignore
                                setStudentValue(value);
                            }}
                        />
                    </div> */}
                    <div className="w-[60px] max-w-full ms-auto opacity-50">
                        <SelectBeyim
                            isDisabled
                            size="xs"
                            placeholder={
                                <img
                                    className="w-4 h-4"
                                    src="/icons/sort.svg"
                                />
                            }
                            options={[{ label: 'uuu' }]}
                        />
                    </div>
                </div>
            </div>
            <div className="px-6 bg-white  rounded-b-2xl max-w-full overflow-auto mb-16">
                <table className="w-full border-0">
                    <thead>
                        <tr className="bg-white">
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 pe-2 py-2 w-8 "
                            ></th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.student')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.section')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.AssessmentForSection')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 ps-0 py-2 pe-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.passedTopics')}
                            </th>
                            <th
                                scope="col"
                                className="border-[#E9F0F3] border-y-[1px] border-x-0 pe-2 ps-0 py-2 text-left text-[#678AA1] text-sm font-medium leading-[normal]"
                            >
                                {t('statistics.detail')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoadingUsers ||
                        isFetchingUsers ||
                        isLoadingStudents ||
                        isFetchingStudents ? (
                            <SkeletonProgress />
                        ) : (
                            classId &&
                            students &&
                            students.data.data.users?.map((student, index) => {
                                return (
                                    <tr key={index}>
                                        <th
                                            scope="row"
                                            className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 py-4 h-12 !bg-white text-[#678AA1] text-sm font-medium leading-[normal]"
                                        >
                                            {index + 1}
                                        </th>
                                        <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                                            {student.firstName}{' '}
                                            {student.secondName}
                                        </td>
                                        <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                                            {student.section.translations?.find(
                                                item => item.locale === locale,
                                            )?.name ||
                                                student.section
                                                    .translations?.[0]?.name}
                                        </td>
                                        <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                                            <div className="flex items-center gap-2">
                                                {student?.totalScore ? (
                                                    (
                                                        (student?.score /
                                                            student?.totalScore) *
                                                        100
                                                    ).toFixed() + '%'
                                                ) : (
                                                    <span className="text-[#8CA9BC] text-base font-normal leading-[normal]">
                                                        {t(
                                                            'statistics.notStarted',
                                                        )}
                                                    </span>
                                                )}
                                                {student?.score && (
                                                    <button
                                                        className="px-2 py-1 cursor-pointer border-[1px] flex items-center text-[#364954] justify-center leading-[normal] text-xs rounded-[10px] border-[#CBDCE7]"
                                                        onClick={() =>
                                                            navigate(
                                                                `/detail-student?subject_id=${subjectId}&class_id=${classId}&user_id=${student?.id}`,
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            'statistics.seeResult',
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                        </td>

                                        <td className="border-[#E9F0F3] cursor-pointer  border-b-[1px] border-t-[1px] pe-24 border-x-0 ps-0  py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                                            <div className="flex gap-6 items-center ">
                                                <span>
                                                    {student?.topics?.length ===
                                                    0 ? (
                                                        <span className="text-[#8CA9BC] text-base font-normal leading-[normal]">
                                                            {t(
                                                                'statistics.notStarted',
                                                            )}
                                                        </span>
                                                    ) : (
                                                        student?.topics?.length
                                                    )}{' '}
                                                </span>
                                                <div className="flex gap-1 w-36">
                                                    {student?.topics?.map(
                                                        topic => {
                                                            return (
                                                                <span
                                                                    key={
                                                                        topic.id
                                                                    }
                                                                    className={classNames(
                                                                        'w-full h-1  rounded',
                                                                        topic
                                                                            .topicProgress
                                                                            .hasPassed
                                                                            ? 'bg-[#55BBEB]'
                                                                            : 'bg-[#D3D6DD]',
                                                                    )}
                                                                ></span>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                                            <button
                                                className="bg-[#55BBEB] h-8 px-2 py-1 rounded-lg text-white text-xs"
                                                onClick={() =>
                                                    navigate(
                                                        `/detail-student?subject_id=${subjectId}&class_id=${classId}&user_id=${student?.id}`,
                                                    )
                                                }
                                            >
                                                {t('statistics.watch')}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                    {/* {students && students.data.data.total > 0 && (
                        <BeyimFooterPage
                            page={page}
                            totalPage={Math.ceil(students.data.data.total / 10)}
                            setPage={setPage}
                            isLastPage={students.data.data.isLastPage}
                        />
                    )} */}
                </table>
            </div>
        </>
    );
};
