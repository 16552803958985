import classNames from 'classnames';
import { Post } from '../../types/GetContentsResponseApiType';
import style from './TextPost.module.scss';
import { useState } from 'react';
import { HeaderPost } from '../HeaderPost';
import { FooterPost } from '../FooterPost';
import { LexcialEditor } from '../LexicalEditor/LexcialEditor';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { PostFeed } from '../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';
import TagManager from 'react-gtm-module';

interface ITextPost {
    data: Post;
    postFooterData?: PostFeed;
}

export const TextPost: React.FC<ITextPost> = ({ data, postFooterData }) => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();
    const [isMore, setIsMore] = useState(false);

    return (
        <article
            className={classNames(
                style.wrapper,
                'sm:p-4 py-4 sm:rounded-2xl sliderImagePost relative',
            )}
        >
            <HeaderPost
                iconUrl={data.iconUrl || '/icons/noIcon.svg'}
                objective={data.objective}
                subject={data.subject}
                id={data.id}
                microptopic={data.microtopic}
                dataPost={data}
            />
            <div>
                <div
                    className={classNames(
                        'sm:px-0 px-4 overflow-hidden text-ellipsis relative',
                        isMore ? 'h-fit' : 'max-h-16',
                        style.content,
                    )}
                >
                    <LexcialEditor
                        fieldData={data.description}
                        generatorType={data.generatorType}
                    />
                    {!isMore && (
                        <div
                            className={classNames(
                                style.shadow,
                                'w-full h-full absolute top-0 left-0',
                            )}
                        ></div>
                    )}
                </div>
                <button
                    onClick={() => {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: isMore ? 'hide_post' : 'expand_post',
                                post_id: data.id,
                                subject_id: data.subjectId,
                                post_type: 'text',
                                locale: locale,
                            },
                            dataLayerName: 'feed',
                        });
                        setIsMore(prev => !prev);
                    }}
                    className={classNames(style.more, 'px-4 sm:px-0')}
                >
                    {isMore ? t('feed.moreClose') : t('feed.moreOpen')}
                </button>
            </div>

            <FooterPost
                postId={data.id}
                contentId={data.contentId}
                microtopic={data.microtopic}
                subject={data.subject}
                category={data.category}
                postFooterData={postFooterData}
            />
            <beyim-xapi
                type="view"
                verb="viewed"
                name={data?.microtopic || 'microtopic'}
                language={locale || 'ru'}
                subject={data.subject || 'subject'}
                backend={process.env.REACT_APP_BACKEND_XAPI}
            >
                <div />
            </beyim-xapi>
        </article>
    );
};
