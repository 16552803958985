import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import style from './Center.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

interface Props {
    title: string | undefined;
    section_type_id: number | undefined;
    isLoading?: boolean;
    href?: string;
}

export const Center = ({ title, section_type_id, isLoading, href }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="flex justify-between items-center w-full mt-0 mb-0 mx-auto max-w-[592px]">
            <div>
                {isLoading ? (
                    <Skeleton
                        height={36}
                        width={200}
                        baseColor="#e9f0f3"
                        borderRadius={10}
                    />
                ) : (
                    <>
                        <h3
                            className={classNames(
                                'text-xs font-medium m-0',
                                style.forType,
                            )}
                        >
                            {/* {t('assessment.assessmentRes')}{' '} */}
                            {section_type_id === 3
                                ? t('assessment.topicAssessment')
                                : t('assessment.sectionAssessment')}
                        </h3>
                        <h2
                            className={classNames(
                                'font-bold text-base m-0 text-ellipsis line-clamp-1  max-w-[250px] sm:max-w-full',
                                style.typeTitle,
                            )}
                        >
                            {title}
                        </h2>
                    </>
                )}
            </div>
            {/* <div
                className={classNames(
                    'cursor-pointer flex justify-center items-center h-10 p-2.5 rounded-[10px]',
                    style.icon,
                )}
                data-tooltip-id="my-spravka"
            >
                <img
                    className="opacity-50 m-0 p-0"
                    src="/icons/testFlag.svg"
                    alt="Beyim logo"
                    width={20}
                    height={20}
                />
            </div> */}
            <button
                className={classNames(
                    'rounded-full p-2 h-8 w-8 flex justify-center items-center cursor-pointer',
                    style.cancel,
                )}
                onClick={() => navigate(href || '/feed')}
            >
                <img
                    src="/icons/backFeed.svg"
                    alt="Beyim logo"
                    className="w-4 h-4"
                />
            </button>
            {/* <Tooltip
                id="my-spravka"
                content={t('assessment.report')}
                place="bottom"
                offset={10}
                style={{
                    borderRadius: '12px',
                    color: '#fff',
                    backgroundColor: '#364954',
                    zIndex: 9999,
                }}
            /> */}
        </div>
    );
};
