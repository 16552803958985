import classNames from 'classnames';
import { Post } from '../../types/GetContentsResponseApiType';
import style from './ImagePost.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper/modules';
import ReactModal from 'react-modal';
import { useEffect, useState } from 'react';
import { HeaderPost } from '../HeaderPost';
import { FooterPost } from '../FooterPost';
import { SliderPopup } from '../SliderPopup';
import { LexcialEditor } from '../LexicalEditor/LexcialEditor';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import Skeleton from 'react-loading-skeleton';
import useResposive, { breakpoints } from '../../hooks/useResponsive';
import { PostFeed } from '../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';
import TagManager from 'react-gtm-module';

interface IImagePost {
    data: Post;
    postFooterData?: PostFeed;
}

export const ImagePost: React.FC<IImagePost> = ({ data, postFooterData }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const { t } = useTranslation();
    const [currentNumber, setCurrentNumber] = useState(1);

    const locale = useNormalizedLocale();
    const [isMore, setIsMore] = useState(false);

    // Состояние для хранения текущего разрешения экрана
    const [screenResolution, setScreenResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Обработчик изменения размеров окна
    const handleResize = () => {
        setScreenResolution({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    // Добавление и удаление обработчика событий при монтировании и размонтировании компонента
    useEffect(() => {
        // Добавление обработчика при монтировании
        window.addEventListener('resize', handleResize);

        // Удаление обработчика при размонтировании
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Пустой массив зависимостей означает, что эффект сработает только при монтировании и размонтировании компонента

    const openModal = (index: number) => {
        setCurrentSlide(index);
        setIsOpen(true);
        // document.body.style.overflow = 'hidden !important';
        document.documentElement.style.setProperty('--visible-pop', 'true');
    };

    const closeModal = () => {
        setIsOpen(false);
        // document.body.style.overflow = 'visible';
        document.documentElement.style.setProperty('--visible-pop', 'false');
    };

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '"></span>';
        },
    };
    const [isLoaded, setIsLoaded] = useState(false);
    const windowSize = useResposive();

    return (
        <article
            className={classNames(
                style.wrapper,
                'sm:p-4 py-4 mb-6 md:mb-0 sm:rounded-2xl sliderImagePost relative',
            )}
        >
            <HeaderPost
                iconUrl={data.iconUrl || '/icons/noIcon.svg'}
                objective={data.objective}
                subject={data.subject}
                id={data.id}
                microptopic={data.microtopic}
                dataPost={data}
            />
            {data.resources.length > 1 && (
                <div
                    className={classNames(
                        'absolute right-4 top-20 rounded-[20px] px-2.5 py-1 flex justify-center items-center md:hidden z-[100]',
                        style.count,
                    )}
                >
                    <span className="text-[#F9F9F9] text-xs leading-[normal] font-normal">
                        {currentNumber + '/' + data.resources.length}
                    </span>
                </div>
            )}
            <div>
                <div
                    className={classNames('mb-3 imagePost')}
                    // dir="ltr"
                >
                    {data.resources && data.resources.length > 0 && (
                        <>
                            <Swiper
                                navigation={
                                    windowSize.width > breakpoints.sm
                                        ? true
                                        : false
                                }
                                className={classNames(style.wrapperSlider)}
                                slidesPerView={
                                    data.resources.length === 1
                                        ? 1
                                        : screenResolution.width < 640
                                          ? 1
                                          : 2
                                }
                                pagination={pagination}
                                spaceBetween={8}
                                modules={[Pagination, Navigation]}
                                lazy={true}
                                style={{
                                    //@ts-ignore
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff',
                                }}
                                onSlideChange={(swiper: any) => {
                                    setCurrentNumber(swiper.activeIndex + 1);
                                }}
                            >
                                {data.resources.map((item, index) => {
                                    return (
                                        <>
                                            <SwiperSlide
                                                key={item}
                                                className="pb-3"
                                            >
                                                <div className=" max-w-full pt-[100%] relative overflow-hidden flex ">
                                                    <button
                                                        className="absolute top-0 left-0 w-full h-full overflow-hidden sm:rounded-2xl flex justify-center"
                                                        onClick={() => {
                                                            TagManager.dataLayer(
                                                                {
                                                                    dataLayer: {
                                                                        event: 'open_picture',
                                                                        post_id:
                                                                            data.id,
                                                                        index_image:
                                                                            index,
                                                                        subject_id:
                                                                            data.subjectId,
                                                                        locale: locale,
                                                                    },
                                                                    dataLayerName:
                                                                        'feed',
                                                                },
                                                            );
                                                            openModal(index);
                                                        }}
                                                    >
                                                        <img
                                                            src={item}
                                                            alt="slide"
                                                            className="h-full max-w-none"
                                                            loading={
                                                                locale === 'ar'
                                                                    ? 'eager'
                                                                    : 'lazy'
                                                            }
                                                            onLoad={() => {
                                                                setIsLoaded(
                                                                    true,
                                                                );
                                                            }}
                                                        />

                                                        {!isLoaded && (
                                                            <div
                                                                className="absolute top-[-10px] left-0 w-full h-full"
                                                                aria-hidden="true"
                                                            >
                                                                <Skeleton
                                                                    height={
                                                                        '110%'
                                                                    }
                                                                    baseColor="#e9f0f3"
                                                                />
                                                            </div>
                                                        )}
                                                        <div
                                                            className={classNames(
                                                                'absolute h-full w-full duration-200',
                                                                style.overlay,
                                                            )}
                                                        />
                                                    </button>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    );
                                })}
                            </Swiper>

                            <ReactModal
                                isOpen={isOpen}
                                contentLabel="Inline Styles Modal Example"
                                shouldCloseOnOverlayClick={true}
                                shouldCloseOnEsc={true}
                                onRequestClose={closeModal}
                                style={{
                                    overlay: {
                                        backgroundColor:
                                            'rgba(31, 41, 55, 0.90)',
                                        zIndex: 2100,
                                    },
                                    content: {
                                        backgroundColor: 'transparent',
                                        border: 0,
                                        overflow: 'auto',
                                        padding: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                }}
                            >
                                <div className="w-full h-full items-center justify-center flex">
                                    <SliderPopup
                                        data={data.resources}
                                        initialSlide={currentSlide}
                                        closeModal={closeModal}
                                    />

                                    <button
                                        className={classNames(
                                            ' top-0 absolute right-0 rounded-full w-10 h-10 flex items-center justify-center z-10',
                                            style.close,
                                        )}
                                        onClick={closeModal}
                                    >
                                        <img
                                            src="/icons/close.svg"
                                            alt="close"
                                            className=" w-3 h-3"
                                        />
                                    </button>
                                </div>
                            </ReactModal>
                        </>
                    )}
                </div>
                <div
                    className={classNames(
                        'sm:px-0 px-4 overflow-hidden text-ellipsis relative',
                        isMore ? 'h-fit' : 'max-h-16',
                        style.content
                    )}
                >
                    <LexcialEditor
                        fieldData={data.description}
                        generatorType={data.generatorType}
                    />
                    {!isMore && (
                        <div
                            className={classNames(
                                style.shadow,
                                'w-full h-full absolute top-0 left-0',
                            )}
                        ></div>
                    )}
                </div>
                <button
                    onClick={() => {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: isMore ? 'hide_post' : 'expand_post',
                                post_id: data.id,
                                subject_id: data.subjectId,
                                post_type: 'picture',
                                locale: locale,
                            },
                            dataLayerName: 'feed',
                        });
                        setIsMore(prev => !prev);
                    }}
                    className={classNames(style.more, 'px-4 sm:px-0')}
                >
                    {isMore ? t('feed.moreClose') : t('feed.moreOpen')}
                </button>
            </div>

            <FooterPost
                postId={data.id}
                contentId={data.contentId}
                microtopic={data.microtopic}
                subject={data.subject}
                category={data.category}
                postFooterData={postFooterData}
            />
            <beyim-xapi
                type="view"
                verb="viewed"
                name={data?.microtopic || 'microtopic'}
                language={locale || 'ru'}
                subject={data.subject || 'subject'}
                backend={process.env.REACT_APP_BACKEND_XAPI}
            >
                <div />
            </beyim-xapi>
        </article>
    );
};
