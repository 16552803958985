import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import style from './Results.module.scss';

import { usePostGetProgressMutation } from '../../../api/beyimProgress';
import { AssessmentHeader } from '../../../features/AssessmentHeader';
import { Center } from '../../../features/AssessmentHeader/utils/Center';
import { AssessmentStarting } from '../../../features/AssessmentStarting';
import { ModalComponent } from '../../../ui/ModalComponent';
import { AssessmentFooterButton } from '../../../features/OnboardingComponents/AssessmentStartingBlock/AssessmentFooterButton/AssessmentFooterButton';
import { useGetQuestionsByIdsQuery } from '../../../api/assessmentService/assessmentServiceApi';
import { AssessmentResult } from '../../../features/AssessmentResult';
import { Question } from '../../../types/ GetQuestionsByIdsResponseApiType';
import { useNormalizedLocale } from '../../../hooks/useNormalizedLocale';
import { XAPIActivity } from '../../../features/XAPI/sender';
import { useGetAllSubjectsQuery } from '../../../api';
import { SkeletonAssessmentStarting } from '../../../features/AssessmentStarting/SkeletonAssessmentStarting';
import Skeleton from 'react-loading-skeleton';
import { SkeletonQuestion } from './SkeletonQuestion';
import { isTryStatement } from 'typescript';

export const ResultTest = () => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();

    const { id, progressId } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isTakeAgainModalOpen, setIsTakeAgainModalOpen] = useState(false);
    const [questionsUnique, setQuestionsUnique] = useState<
        Question[] | undefined
    >([]);

    const [
        postGetProgress,
        { data: detailsProgress, isLoading: isLoadingProgress },
    ] = usePostGetProgressMutation();
    const { data: subjects } = useGetAllSubjectsQuery({
        limit: 100,
    });

    useEffect(() => {
        postGetProgress({ progress_id: Number(progressId) });
    }, [progressId, postGetProgress]);

    const {
        data: questions,
        isLoading: isLoadingQuestions,
        isFetching: isFetchingQuestions,
    } = useGetQuestionsByIdsQuery(
        {
            questionIds:
                detailsProgress?.data.progress.questions?.map(
                    q => q?.question_id,
                ) || [],
        },
        {
            skip: !detailsProgress,
        },
    );
    const passed = searchParams.get('isCompleted');
    useEffect(() => {
        if (
            questions?.data.questions &&
            detailsProgress?.data.progress &&
            passed
        ) {
            // try {
            //     new XAPIActivity({
            //         type: 'assessment',
            //         verb: 'attempted',
            //         lrs_endpoint: `${process.env.REACT_APP_BACKEND_XAPI}/beyim-lrs/xapi/`,
            //         local_url: id as string,
            //         content_name: detailsProgress.data.progress.section_name,
            //         content_type: 'assessment',
            //         language: locale,
            //         subject:
            //             (subjects &&
            //                 subjects.data.subjects
            //                     .find(
            //                         item =>
            //                             item?.id.toString() ===
            //                             searchParams.get('subject'),
            //                     )
            //                     ?.translations.find(
            //                         item => item?.locale === locale,
            //                     )?.name) ||
            //             '',
            //         section:
            //             detailsProgress.data.progress.section_type_id === 2
            //                 ? detailsProgress.data.progress.section_name
            //                 : '',
            //         topic:
            //             detailsProgress.data.progress.section_type_id === 3
            //                 ? detailsProgress.data.progress.section_name
            //                 : '',
            //         duration: `PT${detailsProgress.data.progress.duration}M`,
            //         score_raw: detailsProgress.data.progress.percentage,
            //         score_min: 0,
            //         score_max: 100,
            //         result_completion: true,
            //         isResponseCorrect:
            //             detailsProgress.data.progress.percentage >=
            //             detailsProgress.data.progress.threshold
            //                 ? true
            //                 : false,
            //     }).sendStatment();
            // } catch (e) {
            //     console.warn(e);
            // }
        }
    }, [questions]);
    useEffect(() => {
        if (passed && detailsProgress?.data.progress && questionsUnique) {
            questionsUnique?.forEach((item, index) => {
                if (item) {
                    try {
                        new XAPIActivity({
                            type: 'question',
                            verb: 'answered',
                            lrs_endpoint: `${process.env.REACT_APP_BACKEND_XAPI}/beyim-lrs/xapi/`,
                            local_url: id as string,
                            content_name: item.body,
                            content_type: 'assessment',
                            language: locale as string,
                            subject:
                                (subjects &&
                                    subjects.data.subjects
                                        .find(
                                            item =>
                                                item?.id.toString() ===
                                                searchParams.get('subject'),
                                        )
                                        ?.translations.find(
                                            item => item?.locale === locale,
                                        )?.name) ||
                                '',
                            section:
                                detailsProgress.data.progress
                                    .section_type_id === 2
                                    ? detailsProgress.data.progress.section_name
                                    : '',
                            topic:
                                detailsProgress.data.progress
                                    .section_type_id === 3
                                    ? detailsProgress.data.progress.section_name
                                    : '',
                            response:
                                detailsProgress.data.progress.questions[index]
                                    .answer === null
                                    ? 'null'
                                    : item.options[
                                          detailsProgress.data.progress
                                              .questions[index].answer
                                      ].body,
                            isResponseCorrect:
                                detailsProgress.data.progress.questions[index]
                                    .is_correct,
                            parent_id: id as string,
                            parent_contentType: 'asssessment',
                            result_completion:
                                detailsProgress.data.progress.questions[index]
                                    .answer === null
                                    ? false
                                    : true,
                        }).sendStatment();
                    } catch (error) {
                        console.warn(error);
                    }
                }
            });
        }
    }, [questionsUnique]);

    useEffect(() => {
        const dataMapping: { [key: string]: Question } = {};
        if (questions?.data.questions) {
            questions?.data.questions.forEach(item => {
                dataMapping[item.id as any] = item;
            });

            const sortedData = detailsProgress?.data.progress.questions?.map(
                qid => dataMapping[qid.question_id],
            );
            const uniqueData = sortedData?.filter((item, index) => {
                return sortedData?.indexOf(item) === index;
            });
            setQuestionsUnique(uniqueData);
        }
    }, [questions?.data.questions]);

    const threshold = detailsProgress?.data.progress.threshold;

    const pass =
        detailsProgress &&
        threshold &&
        Number(detailsProgress?.data.progress.percentage) >= Number(threshold);
    useEffect(()=>{
            document.title=t('pages.results');
        },[locale]);  
    return (
        <>
            {' '}
            <Helmet>
                <title>{t('pages.results')}</title>
            </Helmet>
            <div
                className={classNames(
                    'min-h-[calc(100vh-59px)] md:min-h-screen',
                    style.wrapper,
                )}
            >
                <AssessmentHeader
                    center={
                        <Center
                            isLoading={isLoadingProgress}
                            title={detailsProgress?.data.progress.section_name}
                            section_type_id={
                                detailsProgress?.data.progress.section_type_id
                            }
                            href={`/feed?idContent=${
                                searchParams.get('idContent') || ''
                            }&fromSearch=${
                                searchParams.get('fromSearch') || ''
                            }&subject=${
                                searchParams.get('subject') || ''
                            }&sectionsBySubject=${
                                searchParams.get('sectionsBySubject') || ''
                            }&idActivity=${
                                searchParams.get('idActivity') || ''
                            }&them=${
                                detailsProgress?.data.progress
                                    .section_type_id === 3
                                    ? searchParams.get('them') || ''
                                    : ''
                            }`}
                        />
                    }
                />

                <div
                    className={classNames(
                        'mt-2 md:mt-[31px] mx-auto flex max-w-[592px] flex-col w-full pb-20',
                        style.resultTest,
                    )}
                >
                    <div
                        className={classNames(
                            'sm:rounded-2xl sm:mb-2',
                            style.resultTest__header,
                        )}
                        style={{
                            background: isLoadingProgress
                                ? '#FFF'
                                : pass
                                  ? '#7FC261'
                                  : '#E55959',
                        }}
                    >
                        {isLoadingProgress ? (
                            <Skeleton
                                height={25}
                                count={2}
                                baseColor="#e9f0f3"
                                borderRadius={10}
                            />
                        ) : (
                            <>
                                <h1>
                                    {pass
                                        ? t('assessment.congradulations')
                                        : t('assessment.unfortunately')}
                                </h1>
                                <h2>
                                    {pass
                                        ? detailsProgress?.data.progress
                                              .section_type_id === 3
                                            ? t('assessment.newTopic')
                                            : t('assessment.newSection')
                                        : t('assessment.messageMinimum', {
                                              threshold:
                                                  detailsProgress?.data.progress
                                                      .threshold,
                                          })}
                                </h2>
                            </>
                        )}
                    </div>
                    <AssessmentStarting
                        type="results"
                        footer={
                            <>
                                <button
                                    className={classNames(
                                        pass
                                            ? style.resultTest_buttonDiasabled
                                            : style.resultTest_buttonInfo,
                                        ' font-bold leading-4 text-xs',
                                    )}
                                    onClick={() =>
                                        setIsTakeAgainModalOpen(true)
                                    }
                                >
                                    {t('assessment.takeTestAgain')}
                                </button>

                                <AssessmentFooterButton
                                    className={classNames(
                                        'font-bold leading-4 text-xs',
                                    )}
                                    href={`/feed?idContent=${
                                        searchParams.get('idContent') || ''
                                    }&fromSearch=${
                                        searchParams.get('fromSearch') || ''
                                    }&subject=${
                                        searchParams.get('subject') || ''
                                    }&sectionsBySubject=${
                                        searchParams.get('sectionsBySubject') ||
                                        ''
                                    }&idActivity=${
                                        searchParams.get('idActivity') || ''
                                    }&them=${
                                        detailsProgress?.data.progress
                                            .section_type_id === 3
                                            ? searchParams.get('them') || ''
                                            : ''
                                    }`}
                                    variant="info"
                                >
                                    {t('assessment.goBackFeed')}
                                </AssessmentFooterButton>
                            </>
                        }
                    />
                    {isLoadingQuestions ||
                    isFetchingQuestions ||
                    isLoadingProgress ? (
                        <SkeletonQuestion count={3} />
                    ) : (
                        detailsProgress?.data.progress.questions !== null &&
                        questionsUnique &&
                        questionsUnique?.length > 0 && (
                            <AssessmentResult
                                test={detailsProgress?.data.progress.questions}
                                questions={questionsUnique}
                            />
                        )
                    )}
                </div>

                <ModalComponent
                    isOpen={isTakeAgainModalOpen}
                    onClose={() => setIsTakeAgainModalOpen(false)}
                    content={
                        pass
                            ? t('assessment.takeAgainModal.content')
                            : t('assessment.takeAgainModal.contentFailed')
                    }
                    headerContent={t('assessment.takeAgainModal.headerContent')}
                    customContent={
                        <>
                            <AssessmentFooterButton
                                variant="info"
                                callback={() => {
                                    navigate(
                                        `/feed/assessment/${id}?${searchParams.toString()}`,
                                    );
                                    setIsTakeAgainModalOpen(false);
                                }}
                            >
                                {t('assessment.takeAgainModal.start')}
                            </AssessmentFooterButton>
                            <AssessmentFooterButton
                                variant="default"
                                callback={() => setIsTakeAgainModalOpen(false)}
                            >
                                {t('assessment.takeAgainModal.cancel')}
                            </AssessmentFooterButton>
                        </>
                    }
                    classNameModal={style.customModalStyles}
                    classNameTitle={style.customTitleStyles}
                    classNameContent={style.customContentStyles}
                    classNameFooter={style.customFooterStyles}
                />
            </div>
        </>
    );
};
