import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const PostSkeleton = () => {
    return (
        <div className="w-100 h-100 min-h-[500px] bg-white sm:p-4 py-4 sm:rounded-2xl sliderImagePost z-10">
            <header className="flex gap-2 mb-3 sm:p-0 px-4">
                <Skeleton
                    count={1}
                    height={40}
                    width={40}
                    circle
                    baseColor="#e9f0f3"
                />

                <div>
                    <Skeleton
                        count={1}
                        height={19}
                        width={200}
                        baseColor="#e9f0f3"
                    />

                    <Skeleton
                        count={1}
                        height={17}
                        width={100}
                        baseColor="#e9f0f3"
                    />
                </div>
            </header>
            <Skeleton height={'60%'} className="mb-3" baseColor="#e9f0f3" />
            <Skeleton
                count={4}
                height={24}
                width={'100%'}
                baseColor="#e9f0f3"
            />
        </div>
    );
};
