import { useNavigate } from 'react-router-dom';

import style from './ButtonNode.module.scss';
import classNames from 'classnames';
import { useAppDispatch, useNormalizedLocale } from '../../../../hooks';
import {
    openModal,
    setContent,
} from '../../../../ui/BeyimModal/modalSlice/modalSlice';
import { ModalContent } from './utils/ModalContent';

export const ButtonNode: React.FC<{
    href?: string;
    confirm?: boolean;
}> = ({ href, confirm }) => {
    const navigate = useNavigate();
    const locale = useNormalizedLocale();

    const dispatch = useAppDispatch();
    return (
        <button
            className={classNames(
                'flex w-8 h-8 justify-center items-center flex-shrink-0 rounded-[64px]',
                style.logo,
            )}
            onClick={() => {
                confirm &&
                    dispatch(openModal()) &&
                    dispatch(setContent(<ModalContent href={href} />));
                !confirm && navigate(href || '/');
            }}
        >
            <img
                src={'/icons/arrow-left.svg'}
                alt="Beyim logo"
                className={classNames(locale === 'ar' ? 'rotate-180' : '')}
            />
        </button>
    );
};
