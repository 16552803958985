// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GetFeedMicrotopicsResponseApiType,
    GetSearchGlobalResponseApiType,
    GetSectionBySubjectsApiResponseApiType,
    GetSectionsRequestApiType,
    GetSectionsResponseApiType,
    GetSubjectRequestApiType,
    GetSubjectResponseApiType,
} from '../../types';
import Cookies from 'js-cookie';
import { getSubjectByIdResponseApiType } from '../../types/getSubjectByIdResponseApiType';
import { GetSchoolsByOrganizationIdResponseApiType } from '../../types/organizationApitype/GetSchoolsByOrganizationIdResponseApiType';
import { getAllClassesByGradeReponseApiType } from '../../types/organizationApitype/getAllClassesByGradeReponseApiType';
import { getAllGradesResponseApiType } from '../../types/organizationApitype/getAllGradesResponseApiType';
import { GetClassByIdResponseApiType } from './types/GetClassByIdResponseApiType';
import {
    Organization,
    getAllOrganizationsApiType,
} from '../../types/organizationsApiType/getAllOrganizationsApiType';
import { GetCheckCodeStudentResponseApiType } from '../../types/organizationsApiType/GetCheckCodeStudentResponseApiType';
import { GetCheckCodeTeacherResponseApiType } from '../../types/organizationsApiType/GetCheckCodeTeacherResponseApiType';

export const organizationServiceApi = createApi({
    reducerPath: 'organizationServiceApi',
    tagTypes: ['organizations'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_ORG_SERVICE_URL}/api/v1/`,
        prepareHeaders: headers => {
            const token = Cookies.get('access_token');
            const id_token = Cookies.get('id_token');

            // If we have a token set in state, let's assume that we should be passing it.
            if (token && id_token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('X-Api-Key', id_token);
            }

            return headers;
        },
    }),

    endpoints: build => ({
        getAllSchools: build.query<
            GetSchoolsByOrganizationIdResponseApiType,
            {
                organizationId?: string;
                schoolId?: number;
                offset?: number;
                limit?: number;
            }
        >({
            query: ({ organizationId, offset = 0, limit = 50, schoolId }) => {
                return {
                    url: `schools`,
                    params: {
                        organizationId,
                        offset,
                        limit,
                        ...(schoolId ? { schoolId } : {}),
                    },
                };
            },
        }),
        getAllClasses: build.query<
            getAllClassesByGradeReponseApiType,
            {
                schoolId: number;
                gradeId?: number;
                limit?: number;
                page?: number;
            }
        >({
            query: ({ schoolId, gradeId, page = 0, limit = 1000 }) => {
                return {
                    url: `classes`,
                    params: {
                        schoolId,
                        gradeId,
                        page,
                        limit,
                    },
                };
            },
        }),
        getClassById: build.query<
            GetClassByIdResponseApiType,
            {
                classId: string;
            }
        >({
            query: ({ classId }) => {
                return {
                    url: `classes/${classId}`,
                };
            },
        }),

        getAllGrades: build.query<
            getAllGradesResponseApiType,
            {
                limit?: number;
                page?: number;
                numberFrom?: number;
            }
        >({
            query: ({ page = 0, limit = 50, numberFrom }) => {
                return {
                    url: `grades`,
                    params: {
                        page,
                        limit,
                        numberFrom,
                    },
                };
            },
        }),
        getAllOrganizations: build.query<getAllOrganizationsApiType, any>({
            query: ({ page = 0, limit = 100 }) => {
                return {
                    url: `organizations`,
                    params: {
                        page,
                        limit,
                        includeDetails: true,
                    },
                };
            },

            providesTags: ['organizations'],
        }),
        getOrganizationDataById: build.query<
            {
                success: boolean;
                message: string;
                data: {
                    organization: Organization;
                };
            },
            string
        >({
            query: organizationId => {
                return { url: `organizations/${organizationId}` };
            },
        }),

        getCheckCodeStudent: build.query<
            GetCheckCodeStudentResponseApiType,
            string
        >({
            query: code => {
                return { url: `classes/code/${code}` };
            },
        }),
        getCheckCodeTeacher: build.query<
            GetCheckCodeTeacherResponseApiType,
            string
        >({
            query: code => {
                return { url: `organizations/code/${code}` };
            },
        }),
    }),
});

export const {
    useGetAllSchoolsQuery,
    useLazyGetAllSchoolsQuery,
    useGetAllClassesQuery,
    useLazyGetAllClassesQuery,
    useGetAllGradesQuery,
    useLazyGetAllGradesQuery,
    useGetAllOrganizationsQuery,
    useLazyGetAllOrganizationsQuery,
    useGetOrganizationDataByIdQuery,
    useLazyGetOrganizationDataByIdQuery,
    useGetCheckCodeStudentQuery,
    useLazyGetCheckCodeStudentQuery,
    useGetCheckCodeTeacherQuery,
    useLazyGetCheckCodeTeacherQuery,
    useGetClassByIdQuery,
    useLazyGetClassByIdQuery,
} = organizationServiceApi;
