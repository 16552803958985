// Your translation file

import { useTranslation } from 'react-i18next';

export const getData = () => {
    const { t } = useTranslation();
    const data = {
        options: [
            t('onboarding.post.option1'),
            t('onboarding.post.option2'),
            t('onboarding.post.option3'),
            t('onboarding.post.option4'),
        ],
        post: {
            microtopic: 1,
            subject: 1,
        },
        subjects: [
            {
                url: '/icons/physics.svg',
                name: t('onboarding.subjects.physics'),
                objective: t('onboarding.objective'),
            },
            {
                url: '/icons/chemistry.svg',
                name: t('onboarding.subjects.chemistry'),
                objective: t('onboarding.objective'),
            },
            {
                url: '/icons/biology.svg',
                name: t('onboarding.subjects.biology'),
                objective: t('onboarding.objective'),
            },
        ],
        star: [
            '/icons/solar_star-bold-gray.svg',
            '/icons/solar_star-bold-gray.svg',
            '/icons/solar_star-bold-gray.svg',
            '/icons/solar_star-bold-gray.svg',
            '/icons/lightning.svg',
        ],
        starActive: [
            '/icons/solar_star-bold.svg',
            '/icons/solar_star-bold-gray.svg',
            '/icons/solar_star-bold-gray.svg',
        ],
        progress: [
            t('onboarding.progress.first'),
            t('onboarding.progress.second'),
            t('onboarding.progress.third'),
        ],
        allStar: [
            '/icons/lightningActive.svg',
            '/icons/lightningActive.svg',
            '/icons/lightningActive.svg',
        ],
    };
    return data;
};
