import classNames from 'classnames';
import style from './BlockOption.module.scss';
import { Option } from '../Option';
import {
    GetSectionsResponseApiType,
    GetSubjectResponseApiType,
} from '../../../../types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BlockEmpty } from '../BlockEmpty/BlockEmpty';
import { Link } from 'react-router-dom';
import { useNormalizedLocale } from '../../../../hooks';

interface IBlockOption<
    T = GetSubjectResponseApiType | GetSectionsResponseApiType,
> {
    title?: string;
    withImage?: boolean;
    data?: T;
    isLoading?: boolean;
    active?: string;
    handleChange?: (id: string) => void;
    isLink?: boolean;
    subject?: string;
    sectionsBySubject?: string;
    them?: string;
    onClick?: () => void;
}

export const BlockOption: React.FC<
    IBlockOption<GetSectionsResponseApiType>
> = ({
    title,
    withImage = false,
    data,
    isLoading = true,
    active,
    handleChange,
    isLink = false,
    subject,
    sectionsBySubject,
    them,
    onClick,
    ...props
}) => {
    const { t } = useTranslation();
    const locale = useNormalizedLocale();

    return (
        <div
            className={classNames(
                ' shrink-0 grow-0 min-h-[516px] py-4 px-3 w-[264px] overflow-auto max-h-full',
                style.wrapper,
            )}
            {...props}
        >
            <span className={classNames(style.title, 'text-sm mb-6 block')}>
                {title}
            </span>
            <div>
                {isLoading ? (
                    <Skeleton count={4} height={38} />
                ) : (
                    data &&
                    (data.data.sections.length > 0 ? (
                        <ul className="w-full">
                            {data.data.sections?.map(item => {
                                return isLink ? (
                                    <Link
                                        to={`/feed?&subject=${
                                            subject || ''
                                        }&sectionsBySubject=${
                                            sectionsBySubject || ''
                                        }&them=${item.id}`}
                                        key={item.id}
                                        onClick={onClick}
                                    >
                                        <Option
                                            name={
                                                item.translations.find(
                                                    element =>
                                                        element.locale ===
                                                        locale,
                                                )?.name ??
                                                t('available.learnLanguage')
                                            }
                                            handleClick={() => {
                                                if (handleChange) {
                                                    handleChange(
                                                        String(item.id),
                                                    );
                                                }
                                            }}
                                            isActive={
                                                active === String(item.id)
                                            }
                                            them={them}
                                        />
                                    </Link>
                                ) : (
                                    <Option
                                        key={item.id}
                                        name={
                                            item.translations.find(
                                                element =>
                                                    element.locale === locale,
                                            )?.name ??
                                            t('available.learnLanguage')
                                        }
                                        handleClick={() => {
                                            if (handleChange) {
                                                handleChange(String(item.id));
                                            }
                                        }}
                                        isActive={active === String(item.id)}
                                    />
                                );
                            })}{' '}
                        </ul>
                    ) : (
                        <BlockEmpty />
                    ))
                )}
            </div>
        </div>
    );
};
