import Skeleton from 'react-loading-skeleton';

export const SkeletonProgress: React.FC = () => {
    return (
        <>
            {new Array(10).fill(0).map(index => (
                <tr key={index}>
                    <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                        <Skeleton baseColor="#e9f0f3" count={1} height={20} />
                    </td>
                    <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                        <Skeleton baseColor="#e9f0f3" count={1} height={20} />
                    </td>{' '}
                    <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                        <Skeleton baseColor="#e9f0f3" count={1} height={20} />
                    </td>{' '}
                    <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                        <Skeleton baseColor="#e9f0f3" count={1} height={20} />
                    </td>{' '}
                    <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                        <Skeleton baseColor="#e9f0f3" count={1} height={20} />
                    </td>
                    <td className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]">
                        <Skeleton baseColor="#e9f0f3" count={1} height={20} />
                    </td>
                </tr>
            ))}
        </>
    );
};
