import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './ErrorScreen.module.scss';
import { useNavigate } from 'react-router-dom';

export const ErrorScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center gap-4 ">
            <img
                src="/icons/FeedErroIcon.svg"
                alt="error"
                className="block w-[52px] h-[52px]"
            />
            <div className="flex flex-col items-center gap-2">
                <p className="text-center font-inter text-base font-bold leading-140">
                    {t('ErrorScreen.header')}
                </p>
                <p className="text-center font-inter text-sm font-medium leading-140">
                    {t('ErrorScreen.description')}
                </p>
            </div>
            <button
                className={classNames(
                    'mt-2 flex items-center border-solid border rounded-xl px-4 py-3 gap-2 ',
                    styles.refreshButton,
                )}
                onClick={() => navigate(0)}
            >
                <img
                    src="/icons/RefreshIcon.svg"
                    alt="refresh"
                    className="block w-[24px] h-[24px]"
                />
                <p className="text-[17px] font-medium leading-[100%]">
                    {t('ErrorScreen.refresh')}
                </p>
            </button>
        </div>
    );
};
