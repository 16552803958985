import classNames from 'classnames';
import { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

interface IBaseCard {
    firstBlock?: ReactNode;
    title?: ReactNode;
    subTitle?: ReactNode;
    isLoading?: boolean;
}

export const BaseCard: React.FC<IBaseCard> = ({
    firstBlock,
    title,
    subTitle,
    isLoading,
}) => {
    return (
        <div className="rounded-2xl bg-white flex py-7 px-8 gap-6 items-center flex-wrap">
            {firstBlock}
            <div className="flex flex-col w-44">
                <span className="text-[#364954] font-semibold text-2xl">
                    {isLoading ? (
                        <Skeleton width={100} borderRadius={4} />
                    ) : (
                        title
                    )}
                </span>
                <span className="text-sm text-[#678AA1]">{subTitle}</span>
            </div>
        </div>
    );
};
