import { useTranslation } from 'react-i18next';
import styles from './EmptyFeedScreen.module.scss';

export const EmptyFeedScreen = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center gap-4">
            <img
                src="/icons/EmptyLocaleIcon.svg"
                alt="empty"
                className="block w-[52px] h-[52px]"
            />
            <div className="flex flex-col items-center gap-2">
                <p className="text-center font-inter text-base font-bold leading-140">
                    {t('EmptyFeedScreen.header')}
                </p>
                <p className="text-center font-inter text-sm font-medium leading-140">
                    {t('EmptyFeedScreen.description')}
                </p>
            </div>
        </div>
    );
};
