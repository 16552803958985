import classNames from 'classnames';
import style from './FilterBlock.module.scss';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useGetAllSubjectsQuery } from '../../../../api';

interface IFilterBlock {
    setVariant: React.Dispatch<React.SetStateAction<'bookmark' | 'like'>>;
    variant: 'like' | 'bookmark';
    setOpenType: React.Dispatch<React.SetStateAction<boolean>>;
    openType: boolean;
    setIsActivity: React.Dispatch<React.SetStateAction<boolean>>;
    isActivity: boolean;
    setIsVideo: React.Dispatch<React.SetStateAction<boolean>>;
    setIsImage: React.Dispatch<React.SetStateAction<boolean>>;
    setSubjectFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setOpenSubject: React.Dispatch<React.SetStateAction<boolean>>;
    openSubject: boolean;
}

export const FilterBlock: React.FC<IFilterBlock> = ({
    setVariant,
    variant,
    setOpenType,
    openType,
    isActivity,
    setIsActivity,
    setIsVideo,
    setSubjectFilter,
    setIsImage,
    setOpenSubject,
    openSubject,
}) => {
    const { t, i18n } = useTranslation();
    const { data: subjects, isFetching: isFetchingSubjects } =
        useGetAllSubjectsQuery({ limit: 100 });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const locale = i18n.translator.language;
    return (
        <aside className=" flex flex-col gap-4">
            <div className={classNames(style.filter, 'rounded-2xl p-4')}>
                <div className="flex flex-col gap-2">
                    <button
                        onClick={() => {
                            setVariant('like');
                        }}
                        className={classNames(
                            variant === 'like' ? style.activeFavorite : '',
                            'text-left px-2 py-3 text-sm font-medium  rounded-lg leading-[17px] flex items-center gap-2',
                        )}
                    >
                        <img
                            src={
                                variant === 'like'
                                    ? '/icons/heart-fillActive.svg'
                                    : '/icons/heart-fill.svg'
                            }
                            alt="like"
                            className="w-6 h-6"
                        />
                        {t('favorite.likes')}
                    </button>
                    <button
                        onClick={() => {
                            setVariant('bookmark');
                        }}
                        className={classNames(
                            variant === 'bookmark' ? style.activeFavorite : '',
                            'text-left px-2 py-3 text-sm font-medium rounded-lg leading-[17px] flex items-center gap-2',
                        )}
                    >
                        <img
                            src={
                                variant === 'bookmark'
                                    ? '/icons/favou-fillActive.svg'
                                    : '/icons/favou-fill.svg'
                            }
                            alt="like"
                            className="w-6 h-6"
                        />
                        {t('favorite.favorite')}
                    </button>
                </div>
                <hr className={classNames('mt-2 mb-2 h-[1px]', style.divide)} />
                <div
                    className={classNames(
                        ' flex flex-col',
                        openType ? 'gap-2' : '',
                    )}
                >
                    <button
                        className=" w-full flex justify-between items-center"
                        onClick={() => {
                            setOpenType(prev => !prev);
                        }}
                    >
                        <span
                            className={classNames(
                                style.openButton,
                                'text-xs font-medium',
                            )}
                        >
                            {t('favorite.type')}
                        </span>
                        <img
                            src="/icons/arrow-top.svg"
                            className={classNames(
                                'block h-6 w-6 duration-200  me-2',
                                openType ? '' : 'rotate-180',
                            )}
                            alt="arrow"
                        />
                    </button>

                    <div
                        className={classNames(
                            'overflow-hidden duration-200 flex flex-col px-2',
                            openType ? 'h-f' : 'h-0',
                            style.typeFilter,
                        )}
                    >
                        <input
                            type="checkbox"
                            onChange={e => {
                                const value = e.target.checked;

                                setIsActivity(value);
                            }}
                            className="hidden"
                            id="activityFilter"
                        />
                        <label
                            htmlFor="activityFilter"
                            className={classNames(
                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                            )}
                        >
                            {t('favorite.activity')}
                            <span
                                className={classNames(
                                    ' block w-6 h-6 border-2 rounded bg-center',
                                )}
                            />
                        </label>
                        <input
                            className="hidden"
                            type="checkbox"
                            id="videoFilter"
                            onChange={e => {
                                const value = e.target.checked;

                                setIsVideo(value);
                            }}
                        />
                        <label
                            htmlFor="videoFilter"
                            className={classNames(
                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                            )}
                        >
                            {t('favorite.video')}
                            <span
                                className={classNames(
                                    ' block w-6 h-6 border-2 rounded bg-center',
                                )}
                            />
                        </label>
                        <input
                            className="hidden"
                            type="checkbox"
                            id="imageFilter"
                            onChange={e => {
                                const value = e.target.checked;

                                setIsImage(value);
                            }}
                        />
                        <label
                            htmlFor="imageFilter"
                            className={classNames(
                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                            )}
                        >
                            {t('favorite.image')}
                            <span
                                className={classNames(
                                    ' block w-6 h-6 border-2 rounded bg-center',
                                )}
                            />
                        </label>
                    </div>
                </div>
                <hr className={classNames('mt-2 mb-2 h-[1px]', style.divide)} />
                <div>
                    <button
                        onClick={() => {
                            setOpenSubject(prev => !prev);
                        }}
                        className=" w-full flex justify-between items-center"
                    >
                        <span
                            className={classNames(
                                style.openButton,
                                'text-xs font-medium',
                            )}
                        >
                            {t('favorite.subject')}
                        </span>
                        <img
                            src="/icons/arrow-top.svg"
                            className={classNames(
                                'block h-6 w-6 duration-200 me-2',
                                openSubject ? '' : 'rotate-180',
                            )}
                            alt="arrow"
                        />
                    </button>

                    <div
                        className={classNames(
                            'overflow-hidden duration-200 flex flex-col px-2',
                            openSubject ? 'h-f' : 'h-0',
                            style.typeFilter,
                        )}
                    >
                        {subjects &&
                            subjects.data.subjects.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <input
                                            type="checkbox"
                                            id={
                                                (String(item.id) ||
                                                    String(index)) +
                                                'subjectFavorite'
                                            }
                                            className="hidden"
                                            onChange={e => {
                                                const value = e.target.checked;

                                                if (value) {
                                                    setSubjectFilter(prev => {
                                                        const oldData = [
                                                            ...prev,
                                                        ];

                                                        oldData.push(item.id);

                                                        return oldData;
                                                    });
                                                } else {
                                                    setSubjectFilter(prev => {
                                                        const oldData = [
                                                            ...prev,
                                                        ];

                                                        const index =
                                                            oldData.indexOf(
                                                                item.id,
                                                            );
                                                        if (index !== -1) {
                                                            oldData.splice(
                                                                index,
                                                                1,
                                                            );
                                                        }

                                                        return oldData;
                                                    });
                                                }
                                            }}
                                        />
                                        <label
                                            htmlFor={
                                                (String(item.id) ||
                                                    String(index)) +
                                                'subjectFavorite'
                                            }
                                            className={classNames(
                                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                                            )}
                                        >
                                            {
                                                item.translations.find(
                                                    translation =>
                                                        translation.locale ===
                                                        locale,
                                                )?.name
                                            }
                                            <span
                                                className={classNames(
                                                    ' block w-6 h-6 border-2 rounded bg-center',
                                                )}
                                            />
                                        </label>
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>
            </div>
        </aside>
    );
};
