import { FC } from 'react';
import { SubjectSelect } from '../../../../features/SubjectSelect';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

export const DashboardStudentInfoButtons: FC = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/feed');
    };

    return (
        <div className={'flex gap-2'}>
            <div className={'w-36 h-10'}>
                <SubjectSelect />
            </div>

            <button
                className={
                    'rounded-xl h-10 border border-[#CBDCE7] px-2 flex items-center opacity-50 '
                }
                disabled
                data-tooltip-id="shield"
            >
                <img
                    src="/icons/shield.svg"
                    alt="shield"
                    className={'w-8 h-8'}
                />
            </button>
            <Tooltip id="shield" noArrow={true} opacity={1} place={'bottom'}>
                В разработке
            </Tooltip>
            <button
                className={
                    'rounded-xl h-10 w-10 border border-[#CBDCE7] flex items-center justify-center opacity-50'
                }
                disabled
                data-tooltip-id="download"
            >
                <img
                    src="/icons/download.svg"
                    alt="download"
                    width={20}
                    height={20}
                    className={'w-5 h-5'}
                />
            </button>
            <Tooltip id="download" noArrow={true} opacity={1} place={'bottom'}>
                В разработке
            </Tooltip>
            <button
                className={
                    'rounded-xl h-10 w-10 border border-[#CBDCE7] flex items-center justify-center opacity-50'
                }
                disabled
                data-tooltip-id="share"
            >
                <img
                    src="/icons/share.svg"
                    alt="share"
                    width={20}
                    height={20}
                    className={'w-5 h-5'}
                />
            </button>
            <Tooltip id="share" noArrow={true} opacity={1} place={'bottom'}>
                В разработке
            </Tooltip>
            <button
                data-tooltip-id="delete"
                className={
                    'rounded-xl h-10 w-10 border border-[#CBDCE7]  items-center justify-center hidden md:flex'
                }
                onClick={handleGoBack}
            >
                <img
                    src="/icons/delete.svg"
                    alt="delete"
                    width={20}
                    height={20}
                    className={'w-5 h-5'}
                />
            </button>
            <Tooltip id="delete" noArrow={true} opacity={1} place={'bottom'}>
                Назад
            </Tooltip>
        </div>
    );
};
