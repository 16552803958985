import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import React, { ReactNode, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { login, setRole } from '../../features/slice/authSlice';
import {
    useLazyGetUserDataQuery,
    useRefreshTokenMutation,
} from '../../api/authService/authServiceApi';
import { roles } from '../../features/AuthForms/constants';
import { getUserDataResponseApiType } from '../../types/getUserDataApiType';
import { Loader } from '../../features/Loader';
import { SmartSuspense } from './utils/SmartSuspence';
import { expires } from '../../constants/expires';

interface IRedirectAuthorization {
    children: ReactNode;
}

export const RedirectAuthorization: React.FC<IRedirectAuthorization> = ({
    children,
}) => {
    const [isLoginCheck, setIsLoginCheck] = useState<boolean>(true);
    const isLogin = useAppSelector(state => state.auth.isLogin);
    const dispatch = useAppDispatch();
    const refresh_token = Cookies.get('refresh_token');
    const navigate = useNavigate();
    const [updateRefreshToken] = useRefreshTokenMutation();
    const [getUserData] = useLazyGetUserDataQuery();
    const checkRole = (userData: getUserDataResponseApiType) => {
        if (
            userData.data.User.organizations.some(
                item =>
                    item.role === roles.CLASS_TEACHER ||
                    item.role === roles.SUBJECT_TEACHER,
            )
        ) {
            dispatch(setRole('teacher'));
        }
        if (
            userData.data.User.organizations.some(
                item => item.role === roles.STUDENT,
            )
        ) {
            dispatch(setRole('student'));
        }

        if (
            userData.data.User.organizations.some(
                item => item.role === roles.EXPERT,
            )
        ) {
            dispatch(setRole('expert'));
        }
    };
    useEffect(() => {
        if (isLogin) {
            setIsLoginCheck(true);
        } else if (Cookies.get('access_token')) {
            getUserData('')
                .unwrap()
                .then(data => {
                    dispatch(login());
                    Cookies.set('user_id', data?.data.User.id, {
                        expires,
                    });
                    checkRole(data);
                });
            setIsLoginCheck(true);
        } else {
            setIsLoginCheck(false);
        }
    }, []);

    useEffect(() => {
        if (!Cookies.get('access_token') && refresh_token) {
            updateRefreshToken(refresh_token)
                .unwrap()
                .then(data => {
                    Cookies.set('access_token', data.access_token, {
                        expires,
                    });
                    Cookies.set('id_token', data.id_token, {
                        expires,
                    });
                    Cookies.get('access_token') &&
                        getUserData('')
                            .unwrap()
                            .then(data => {
                                dispatch(login());
                                checkRole(data);
                                Cookies.set('user_id', data?.data.User.id, {
                                    expires: expires,
                                });
                            });
                });
        }
    }, [Cookies.get('access_token')]);

    return (
        <SmartSuspense fallback={<Loader />} fallbackMinDurationMs={1000}>
            {isLoginCheck ? children : <Navigate to="/authorization" />}
        </SmartSuspense>
    );
};
