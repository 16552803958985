import React from 'react';
import style from './HeaderFormRight.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { states } from '../constants';

interface HeaderFormRightProps {
    setState: (state: states) => void;
    state: states;
}
export const HeaderFormRight = ({ state, setState }: HeaderFormRightProps) => {
    const { t } = useTranslation();
    console.log('HeaderFormRight', state);

    return (
        <div>
            {state === states.login || states.type_user === state ? (
                <p className="m-0 items-center justify-center">
                    <span
                        className={classNames(
                            'text-base leading-7 font-[400] whitespace-nowrap',
                            style.textSpan,
                        )}
                    >
                        {states.type_user === state
                            ? t('authorization.haveAccount')
                            : t('authorization.noAccount')}
                    </span>{' '}
                    <button
                        className={classNames(
                            style.textButton,
                            'bg-transparent ml-1 font-semibold text-base leading-7',
                        )}
                        onClick={() => {
                            setState(
                                states.login === state
                                    ? states.type_user
                                    : states.login,
                            );
                        }}
                    >
                        {states.login === state
                            ? t('authorization.register')
                            : t('authorization.title')}
                    </button>
                </p>
            ) : null}
            {states.personal_data === state ||
            state === states.change_password ? (
                <div className="flex flex-col justify-end">
                    <span
                        className={classNames(
                            'text-end text-sm font-medium',
                            style.textButton,
                        )}
                    >
                        {t('authorization.step')} 0
                        {state === states.change_password ? 1 : 2}/02
                    </span>
                    <span
                        className={classNames(
                            'text-end text-sm font-medium',
                            style.textSpan,
                        )}
                    >
                        {t('authorization.codeOrganization')}
                    </span>
                </div>
            ) : null}
        </div>
    );
};
