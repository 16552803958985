import { Link, useSearchParams } from 'react-router-dom';
import { Bookmark } from '../Bookmark';
import { Like } from '../like';
import classNames from 'classnames';
import style from './FooterPost.module.scss';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { PostFeed } from '../../types/contentInteraction/GetFooterDataByPostIdsResponseApiType';
import TagManager from 'react-gtm-module';

interface IFooterPost {
    postId: string;
    contentId: string;
    subject?: string;
    microtopic?: string;
    category?: string;
    postFooterData?: PostFeed;
}

export const FooterPost: React.FC<IFooterPost> = ({
    postId,
    contentId,
    subject,
    microtopic,
    category,
    postFooterData,
}) => {
    const { t } = useTranslation();

    const locale = useNormalizedLocale();
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <footer className="flex justify-between items-center px-4 sm:p-0">
            <div className="flex gap-4">
                <Like
                    postId={postId}
                    microtopic={microtopic}
                    subject={subject}
                    category={category}
                    isLikedPost={postFooterData?.isLiked}
                    likeCount={postFooterData?.likesCount}
                />{' '}
                <Bookmark
                    postId={postId}
                    microtopic={microtopic}
                    subject={subject}
                    category={category}
                    isBookmarkedPost={postFooterData?.isBookmarked}
                    bookmarkCount={postFooterData?.bookmarkCount}
                />
            </div>
            <beyim-xapi
                type="button"
                verb="experienced"
                name={microtopic || 'microtopic'}
                subject={subject || 'subject'}
                backend={process.env.REACT_APP_BACKEND_XAPI}
                language={locale}
            >
                <button
                    onClick={() => {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'learn_more',
                                post_id: postId,
                                subject: subject,
                                locale: locale,
                            },
                            dataLayerName: 'feed',
                        });
                        setSearchParams(prev => {
                            return {
                                idContent: String(contentId) || '',
                                fromSearch: prev.get('fromSearch') || '',
                                subject: prev.get('subject') || '',
                                sectionsBySubject:
                                    prev.get('sectionsBySubject') || '',
                                them: prev.get('them') || '',
                            };
                        });
                    }}
                    className={classNames(
                        'rounded-2xl  h-11 items-center flex justify-center uppercase text-xs font-bold min-w-[132px] tracking-[1px] p-3',
                        style.more,
                    )}
                >
                    {t('onboarding.detail')}
                </button>
            </beyim-xapi>
        </footer>
    );
};
