import {
    ReactNode,
    Suspense,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

/**
 *
 * @param children - children компонент который изначально должен отрисоваться
 * @param fallback - fallback компонент
 * @param fallbackMinDurationMs - сколько миллисекунд показывать fallback
 *
 */

const PromiseThrower = () => {
    throw new Promise(() => {});
};

const FallbackDelayer = ({
    fallback,
    onShowFallback,
}: {
    fallback: ReactNode;
    onShowFallback: () => void;
}) => {
    useEffect(() => {
        onShowFallback();
    }, [onShowFallback]);

    return <>{fallback}</>;
};

export const SmartSuspense = ({
    children,
    fallback,
    fallbackMinDurationMs = 0,
}: {
    children: ReactNode;
    fallback: ReactNode;
    fallbackMinDurationMs?: number;
}) => {
    const [isWaitingFallbackMinDurationMs, setIsWaitingFallbackMinDurationMs] =
        useState<boolean>();

    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(
        undefined,
    );

    const startWaitingFallbackMinDurationMs = useCallback(() => {
        setIsWaitingFallbackMinDurationMs(true);
        timeoutIdRef.current && clearInterval(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => {
            setIsWaitingFallbackMinDurationMs(false);
        }, fallbackMinDurationMs);
    }, [fallbackMinDurationMs]);

    useEffect(() => {
        return () =>
            timeoutIdRef.current && clearInterval(timeoutIdRef.current);
    }, []);

    return (
        <Suspense
            fallback={
                <FallbackDelayer
                    fallback={fallback}
                    onShowFallback={startWaitingFallbackMinDurationMs}
                />
            }
        >
            {isWaitingFallbackMinDurationMs && <PromiseThrower />}
            {children}
        </Suspense>
    );
};
