import classNames from 'classnames';
import style from './MenuMobile.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import React, { useEffect } from 'react';
import { ProfileMobile } from '../ProfileMobile';
import { useTranslation } from 'react-i18next';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import Skeleton from 'react-loading-skeleton';
import useResposive, { breakpoints } from '../../hooks/useResponsive';
export const MenuMobileBottom = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const openModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    };

    const { data, isLoading } = useGetUserDataQuery('');
    const menuItems = [
        {
            active: location.pathname === '/feed' && !isOpen,
            icon:
                location.pathname === '/feed' && !isOpen
                    ? '/icons/feedMobileActive.svg'
                    : '/icons/feedMobile.svg',
            text: t('mobile.feed'),

            onClick: () => {
                navigate('/feed', {
                    state: { key: 'feed' },
                });
                closeModal();
            },
        },
        {
            icon:
                location.pathname === '/favorites' && !isOpen
                    ? '/icons/myActionsActive.svg'
                    : '/icons/myActions.svg',
            text: t('mobile.favorite'),
            active: location.pathname === '/favorites' && !isOpen,
            onClick: () => {
                navigate('/favorites', {
                    state: { key: 'feed' },
                });
                closeModal();
            },
        },
        {
            icon:
                // location.pathname === '/ai' && !isOpen
                //     ? '/icons/AiActive.svg'
                //     : '/icons/Ai.svg',
                '',
            text: '',
            active: location.pathname === '/ai' && !isOpen,
            onClick: () => {
                closeModal();
            },
        },
        {
            icon: data?.data.User.picture,
            text: t('mobile.profile'),
            active: isOpen,
            onClick: () => {
                openModal();
            },
        },
    ];
    const root = document.documentElement;
    const windowSize = useResposive();
    useEffect(() => {
        if (window.innerWidth < breakpoints.md) {
            root.style.setProperty(
                '--isChatbot-visible',
                isOpen ? 'visible' : 'hidden',
            );
        }
    }, [isOpen]);

    return (
        <>
            {location.pathname !== '/dashboard' &&
            !location.pathname.includes('passing') ? (
                <>
                    <footer
                        className={classNames(
                            'fixed z-[3002] bottom-0 w-full bg-[#FFF] h-[59px] block md:hidden',
                            style.wrapper,
                        )}
                    >
                        <div className="grid grid-cols-4">
                            {menuItems.map(
                                ({ icon, text, active, onClick }, index) => (
                                    <button
                                        onClick={onClick}
                                        key={index}
                                        className="flex flex-col justify-center items-center py-[5px]"
                                    >
                                        {index === 2 ? (
                                            <div className="w-[25px] h-[25px] mb-[2px]" />
                                        ) : index === 3 && isLoading ? (
                                            <Skeleton
                                                circle={true}
                                                height={25}
                                                width={25}
                                            />
                                        ) : icon ? (
                                            <img
                                                src={icon}
                                                alt={'icon'}
                                                className={classNames(
                                                    'w-[25px] h-[25px] mb-[2px]',
                                                    index === 3 &&
                                                        'rounded-full',
                                                )}
                                            />
                                        ) : (
                                            <div className="w-[25px] h-[25px] rounded-full bg-[#e9f0f3] flex items-center justify-center font-medium text-[12px]">
                                                {data?.data.User.firstName[0]?.toUpperCase()}
                                                {data?.data.User.secondName[0]?.toUpperCase()}
                                            </div>
                                        )}
                                        <span
                                            className={classNames(
                                                'text-xs leading-[16.8px] font-medium text-[#678AA1]',
                                                active && style.activeMenu,
                                            )}
                                        >
                                            {text}
                                        </span>
                                    </button>
                                ),
                            )}
                        </div>
                    </footer>
                    <ProfileMobile isOpen={isOpen} closeModal={closeModal} />
                </>
            ) : (
                ''
            )}
        </>
    );
};
