import React, { useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import style from './AssessmentResult.module.scss';
import { Question } from '../../types/ GetQuestionsByIdsResponseApiType';
import { Question as Test } from '../../types/PostGetProgressResponseApiType';
import { indexLatinLetters } from '../ActivityPost/utils/indexLatinLetters';
import { LexcialEditor } from '../LexicalEditor/LexcialEditor';
import classNames from 'classnames';

export interface TestAssessmentProps {
    test: Test[] | undefined;
    questions: Question[] | undefined | 0;
}

export const AssessmentResult = ({ questions, test }: TestAssessmentProps) => {
    const { t } = useTranslation();

    return (
        <>
            {questions !== 0 &&
                questions &&
                questions?.map((question, indexQuestion) => {
                    return (
                        <article
                            key={question.id}
                            className={` mt-2 flex flex-col p-6 sm:rounded-[16px] max-w-[592px] w-full mx-auto my-0 ${style.questions}`}
                        >
                            <header className="flex justify-between mb-2 flex-row">
                                <span
                                    className={classNames(
                                        'm-0 text-base font-semibold',
                                        style.count,
                                    )}
                                >
                                    {t('assessment.question')}{' '}
                                    {indexQuestion + 1}
                                </span>
                            </header>
                            <aside>
                                <div className="mb-4">
                                    <LexcialEditor fieldData={question.body} />
                                </div>
                                <div className="flex flex-col gap-1 mb-4">
                                    {question?.type === 'SS_MCQ' &&
                                        question?.options.map(
                                            (option, optionIndex) => {
                                                const id = nanoid();
                                                return (
                                                    <div key={id}>
                                                        <input
                                                            className={classNames(
                                                                'opacity-100 w-0 h-0 hidden',
                                                                style.input,
                                                            )}
                                                            type="radio"
                                                            id={id}
                                                            disabled
                                                        />
                                                        <label
                                                            htmlFor={id}
                                                            className={`${
                                                                style.label
                                                            } cursor-default rounded-xl px-4 py-[14px] text-base mb-0 flex font-medium items-center ${
                                                                test &&
                                                                test[
                                                                    indexQuestion
                                                                ].is_correct ===
                                                                    true &&
                                                                test[
                                                                    indexQuestion
                                                                ].answer ===
                                                                    optionIndex
                                                                    ? style.true
                                                                    : test &&
                                                                        test[
                                                                            indexQuestion
                                                                        ]
                                                                            .is_correct ===
                                                                            false &&
                                                                        test[
                                                                            indexQuestion
                                                                        ]
                                                                            .answer ===
                                                                            optionIndex
                                                                      ? style.error
                                                                      : ``
                                                            }`}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    'text-base font-medium me-3 ',
                                                                    style.letter,
                                                                )}
                                                            >
                                                                {
                                                                    indexLatinLetters[
                                                                        optionIndex
                                                                    ]
                                                                }
                                                            </span>{' '}
                                                            <LexcialEditor
                                                                fieldData={
                                                                    option.body
                                                                }
                                                            />
                                                        </label>
                                                        {test &&
                                                            test[indexQuestion]
                                                                .is_correct ===
                                                                true &&
                                                            test[indexQuestion]
                                                                .answer ===
                                                                optionIndex && (
                                                                <div
                                                                    className={`mt-1 flex max-w-[544px] w-full px-4 py-3 flex-col rounded-2xl ${style.explanation}`}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            'text-sm font-bold',
                                                                            style.explanation_definition,
                                                                        )}
                                                                    >
                                                                        {t(
                                                                            'assessment.correctAnswer',
                                                                        )}
                                                                    </span>
                                                                    <p
                                                                        className={classNames(
                                                                            'm-0 text-base font-normal',
                                                                            style.explanation_text,
                                                                        )}
                                                                    >
                                                                        {test[
                                                                            indexQuestion
                                                                        ]
                                                                            .explanation ? (
                                                                            <LexcialEditor
                                                                                fieldData={
                                                                                    test[
                                                                                        indexQuestion
                                                                                    ]
                                                                                        .explanation
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            ' '
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </div>
                                                );
                                            },
                                        )}
                                    {question?.type === 'MS_SCQ' &&
                                        question?.options.map(
                                            (option, optionIndex) => {
                                                const id = nanoid();
                                                return (
                                                    <div key={id}>
                                                        <input
                                                            className={classNames(
                                                                'opacity-100 w-0 h-0 hidden',
                                                                style.input,
                                                            )}
                                                            type="radio"
                                                            id={id}
                                                            disabled
                                                        />
                                                        <label
                                                            htmlFor={id}
                                                            className={classNames(
                                                                'cursor-default rounded-xl px-4 py-[14px] text-base mb-0 flex font-medium items-center',
                                                                style.label,
                                                                test &&
                                                                    test[
                                                                        indexQuestion
                                                                    ]
                                                                        .is_correct ===
                                                                        true &&
                                                                    test[
                                                                        indexQuestion
                                                                    ].answer.find(
                                                                        (
                                                                            item: any,
                                                                        ) =>
                                                                            item ===
                                                                            optionIndex,
                                                                    )
                                                                    ? style.true
                                                                    : test &&
                                                                        test[
                                                                            indexQuestion
                                                                        ]
                                                                            .is_correct ===
                                                                            false &&
                                                                        test[
                                                                            indexQuestion
                                                                        ].answer.find(
                                                                            (
                                                                                item: any,
                                                                            ) =>
                                                                                item ===
                                                                                optionIndex,
                                                                        )
                                                                      ? style.false
                                                                      : '',
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    'text-base font-medium me-3 ',
                                                                    style.letter,
                                                                )}
                                                            >
                                                                {
                                                                    indexLatinLetters[
                                                                        optionIndex
                                                                    ]
                                                                }
                                                            </span>{' '}
                                                            <LexcialEditor
                                                                fieldData={
                                                                    option.body
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                );
                                            },
                                        )}
                                </div>
                            </aside>
                        </article>
                    );
                })}
        </>
    );
};
