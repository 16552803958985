export enum roles {
    ADMIN = 'admin',
    AUTHOR = 'author',
    STUDENT = 'student',
    GUEST = 'guest',
    ORG_ADMIN = 'org_admin',
    SUBJECT_TEACHER = 'subject_teacher',
    CLASS_TEACHER = 'class_teacher',
    EXPERT = 'expert',
}
