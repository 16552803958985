import classNames from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNormalizedLocale } from '../../../../../../hooks';
import { Section } from '../../../../../../api/beyimProgressDashboard/types/getProgressByStudentsFullResponseApiType/getProgressByStudentsFullResponseApiType';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface IAccordionElementsProps {
    item: Section;
    isStudent?: boolean;
}

export const AccordionElements: FC<IAccordionElementsProps> = ({
    item,
    isStudent = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const [_, setSearchParams] = useSearchParams();
    const locale = useNormalizedLocale();

    return (
        <>
            <tr>
                <td
                    scope="row"
                    className="border-[#E9F0F3] cursor-pointer border-b-[1px] border-t-[1px]  border-x-0 py-3 h-12 !bg-white "
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex gap-2 ">
                        <img
                            src="/icons/arrowAc.svg"
                            className={classNames(
                                'block duration-200',
                                isOpen ? 'rotate-90' : 'rotate-0',
                            )}
                            alt={'arrow'}
                        />{' '}
                        <span className="text-[#364954] text-base font-normal leading-[normal]">
                            {item.section.translations?.find(
                                item => item?.locale === locale,
                            )?.name || item?.section.translations[0]?.name}
                        </span>
                    </div>
                </td>

                <td
                    onClick={() => setIsOpen(!isOpen)}
                    className="border-[#E9F0F3] cursor-pointer border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]"
                >
                    <div className="flex gap-2 items-center">
                        <span className="text-[#364954] text-base font-medium leading-[normal]">
                            {item?.sectionProgress?.totalScore ? (
                                (
                                    (item?.sectionProgress?.score /
                                        item?.sectionProgress.totalScore) *
                                    100
                                ).toFixed() + '%'
                            ) : (
                                <span className="text-[#8CA9BC] text-base font-normal leading-[normal]">
                                    {t('statistics.notStarted')}
                                </span>
                            )}
                        </span>{' '}
                        {item?.sectionProgress?.score ? (
                            <button
                                className="px-2 py-1 cursor-pointer border-[1px] flex items-center text-[#364954] justify-center leading-[normal] text-xs rounded-[10px] border-[#CBDCE7]"
                                onClick={() =>
                                    setSearchParams(
                                        prev =>
                                            new URLSearchParams({
                                                ...Object.fromEntries(prev),
                                                type: 'results',
                                                section_id: String(
                                                    item.section.id,
                                                ),
                                                type_id: '2',
                                            }),
                                    )
                                }
                            >
                                {t('statistics.seeResult')}
                            </button>
                        ) : null}
                    </div>
                </td>

                <td
                    onClick={() => setIsOpen(!isOpen)}
                    className="border-[#E9F0F3] cursor-pointer w-44 border-b-[1px] border-t-[1px] pe-24 border-x-0 ps-0  py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]"
                >
                    <div className="flex gap-6 items-center w-44">
                        <span>{item?.topics?.length} </span>
                        <div className="flex gap-1 w-36">
                            {item.topics?.map((topic: any) => {
                                return (
                                    <span
                                        key={topic.id}
                                        className={classNames(
                                            'w-full h-1  rounded',
                                            topic?.topicProgress?.hasPassed
                                                ? isStudent
                                                    ? 'bg-[#71BD4E]'
                                                    : 'bg-[#55BBEB]'
                                                : 'bg-[#D3D6DD]',
                                        )}
                                    ></span>
                                );
                            })}
                        </div>
                    </div>
                </td>
            </tr>
            {isOpen && (
                <>
                    <tr className="bg-[#E9F0F3]">
                        <th
                            scope="row"
                            className=" border-0 ps-6  text-[#678AA1] bg-[#F5F9FA] text-sm font-medium p-0 leading-[normal]"
                        >
                            <div className="flex items-center ps-7  h-8 py-2 text-sm text-[#678AA1] bg-[#F5F9FA] border-[#E9F0F3] border-b-[1px]  border-t-[0px]  border-x-0 ">
                                {t('statistics.topicsInTheSection')}
                            </div>
                        </th>
                        <td className="border-0  text-[#678AA1] text-sm font-medium p-0 leading-[normal]">
                            <div className="flex items-center  h-8 py-2 text-sm text-[#678AA1] bg-[#F5F9FA] border-[#E9F0F3] border-b-[1px]  border-t-[0px]  border-x-0">
                                {t('statistics.activity')}
                            </div>
                        </td>
                        <td className=" border-0  text-[#678AA1] text-sm font-medium p-0 leading-[normal] ">
                            <div className="flex items-center h-8 py-2 text-sm text-[#678AA1] bg-[#F5F9FA] border-[#E9F0F3] border-b-[1px]  border-t-[0px]  border-x-0">
                                {t('statistics.assessment')}
                            </div>
                        </td>
                    </tr>
                    {item.topics?.map((topic, index: number) => {
                        return (
                            <tr key={topic.id} className="bg-[#E9F0F3]">
                                <td
                                    scope="row"
                                    className="border-[#E9F0F3] px-6 border-t-[0px] border-0 border-x-0 py-4 h-12 !bg-[#F5F9FA] text-[#678AA1] text-sm font-medium leading-[normal]  border-b-[1px]   "
                                >
                                    <span>{index + 1}</span>
                                    <span className="pl-5">
                                        {topic.translations?.find(
                                            item => item.locale === locale,
                                        )?.name || topic?.translations[0]?.name}
                                    </span>
                                </td>

                                <td className="border-[#E9F0F3]   border-0 ps-0 pe-2 py-2 h-12 !bg-[#F5F9FA] text-[#3D5463] text-sm font-medium leading-[normal] border-b-[1px]  border-t-[0px]  border-x-0">
                                    {topic.activityCount > 0 ? (
                                        <div className="flex gap-1 items-center">
                                            <img
                                                src={`/icons/${
                                                    topic?.activityPassed > 0
                                                        ? 'solar_star'
                                                        : 'solar_star-gray'
                                                }.svg`}
                                                alt="solar_star"
                                            />
                                            <span className="text-[#667080] font-normal leading-[normal] text-base">
                                                {topic?.activityPassed}/
                                                {topic?.activityCount}
                                            </span>
                                        </div>
                                    ) : (
                                        <span className="text-[#8CA9BC] text-base font-normal leading-[normal]">
                                            {t('statistics.notStarted')}
                                        </span>
                                    )}
                                </td>
                                <td className="border-[#E9F0F3]   border-0 ps-0 pe-2 py-2 h-12 !bg-[#F5F9FA] text-[#3D5463] text-sm font-medium leading-[normal]  border-b-[1px]  border-t-[0px]  border-x-0">
                                    {topic?.topicProgress?.totalScore ? (
                                        <div className="flex gap-4 items-center">
                                            <div className="flex gap-1 items-center w-16">
                                                <img
                                                    src={`/icons/${
                                                        topic?.activityPassed >
                                                        0
                                                            ? 'activity-lightning-green'
                                                            : 'activity-lightning-gray'
                                                    }.svg`}
                                                    alt="Alt"
                                                />
                                                <span>
                                                    {topic?.topicProgress
                                                        ?.totalScore
                                                        ? (
                                                              (topic
                                                                  .topicProgress
                                                                  .score /
                                                                  topic
                                                                      ?.topicProgress
                                                                      ?.totalScore) *
                                                              100
                                                          ).toFixed() + '%'
                                                        : '-'}
                                                </span>
                                            </div>
                                            <button
                                                onClick={() =>
                                                    setSearchParams(
                                                        prev =>
                                                            new URLSearchParams(
                                                                {
                                                                    ...Object.fromEntries(
                                                                        prev,
                                                                    ),
                                                                    type: 'results',
                                                                    them: String(
                                                                        topic.id,
                                                                    ),
                                                                    type_id:
                                                                        '3',
                                                                    section_id:
                                                                        String(
                                                                            item
                                                                                .section
                                                                                .id,
                                                                        ),
                                                                },
                                                            ),
                                                    )
                                                }
                                                className="border-[#CBDCE7] border-[1px] px-2 py-1 rounded-[10px] text-[#364954] font-medium leading-[normal] text-xs"
                                            >
                                                {t('statistics.watch')}
                                            </button>

                                            <img
                                                src={`/icons/${
                                                    topic?.topicProgress
                                                        ?.hasPassed
                                                        ? 'checkedBlue.svg'
                                                        : 'checkedGray.svg'
                                                }`}
                                                alt="checked"
                                            />
                                        </div>
                                    ) : (
                                        <span className="text-[#8CA9BC] text-base font-normal leading-[normal]">
                                            {t('statistics.notStarted')}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </>
            )}
        </>
    );
};
