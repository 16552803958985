import React, { Dispatch, SetStateAction, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-scroll';

type Props = {
    activity: string;
    isCorrect: boolean;
    handleSetActive: Dispatch<SetStateAction<any>>;
    closeSectionMobile?: () => void;
};

const ActivityStar = ({
    activity,
    isCorrect = false,
    handleSetActive,
    closeSectionMobile,
}: Props) => {
    return (
        <Link
            activeClass="active"
            to={activity}
            spy={true}
            smooth={true}
            offset={-250}
            duration={300}
        >
            <img
                data-tooltip-id="activity"
                src={`/icons/solar_star${isCorrect ? '' : '-gray'}.svg`}
                alt="solar_star"
                onClick={() => {
                    handleSetActive(activity);
                    closeSectionMobile && closeSectionMobile();
                }}
            />
        </Link>
    );
};

export default ActivityStar;
