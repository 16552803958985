import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetProgressByAssessmentQuery } from '../../api/beyimProgressDashboard';
import { SelectBeyim } from '../../ui/SelectBeyim/SelectBeyim';
import { ResultElement } from './utils/ResultElement';
import { Progress } from '../../api/beyimProgressDashboard/types/getProgressByAssessmentResponseApiType';
import { useGetProgressByAssessmentDetailedQuery } from '../../api/beyimProgressDashboard/beyimProgressDashboardApi';
import { useGetAllSectionsQuery } from '../../api';
import { SelectComponent } from './utils/SelectMultiple';
import { useNormalizedLocale } from '../../hooks';
import { SkeletonDetailAssessmentResults } from './utils/SkeletonDetailAssessmentResults';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

export const formatDate = (date: Date) =>
    `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
type Option = {
    value: number;
    label: string;
};
interface ICurrenProgress extends Progress {
    value: number;
    label: string;
}

interface IDetailAssessmentResults {
    isStudent?: boolean;
}
export const DetailAssessmentResults: FC<IDetailAssessmentResults> = ({
    isStudent = false,
}) => {
    const { t } = useTranslation();
    const locale = useNormalizedLocale();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentProgressId, setCurrentProgressId] =
        React.useState<ICurrenProgress | null>();
    const [sectionId, setSectionId] = React.useState<number | undefined>();
    const [typeAssessment, setTypeAssessment] = React.useState<
        number | undefined
    >(undefined);

    const { data: sections } = useGetAllSectionsQuery(
        {
            subject_id: Number(searchParams.get('subject_id')) || 0,
            type_id: 2,
        },
        { skip: !searchParams.get('section_id') },
    );

    const {
        data: assessmentData,
        isFetching: isFetchingAssessmentData,
        isLoading: isLoadingAssessmentData,
    } = useGetProgressByAssessmentQuery(
        {
            sectionId: String(sectionId),
            userId: searchParams.get('user_id') || '',
        },
        {
            skip: !sectionId || !searchParams.get('user_id'),
        },
    );

    const {
        data: detailedData,
        isLoading: isLoadingDetailedData,
        isFetching: isFetchingDetailedData,
        isError: isErrorDetailedData,
    } = useGetProgressByAssessmentDetailedQuery(
        {
            progressId: currentProgressId?.value.toString() || '',
        },
        {
            skip: !currentProgressId,
        },
    );
    const { data: sectionInfoWithChildren } = useGetAllSectionsQuery(
        {
            parent_id: Number(searchParams.get('section_id')) || 0,
            subject_id: Number(searchParams.get('subject_id')) || 0,
        },
        { skip: !searchParams.get('section_id') },
    );

    const filteredOptions = assessmentData?.data.data.progresses?.filter(
        item => item.score !== null,
    );

    useEffect(() => {
        filteredOptions
            ? setCurrentProgressId({
                  ...(filteredOptions[0] as Progress),
                  value: filteredOptions[0].progressId || 0,
                  label: `${1} - ${formatDate(
                      new Date(filteredOptions[0].date),
                  )}`,
              })
            : setCurrentProgressId(null);
    }, [assessmentData, sectionId]);

    const initialOptions: Option[] = [
        { value: 3, label: `${t('dashboardResults.topicAssessment')}` },
        { value: 2, label: `${t('dashboardResults.sectionAssessment')}` },
    ];

    return (
        <>
            <div
                className={classNames(
                    'bg-white px-6 py-4',
                    !isStudent && 'rounded-tr-2xl rounded-tl-2xl',
                )}
            >
                <button
                    className="w-fit flex bg-white mb-6 items-center rounded-lg gap-1 text-xs text-[#577487]"
                    onClick={() =>
                        setSearchParams(
                            new URLSearchParams({
                                ...Object.fromEntries(searchParams),
                                type: 'info',
                            }),
                        )
                    }
                >
                    <img src="/icons/back1.svg" alt={'back'} />
                    {t('statistics.back')}
                </button>
                <div>
                    <div className="flex gap-2 flex-wrap w-full">
                        <div className="w-full md:w-[280px]">
                            <SelectComponent
                                initialOptions={initialOptions}
                                setTypeAssessment={setTypeAssessment}
                                setSectionId={setSectionId}
                                additionalOptions={
                                    typeAssessment === 2
                                        ? sections?.data.sections?.map(
                                              section => ({
                                                  label:
                                                      section.translations.find(
                                                          translation =>
                                                              translation.locale ===
                                                              locale,
                                                      )?.name ||
                                                      section.translations[0]
                                                          .name,
                                                  value: section.id,
                                              }),
                                          ) || []
                                        : sectionInfoWithChildren?.data.sections?.map(
                                              section => ({
                                                  label:
                                                      section.translations.find(
                                                          translation =>
                                                              translation.locale ===
                                                              locale,
                                                      )?.name ||
                                                      section.translations[0]
                                                          .name,
                                                  value: section.id,
                                              }),
                                          ) || []
                                }
                            />
                        </div>

                        <div className="w-full md:w-[210px]">
                            <SelectBeyim
                                className=" md:w-[210px]"
                                size="xs"
                                value={currentProgressId}
                                placeholder={
                                    t('dashboardResults.attempt') + ': '
                                }
                                isPrefix={true}
                                options={
                                    filteredOptions?.map((item, index) => ({
                                        label: `${index + 1} - ${formatDate(
                                            new Date(item.date),
                                        )}`,
                                        value: item.progressId,
                                        ...item,
                                    })) || []
                                }
                                onChange={value => setCurrentProgressId(value)}
                            />
                        </div>
                        {isLoadingDetailedData || isFetchingDetailedData ? (
                            <Skeleton
                                baseColor="#e9f0f3"
                                width={300}
                                height={27}
                                borderRadius={12}
                            />
                        ) : (
                            assessmentData?.data.data.progresses &&
                            detailedData && (
                                <div className="flex gap-2 items-center md:px-2">
                                    <img
                                        src="/icons/activity-lightning-green.svg"
                                        alt="assessment"
                                    />
                                    <div className=" whitespace-nowrap">
                                        <span className="text-[#678AA1] text-sm font-normal leading-[22px]">
                                            {t('dashboardResults.scored')}:
                                        </span>{' '}
                                        <b>
                                            {(
                                                (detailedData?.data.data.score /
                                                    detailedData.data.data
                                                        .totalScore) *
                                                100
                                            ).toFixed() + '%'}
                                        </b>{' '}
                                        <span className="text-[#364954] text-sm font-normal leading-[22px] hidden md:inline-block">
                                            (
                                            {t(
                                                'dashboardResults.rightAnswers',
                                                {
                                                    scoredCount:
                                                        detailedData?.data.data
                                                            .score,
                                                    totalCount:
                                                        detailedData?.data.data
                                                            .totalScore,
                                                },
                                            )}
                                            )
                                        </span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-white  rounded-b-2xl max-w-full overflow-auto mb-16 ">
                {isLoadingDetailedData || isFetchingDetailedData ? (
                    <SkeletonDetailAssessmentResults />
                ) : (
                    assessmentData?.data.data.progresses &&
                    detailedData &&
                    detailedData?.data.data.questions?.map((item, index) => {
                        return (
                            <React.Fragment key={item.question.id}>
                                <ResultElement item={item} index={index} />
                            </React.Fragment>
                        );
                    })
                )}
            </div>
        </>
    );
};
