class User {
    static X_API_USERNAME = 'admin@localhost.com';
    static X_API_PASSWD = 'fAWnRBrDXhvM7J6u';

    constructor(
        public id: string,
        public email: string,
    ) {}

    static getToken(): string | undefined {
        const idToken =
            document.cookie
                .split('; ')
                .find(c => c.includes('idToken'))
                ?.split('=') ?? [];
        if (idToken.length != 2) return;

        return idToken[1];
    }

    static getUser(): User | undefined {
        const idToken = User.getToken();

        if (!idToken) return;

        const data: { 'cognito:username': string; email: string } = JSON.parse(
            atob(idToken.split('.')[1]),
        );

        return new User(data['cognito:username'], data['email']);
    }
}

interface XAPIBaseArg {
    lrs_endpoint: string;
    local_url: string;
    content_name: string;
    content_type: string;
    language: string;
    verb: string;
    subject: string;
    section: string;
    topic: string;
}

interface XAPIActivityArg extends XAPIBaseArg {
    type: 'activity';
    hintCount: number | undefined;
    hintMax: number | undefined;
    response: string | number | string[] | number[] | undefined;
    subject: string;
    section: string;
    topic: string;
    isResponseCorrect: boolean | undefined;
    result_completion: boolean;
}

interface XAPIAssessmentArg extends XAPIBaseArg {
    type: 'assessment';
    duration: string;
    score_raw: number | undefined;
    score_min: number | undefined;
    score_max: number | undefined;
    subject: string;
    section: string;
    topic: string;
    isResponseCorrect: boolean | undefined;
    result_completion: boolean;
}

interface XAPIQuestionArg extends XAPIBaseArg {
    type: 'question';
    response: string | number | string[] | number[] | undefined;
    parent_id: string;
    parent_contentType: string;
    subject: string;
    section: string;
    topic: string;
    isResponseCorrect: boolean | undefined;
    result_completion: boolean;
}
interface XAPIFilterArg extends XAPIBaseArg {
    type: 'filter';
}
interface XAPIVideoArg extends XAPIBaseArg {
    type: 'video';
}

type XAPIArg =
    | XAPIActivityArg
    | XAPIAssessmentArg
    | XAPIQuestionArg
    | XAPIFilterArg
    | XAPIVideoArg;

export class XAPIActivity {
    constructor(arg: XAPIArg) {
        if (arg?.lrs_endpoint?.endsWith('/')) {
            this.conf_endpoint = arg.lrs_endpoint;
        } else {
            this.conf_endpoint = arg.lrs_endpoint + '/';
        }

        this.object_id = arg.local_url;
        this.definition_name = arg.content_name;
        this.context_language = arg.language;
        this.verb = arg.verb;
        this.conf_auth =
            'Basic ' + btoa(`${'admin@localhost.com'}:${'fAWnRBrDXhvM7J6u'}`);
        this.x_api_key = `Bearer ${User.getToken()}`;
        this.objectType = 'Activity'; //non-custom
        this.user_id = 'aaaaaakjfrnlkjds';
        this.user_email = 'example@example.com';
        this.context_extensions_subject = arg.subject;
        this.context_extensions_section = arg.section;
        this.context_extensions_topic = arg.topic;
        this.definition_type = arg.content_type;

        if (arg.type === 'activity') {
            this.context_extensions_hintsOpened = arg.hintCount;
            this.context_extensions_hintsMax = arg.hintMax;
            this.result_response = arg.response;
            this.result_success = arg.isResponseCorrect;
            this.definition_type = arg.content_type;
        } else if (arg.type === 'assessment') {
            this.duration = arg.duration;
            this.score_raw = arg.score_raw; // Raw score
            this.score_min = arg.score_min; // Minimum score
            this.score_max = arg.score_max; // Maximum score
            this.result_completion = arg.result_completion;
            this.result_success = arg.isResponseCorrect;
            this.definition_type = arg.content_type;
        } else if (arg.type === 'question') {
            this.result_response = arg.response;
            this.parent_id = arg.parent_id;
            this.parent_contentType = arg.parent_contentType;
            this.result_completion = arg.result_completion;
            this.result_success = arg.isResponseCorrect;
            this.definition_type = arg.content_type;
        } else if (arg.type === 'filter') {
            this.definition_type = arg.content_type;
        }
    }

    private conf_auth: string;
    private x_api_key: string;
    private objectType: string;
    private user_id: string;
    private user_email: string;
    private verb: string;

    private conf_endpoint: string;
    private object_id: string;
    private definition_name: string;
    private definition_type: string;
    private context_language: string;
    private result_response: string | number | string[] | number[] | undefined;
    private result_success: boolean | undefined;
    private context_extensions_subject: string;
    private context_extensions_section: string;
    private context_extensions_topic: string;

    // activity specific
    private context_extensions_hintsOpened: number | undefined;
    private context_extensions_hintsMax: number | undefined;

    // assessment specific
    private duration: string | undefined;
    private score_raw: number | undefined; // Raw score
    private score_min: number | undefined; // Minimum score
    private score_max: number | undefined; // Maximum score
    private result_completion: boolean | undefined;

    //question specific
    private parent_id: string | undefined;
    private parent_contentType: string | undefined;

    sendStatment() {
        window.ADL.XAPIWrapper.changeConfig({
            endpoint: this.conf_endpoint,
            auth: this.conf_auth,
            'X-API-KEY': this.x_api_key,
        });

        const statement: any = {
            actor: {
                name: this.user_id,
                mbox: 'mailto:' + this.user_email,
            },
            verb: {
                id: 'http://adlnet.gov/expapi/verbs/' + this.verb,
                display: { 'en-US': this.verb },
            },
            object: {
                id: this.object_id,
                objectType: this.objectType,
                definition: {
                    name: { ru: this.definition_name },
                    type: this.definition_type,
                },
            },
            result: {
                response: this.result_response,
                success: this.result_success,
                completion: this.result_completion,
            },
        };

        if (
            this.score_raw !== undefined ||
            this.score_min !== undefined ||
            this.score_max !== undefined
        ) {
            statement.result.score = {
                raw: this.score_raw,
                min: this.score_min,
                max: this.score_max,
            };
        }

        if (this.duration !== undefined) {
            statement.result.duration = this.duration;
        }

        if (this.context_language) {
            statement.context = {
                language: this.context_language,
            };

            if (this.parent_id || this.parent_contentType) {
                statement.context.contextActivities = {
                    parent: [
                        {
                            id: this.parent_id,
                            objectType: 'Activity',
                            definition: {
                                type:
                                    'http://adlnet.gov/expapi/activities/' +
                                    this.parent_contentType,
                            },
                        },
                    ],
                };
            }
            if (
                this.context_extensions_subject ||
                this.context_extensions_section ||
                this.context_extensions_topic ||
                this.context_extensions_hintsOpened ||
                this.context_extensions_hintsMax ||
                this.parent_id ||
                this.parent_contentType
            ) {
                statement.context.extensions = {
                    extensions: {
                        [window.location.host + '/subject']:
                            this.context_extensions_subject,
                        [window.location.host + '/section']:
                            this.context_extensions_section,
                        [window.location.host + '/topic']:
                            this.context_extensions_topic,
                        [window.location.host + '/hintsOpened']:
                            this.context_extensions_hintsOpened,
                        [window.location.host + '/hintsMax']:
                            this.context_extensions_hintsMax,
                    },
                };
            }
        }

        console.dir(statement);
        window.ADL.XAPIWrapper.sendStatement(statement);
    }
}
