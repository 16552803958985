import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    content: null,
};

const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        openModal: state => {
            state.isOpen = true;
        },

        setContent: (state, action) => {
            state.content = action.payload;
        },

        closeModal: state => {
            state.isOpen = false;
            state.content = null;
        },
    },
});

export const { openModal, closeModal, setContent } = modalSlice.actions;

export default modalSlice.reducer;
