import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '../BaseCard';
import { useGetStatisticsQuery } from '../../../../api/beyimProgress/beyimProgressApi';

interface IAssessmentProgress {
    classId: string;
    subjectId: string;
    quarter: string;
}

export const AssessmentProgress: React.FC<IAssessmentProgress> = ({
    classId,
    subjectId,
    quarter,
}) => {
    const { t } = useTranslation();

    const {
        data: statistics,
        isLoading: isLoadingStatistics,
        isFetching: isFetchingStatistics,
    } = useGetStatisticsQuery(
        {
            classId,
            subjectId,
            quarter,
            assessmentRequired: true,
        },
        {
            skip: !classId || !subjectId || !quarter,
        },
    );

    const passedAsessment =
        statistics?.data.data?.widgetStatistics.assessment_progress
            ?.passed_assessment;
    const totalAssessment =
        statistics?.data.data?.widgetStatistics.assessment_progress
            ?.total_assessment;

    const assessmentProgressPercentage =
        passedAsessment &&
        totalAssessment &&
        ((passedAsessment / totalAssessment) * 100).toFixed();

    return (
        <BaseCard
            firstBlock={
                <span className="rounded-full w-14 h-14 flex items-center justify-center shrink-0 bg-[#ECFCE4]">
                    <img src="/icons/iconamoon_lightning-2-fill.svg" alt="" />
                </span>
            }
            title={
                classId && subjectId && quarter && assessmentProgressPercentage
                    ? assessmentProgressPercentage + '%'
                    : '-'
            }
            subTitle={t('dashboard.assessmentProgress')}
            isLoading={isLoadingStatistics || isFetchingStatistics}
        />
    );
};
