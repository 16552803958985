import classNames from 'classnames';
import style from './Option.module.scss';
import { useNormalizedLocale } from '../../../../hooks';

interface IOption {
    name?: string;
    isActive?: boolean;
    imageSrc?: string;
    handleClick?: () => void;
    them?: string;
}

export const Option: React.FC<IOption> = ({
    isActive = false,
    name,
    imageSrc,
    handleClick,
    them,
    ...props
}) => {
    const locale = useNormalizedLocale();
    return (
        <li
            {...props}
            className={classNames(
                isActive ? style.active : '',
                'rounded-2xl block duration-200',
                style.wrapper,
            )}
        >
            <button
                className={classNames(
                    'px-4 flex items-center min-h-[42px]  w-full',
                )}
                type="button"
                onClick={() => {
                    if (handleClick) {
                        handleClick();
                    }
                }}
            >
                {imageSrc && (
                    <img
                        src={imageSrc}
                        alt="option"
                        className="rounded-full bock me-2 block w-6 h-6"
                    />
                )}

                <span
                    className={classNames(
                        style.text,
                        'text-sm text-ellipsis text-left  overflow-hidden py-2 me-2',
                    )}
                >
                    {name}
                </span>
                {!them && (
                    <img
                        src={!them ? '/icons/arrowRight.svg' : ''}
                        className={classNames(
                            'ms-auto ',
                            locale === 'ar' ? 'rotate-180' : '',
                        )}
                        alt="arrowRight"
                    />
                )}
            </button>
        </li>
    );
};
