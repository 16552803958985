import classNames from 'classnames';
import style from './MobileFilter.module.scss';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useGetAllSubjectsQuery } from '../../../../api';
import { useNormalizedLocale } from '../../../../hooks';

interface IMobileFilter {
    setOpenType: React.Dispatch<React.SetStateAction<boolean>>;
    setIsActivity: React.Dispatch<React.SetStateAction<boolean>>;
    isActivity: boolean;
    setIsVideo: React.Dispatch<React.SetStateAction<boolean>>;
    setIsImage: React.Dispatch<React.SetStateAction<boolean>>;
    setSubjectFilter: React.Dispatch<React.SetStateAction<number[]>>;
    setOpenSubject: React.Dispatch<React.SetStateAction<boolean>>;
    openSubject: boolean;
    isVideo: boolean;
    isImage: boolean;
    subjectFilter: number[];
}

export const MobileFilter: React.FC<IMobileFilter> = ({
    isActivity,
    setIsActivity,
    setIsVideo,
    setSubjectFilter,
    setIsImage,
    setOpenSubject,
    isVideo,
    isImage,
    subjectFilter,
}) => {
    const { t } = useTranslation();
    const { data: subjects } = useGetAllSubjectsQuery({ limit: 100 });

    const locale = useNormalizedLocale();

    return (
        <aside className=" flex flex-col gap-4">
            <div className={classNames(style.filter, 'rounded-2xl px-4 py-5')}>
                <div className={classNames(' flex flex-col')}>
                    <h2 className=" text-base font-bold leading-[20.8px] text-[#364954] mb-5">
                        {t('favorite.filter')}
                    </h2>
                    <button className=" w-full flex justify-between items-center">
                        <span
                            className={classNames(
                                style.openButton,
                                'text-sm font-medium',
                            )}
                        >
                            {t('favorite.type')}
                        </span>
                    </button>

                    <div
                        className={classNames(
                            'overflow-hidden duration-200 flex flex-col mb-5',
                            style.typeFilter,
                        )}
                    >
                        <input
                            type="checkbox"
                            onChange={e => {
                                const value = e.target.checked;
                                setIsActivity(value);
                            }}
                            className="hidden"
                            id="activityFilterMobile"
                            checked={isActivity}
                        />

                        <label
                            htmlFor="activityFilterMobile"
                            className={classNames(
                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                            )}
                        >
                            {t('favorite.activity')}
                            <span
                                className={classNames(
                                    ' block w-6 h-6 border-2 rounded bg-center',
                                )}
                            />
                        </label>
                        <hr
                            className={classNames(
                                'mt-2 mb-2 h-[1px]',
                                style.divide,
                            )}
                        />
                        <input
                            className="hidden"
                            type="checkbox"
                            id="videoFilterMobile"
                            onChange={e => {
                                const value = e.target.checked;

                                setIsVideo(value);
                            }}
                            checked={isVideo}
                        />
                        <label
                            htmlFor="videoFilterMobile"
                            className={classNames(
                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                            )}
                        >
                            {t('favorite.video')}
                            <span
                                className={classNames(
                                    ' block w-6 h-6 border-2 rounded bg-center',
                                )}
                            />
                        </label>
                        <hr
                            className={classNames(
                                'mt-2 mb-2 h-[1px]',
                                style.divide,
                            )}
                        />
                        <input
                            className="hidden"
                            type="checkbox"
                            id="imageFilterMobile"
                            onChange={e => {
                                const value = e.target.checked;

                                setIsImage(value);
                            }}
                            checked={isImage}
                        />
                        <label
                            htmlFor="imageFilterMobile"
                            className={classNames(
                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                            )}
                        >
                            {t('favorite.image')}
                            <span
                                className={classNames(
                                    ' block w-6 h-6 border-2 rounded bg-center',
                                )}
                            />
                        </label>
                        <hr
                            className={classNames(
                                'mt-2 mb-2 h-[1px]',
                                style.divide,
                            )}
                        />
                    </div>
                </div>

                <div>
                    <button
                        onClick={() => {
                            setOpenSubject(prev => !prev);
                        }}
                        className=" w-full flex justify-between items-center"
                    >
                        <span
                            className={classNames(
                                style.openButton,
                                'text-sm font-medium',
                            )}
                        >
                            {t('favorite.subject')}
                        </span>
                    </button>

                    <div
                        className={classNames(
                            'overflow-hidden duration-200 flex flex-col',
                            style.typeFilter,
                        )}
                    >
                        {subjects &&
                            subjects.data.subjects.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        <input
                                            type="checkbox"
                                            id={
                                                (String(item.id) ||
                                                    String(index)) +
                                                'subjectFavoriteMobile'
                                            }
                                            className="hidden"
                                            onChange={e => {
                                                const value = e.target.checked;

                                                if (value) {
                                                    setSubjectFilter(prev => {
                                                        const oldData = [
                                                            ...prev,
                                                        ];

                                                        oldData.push(item.id);

                                                        return oldData;
                                                    });
                                                } else {
                                                    setSubjectFilter(prev => {
                                                        const oldData = [
                                                            ...prev,
                                                        ];

                                                        const index =
                                                            oldData.indexOf(
                                                                item.id,
                                                            );
                                                        if (index !== -1) {
                                                            oldData.splice(
                                                                index,
                                                                1,
                                                            );
                                                        }

                                                        return oldData;
                                                    });
                                                }
                                            }}
                                            checked={subjectFilter.includes(
                                                item.id,
                                            )}
                                        />
                                        <label
                                            htmlFor={
                                                (String(item.id) ||
                                                    String(index)) +
                                                'subjectFavoriteMobile'
                                            }
                                            className={classNames(
                                                'cursor-pointer text-sm font-medium h-[41px] flex justify-between items-center',
                                            )}
                                        >
                                            {
                                                item.translations.find(
                                                    translation =>
                                                        translation.locale ===
                                                        locale,
                                                )?.name
                                            }
                                            <span
                                                className={classNames(
                                                    ' block w-6 h-6 border-2 rounded bg-center',
                                                )}
                                            />
                                        </label>
                                        {index !==
                                            subjects.data.subjects.length -
                                                1 && (
                                            <hr
                                                className={classNames(
                                                    'mt-2 mb-2 h-[1px]',
                                                    style.divide,
                                                )}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>
            </div>
        </aside>
    );
};
