interface ITitleCabinert {
    h1?: string;
    subTitle?: string;
}
export const TitleCabinert: React.FC<ITitleCabinert> = ({
    h1 = '',
    subTitle = '',
}) => {
    return (
        <header className="">
            <h1 className="text-[#364954]  font-medium text-2xl leading-[normal] mb-2">{h1}</h1>
            <span className="text-[#678AA1] text-base">{subTitle}</span>
        </header>
    );
};
