import { useTranslation } from 'react-i18next';
import { BaseCard } from '../BaseCard';
import { useGetStatisticsQuery } from '../../../../api/beyimProgress/beyimProgressApi';

interface IActivityProgress {
    classId: string;
    subjectId: string;
    quarter: string;
}

export const ActivityProgress: React.FC<IActivityProgress> = ({
    classId,
    subjectId,
    quarter,
}) => {
    const { t } = useTranslation();
    const {
        data: statistics,
        isLoading: isLoadingStatistics,
        isFetching: isFetchingStatistics,
    } = useGetStatisticsQuery(
        {
            classId,
            subjectId,
            quarter: quarter,
            activityRequired: true,
        },
        {
            skip: !classId || !subjectId || !quarter,
        },
    );

    const passedActivity =
        statistics?.data.data?.widgetStatistics.activity_progress
            ?.correct_count;
    const totalActivity =
        statistics?.data.data?.widgetStatistics.activity_progress?.total_count;
    const activityProgressPercentage =
        passedActivity &&
        totalActivity &&
        ((passedActivity / totalActivity) * 100).toFixed();

    return (
        <BaseCard
            firstBlock={<img src="/icons/solar_star-bold2.svg" alt="" />}
            title={
                statistics &&
                classId &&
                subjectId &&
                quarter &&
                activityProgressPercentage
                    ? activityProgressPercentage + '%'
                    : '-'
            }
            subTitle={t('dashboard.activityProgress')}
            isLoading={isLoadingStatistics || isFetchingStatistics}
        />
    );
};
