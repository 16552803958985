import { useTranslation } from 'react-i18next';
import { ChangingLanguage } from '../../../../features/ChangingLanguage';
import { Menu } from '../../../../features/Menu';
import classNames from 'classnames';
import { useState } from 'react';
import ReactModal from 'react-modal';

export const MobileHeader: React.FC = () => {
    const { t } = useTranslation();
    const [isOpenMenu, setIsOpen] = useState<boolean>(false);
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
    return (
        <>
            <div className="">
                <header className="h-14 flex bg-white py-3 px-4 gap-1 items-center fixed top-0 z-10 w-full">
                    <button
                        className="w-8 h-8 flex items-center justify-center "
                        onClick={() => {
                            setIsOpen(prev => !prev);
                        }}
                    >
                        <img src="/icons/menu-line-horizontal.svg" />
                    </button>
                    <img src="/icons/beyimMobile.svg" className="w-24" alt="" />
                    <div className="flex ms-auto gap-1 items-center">
                        <ChangingLanguage onlyDesktop={true} />
                        {/* <button className="w-9 h-8 flex items-center justify-center bg-[#E9F0F3] rounded-lg">
                            <img src="/icons/filter1.svg" />
                        </button> */}
                    </div>
                </header>
                <div
                    className={classNames(
                        isOpenMenu ? 'w-60 opacity-100' : 'w-0 opacity-0',
                        ' duration-300 fixed top-14 z-10 overflow-auto',
                    )}
                >
                    <div className="max-h-[calc(100vh_-_56px)] overflow-x-hidden overflow-y-auto">
                        <Menu />
                    </div>
                </div>
                {/* <div className='fixed bottom-0 z-10 w-full h-[70vh] bg-white'>
                    <div className=" h-full w-fit overflow-auto">
                        <div className="px-3 ">
                            <span className="text-[#678AA1]  me-2 inline-block">
                                {t('search.filter.subjects')}:
                            </span>

                            <select className="text-[#364954] px-2 py-1.5 border-[#E4F3FB] border-[1px] border-solid rounded-lg active:border-[#55BBEB] focus:border-[#55BBEB] duration-100">
                                <option value="value1">Математика</option>
                                <option value="value1">Физика</option>
                            </select>
                        </div>
                        <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                        <div className="px-3 flex flex-nowrap items-center">
                            <span className="me-2 text-[#678AA1] inline-block">
                                {t('dashboard.class')}:
                            </span>
                            
                        </div>
                        <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                        <div className="px-3 flex flex-nowrap items-center">
                            <span className="me-2 text-[#678AA1] inline-block">
                                {t('dashboard.letter')}
                            </span>
                            <select className="text-[#364954] px-2  py-1.5 border-[#E4F3FB] border-[1px] border-solid rounded-lg active:border-[#55BBEB] focus:border-[#55BBEB] duration-100">
                                <option value="value1">А</option>
                                <option value="value1">Б</option>
                            </select>
                        </div>
                        <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                        <div className="px-3 flex flex-nowrap items-center">
                            <span className="me-2 text-[#678AA1] inline-block">
                                {t('dashboard.quarter')}
                            </span>
                            <select className="text-[#364954] px-2 py-1.5 border-[#E4F3FB] border-[1px] border-solid rounded-lg active:border-[#55BBEB] focus:border-[#55BBEB] duration-100">
                                <option value="value1">1</option>
                                <option value="value1">2</option>
                                <option value="value1">3</option>
                                <option value="value1">4</option>
                            </select>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};
