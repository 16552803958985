import { useTranslation } from 'react-i18next';
import { SelectBeyim } from '../../../../ui/SelectBeyim/SelectBeyim';
import { useGetUserDataQuery } from '../../../../api/authService/authServiceApi';
import { useGetAllSubjectsQuery } from '../../../../api';
import React, { useEffect, useState } from 'react';
import { Class, Organization } from '../../../../types/getUserDataApiType';
import { useNormalizedLocale } from '../../../../hooks';
import {
    useGetAllClassesQuery,
    useGetAllGradesQuery,
    useLazyGetClassByIdQuery,
} from '../../../../api/organizationService/organizationApiType';
import { roles } from '../../../../features/AuthForms/constants';
import { useSearchParams } from 'react-router-dom';
import { ModalComponent } from '../../../../ui/ModalComponent';
import { AssessmentFooterButton } from '../../../../features/OnboardingComponents/AssessmentStartingBlock/AssessmentFooterButton/AssessmentFooterButton';
import style from '../../../assessment/results/Results.module.scss';
import { AddClassToTeacher } from '../../../../features/AddClassToTeacher';

interface IFilter {
    setStateFilter: React.Dispatch<
        React.SetStateAction<{
            subject_id: string;
            class_id: string;
            quarter: string;
        }>
    >;
}

function getUniqueClassNumbers(arr: Class[]) {
    const classNumbers = arr.map(item => item.class.grade.number);
    return classNumbers.filter(
        (number: number, index) => classNumbers.indexOf(number) === index,
    );
}

export const FilterCabinet2: React.FC<IFilter> = ({ setStateFilter }) => {
    const { t } = useTranslation();
    const { data } = useGetUserDataQuery('');
    const { data: grades } = useGetAllGradesQuery({
        limit: 100,
        numberFrom: 7,
    });
    const { data: subjects } = useGetAllSubjectsQuery({ limit: 100 });
    const locale = useNormalizedLocale();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentOrganization, setCurrentOrganization] =
        useState<Organization | null>(null);
    const [role, setRole] = useState<{ value: string; label: string } | null>(
        null,
    );
    const [currentSchool, setCurrentSchool] = useState<{
        value: number;
        label: string;
    } | null>(null);
    const [currentSubject, setCurrentSubject] = useState<{
        value: number | undefined;
        label: string;
    } | null>(null);
    const [currentGradeId, setCurrentGradeId] = useState<{
        value: number | undefined;
        label: string | number | undefined;
    } | null>(null);
    const [currentClassId, setCurrentClassId] = useState<{
        value: number | undefined;
        label: string | undefined;
    } | null>(null);
    const [currentQuarter, setCurrentQuarter] = useState<{
        value: number | null;
        label: number | null;
    } | null>({ value: 1, label: 1 });

    const [addClassModal, setAddClassModal] = useState(false);

    const handleChangeModalState = () => {
        setAddClassModal(prevState => !prevState);
    };

    useEffect(() => {
        if (data) {
            const roleValue =
                data.data.User.organizations?.find(
                    org =>
                        (org.role === roles.CLASS_TEACHER ||
                            org.role === roles.SUBJECT_TEACHER) &&
                        org?.schools,
                )?.role || '';

            setRole({
                value: roleValue,
                label:
                    roleValue === roles.CLASS_TEACHER
                        ? t('role.class_teacher')
                        : t('role.subject_teacher'),
            });
        }
    }, [data, locale]);

    useEffect(() => {
        if (data && role) {
            setCurrentOrganization(
                data.data.User.organizations?.find(
                    org => org.role === role.value,
                ) || null,
            );
        }
    }, [data, role]);

    useEffect(() => {
        if (currentOrganization?.schools) {
            const school = currentOrganization?.schools[0];
            setCurrentSchool({
                value: school?.schoolId,
                label:
                    school?.school.translations.find(
                        (lng: any) => lng.locale === locale,
                    )?.shortName || school.school.translations[0]?.shortName,
            });
        }
    }, [currentOrganization, locale]);

    useEffect(() => {
        if (
            role &&
            currentOrganization?.schools &&
            currentSchool?.value &&
            subjects
        ) {
            if (role.value === roles.SUBJECT_TEACHER) {
                const school = currentOrganization.schools.find(
                    school => school.schoolId === currentSchool.value,
                );
                const subject = school?.subjects?.[0]?.subject;
                // @ts-ignore
                setCurrentSubject({
                    value: school?.subjects?.[0]?.subjectId,
                    label:
                        subject?.translations?.find(
                            (lng: any) => lng.locale === locale,
                        )?.name ||
                        subject?.translations[0]?.name ||
                        '',
                });
            } else {
                const subject = subjects.data.subjects[0];
                setCurrentSubject({
                    value: subject.id,
                    label:
                        subject?.translations.find(
                            (lng: any) => lng.locale === locale,
                        )?.name || subject.translations[0].name,
                });
            }
        }
    }, [currentSchool, role, locale, subjects, currentOrganization]);

    useEffect(() => {
        if (
            role &&
            grades &&
            currentOrganization &&
            currentSchool &&
            !searchParams.get('class_id')
        ) {
            if (role.value === roles.SUBJECT_TEACHER) {
                setCurrentGradeId({
                    value: grades.data.grades[0].id,
                    label: grades.data.grades[0].number,
                });
            } else {
                const school = currentOrganization?.schools?.find(
                    school => school.schoolId === currentSchool.value,
                );
                const grade = school?.classes?.[0]?.class.grade;

                setCurrentGradeId({
                    value: school?.classes?.[0]?.class.grade.number,
                    label: grade?.number,
                });
            }
        }
    }, [grades, role, currentOrganization, currentSchool, locale]);

    const { data: classes } = useGetAllClassesQuery(
        {
            schoolId: currentSchool?.value || 0,
            gradeId: currentGradeId?.value || 0,
        },
        {
            skip: !currentSchool || !currentGradeId,
        },
    );

    const classesArray = getUniqueClassNumbers(
        currentOrganization?.schools?.find(
            school => school.schoolId === currentSchool?.value,
        )?.classes || [],
    );

    useEffect(() => {
        if (
            role &&
            currentOrganization &&
            currentSchool &&
            currentGradeId &&
            classes &&
            !searchParams.get('class_id')
        ) {
            if (role.value === roles.SUBJECT_TEACHER) {
                setCurrentClassId({
                    value: classes.data.classes[0]?.id,
                    label: classes.data.classes[0]?.letter,
                });
            } else {
                const school = currentOrganization?.schools?.find(
                    school => school.schoolId === currentSchool.value,
                );
                const cls = school?.classes?.find(
                    cls => cls.class.grade.number === currentGradeId.value,
                );

                setCurrentClassId({
                    value: cls?.classId,
                    label: cls?.class.letter,
                });
            }
        }
    }, [currentSchool, currentGradeId, classes, role, currentOrganization]);

    useEffect(() => {
        if (currentSubject && currentClassId && currentQuarter) {
            setStateFilter({
                subject_id: currentSubject.value?.toString() || '',
                class_id: currentClassId.value?.toString() || '',
                quarter: currentQuarter.value?.toString() || '',
            });
        }
    }, [currentSubject, currentClassId, currentQuarter, setStateFilter]);
    const [getClassById] = useLazyGetClassByIdQuery();
    useEffect(() => {
        if (searchParams.get('subject_id')) {
            setCurrentSubject({
                value: Number(searchParams.get('subject_id')),
                label:
                    subjects?.data.subjects
                        .find(
                            subject =>
                                subject.id ===
                                Number(searchParams.get('subject_id')),
                        )
                        ?.translations.find((lng: any) => lng.locale === locale)
                        ?.name || '',
            });
        }
    }, [searchParams.get('subject_id')]);
    useEffect(() => {
        if (searchParams.get('class_id')) {
            getClassById({ classId: searchParams.get('class_id') || '' })
                .unwrap()
                .then(data => {
                    setCurrentGradeId({
                        value: data.data.class.gradeId,
                        label: data.data.class.grade.number,
                    });
                    setCurrentClassId({
                        value: data.data.class.id,
                        label: data.data.class.letter,
                    });
                });
        }
    }, [searchParams.get('class_id')]);
    useEffect(() => {
        if (classes?.data.classes.length === 0) {
            setStateFilter({
                subject_id: '',
                class_id: currentClassId?.value?.toString() || '',
                quarter: currentQuarter?.value?.toString() || '',
            });
        }
    }, [classes]);
    const filteredOrganizations =
        data?.data.User.organizations?.filter(
            org =>
                (org.role === roles.CLASS_TEACHER ||
                    org.role === roles.SUBJECT_TEACHER) &&
                org?.schools,
        ) || [];

    return (
        <div className="bg-white rounded-2xl min-h-12 flex py-1">
            <div className="items-center flex gap-2 flex-wrap h-full">
                <div className="px-3 flex flex-nowrap items-center shrink-0">
                    <span className="text-[#678AA1] me-2 inline-block">
                        {t('dashboard.role')}:
                    </span>
                    <div className="min-w-44 shrink-0">
                        <SelectBeyim
                            placeholder={t('dashboard.role')}
                            options={filteredOrganizations.map(org => ({
                                value: org.role,
                                label:
                                    org.role === roles.CLASS_TEACHER
                                        ? t('role.class_teacher')
                                        : t('role.subject_teacher'),
                            }))}
                            hasBorder={false}
                            widthFitContent={true}
                            onChange={val => setRole(val)}
                            value={role}
                            size="sm"
                            isSearchable={false}
                        />
                    </div>
                </div>
                <span className="h-4 w-[1px] shrink-0 bg-[#CBDCE7]"></span>
                <div className="px-3 shrink-0 flex flex-nowrap items-center">
                    <span className="me-2 text-[#678AA1] block">
                        {t('dashboard.school')}:
                    </span>
                    <div className="min-w-16 shrink-0">
                        <SelectBeyim
                            className="rounded-2xl shrink-0"
                            hasBorder={false}
                            widthFitContent={true}
                            placeholder={t('dashboard.choiceSchool')}
                            options={
                                currentOrganization?.schools?.map(element => ({
                                    value: element.schoolId,
                                    label:
                                        element.school.translations.find(
                                            (lng: any) => lng.locale === locale,
                                        )?.shortName ||
                                        element.school.translations[0]
                                            ?.shortName,
                                })) || []
                            }
                            onChange={val => setCurrentSchool(val)}
                            value={currentSchool}
                            size="sm"
                            isSearchable={false}
                        />
                    </div>
                </div>
                <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                <div className="px-3 flex flex-nowrap items-center shrink-0">
                    <span className="text-[#678AA1] me-2 inline-block">
                        {t('search.filter.subjects')}:
                    </span>
                    <div className=" shrink-0">
                        <SelectBeyim
                            className="rounded-2xl"
                            placeholder={t('search.filter.subjects')}
                            hasBorder={false}
                            widthFitContent={true}
                            options={
                                role?.value === roles.SUBJECT_TEACHER
                                    ? currentOrganization?.schools
                                          ?.find(
                                              school =>
                                                  school.schoolId ===
                                                  currentSchool?.value,
                                          )
                                          ?.subjects?.map(element => ({
                                              value: element?.subjectId,
                                              label:
                                                  element?.subject.translations.find(
                                                      (lng: any) =>
                                                          lng.locale === locale,
                                                  )?.name ||
                                                  element?.subject
                                                      .translations[0]?.name,
                                          })) || []
                                    : subjects?.data.subjects?.map(element => ({
                                          value: element.id,
                                          label:
                                              element.translations.find(
                                                  (lng: any) =>
                                                      lng.locale === locale,
                                              )?.name ||
                                              element.translations[0].name,
                                      })) || []
                            }
                            onChange={val => setCurrentSubject(val)}
                            value={currentSubject}
                            size="sm"
                            isSearchable={false}
                        />
                    </div>
                </div>
                <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                <div className="px-3 flex flex-nowrap items-center">
                    <span className="me-2 text-[#678AA1] inline-block">
                        {t('dashboard.class')}
                    </span>
                    <div className="min-w-16 shrink-0">
                        <SelectBeyim
                            className="rounded-2xl shrink-0"
                            hasBorder={false}
                            placeholder={'11'}
                            widthFitContent={true}
                            options={
                                role?.value === roles.SUBJECT_TEACHER
                                    ? [
                                          ...(grades?.data?.grades?.map(
                                              element => ({
                                                  value: element?.id,
                                                  label: element?.number,
                                              }),
                                          ) || []),
                                          // { value: -1, label: 'Добавить' },
                                      ]
                                    : [
                                          ...classesArray.map(element => ({
                                              value: element,
                                              label: String(element),
                                          })),
                                          // { value: -1, label: 'Добавить' },
                                      ] || []
                            }
                            onChange={val => {
                                if (val?.value === -1) {
                                    handleChangeModalState();
                                } else {
                                    setCurrentGradeId(val);
                                    setCurrentClassId(null);
                                    searchParams.delete('class_id');
                                    searchParams.delete('subject_id');
                                    searchParams.delete('user_id');
                                    setSearchParams(searchParams);
                                }
                            }}
                            value={currentGradeId}
                            size="sm"
                            isSearchable={false}
                        />
                    </div>
                </div>
                <ModalComponent
                    isOpen={addClassModal}
                    onClose={handleChangeModalState}
                    content={'Добавление класса'}
                    customContent={
                        <AddClassToTeacher
                            role={currentOrganization?.role as roles}
                        />
                    }
                    classNameModal={style.customModalStyles}
                    classNameTitle={style.customTitleStyles}
                    classNameContent={style.customContentStyles}
                    classNameFooter={style.customFooterStyles}
                />
                <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                <div className="px-3 flex flex-nowrap items-center">
                    <span className="me-2 text-[#678AA1] inline-block">
                        {t('dashboard.letter')}
                    </span>
                    <div className="min-w-16 shrink-0">
                        <SelectBeyim
                            className="rounded-2xl shrink-0"
                            hasBorder={false}
                            placeholder={'a'}
                            widthFitContent={true}
                            options={
                                role?.value === roles.SUBJECT_TEACHER
                                    ? classes?.data.classes?.map(element => ({
                                          value: element.id,
                                          label: element.letter,
                                      })) || []
                                    : currentOrganization?.schools
                                          ?.find(
                                              school =>
                                                  school.schoolId ===
                                                  currentSchool?.value,
                                          )
                                          ?.classes?.filter(
                                              cls =>
                                                  cls.class.grade.number ===
                                                  currentGradeId?.value,
                                          )
                                          .map(cls => ({
                                              value: cls.classId,
                                              label: cls.class.letter,
                                          }))
                                //  [
                                //       {
                                //           value: currentOrganization?.schools
                                //               ?.find(
                                //                   school =>
                                //                       school.schoolId ===
                                //                       currentSchool?.value,
                                //               )
                                //               ?.classes?.find(
                                //                   cls =>
                                //                       cls.class?.gradeId ===
                                //                       currentGradeId?.value,
                                //               )?.classId,
                                //           label: currentOrganization?.schools
                                //               ?.find(
                                //                   school =>
                                //                       school.schoolId ===
                                //                       currentSchool?.value,
                                //               )
                                //               ?.classes?.filter(
                                //                   cls =>
                                //                       cls.class.grade.number ===
                                //                       currentGradeId?.value,
                                //               )?.class.letter,
                                //       },
                                //   ]
                            }
                            onChange={val => setCurrentClassId(val)}
                            value={currentClassId}
                            noOptionsMessage={() => t('dashboard.noLetters')}
                            size="sm"
                            isSearchable={false}
                        />
                    </div>
                </div>
                <span className="h-4 w-[1px] bg-[#CBDCE7]"></span>
                <div className="px-3 flex flex-nowrap items-center">
                    <span className="me-2 text-[#678AA1] inline-block">
                        {t('dashboard.quarter')}
                    </span>
                    <div className="min-w-16 shrink-0">
                        <SelectBeyim
                            className="rounded-2xl shrink-0"
                            hasBorder={false}
                            widthFitContent={true}
                            placeholder={'1'}
                            options={[
                                { value: 1, label: 1 },
                                { value: 2, label: 2 },
                                { value: 3, label: 3 },
                                { value: 4, label: 4 },
                            ]}
                            onChange={val => setCurrentQuarter(val)}
                            value={currentQuarter}
                            size="sm"
                            isSearchable={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
