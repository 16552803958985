// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import Cookies from 'js-cookie';
import { uploadImageUserResponseApiType } from '../../types/uploadImageUserResponseApiType';

export const storageServiceApi = createApi({
    reducerPath: 'storageServiceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_STORAGE_SERVICE_URL}/`,
        prepareHeaders: headers => {
            const token = Cookies.get('access_token');
            const id_token = Cookies.get('id_token');

            // If we have a token set in state, let's assume that we should be passing it.
            if (token && id_token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('X-Api-Key', id_token);
            }

            return headers;
        },
    }),

    endpoints: build => ({
        uploadImageUser: build.mutation<
            uploadImageUserResponseApiType,
            {
                file: FormData;
                id: string;
            }
        >({
            query: ({ file, id }) => ({
                url: `users/${id}/upload`,
                method: 'POST',
                body: file,
            }),
        }),
    }),
});

export const { useUploadImageUserMutation } = storageServiceApi;
