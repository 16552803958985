import Skeleton from 'react-loading-skeleton';

export const SkeletonQuestion = ({
    count = 1,
    type,
}: {
    count?: number;
    type?: 'results' | 'passing';
}) => {
    return (
        <>
            {Array.from({ length: count }).map((_, index) => (
                <article
                    key={index}
                    className={`flex flex-col p-6 rounded-[16px] max-w-[592px] w-full mx-auto my-0 bg-white h-[350px]`}
                >
                    <Skeleton
                        count={1}
                        baseColor="#e9f0f3"
                        height={20}
                        width={80}
                        borderRadius={10}
                        className="mb-2"
                    />

                    <Skeleton
                        count={1}
                        baseColor="#e9f0f3"
                        height={20}
                        borderRadius={10}
                        className="mb-3"
                    />

                    <Skeleton
                        count={4}
                        baseColor="#e9f0f3"
                        height={56}
                        borderRadius={10}
                    />
                </article>
            ))}
        </>
    );
};
