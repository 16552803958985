import React from 'react';
import style from './NotificationBlock.module.scss';

interface NotificationBlockProps
    extends React.AllHTMLAttributes<HTMLDivElement> {
    type?: 'warning' | 'success' | 'error';
    title?: string;
    description?: string;
    icon?: any;
}

export const NotificationBlock: React.FC<NotificationBlockProps> = ({
    type = 'warning',
    title = 'Title',
    description = 'Description',
    icon,
    ...props
}: NotificationBlockProps) => {
    return (
        <div className={`${style.root} ${style[type]}`} {...props}>
            {icon && <img className="w-10 h-10" src={icon} alt="Lock" />}
            <div className={style.info}>
                <h3 className={style.title}>{title}</h3>
                <h6 className={style.description}>{description}</h6>
            </div>
        </div>
    );
};
