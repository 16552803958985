import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassStatus } from '../../../../../../features/ClassStatus';
import { useNormalizedLocale } from '../../../../../../hooks';
import { TopicInfo } from '../../../../../../api/progressService/types';

interface IAccordionRow {
    topic: TopicInfo;
    index: number;
    classId: string;
    subjectId: string;
}

export const AccordionRow: FC<IAccordionRow> = ({
    topic,
    index,
    classId,
    subjectId,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const locale = useNormalizedLocale();
    const handleClick = () => {
        setIsOpen(prev => !prev);
    };
    return (
        <>
            {!!topic?.classProgress && (
                <tr className="cursor-pointer">
                    <th
                        onClick={handleClick}
                        scope="row"
                        className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 py-4 h-12 !bg-white text-[#678AA1] text-sm font-medium leading-[normal]"
                    >
                        {index + 1}
                    </th>
                    <td
                        onClick={handleClick}
                        className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]"
                    >
                        {topic.translations.find(item => item.locale === locale)
                            ?.name || topic.translations[0].name}
                    </td>
                    <td
                        onClick={handleClick}
                        className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]"
                    >
                        {topic?.classProgress?.studentsPassedCount}/{' '}
                        {topic?.classProgress?.studentsCount}
                    </td>
                    <td
                        onClick={handleClick}
                        className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white !pointer-events-autobg-white text-[#3D5463] text-sm font-medium leading-[normal]"
                    >
                        {(
                            (topic?.classProgress?.averageScore /
                                topic?.classProgress?.totalScore) *
                            100
                        ).toFixed()}
                        %
                    </td>

                    <td
                        onClick={handleClick}
                        className="border-[#E9F0F3] border-b-[1px] border-t-[1px]  border-x-0 ps-0 pe-2 py-2 h-12 !bg-white text-[#3D5463] text-sm font-medium leading-[normal]"
                    >
                        {isOpen ? (
                            <button className="h-8  py-1 rounded-lg text-sm flex text-[#364954] items-center">
                                {t('dashboardResults.hide')}
                                <img src="/icons/arrowDown.svg" alt="arrow" />
                            </button>
                        ) : (
                            <button className="h-8  py-1 rounded-lg text-sm flex text-[#364954 items-center">
                                {t('dashboardResults.reveal')}
                                <img src="/icons/arrowDown.svg" alt="arrow" />
                            </button>
                        )}
                    </td>
                </tr>
            )}

            {topic?.classProgress && isOpen && (
                <tr>
                    <td
                        colSpan={5}
                        className="pt-0 px-0  pb-4 !bg-white border-t-0 border-x-0 border-[#E9F0F3]"
                    >
                        <ClassStatus
                            students={topic?.classProgress?.students}
                            classId={classId}
                            subjectId={subjectId}
                        />
                    </td>
                </tr>
            )}
        </>
    );
};
