import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../hooks';
import { Button } from '../../../../../AuthForms/components/ButtonComponent';
import classNames from 'classnames';
import { closeModal } from '../../../../../../ui/BeyimModal/modalSlice/modalSlice';
import style from './ModalContent.module.scss';

export const ModalContent = ({ href }: { href?: string }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <>
            <header className="mb-2">
                <h2 className="text-[#232F37] text-lg font-semibold leading-[normal]">
                    {t('assessment.modalBack.header')}
                </h2>
            </header>
            <div className="mb-8">
                <p className="text-[#364954] text-base font-normal  leading-[normal]">
                    {t('assessment.modalBack.content')}
                </p>
            </div>
            <footer className="w-full flex flex-row justify-between gap-4">
                <Button
                    variant="disabled"
                    className={classNames('!cursor-pointer', style.active)}
                    onClick={() => {
                        dispatch(closeModal());
                        navigate(href || '/');
                    }}
                    text={t('assessment.modalBack.logout')}
                />

                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(closeModal());
                    }}
                    text={t('assessment.modalBack.continue')}
                />
            </footer>
        </>
    );
};
