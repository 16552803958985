// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { GetMicrotopicResponseApiType } from '../../types/microtopicsApiType/GetMicrotopicResponseApiType';
import { GetMicrotopicRequestApiType } from '../../types/microtopicsApiType/GetMicrotopicRequestApiType';

export const microtopicsApi = createApi({
    reducerPath: 'microtopicsApi',
    tagTypes: ['MicrotopicsApi'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_PROGRAMS_SERVICE_URL}/api/v1/`,
        prepareHeaders: headers => {
            const token = Cookies.get('access_token');
            const id_token = Cookies.get('id_token');

            // If we have a token set in state, let's assume that we should be passing it.
            if (token && id_token) {
                headers.set('authorization', `Bearer ${token}`);
                headers.set('X-Api-Key', id_token);
            }

            return headers;
        },
    }),

    endpoints: build => ({
        getAllMicrotopics: build.query<
            GetMicrotopicResponseApiType,
            GetMicrotopicRequestApiType
        >({
            query: ({
                section_id,
                subject_id,
                page,
                objective_id,
                limit = 10,
                quarter,
                grade_id,
                sort,
                activeView,
                locale,
            }) => {
                return {
                    url: `microtopics?${limit ? `limit=${limit}` : ''}${
                        page ? `&offset=${page * limit}` : ''
                    }${subject_id ? `&subject_id=${subject_id}` : ''}${
                        section_id ? `&section_id=${section_id}` : ''
                    }${objective_id ? `&objective_id=${objective_id}` : ''}${
                        quarter ? `&quarter=${quarter}` : ''
                    }${grade_id ? `&grade_id=${grade_id}` : ''}${
                        sort ? `&sort=${sort}` : ''
                    }`,
                };
            },
            providesTags: ['MicrotopicsApi'],
        }),
    }),
});

export const { useGetAllMicrotopicsQuery, useLazyGetAllMicrotopicsQuery } =
    microtopicsApi;
