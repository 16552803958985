import { useEffect, useState } from 'react';
import {
    useDeleteBookmarkMutation,
    usePostBookmarkMutation,
} from '../../api/contentInteractionApi';
import classNames from 'classnames';
import style from './Bookmark.module.scss';
import { useNormalizedLocale } from '../../hooks/useNormalizedLocale';
import { useGetSectionsBySubjectQuery } from '../../api/programService';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetFooterDataByPostIdsQuery } from '../../api/contentInteractionApi/contentInteractionApi';
import { useAppDispatch } from '../../hooks';
import { contentServiceApi } from '../../api/contentService';

interface IBookmark {
    postId: string;
    postType?: 'activity' | 'post';
    microtopic?: string | undefined;
    subject?: string | undefined;
    category?: string | undefined;
    title?: string | undefined;
    isBookmarkedPost?: boolean;
    bookmarkCount?: number;
}

export const Bookmark: React.FC<IBookmark> = ({
    postId,
    postType = 'post',
    microtopic,
    subject,
    category,
    title,
    isBookmarkedPost,
    bookmarkCount,
}) => {
    const locale = useNormalizedLocale();
    const [searchParams] = useSearchParams();

    const [isBooked, setIsBooked] = useState<boolean>(false);

    const [getCount, { data }] = useLazyGetFooterDataByPostIdsQuery();
    const [postBookmark] = usePostBookmarkMutation();
    const [deleteBookmark] = useDeleteBookmarkMutation();

    const { data: oneSectionInfo } = useGetSectionsBySubjectQuery(
        {
            subject_id: searchParams.get('subject') || '',
            limit: 100,
        },
        {
            skip: !searchParams.get('subject'),
        },
    );

    useEffect(() => {
        if (isBookmarkedPost) {
            setIsBooked(true);
        } else {
            setIsBooked(false);
        }
    }, [isBookmarkedPost]);

    const sectionsBySubject = searchParams.get('sectionsBySubject');
    const dispath = useAppDispatch();
    return (
        <beyim-xapi
            type="button"
            verb={!isBooked ? 'favourited' : 'unFavourited'}
            language={locale}
            name={microtopic ? microtopic : title || 'name'}
            contentType={category || postType || 'post'}
            subject={subject || 'subject'}
            backend={process.env.REACT_APP_BACKEND_XAPI}
            section={
                sectionsBySubject
                    ? oneSectionInfo?.data.sections
                          ?.find(section => section.id === +sectionsBySubject)
                          ?.translations.find(
                              section => section.locale === locale,
                          )?.name
                    : oneSectionInfo?.data.sections[0]?.translations.find(
                          section => section.locale === locale,
                      )?.name
            }
        >
            <button
                type="button"
                className="flex gap-2 items-center h-7 justify-between"
                onClick={() => {
                    if (isBooked) {
                        deleteBookmark({ postId })
                            .unwrap()
                            .then(() => {
                                getCount({ postIds: [postId] });
                                dispath(
                                    contentServiceApi.util.invalidateTags([
                                        'bookmark',
                                    ]),
                                );
                            })
                            .catch(() => {
                                setIsBooked(false);
                            });
                        setIsBooked(false);
                    } else {
                        postBookmark({ postId, postType })
                            .unwrap()
                            .then(() => {
                                setIsBooked(true);
                                getCount({ postIds: [postId] });
                                dispath(
                                    contentServiceApi.util.invalidateTags([
                                        'bookmark',
                                    ]),
                                );
                            });
                    }
                }}
            >
                <img
                    src={
                        isBooked
                            ? '/icons/bookmarkActive.svg'
                            : '/icons/bookmark.svg'
                    }
                    alt="bookmark"
                    className="block h-7 w-7"
                />{' '}
                <span
                    className={classNames(
                        'text-base font-bold ',
                        style.count,
                        isBooked ? style.active : '',
                    )}
                >
                    {data
                        ? data.data.postFeed[0]?.bookmarkCount === 0
                            ? ''
                            : data.data.postFeed[0]?.bookmarkCount
                        : bookmarkCount === 0
                          ? ''
                          : bookmarkCount}
                </span>
            </button>
        </beyim-xapi>
    );
};
