import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { GetAssessmentInfoResponseApiType } from '../../../../types/beyimAssessmentApiType/GetAssessmentInfoResponseApiType';

type Props = {
    assessmentInfo: GetAssessmentInfoResponseApiType | undefined;
    searchParams: URLSearchParams;
    closeSectionMobile?: () => void;
};

const AssessmentStar = ({
    assessmentInfo,
    searchParams,
    closeSectionMobile,
}: Props) => {
    return (
        <Link
            activeClass="active"
            to="assessment"
            spy={true}
            smooth={true}
            duration={800}
        >
            {assessmentInfo?.data.assessment?.subject_id ===
                Number(searchParams.get('subject')) &&
                assessmentInfo.data.assessment?.is_available && (
                    <img
                        data-tooltip-id="assessment"
                        onClick={() => {
                            closeSectionMobile && closeSectionMobile();
                        }}
                        src={
                            !assessmentInfo?.data.assessment?.progress
                                ? `/icons/activity-lightning-gray.svg`
                                : assessmentInfo?.data.assessment?.progress
                                        .percentage >=
                                    assessmentInfo?.data.assessment?.threshold
                                  ? `/icons/activity-lightning-green.svg`
                                  : `/icons/activity-lightning-red.svg`
                        }
                        alt="Alt"
                    />
                )}
        </Link>
    );
};

export default AssessmentStar;
