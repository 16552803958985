import classNames from 'classnames';
import style from './SmartSearch.module.scss';
import ReactModal from 'react-modal';
import { useEffect, useRef, useState } from 'react';
import {
    useGetAllSubjectsQuery,
    useGetSearchGlobalQuery,
} from '../../api/programService';
import { useTranslation } from 'react-i18next';
import { useGetFeedQuery } from '../../api/contentService';
import { SearchItem } from '../SearchItem/SearchItem';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { EmptyResult } from './utils';
import useDebounce from '../../hooks/useDebounce';
import Skeleton from 'react-loading-skeleton';
import { SearchPostSkeleton } from '../../ui/SearchPostSkeleton';
import { useNormalizedLocale } from '../../hooks';
import { LanguageType } from '../../types';
import useResposive, { breakpoints } from '../../hooks/useResponsive';
import { SearchFilterForm } from './utils/SearchFilterForm';
import { useGetUserDataQuery } from '../../api/authService/authServiceApi';
import { roles } from '../AuthForms/constants';

export type TypeInclude = 'video' | 'image';

export interface IFilter {
    subjects: string[];
    typeResources: TypeInclude[];
    locale: LanguageType;
}

interface ISmartSearch {
    changeSearch?: (value: boolean) => void;
    closeSearch?: () => void;
    isOpenSearch?: boolean;
}

export const SmartSearch: React.FC<ISmartSearch> = ({
    changeSearch,
    closeSearch,
    isOpenSearch,
}) => {
    const { t } = useTranslation(); //локализация

    const locale = useNormalizedLocale();
    const windowSize = useResposive();
    const isDesktop = windowSize.width > breakpoints.md;
    const currentRouter = useLocation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const ref = useRef<HTMLElement>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [isOpenGallery, setIsOpenGallery] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilter>();
    const [isFocused, setIsFocused] = useState(false);
    const [searchParams] = useSearchParams();

    const openId = searchParams.get('idContent');
    const { data: myData } = useGetUserDataQuery('');

    const methods = useForm<IFilter>();
    const onSubmit: SubmitHandler<IFilter> = data => {
        setIsOpenFilter(false);
        setFilter(data);
    };

    const debouncedSearchTerm = useDebounce(value, 300);

    const { data: dataIdSearch, isFetching: isFetchingId } =
        useGetSearchGlobalQuery(
            {
                input: debouncedSearchTerm,
            },
            {
                skip: !debouncedSearchTerm,
            },
        );

    const { data: dataContentSearch, isFetching: isFetchingContent } =
        useGetFeedQuery(
            {
                limit: 1000,
                microtopicIds: dataIdSearch?.data.result || [],
                subjectIds: filter?.subjects.filter(item => item),
                locale: filter?.locale ? filter?.locale : locale,
                // typeResources: filter?.typeResources
                //     ? filter?.typeResources.filter(item => item)
                //     : [],
                typeResources: ['image'],
            },
            {
                skip: !dataIdSearch,
            },
        );

    useEffect(() => {
        if (value != '') {
            if (changeSearch) {
                changeSearch(false);
            }
            setIsOpen(true);
            document.body.style.overflow = 'hidden';

            if (inputRef) {
                inputRef.current?.focus();
            }
        } else {
            if (changeSearch) {
                changeSearch(true);
            }

            setIsOpen(false);
            document.body.style.overflow = 'visible';
        }
    }, [value, currentRouter]);

    //нужно для того что бы отслеживать открывался пост подробнее или нет, это помогает исправлять скролл страницы
    useEffect(() => {
        if (value != '') {
            if (!searchParams.get('idContent')) {
                document.body.style.overflow = 'hidden';
            }
        } else {
            if (changeSearch) {
                changeSearch(true);
            }

            setIsOpen(false);
        }
    }, [openId, document.body.style.overflow]);

    useEffect(() => {
        if (isOpenSearch && inputRef.current) {
            setIsFocused(true);
            inputRef.current?.focus();
        }
    }, [inputRef.current, isOpenSearch]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                isOpenFilter &&
                ref.current &&
                !ref.current.contains(event.target as Node) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node)
            ) {
                setIsOpenFilter(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, isOpenFilter]);

    return (
        <div className="md:w-[322px] w-full">
            <div className="relative">
                <input
                    type="text"
                    className={classNames(
                        'block w-full h-9 text-sm  border-0 rounded-xl sm:rounded-lg p-2 bg-no-repeat pl-[35px] bg-[length:16px_16px]',
                        style.input,
                        isFocused || value ? style.inputWrapper : '',
                        locale === 'ar'
                            ? `pr-[35px] ${style.arLng}`
                            : 'pl-[35px]',
                    )}
                    value={value}
                    ref={inputRef}
                    onChange={e => {
                        const value = e.target.value;
                        if (
                            !myData?.data?.User?.organizations?.some(
                                org => org?.role === roles.EXPERT,
                            )
                        ) {
                            setValue(value || '');
                        }
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={t('search.placeholder')}
                />
                {value !== '' && (
                    <img
                        className={classNames(
                            'w-5 h-5 absolute top-2 ',
                            locale === 'ar' ? `left-1` : 'right-1',
                        )}
                        src="/icons/cancel.svg"
                        alt="cancel"
                        onClick={() => {
                            if (changeSearch) {
                                changeSearch(true);
                            }
                            closeSearch && closeSearch();
                            setValue('');
                            setIsOpen(false);

                            setIsOpenGallery(false);
                            document.body.style.overflow = 'visible';
                        }}
                    />
                )}
            </div>

            <ReactModal
                isOpen={true}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                shouldCloseOnEsc={false}
                style={{
                    overlay: {
                        backgroundColor: '#E9F0F3',
                        zIndex: 1002,
                        padding: 0,
                        top: 48,
                        height:
                            isOpen || isOpenSearch ? 'calc(100vh - 48px)' : 0,
                        opacity: isOpen || isOpenSearch ? 1 : 0,
                    },
                    content: {
                        inset: 0,
                        backgroundColor: '#E9F0F3',
                        border: 0,
                        overflow: isOpenGallery ? 'hidden' : 'auto',
                        padding: 0,
                        height: isOpen
                            ? windowSize.width < breakpoints.md
                                ? 'calc(100vh - 110px)'
                                : 'calc(100vh - 56px)'
                            : 0,
                    },
                }}
            >
                {isFetchingContent || isFetchingId ? (
                    <SearchPostSkeleton />
                ) : (
                    <div
                        className={classNames(
                            'container  grid gap-4 pt-6 grid-cols-12',
                        )}
                    >
                        {dataIdSearch?.data.result.length === 0 ? (
                            <EmptyResult value={value} />
                        ) : (
                            <div className="md:col-span-8 col-span-12 md:col-start-3 flex gap-4 flex-col">
                                <div className="z-[1000] relative flex justify-between  px-2 md:px-0 ">
                                    <h2
                                        className={classNames(
                                            'text-xl font-medium',
                                        )}
                                    >
                                        {(dataContentSearch?.data.posts &&
                                            dataContentSearch?.data.posts
                                                .length) ||
                                            0}{' '}
                                        {t('search.empty.valueTitleFirst')}{' '}
                                        <span
                                            className={classNames(
                                                'text-xl font-medium',
                                                style.blue,
                                            )}
                                        >
                                            {' '}
                                            &quot;{value}&quot;
                                        </span>
                                        <span className="text-xl font-medium">
                                            {' '}
                                            {t('search.empty.valueTitleSecond')}
                                        </span>
                                    </h2>

                                    <button
                                        className={classNames(
                                            'flex md:px-6 px-2 items-center gap-1 rounded-xl md:border-2 h-10',
                                            style.buttonFilter,
                                        )}
                                        ref={buttonRef}
                                        onClick={() =>
                                            setIsOpenFilter(!isOpenFilter)
                                        }
                                        data-tooltip-id="filter-search"
                                        data-tooltip-offset={8}
                                    >
                                        <img
                                            src="/icons/filter.svg"
                                            alt="filter"
                                        />
                                        <span className="text-base hidden md:block">
                                            {t('search.filter.title')}
                                        </span>
                                    </button>
                                    {/* <Tooltip
                                        id="filter-search"
                                        openOnClick={true}
                                        clickable={true}
                                        noArrow={true}
                                        opacity={1}
                                        place={'bottom-end'}
                                        className={classNames(
                                            style.wrapperFilter,
                                        )}
                                        setIsOpen={() => {
                                            console.log(ref.current, 1234);
                                            ref.current?.click();
                                        }}
                                    > */}
                                    <FormProvider {...methods}>
                                        {isDesktop && isOpenFilter && (
                                            <div className="absolute top-[52px] right-0">
                                                <aside
                                                    ref={ref}
                                                    className={classNames(
                                                        'px-4 pt-5 rounded-2xl max-w-[393px] border-2',
                                                        style.blockFilter,
                                                    )}
                                                >
                                                    <form
                                                        onSubmit={methods.handleSubmit(
                                                            onSubmit,
                                                        )}
                                                    >
                                                        <SearchFilterForm />
                                                    </form>
                                                </aside>
                                            </div>
                                        )}
                                        {!isDesktop && (
                                            <ReactModal
                                                isOpen={isOpenFilter}
                                                shouldCloseOnOverlayClick={true}
                                                ariaHideApp={true}
                                                shouldCloseOnEsc={true}
                                                onRequestClose={() => {
                                                    setIsOpenFilter(false);
                                                }}
                                                style={{
                                                    overlay: {
                                                        backgroundColor:
                                                            'rgba(35, 47, 55, 0.80)',
                                                        zIndex: 4000,
                                                        padding: 0,
                                                        top: 49,
                                                        height: isOpenFilter
                                                            ? 'calc(100vh - 49px)'
                                                            : 0,
                                                        opacity: isOpenFilter
                                                            ? 1
                                                            : 0,
                                                        transition: '0.2s',
                                                    },
                                                    content: {
                                                        inset: 0,
                                                        backgroundColor: '#FFF',
                                                        border: 0,
                                                        overflow: 'auto',
                                                        padding: 0,
                                                        height: isOpenFilter
                                                            ? '70vh'
                                                            : 0,
                                                        borderRadius:
                                                            '24px 24px 0 0',
                                                        transition: '0.2s',
                                                        marginTop: 'auto',
                                                        paddingBottom: '110px',
                                                    },
                                                }}
                                            >
                                                <form
                                                    onSubmit={methods.handleSubmit(
                                                        onSubmit,
                                                    )}
                                                >
                                                    <SearchFilterForm
                                                        setIsOpen={
                                                            setIsOpenFilter
                                                        }
                                                    />
                                                </form>
                                            </ReactModal>
                                        )}
                                    </FormProvider>
                                    {/* </Tooltip> */}
                                </div>

                                {dataContentSearch?.data.posts !== null &&
                                    dataContentSearch?.data.posts?.map(item => {
                                        return (
                                            <SearchItem
                                                key={item.id}
                                                data={item}
                                                openGallery={() => {
                                                    setIsOpenGallery(true);
                                                }}
                                                closeGallery={() =>
                                                    setIsOpenGallery(false)
                                                }
                                            />
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                )}
            </ReactModal>
            {/* )} */}
        </div>
    );
};
