import classNames from 'classnames';
import style from './TypePost.module.scss';
import Skeleton from 'react-loading-skeleton';

export const TypePost: React.FC<{
    title: string | undefined;
    typePost: string;
    isLoading?: boolean;
}> = ({ title, typePost, isLoading }) => {
    return (
        <div className="flex justify-center flex-col min-w-[120px] sm:min-w-[200px]">
            {isLoading ? (
                <Skeleton
                    count={2}
                    baseColor="#e9f0f3"
                    height={20}
                    width={150}
                    borderRadius={10}
                />
            ) : (
                <>
                    <h2
                        className={classNames(
                            ' overflow-hidden text-ellipsis text-base font-bold line-clamp-1 m-0',
                            style.title,
                        )}
                    >
                        {title}
                    </h2>
                    <span
                        className={classNames(
                            'text-xs font-medium whitespace-nowrap',
                            style.typePost,
                        )}
                    >
                        {typePost}
                    </span>
                </>
            )}
        </div>
    );
};
